var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " Não Cadastrados";
},"3":function(container,depth0,helpers,partials,data) {
    return "-new";
},"5":function(container,depth0,helpers,partials,data) {
    return "Não Cadastrados";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<header class=\"page-header view-according-device\">\n      <div class=\"bs-callout bs-callout-default\">\n        <i aria-hidden=\"true\" class=\"fa fa-cart-arrow-down\"></i>\n        <h4>Gestão de Materiais</h4>\n\n        <div class=\"view-options\">\n          <button type=\"button\" class=\"btn btn-default btn-dropdown-view dropdown-toggle\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n            <span style=\"text-decoration:underline\">"
    + container.escapeExpression(((helper = (helper = helpers.viewName || (depth0 != null ? depth0.viewName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"viewName","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isNewMaterials : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</span>\n            <span class=\"caret\"></span>\n            <span class=\"sr-only btn-default\">Toggle Dropdown</span>\n          </button>\n          <ul class=\"dropdown-menu dropdown-menu-right\">\n            <li><a class=\"dropdown-options view-materials-orders\">Encomendas de Materiais</a></li>\n            <li><a class=\"dropdown-options view"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isNewMaterials : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "-materials-requests\">Necessidades de Materiais "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isNewMaterials : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</a></li>\n          </ul>\n        </div>\n      </div>\n\n      <div class=\"pull-right\" style=\"margin:10px 0 10px 10px\">\n            <button class=\"btn btn-primary add-new-material\" data-toggle=\"modal\" data-target=\"#helperModal\">\n              <i class=\"fa fa-plus-circle\" aria-hidden=\"true\"  style=\"display:inline\"></i>\n              <span style='color: white'>Material</span>\n          </button>\n\n          <button class=\"btn btn-primary add-to-material-order\" style=\"display:none\">\n              <i class=\"fa fa-plus-circle\" aria-hidden=\"true\" style=\"display:inline\"></i>\n              <span style='color: white'>Encomenda</span>\n          </button>\n      </div>\n</header>\n\n<section class=\"content view view-according-device\"></section>\n\n<section class=\"rotate-phone\" align=\"center\" style=\"margin-top:40px\">\n  <i class=\"fa fa-repeat fa-5x\" aria-hidden=\"true\" style=\"margin-bottom:20px\"></i>\n  <p>Por favor rode o telefone</p>\n</section>\n\n<div class=\"modal-content\">\n  <form action=\"\" class=\"create\"></form>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}