(function () {
    'use strict';

    var api = require('shim/api'),

        Radio = require('shim/radio');

    module.exports = Backbone.Model.extend({
        urlRoot: api.urlRoot + '/orders',
        store: function () {
            return ['event', this.get('id')].join('/');
        },

        defaults: {
            order_category_slug: 'event'
        },

        validation: {
            title: {
                required: true,
                msg: 'Por favor insira uma descrição'
            },

            start_date: [
                {
                    required: true,
                    msg: 'Por favor escolha uma data de início'
                },
                {
                    dateValidator: 'start'
                }
            ],

            start_time: [
                {
                    required: true,
                    msg: 'Por favor escolha uma hora de início'
                },
                {
                    timeValidator: 'start'
                }
            ],

            end_date: [
                {
                    required: true,
                    msg: 'Por favor escolha uma data de fim'
                },
                {
                    dateValidator: 'end'
                }
            ],

            end_time: [
                {
                    required: true,
                    msg: 'Por favor escolha uma hora de fim'
                },
                {
                    timeValidator: 'end'
                }
            ]
        },

        save: function (attrs, options) {
            options = options || {};
            attrs = attrs || _.clone(this.attributes);

            attrs = _.pick(attrs, ['title', 'order_category_id', 'user_ids']);

            attrs.start_date_preview = [this.get('start_date'), this.get('start_time')].join(' ');
            attrs.end_date_preview = [this.get('end_date'), this.get('end_time')].join(' ');
            attrs.order_category_id = Radio.global.request('category:id', 'event');

            options.data = JSON.stringify({order: attrs});
            options.contentType = 'application/json';

            return Backbone.Model.prototype.save.call(this, attrs, options);
        }
    });
})();
