(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.AppRouter.extend({
        before: function (route) {
            if (this._routeIsForbidden(route)) {
                return false;
            }

            Radio.global.command('alerts:dismiss');
            Radio.global.command('render:layout');
        },

        routeHasForbidden: function (route) {
            return this.forbidden && _.isObject(this.forbidden) && this.forbidden.hasOwnProperty(route);
        },

        userIsRouteForbidden: function (route, role) {
            return _.contains(this.forbidden[route], role);
        },

        _routeIsForbidden: function (route) {
            var role = this._userRole();

            //If no role is found, it means the user is not logged-in
            if (!role)
                return this._redirect({ resource: 'login', route: 'login' });

            if (this.routeHasForbidden(route) && this.userIsRouteForbidden(route, role)) {
                var defaultRoute = Radio.global.request('app:show:forbiddenRouteRedirect')[role];
                return this._redirect(defaultRoute);
            }

            return false;
        },

        _redirect: function (route) {
            Radio.global.command('router:' + route.router + ':navigate', route.redirect, { trigger: true });
            return true;
        },

        _userRole: function () {
            var user = Radio.global.request('app:session').get('user');
            return user ? user.get('role_slug') : false;
        }
    });
})();
