(function () {
    'use strict';

    module.exports = Backbone.Model.extend({
        idAttribute: '_id',
        validation: {
            report: {
                required: true,
                msg: 'Por favor, indique quantas horas foram feitas nesta unidade'
            },

            collab: {
                required: true,
                msg: 'Por favor, indique se teve colaboração de algum colega'
            },

            verified: {
                required: true,
                msg: 'Por favor, indique se verificou todo o equipamento ou não'
            },

            'collab-report': [
                {
                    required: function (value, attr, model) {
                        return model.collab;
                    },
                    msg: 'Por favor, indique as horas feitas pelo colaborador'
                },
                {
                    fn: function (value, attr, model) {
                        if (_(value).any(_.isEmpty)) {
                            return 'Por favor, indique as horas feitas pelos colaboradores';
                        }

                        var isGreaterThanMain = _(value).any(function (val) {
                            return moment(val, 'HH:mm').isAfter(moment(model.report, 'HH:mm'));
                        });

                        if (isGreaterThanMain) {
                            return 'As horas feitas pelos colaboradores não podem ser maiores que as feitas por si';
                        }
                    }
                }
            ]
        }
    });
})();
