var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <div style=\"\n        position: absolute;\n        right: 1.5%;\n        top: 5%;\n        border-radius: 50%;\n        background-color: #b40043;\n        width: 50px;\n        height: 50px;\n        text-align: center;\n        padding: 14px;\n        color: white;\n        font-weight: bold;\n      \"> "
    + alias4(((helper = (helper = helpers.priority || (depth0 != null ? depth0.priority : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"priority","hash":{},"data":data}) : helper)))
    + "\n        <div class=\"tooltip\">\n          <i class=\"fa fa-info-circle\" aria-hidden=\"true\"></i>\n          <span class=\"tooltiptext\" style=\"right: -10px;\">"
    + alias4(((helper = (helper = helpers.priorityText || (depth0 != null ? depth0.priorityText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"priorityText","hash":{},"data":data}) : helper)))
    + "</span>\n        </div>\n      </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <dt>Tipo</dt>\n        <dd>"
    + container.escapeExpression(((helper = (helper = helpers.work_type_title || (depth0 != null ? depth0.work_type_title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"work_type_title","hash":{},"data":data}) : helper)))
    + "</dd>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <dt>Periocidade</dt>\n        <dd>"
    + container.escapeExpression(((helper = (helper = helpers.period_title || (depth0 != null ? depth0.period_title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"period_title","hash":{},"data":data}) : helper)))
    + "</dd>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "        <dt>Descriçao</dt>\n        <dd class='collapse-text'>"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</dd>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "        <dt>Início previsto</dt>\n        <dd>"
    + container.escapeExpression((helpers.moment || (depth0 && depth0.moment) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.start_date_preview : depth0),{"name":"moment","hash":{"format":"L","lang":"pt"},"data":data}))
    + "</dd>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "        <dt>Fim previsto</dt>\n        <dd>"
    + container.escapeExpression((helpers.moment || (depth0 && depth0.moment) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.end_date_preview : depth0),{"name":"moment","hash":{"format":"L","lang":"pt"},"data":data}))
    + "</dd>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <dt>Tempo previsto</dt>\n        <dd>"
    + container.escapeExpression(((helper = (helper = helpers.duration || (depth0 != null ? depth0.duration : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"duration","hash":{},"data":data}) : helper)))
    + "</dd>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <dt>Tempo real</dt>\n        <dd>"
    + container.escapeExpression(((helper = (helper = helpers.spentTime || (depth0 != null ? depth0.spentTime : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"spentTime","hash":{},"data":data}) : helper)))
    + " </dd>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.zone_title || (depth0 != null ? depth0.zone_title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"zone_title","hash":{},"data":data}) : helper)));
},"19":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.rootzone_title || (depth0 != null ? depth0.rootzone_title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"rootzone_title","hash":{},"data":data}) : helper)));
},"21":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <dt>Estado</dt>\n        <dd>"
    + container.escapeExpression(((helper = (helper = helpers.state_title || (depth0 != null ? depth0.state_title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"state_title","hash":{},"data":data}) : helper)))
    + "</dd>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.users || (depth0 != null ? depth0.users : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"users","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.users) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"24":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                "
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)))
    + ";<br>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.user_group_title : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                "
    + container.escapeExpression(((helper = (helper = helpers.user_group_title || (depth0 != null ? depth0.user_group_title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"user_group_title","hash":{},"data":data}) : helper)))
    + "\n";
},"29":function(container,depth0,helpers,partials,data) {
    return "                N/A\n";
},"31":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <dt>Notas OT</dt>\n        <dd>"
    + container.escapeExpression(((helper = (helper = helpers.notes || (depth0 != null ? depth0.notes : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"notes","hash":{},"data":data}) : helper)))
    + "</dd>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <dt>Criado por</dt>\n    <dd>"
    + container.escapeExpression(((helper = (helper = helpers.created_by || (depth0 != null ? depth0.created_by : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"created_by","hash":{},"data":data}) : helper)))
    + "</dd>\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "Sim";
},"37":function(container,depth0,helpers,partials,data) {
    return "Não";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<dl class='dl-horizontal' style=\"position: relative\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.priority : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.work_type_title : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.period_title : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.start_date_preview : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.end_date_preview : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.duration : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.spentTime : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <dt>Localização</dt>\n    <dd>\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.zone_title : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    </dd>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.state_title : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <dt>Assignado a</dt>\n    <dd>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.users : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(26, data, 0),"data":data})) != null ? stack1 : "")
    + "    </dd>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.notes : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.created_by : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <dt>Notas Unidade(s)</dt>\n    <dd>\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasNotesInUnits : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    </dd>\n\n    <dt>Imagens em tarefas?</dt>\n    <dd>\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasImagesOnTasks : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    </dd>\n</dl>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}