/* global module, require, Backbone */
(function () {
  'use strict';

  module.exports = Marionette.ItemView.extend({

    template:   require('templates/calendars/calendar-day-empty'),
    tagName:    'div'

  });

})();
