(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.Behavior.extend({
        events: {
            'change @ui.date_begin_input': 'onBeginDateChanged',
            'change @ui.date_end_input': 'onEndDateChanged',
            'keypress @ui.date_begin_input': '_preventFilterReset',
            'keypress @ui.date_end_input': '_preventFilterReset',
            'click @ui.reset_filters': '_resetDateFilter'
        },

        onBeginDateChanged: function () {
            var dates = this._checkBeginEnd(this._getCurrentFilters());
            this._saveDates(dates);
            Radio.global.trigger('filter:orders:change', dates);
        },

        onEndDateChanged: function () {
            var dates = this._checkEndBegin(this._getCurrentFilters());
            this._saveDates(dates);
            Radio.global.trigger('filter:orders:change', dates);
        },

        _getCurrentFilters: function () {
            //Check year
            var begin_date = $('#date_begin_input').val(),
                end_date = $('#date_end_input').val(),
                cYear = moment().get('year'),
                maxYear = cYear + 30;

            if (begin_date && moment(begin_date).get('year') > maxYear) {
                begin_date = moment(begin_date).set('year', cYear).format('YYYY-MM-DD');
                $('#date_begin_input').val(begin_date);
            }

            if (end_date && moment(end_date).get('year') > maxYear) {
                end_date = moment(end_date).set('year', cYear).format('YYYY-MM-DD');
                $('#date_end_input').val(end_date)
            }

            return {
                begin_date: begin_date,
                end_date: end_date
            };
        },

        _checkBeginEnd: function (dates) {
            var begin = moment(dates.begin_date),
                end = moment(dates.end_date);

            if (!end.isValid() || end.isBefore(begin)) {
                dates.end_date = dates.begin_date;
                $('#date_end_input').val(dates.end_date)
            }

            return dates;
        },

        _checkEndBegin: function (dates) {
            var begin = moment(dates.begin_date),
                end = moment(dates.end_date);

            if (!begin.isValid() || begin.isAfter(end)) {
                dates.begin_date = dates.end_date;
                $('#date_end_input').val(dates.begin_date);
            }

            return dates;
        },

        _preventFilterReset: function (e) {
            //Pressing enter at the date fileds, for some reason triggers the filter reset function,
            //so this is to prevent dat
            if (e.keyCode == 13) { //Enter key
                e.preventDefault();
                return false;
            }

        },

        _saveDates: function(dates){
            var filters = Radio.global.request('app:filters');
            filters.begin_date = dates.begin_date;
            filters.end_date = dates.end_date;
        },

        _resetDateFilter: function(){
            var filters = Radio.global.request('app:filters');
            filters.begin_date = filters.end_date = -1;
        }

    });
})();
