(function () {
    'use strict';

    var DoOrderController = require('./do-order-object'),
        config = require('shim/config'),
        Radio = require('shim/radio'),

        DoOrderButtonsView = require('views/orders/do-order-buttons-view');

    module.exports = DoOrderController.extend({
        getButtonsView: function () {
            return new DoOrderButtonsView({
                steps: [
                    { // Verificações
                        prev: 'Voltar à Página Inicial da OT',
                        next: 'Ir para Parâmetros'
                    },
                    { // Parâmetros
                        prev: 'Voltar às Verificações',
                        next: 'Ir para Notas'
                    },
                    { // Notas
                        prev: 'Voltar aos Parâmetros',
                        next: 'Ir para Confirmação'
                    },
                    { // Confirmação
                        prev: 'Voltar para Notas',
                        next: 'Verificar',
                        unapproved: 'Não Verificar'
                    }
                ]
            });
        },

        _concludeProcess: function (transition) {
            //Check If Complete -> Verify || Validated -> Verify
            if (!transition) {
                if (this.task.get('state_id') == 4)
                    this._verifyTask();
                else
                    this._validateTask();
            }
            else {
                this._verifyTask(transition);

            }

        },

        _validateTask: function () {
            var self = this;
            this.task._transitionTask(this.order.get('id'), 'validate').save()
                .always(function(){self._verifyTask()});
        },

        _verifyTask: function (transition) {
            transition = transition || 'verify';

            var self = this;

            this.task.set({
                validation_notes: $('#validation-notes').val()
            })
                ._transitionTask(this.order.get('id'), transition).save()
                .always(function(){
                    self.order.emulateStateMachine();
                    self._taskVerified()
                })
        },

        _taskVerified: function () {
            var self = this,
                route = ['orders', self.order.get('id'), 'verify', self.task.get('id'), 'submitted'].join('/');
            Radio.global.command('router:orders:navigate', route, {trigger: true});
        }
    });
})();
