/* global module, require */
(function () {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        template: require('templates/main/notifications'),
        tagName: 'div',
        className: 'section-box',
        regions: {
            view: '.view'
        }
    });
})();
