/* global module, require */
(function () {
  'use strict';

  module.exports = Marionette.LayoutView.extend({
    template: require('templates/settings/typified-orders/typified-orders'),
    tagName: 'div',
    className: 'section-box',

    regions: {
      view:       '.view',
      filters:    '.filters'
    }
  });
})();
