(function () {
  'use strict';

  var api = require('shim/api'),

  Material = require('models/material');

  module.exports = Backbone.Collection.extend({
    model: Material,
    url: api.urlRoot + '/materials',
    store: 'materials',

    parse: function (response) {
      return response.materials;
    }
  });
})();
