(function () {
    'use strict';

    var config = require('shim/config'),
        BaseObject = require('controllers/base-object'),
        Radio = require('shim/radio'),

        OrderLayout = require('layouts/order-layout'),
        OrderDetailsView = require('views/orders/order-details-view'),
        OrderEquipmentView = require('views/orders/order-equipment-view'),
        OrderMaterialView = require('views/orders/order-material-view'),
        MaterialRequestsListView = require('views/materials/material-requests/material-requests-list-view'),
        OrderSummaryView = require('views/orders/order-summary-view'),
        MaterialRequestsCollection = require('collections/material-requests'),
        MaterialRequest = require('models/material-request'),
        MaterialRequestCreateView = require('views/materials/material-requests/material-requests-create-view');

    module.exports = BaseObject.extend({
        radio: {
          'order:edit': 'onOrderEdit',
          'order:submit': 'onOrderSubmit',
          'order:transition:next': 'onTransitionNext',
          'order:transition:back': 'onTransitionBack',
          'order:gtransition:next': 'onValidateAndVerifyTask',
          'order:render': 'render',
        },

        //Object Initializer
        initialize: function (options) {
          BaseObject.prototype.initialize.apply(this, arguments);
          options = options || {};
          this.order = options.order;
          this.orders = options.orders;
          this.categories = options.categories;
          this.materials = options.materials;
          this.hasMaterials = !_.isEmpty(options.materials.models);
          if (!this.order.has('isRequest') && this.hasMaterials) {
            this.listenTo(this.order, 'sync', () => {
              this.renderMaterialRequestsView();
              this.region.currentView.material.currentView.render();
            })
          }
        },

        //Render Views
        render: function () {
          $('.search-ot-group').hide();
          //Render MainView
          this.show(new OrderLayout({ model: this.order, hasMaterials: this.hasMaterials }), this);

          //Render SubViews
          this.region.currentView.showChildView('details', new OrderDetailsView({ model: this.order }));

          if (!this.order.has('isRequest')) {
            this.region.currentView.showChildView('equipment', new OrderEquipmentView({
              model: this.order,
              collection: this.order.get('tasks')
            }));

            this.region.currentView.showChildView('summary', new OrderSummaryView({ model: this.order }));

            // Dont show materials tab if no materials present
            if (this.hasMaterials) {
              this.region.currentView.showChildView('material', new OrderMaterialView({
                model: this.order,
                collection: this.categories,
                materials: this.materials
              }));
              this.renderMaterialRequestsView();
              this.renderNewMaterialRequestModal();
            }
          }
        },

        renderMaterialRequestsView() {
          let materialRequests = new MaterialRequestsCollection(_.map(this.order.get('material_requests'), (mr) => {
                return new MaterialRequest(mr)
              })),
              materialRequestsView = new MaterialRequestsListView({
                collection: materialRequests.vCollection(),
                order: this.order
              });
          this.region.currentView.showChildView('materialRequests', materialRequestsView);
        },

        renderNewMaterialRequestModal() {
          this.region.currentView.showChildView('create', new MaterialRequestCreateView({
            materials: this.materials,
            categories: this.categories,
            model: new MaterialRequest(),
            order: this.order
          }));
          this.region.currentView.buildMaterialRequestsModal();
          this.region.currentView.create.$el.css({
            'background': 'white',
            'padding': '20px',
          })
        },

        stopListeningToMaterialResquests () {
          this.stopListening(this.order, 'sync', () => {
            this.renderMaterialRequestsView();
            this.region.currentView.material.currentView.render();
          })
        },

        /**
         * Other Triggers
         */
        //Returns Current Controller Order
        getOrder: function () {
          return this.order;
        },

        //Updates Order on Collection
        _updateCollection: function () {
          var self = this;
          self.orders.set(self.order, { remove: false });
        },

        //Submit Order to API
        onOrderSubmit: function () {
            var self = this,
                transition = self._getTransition();

            if (self.region.currentView.material.currentView)
                self.region.currentView.material.currentView.triggerMethod('set:material:assignments')

            self.order.set({
                transition: transition,
                state: self._getState(transition)
            });

            self.order.updateState().save()
                .always(self._updateCollection(), self.order.afterSync(), self._completeOrder())
                .fail(() => {
                    this.order.set({ is_declined_verification: false })
                    this.order.set({ is_declined_validation: false })
                });
        },

        //Displays Submit Button if Allowed [* Tasks Completed/Validated/Verified]
        _showSubmitBtn: function (states) {
          if (this.order.get('state') == 'complete') {
            this.region.currentView.ui.submitBtn.show();
          }
          else if (this.order.get('state') == 'done') {
            if (this.order.get('tasks')._every(['validated', 'verified'])) {
              this.region.currentView.ui.submitBtn.show();
              this.region.currentView.ui.submitBtn.html("Confirmar Validação");
            }
          }
          else if (this.order.get('state') == 'validated') {
            if (this.order.get('tasks')._every(['verified'])) {
              this.region.currentView.ui.submitBtn.show();
              this.region.currentView.ui.submitBtn.html("Confirmar Fecho");
            }
          }
        },

        // Re-renders Material View to allow edit when quick transitioning an Order to state less than done{3}
        renderMaterialView (self) {
          if (self.order.get('state_id') < 3 && self.region.currentView.material.currentView)
              self.region.currentView.material.currentView.render();
        },

        /**
         * Transitions [Validation/Verification]
         */
        //Moves to Next Transition State
        onTransitionNext: function (task, transition, states) {
          var self = this;
          task._transitionTask(this.order.get('id'), transition).save()
              .always(self._showSubmitBtn(states));
        },

        //Moves to Previous Transition State
        onTransitionBack: function (task, transition) {
          var self = this;
          task._transitionTask(this.order.get('id'), transition).save()
              .always(function () {
                self.order.emulateStateMachine();
                if (transition === 'no_validate') {
                  self.order.set({ is_declined_validation: true })
                }
                if (transition === 'no_verify') {
                  self.order.set({ is_declined_verification: true })
                }
                self.renderMaterialView(self);
              });
          this.region.currentView.ui.submitBtn.hide();
        },

        //Validate and Verify OT [Manager Only]
        onValidateAndVerifyTask: function (task) {
            var self = this;
            task._transitionTask(this.order.get('id'), 'validate').save()
                .always(function () {
                    self.onTransitionNext(task, 'verify', ['verified']);
                });
        },

        /**
         * Transitions
         */
        //Get Current Order State Based on Transition
        _getState: function (transition) {
            var self = this,
                state = config.fromTransition(transition);
            return state ? state : self.order.get('state');
        },

        //Get Submit_Order Transition State
        _getTransition: function () {
            var tasks = this.order.get('tasks');

            if (tasks._some(['pending', 'ongoing']))
                return 'save_order';
            if (tasks._every(['done']) || tasks._some(['done']) || (tasks._some(['validated']) && !tasks._every(['validated']) && !tasks._some(['validated'])))
                return 'submit_order';
            if (tasks._every(['validated']) || (tasks._some(['verified']) && !tasks._every(['verified'])))
                return 'validate';
            if (tasks._every(['verified']))
                return 'verify';
        },

        /**
         * Route Triggers
         */
        onOrderEdit: function () {
            Radio.global.command('router:orders:navigate', ['orders/edit', this.getOrder().get('id')].join('/'), { trigger: true });
        },
        _completeOrder: function () {
            var self = this,
                route = this.triggerMethod('complete:order') || ['orders', self.order.get('id'), 'submitted'].join('/');
            Radio.global.command('router:orders:navigate', route, { trigger: true });
        }

    });
})();
