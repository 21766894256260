(function () {
    'use strict';

    var Radio = require('shim/radio'),
        NotificationsController = require('controllers/main/notifications-details-controller')

    module.exports = Marionette.Controller.extend({
        initialize: function (options) {
            options = options || {};
            this.notifications = options.notifications;
        },

        showNotificationById: function (id) {
            id = parseInt(id) || id;
            if (!this.notifications.get(id))
                return;

            this.controller = new NotificationsController({
                notifications: this.notifications,
                notification: this.notifications.get(id)
            });

            this.controller.showNotification();
        },

    });
})();
