var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, alias5=container.escapeExpression, alias6=container.lambda, buffer = 
  "    <div class=\"col-xs-7\">\n        <label for=\"nav-trigger\" class=\"button button-primary button-menu\" id=\"menu-trigger\">\n          <i class=\"fa fa-bars\"></i>\n          <span>Menu</span>\n        </label>\n\n        <a href=\"#orders/create\" class=\"button button-primary\">\n            <i aria-hidden=\"true\" class=\"fa fa-wrench\"></i>\n            <span>";
  stack1 = ((helper = (helper = helpers.isSolicitant || (depth0 != null ? depth0.isSolicitant : depth0)) != null ? helper : alias2),(options={"name":"isSolicitant","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isSolicitant) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n        </a>\n\n        <div class=\"input-group search-ot-group\">\n            <input id=\"search-ot\" class=\"form-control\" placeholder=\"Pesquisar\" autofocus=\"autofocus\" />\n            <button class=\"button button-primary search\" type=\"button\" style=\"display: inline-block\">\n                <i class=\"fa fa-search\" aria-hidden=\"true\"></i>\n            </button>\n        </div>\n    </div>\n\n    <div class=\"col-xs-5\" align=\"right\">\n        ";
  stack1 = ((helper = (helper = helpers.isNotSolicitant || (depth0 != null ? depth0.isNotSolicitant : depth0)) != null ? helper : alias2),(options={"name":"isNotSolicitant","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isNotSolicitant) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n\n        <a href=\"#\" class=\"sync notification\" style=\"padding-left:0px;padding-right:0px\">\n            <span>1"
    + alias5(((helper = (helper = helpers.count || (depth0 != null ? depth0.count : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"count","hash":{},"data":data}) : helper)))
    + "</span><i class=\"fa fa-refresh fa-2x\"></i>\n        </a>\n\n        <figure class=\"user-id\">\n          <em>"
    + alias5(alias6(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "<br>\n            <span>"
    + alias5(alias6(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.role_title : stack1), depth0))
    + "</span>\n          </em>\n          <br/>\n        </figure>\n\n        <a href=\"#/help\" class=\"user-id\" style=\"margin-right:10px\">\n          <em style=\"text-decoration:underline\">Ajuda<br>\n            <span></span>\n          </em>\n          <br/>\n        </a>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "Criar Pedido";
},"4":function(container,depth0,helpers,partials,data) {
    return "Criar OT";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options;

  stack1 = ((helper = (helper = helpers.isNotOperator || (depth0 != null ? depth0.isNotOperator : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"isNotOperator","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.isNotOperator) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { return stack1; }
  else { return ''; }
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = (helpers.gt || (depth0 && depth0.gt) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.notifications : depth0),0,{"name":"gt","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        ";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <a href=\"#notifications\" class=\"notification bell  view-according-device\">\n                <span>"
    + container.escapeExpression(((helper = (helper = helpers.notifications || (depth0 != null ? depth0.notifications : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"notifications","hash":{},"data":data}) : helper)))
    + "</span>\n            </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.isNotDashboard || (depth0 != null ? depth0.isNotDashboard : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"isNotDashboard","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.isNotDashboard) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}