(function () {
  'use strict';

  var Radio = require('shim/radio')

  module.exports = Marionette.ItemView.extend({
    template: require('templates/materials/material-orders/material-orders-list-item'),
    tagName: 'tr',
    attributes : function () {
      return {
        'data-target' : this.model.id
      };
    },

    ui: {
      changeQuantity: '.change-qty',
      saveQuantity:   '.save-qty',
      removeQuantity: '.remove-qty'
    },

    events: {
      'click @ui.changeQuantity': 'onChangeQuantityClicked',
      'click @ui.saveQuantity':   'onSaveQuantityClicked',
      'click @ui.removeQuantity': 'onRemoveQuantityClicked'
    },

    modelEvents: {
      change: 'render',
    },

    onChangeQuantityClicked: function (event) {
      event.preventDefault();

      $('#purchase-qty-' + this.model.id).prop('disabled', false);
      $('#change-qty-' + this.model.id).hide();
      $('#save-qty-' + this.model.id).show();
    },

    onSaveQuantityClicked: function (event) {
      event.preventDefault();

      $('#purchase-qty-' + this.model.id).prop('disabled', true);
      $('#change-qty-' + this.model.id).show();
      $('#save-qty-' + this.model.id).hide();

      var qtyToPurchase = $('#purchase-qty-' + this.model.id).val();
      this.model.save( {purchase_qty: qtyToPurchase}, {patch: true} )
    },

    onRemoveQuantityClicked: function (event) {
      event.preventDefault();

      this.model.destroy({wait: true})
    }
  });
})();
