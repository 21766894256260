(function () {
    'use strict';

    var BaseObject = require('controllers/base-object'),
        Radio = require('shim/radio'),

        Orders = require('collections/orders'),
        DoOrderView = require('views/orders/do-order-view'),
        DoOrderProgressView = require('views/orders/do-order-progress-view'),
        DoOrderButtonsView = require('views/orders/do-order-buttons-view');

    module.exports = BaseObject.extend({
        steps: [
            require('views/orders/steps/order-step-one-view'),
            require('views/orders/steps/order-step-two-view'),
            require('views/orders/steps/order-step-three-view'),
            require('views/orders/steps/order-step-four-view')
        ],
        maxStep: 5,
        radio: {
            'do:order:step': 'onOrderStep',
            'do:order:prev:step': 'onPrevStep',
            'do:order:next:step': 'onNextStep',
            'do:order:unapproved:step': 'onUnapprovedStep'
        },

        initialize: function (options) {
            BaseObject.prototype.initialize.apply(this, arguments);
            options = options || {};
            this.order = options.order;
            this.task = options.task;
            this.users = options.users;
            this.orders = options.orders;
            this.step = 1;
            this._prepareTask(this.task);
        },

        //Render Views
        render: function () {
            var self = this,
                today = moment(),
                formatted = today.format('YYYY-MM-DD'),
                orders = new Orders(this.orders.filter(function (order) {
                    return order.get('start').isSame(formatted) || order.get('end').isSame(formatted) || today.isBetween(order.get('start').format('YYYY-MM-DD'), order.get('end').format('YYYY-MM-DD'));
                }));

            //Render MainView
            self.show(new DoOrderView({model: this.order, collection: orders, task: this.task}), self);

            //Render SubViews
            self._showLayout();
            self._showStep(self.step);
        },
        _showStep: function (step) {
            var self = this,
                StepView = self.steps[step - 1];

            if (StepView) {
                var currentModel = self._currentStepsModel();
                self.region.currentView.step.show(new StepView({
                    model: currentModel,
                    collection: this.users,
                    order: this.order,
                    user: Radio.global.request('app:session').get('user')
                }));
                Radio.global.trigger('do:order:change:step', {step: step});
            }
        },
        _showLayout: function () {
            var self = this;
            self.region.currentView.progress.show(this.getProgressView());
            self.region.currentView.buttons.show(this.getButtonsView());
        },
        getProgressView: function () {
            return new DoOrderProgressView({ order: this.order, task: this.task });
        },
        getButtonsView: function () {
            return new DoOrderButtonsView();
        },
        _currentStepsModel: function () {
            var self = this;

            if (_.isUndefined(self.region.currentView.step.currentView)) {
                return this.task;
            }
            self.region.currentView.step.currentView.fillCurrentModelState();
            return self.region.currentView.step.currentView.model;
        },

        //Change SubViews
        onPrevStep: function () {
            var self = this;
            self.step--;
            self._changeView({step: self.step});
        },
        onNextStep: function () {
            var self = this;
            if (self._shouldMoveNext()) {
                self.step++;
                self._changeView({step: self.step});
            }
        },
        onOrderStep: function (options) {
            this._changeView(options);
        },
        _changeView: function (options) {
            var self = this;
            self.step = options.step;

            if (self.step === 0) {
                return self._navigateToOrder();
            }

            if (self.step === self.maxStep) {
                return self._concludeProcess();
            }

            self._showStep(self.step);
        },
        _shouldMoveNext: function () {
            var self = this,
                currentView = self.region.currentView.step.currentView;
            return !currentView || (currentView && currentView.isValid());
        },
        _navigateToOrder: function () {
            var self = this,
                route = ['orders', self.order.get('id')].join('/');
            Radio.global.command('router:orders:navigate', route, {trigger: true});
        },
        _concludeProcess: function () {
            var self = this;
            self.order = self.triggerMethod('before:sync', self.task, self.order) || self.order;
            self._syncAnd();
        },

        //Unapproved Method
        onUnapprovedStep: function () {
            var transition;

            switch (this.task.get('state_id')) {
                case 4:
                    transition = 'no_verify';
                    break;
                case 2:
                default:
                    this.task.set({done_status: 'ongoing'});
                    transition = 'no_validate'
            }

            this._concludeProcess(transition)

        },

        //Save|Update Process
        _completeTask: function () {
            var self = this,
                route = self.triggerMethod('complete:order', self.order, self.task) || ['orders', self.order.get('id'), 'do', self.task.get('id'), 'submitted'].join('/');
            Radio.global.command('router:orders:navigate', route, {trigger: true});
        },

        _syncAnd: function () {
            var self = this,
                task = self.task;

            task.set({
                order_id: self.order.get('id'),
                transition: self.triggerMethod('task:transition', self.task),
                state: self.triggerMethod('task:state', self.task),
                done_status: self.triggerMethod('task:doneStatus', self.task)
            });

            self.task.save()
                .fail(function(xhr){
                    if(xhr.readyState == 0){
                        self.task.offlineStateUpdate();
                    }
                })
                .always(function () {
                    self.task.cleanTmpAttributes();
                    self.order.emulateStateMachine();
                    self._completeTask();
                })

        },

        //Preparing Tasks
        _prepareTask: function (task) {
            //Parse task State
            switch (task.get('state')) {
                case 'ongoing':
                    task.set('verified', '1');
                    break;
                case 'done':
                    task.set('verified', '0');
                    break;
                default:
                    task.set('verified', 'undefined');
                    break;
            }

            //Parse Task Done Status
            switch (task.get('done_status')) {
                case 'unrealized':
                    task.set('verified', '2');
                    break;
                case 'uncompleted':
                    task.set('verified', '3');
                    break;
                case 'complete':
                    task.set('verified', '0');
                    break;
            }
        },
        onTaskTransition: function (task) {
            switch (task.get('verified')) {
                case "0":
                    return 'submit_task';
                case "2":
                    return 'submit_task';
                case "3":
                    return 'submit_task';
                default:
                    return 'save_task';
            }
        },
        onTaskState: function (task) {
            switch (task.get('verified')) {
                case "0":
                case "2":
                case "3":
                    return 'done';
                case "1":
                    return 'ongoing';
                default:
                    return 'pending'
            }
        },
        onTaskDoneStatus: function (task, state) {
            //DoneStatus Transaction Value
            state = state || task.get('verified');

            switch (state) {
                case "0":
                    return 'complete';
                case "1":
                    return 'ongoing';
                case "2":
                    return 'unrealized';
                case "3":
                    return 'uncompleted';
                default:
                    return ''
            }
        }


    });
})();
