var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "fileinput-exists ";
},"3":function(container,depth0,helpers,partials,data) {
    return " fileinput-new";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<label>Anexar Fotografias <span>(*.png, *.pdf, *.jpg)</span></label>\n<div class=\"fileinput "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.imagePreview : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n    <div class=\"fileinput-preview thumbnail\">\n        <img class='fileinput-image-preview thumbnail' src='"
    + alias4(((helper = (helper = helpers.imagePreview || (depth0 != null ? depth0.imagePreview : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imagePreview","hash":{},"data":data}) : helper)))
    + "'/>\n    </div>\n    <div>\n    <span class=\"button button-default btn-file\">\n      <span class=\"fileinput-new\">Selecionar Imagem</span>\n      <span class=\"fileinput-exists\">Alterar</span>\n      <input type=\"file\" name=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" accept=\"image/*\">\n    </span>\n        <span class=\"button button-white fileinput-exists js-fileinput-remove\">Remover Imagem</span>\n    </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}