(function () {
    'use strict';

    var config = require('shim/config'),
        Radio = require('shim/radio');

    module.exports = Marionette.CompositeView.extend({
        template: require('templates/orders/order-equipment'),
        childView: require('./order-units-view'),
        childViewContainer: '#order-units',
        childViewOptions: function () {
            return { order: this.model };
        },

        ui: {
            stateFilter: '#unit-state-filter',
            validateAll: '.validate_all',
            verifyAll: '.verify_all'
        },
        events: {
            'change @ui.stateFilter': 'onStateFilterChange',
            'click @ui.validateAll': 'onValidateAllClicked',
            'click @ui.verifyAll': 'onVerifyAllClicked',
            'click @ui.selectAllUnits': 'onSelectAllUnits'
        },
        modelEvents: {
            'change:tasks': 'modelSynced',
            'change:state': 'render'
        },
        collectionEvents: {
            'request': 'render'
        },

        //Object Initializer
        initialize: function () {
            this.selectedState = -1;
        },

        modelSynced() {
            this.collection.reset(this.model.get('tasks').models);
        },

        //After Rending Object
        onRender: function () {
            this.ui.stateFilter.val(this.selectedState);
        },

        //Filter Units By SelectState (Look into every child unit-view separately)
        addChild: function (child) {
            switch (this.selectedState) {

                //Pending done_status
                case 0:
                    if (child.filterByDoneStatus(['pending', null]))
                        Marionette.CollectionView.prototype.addChild.apply(this, arguments);
                    break;

                //OnGoing done_status
                case 1:
                    if (child.filterByDoneStatus(['ongoing', '']))
                        Marionette.CollectionView.prototype.addChild.apply(this, arguments);
                    break;

                //Completed/Uncompleted done_status
                case 2:
                    if (child.filterByDoneStatus(['complete', 'uncompleted']))
                        Marionette.CollectionView.prototype.addChild.apply(this, arguments);
                    break;

                //Unrealized done_status
                case 3:
                    if (child.filterByDoneStatus(['unrealized']))
                        Marionette.CollectionView.prototype.addChild.apply(this, arguments);
                    break;

                //No Filter
                default:
                    Marionette.CollectionView.prototype.addChild.apply(this, arguments);
                    break;
            }
        },

        //Template Variables/Functions
        templateHelpers: function () {
            return {
                statuses: config.getTaskFilters(),
                showValidateAll: this.canShowValidateAllBtn(),
                showVerifyAll: this.canShowVerifyAllBtn()
            }
        },

        canShowValidateAllBtn: function () {
            return this.isPermitedTaskState(3) && this.isPermitedRole(['foreman', 'manager']);
        },

        canShowVerifyAllBtn: function () {
            return this.isPermitedTaskState([3, 4]) && this.isPermitedRole('manager');
        },

        isPermitedTaskState: function (states) {
            states = _.isArray(states) ? states : [states];
            return _.contains(states, this.model.get('state_id'));
        },

        isPermitedRole: function (roles) {
            return Radio.global.request('app:session').get('user').is(roles);
        },

        //Serialize Task into JSON Object
        serializeData: function () {
            return _.extend({}, this.model.toJSON(), {
                equipment: this.model.get('equipment_title'),
                hasTasks: this.collection.length > 0,
                isOrder: this.collection.length === 0 && this.model.get('equipment_title') !== null,
                isDoneState: this.selectedState === config.getStatus('done').id
            });
        },

        //Task Filter Change
        onStateFilterChange: function () {
            this.selectedState = parseInt($('option:selected', this.ui.stateFilter).val(), 10);
            this.render();
            this.bindUIElements();
        },

        /**
         * Multiple Validation
         */
        onValidateAllClicked: function (e) {
            e.preventDefault();
            this._unitsClickTrigger('done', 'validate', ['validate', 'verify']);
        },
        onVerifyAllClicked: function (e) {
            e.preventDefault();
            this._unitsClickTrigger('validated', 'verify', ['verify']);
        },
        _unitsClickTrigger: function (state, action, verifyActions) {
            //Mapping view models
            const mappingViews = _.map(this.children._views, view => view.model);

            //Task transition radio call
            const taskTransitionCall = task => Radio.global.trigger(
                'order:transition:next',
                task,
                action,
                verifyActions.map(action => this._getStateByAction(action))
            );

            //Rejection tasks based on state condition
            const tasks = _.reject(mappingViews, task => !task.isState(state));

            // Call Transition Global Methods
            tasks.map(taskTransitionCall);
        },
        _getStateByAction: function (action) {
            switch (action) {
                case 'validate':
                    return 'validated';
                case 'verify':
                    return 'verified';
                default:
                    return '';
            }
        }
    })

})();
