(function () {
    'use strict';

    var Dashboard1 = require('views/dashboards/dashboard-1-view'),
        Dashboard2 = require('views/dashboards/dashboard-2-view'),
        Dashboard3 = require('views/dashboards/dashboard-3-view'),
        Dashboard4 = require('views/dashboards/dashboard-4-view'),
        CollectionDashboard1 = require('collections/dashboards/dashboard1-collection'),
        CollectionDashboard2 = require('collections/dashboards/dashboard2-collection'),
        CollectionDashboard3 = require('collections/dashboards/dashboard3-collection'),
        CollectionDashboard4 = require('collections/dashboards/dashboard4-collection');

    module.exports = Marionette.LayoutView.extend({
        template: require('templates/dashboards/dashboards'),
        tagName:   'div',
        className: 'section-box',

        regions: {
            view: '.view'
        },
        events: {
            'click .btn_dashboard': '_onDashboardClicked',
            'click @ui.helperBtn':  '_stopSlide'
        },
        ui: {
            'autoBtn':      '.auto-btn',
            'modalContent': '.modal-content',
            'helperBtn':    '.helper-btn'
        },

        initialize: function () {
            this.slideShowTime = 10000;
            this.currentDashboard = 0;
            this.slideShow = "on";
            this.slide = setInterval(this._onSlide.bind(this), this.slideShowTime);
            this._onSlide();
            this.collectionDashboard4 = new CollectionDashboard4();
        },

        onRender: function () {
            const model = this.options.rootzone,
                  isAuto = this.slideShow;

            var collectionDashboard1 = new CollectionDashboard1();
            var collectionDashboard2 = new CollectionDashboard2();
            var collectionDashboard3 = new CollectionDashboard3();

            //Reset Helper Modal
            this._resetModal();

            //Load Currect Dashboard
            switch (this.currentDashboard) {
                case 1:
                    collectionDashboard1.fetch({ reset: true }).done(function(){
                      this.getRegion('view').show(new Dashboard1({
                        collection: collectionDashboard1
                      }), {});
                    }.bind(this));
                    break;
                case 2:
                    collectionDashboard2.fetch({ reset: true })
                      .always(function(){
                        this.collectionDashboard4.fetch({ reset: true })
                      }.bind(this)).done(function(){
                      this.getRegion('view').show(new Dashboard2({
                        collection: collectionDashboard2
                      }), {});
                    }.bind(this));
                    break;
                case 3:
                    collectionDashboard3.fetch({ reset: true }).done(function(){
                      this.getRegion('view').show(new Dashboard3({
                        collection: collectionDashboard3
                      }), {});
                    }.bind(this));
                    break;
                case 4:
                    if (this.collectionDashboard4.length === 0) {
                      this.collectionDashboard4.fetch({ reset: true }).done(function(){
                        this.getRegion('view').show(new Dashboard4({
                          collection: this.collectionDashboard4,
                          isAuto: isAuto
                        }), {});
                      }.bind(this));
                    } else {
                      this.getRegion('view').show(new Dashboard4({
                        collection: this.collectionDashboard4,
                        isAuto: isAuto
                      }), {});
                    }
                    break;
            }
        },

        onBeforeDestroy: function () {
            clearInterval(this.slide);
        },

        templateHelpers: function () {
            return {
                currentDashboard: this.currentDashboard,
                slideShow: this.slideShow,
                logo_centro: this.options.rootzone.get('image')
            }
        },

        _onDashboardClicked: function (ev) {
            var self = this;

            if ($(ev.target).hasClass('auto-btn')) {
                if (self.slideShow === "off") {
                    self.slideShow = "on";
                    self.slide = setInterval(self._onSlide.bind(self), 10000);
                }
                else {
                    self.slideShow = "off";
                    clearInterval(self.slide);
                }

            } else {
                if (!($(ev.target).hasClass('auto-btn'))) {
                    self.slideShow = "off";
                    clearInterval(self.slide);
                }
                this.currentDashboard = parseInt(ev.target.innerHTML, 10);
            }

            self.render();
        },

        _stopSlide: function () {
            if (this.slideShow == 'on') {
                this.slideShow = "off";
                clearInterval(this.slide);
                this.ui.autoBtn
                    .removeClass('button-primary')
                    .addClass('button-default');
            }
        },

        _diffTimeDashboard: function (duration) {
            clearInterval(this.slide);
            this.slide = setInterval(this._onSlide.bind(this), duration);
        },

        _resetDiffTimeDashboard: function () {
            clearInterval(this.slide);
            this.slide = setInterval(this._onSlide.bind(this), this.slideShowTime);
        },

        _onSlide: function () {
            if (this.currentDashboard === 4) {
                this._resetDiffTimeDashboard();
                this.currentDashboard = 0;
            }

            if (this.currentDashboard === 3)
                this._diffTimeDashboard(60000);
            this.currentDashboard++;
            this.render();
        },

        _resetModal: function () {
            $('#helperModal .modal-dialog')
                .empty()
                .append(this.ui.modalContent);
        }
    });
})();
