(function () {
    'use strict';

    var api = require('shim/api'),
        User = require('models/user');

    module.exports = Backbone.Collection.extend({
        model: User,
        url: api.urlRoot + '/users/all',
        store: 'allUsers',

        parse: function (response) {
            return response.users;
        }
    });
})();
