'use strict';

module.exports = Marionette.ItemView.extend({

    fillCurrentModelState: _.noop,
    isValid: function () {
        this.fillCurrentModelState();
        return true;
    }
});
