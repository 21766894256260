(function () {
  'use strict';

  var Radio  = require('shim/radio'),

      Order  = require('models/order');

  Radio.global.comply('convert:alarms', function (notifications, orders) {
    notifications.each(function (notification) {
      orders.add(new Order(convert(notification.toJSON())));
    });
  });

  function convert(alarm) {
    var attrs = _.pick(alarm, ['title', 'message', 'state']);

    attrs.id                  = alarm._id;
    attrs.skip_prefetch       = true;
    attrs.start_date_preview  = moment(alarm.created_at).format('YYYY-MM-DD');
    attrs.date                = attrs.start_date_preview;
    attrs.order_category_slug = 'alarm';
    attrs.order_category_id   = Radio.global.request('category:id', 'alarm');

    return attrs;
  }
})();
