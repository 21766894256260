'use strict';

var ImageUpload = require('templates/inputs/image-upload');

module.exports = Marionette.Behavior.extend({
    events: {
        "change [type='file']": 'onFileChange',
        "click .js-fileinput-remove": 'onRemoveClicked'
    },
    uiSelectors: {
        container: '.fileinput',
        preview: '.fileinput-image-preview',
        fileInput: "[type='file']",
        removeButton: '.js-fileinput-remove'
    },

    initialize: function(){
    },

    onShow: function () {
        var self = this;
        self.$(self.options.container).html(ImageUpload({
            name: self.options.name, imagePreview: self.view.imagePreview()
        }));
        self.ui = _.mapValues(self.uiSelectors, self.$.bind(self));
    },

    onFileChange: function () {
        var self = this,
            files = self.ui.fileInput[0].files;

        if (files && files.length > 0) {
            var reader = new FileReader();
            reader.onload = function(e){
                self._attachPreviewEle(e.target.result);
                self._showRemoveImage();
            };
            reader.readAsDataURL(files[0]);
        }
    },

    onRemoveClicked: function () {
        this.ui.fileInput.val('');
        this._toggleNewExists();
    },

    _handleImagePreview: function (e) {
        this._attachPreviewEle(e.target.result);
        this._showRemoveImage();
    },

    _showRemoveImage: function () {
        if (this.ui.container.hasClass('fileinput-exists')) {
            return;
        }
        this._toggleNewExists();
    },

    _toggleNewExists: function () {
        this.ui.container.toggleClass('fileinput-new fileinput-exists');
    },

    _attachPreviewEle: function (src_img) {
        this.ui.preview.attr('src', src_img);
    }
});
