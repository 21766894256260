(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/settings/rootzone-settings'),
        tagName: 'section',

        ui: {
            'imageUpload': '.overlay',
            'fileInput': 'input[type=file]',
            'formSubmit': '[type="submit"]',
            'greenSem': '[name = "sem_green"]',
            'redSem': '[name = "sem_red"]',
            'yellowSem': '[name = "sem_yellow"]'
        },
        events: {
            'click @ui.formSubmit': 'onSubmit',
            'focusout @ui.greenSem': 'onSemaphoreFocusOut',
            'focusout @ui.redSem': 'onSemaphoreFocusOut'
        },
        behaviors: {
            AvatarUpload: {}
        },

        initialize: function () {
            Backbone.Validation.bind(this);
        },

        onBeforeDestroy: function () {
            Backbone.Validation.unbind(this);
        },

        templateHelpers: function () {
            const image_logo = this.model.get('image') || 'https://i.imgur.com/GUnBw8H.png';
            return {
                image_logo,
                sem_yellow: `${this.model.get('sem_green')} e ${this.model.get('sem_red')}`
            }
        },

        uploadImage: function (logoB64) {
            $('.image').attr('src', logoB64);
            this.model.set({ image: logoB64 });
            if (this.model.isValid(true))
                Radio.global.trigger('rootzone:update:logo');
            else
                console.log('User update request not valid');

        },

        onSemaphoreFocusOut(ev) {
            ev.preventDefault();
            this.ui.yellowSem.val(`${this.ui.greenSem.val()} e ${this.ui.redSem.val()}`);
        },

        onSubmit: function () {
            event.preventDefault();
            this.model.set(_.extend({ semChange: true }, this.$el.serializeObject()));
            if (this.model.isValid(true)) {
                Radio.global.trigger('rootzone:update:semaphores');
            }
        }
    });
})();
