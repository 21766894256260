(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/settings/change-password'),
        tagName: 'section',

        ui: {
            form: 'form',
            button: 'button[type="submit"]'
        },

        events: {
            'submit @ui.form': 'onSubmit',
            'click @ui.button': 'onSubmit'
        },

        initialize: function () {
            Backbone.Validation.bind(this);
        },

        onBeforeDestroy: function () {
            Backbone.Validation.unbind(this);
        },

        onShow: function () {
            this.$('#actual_password').focus();
        },

        onSubmit: function (event) {
            event.preventDefault();
            this.model.set(_.extend({ passChange: true }, this.$el.serializeObject()));
            if (this.model.isValid(true)) {
                Radio.global.trigger('user:update:password');
            }
        }
    });
})();
