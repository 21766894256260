var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.number || (depth0 != null ? depth0.number : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"number","hash":{},"data":data}) : helper)))
    + " - ";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <li>Equipamento: "
    + container.escapeExpression(((helper = (helper = helpers.equipment_title || (depth0 != null ? depth0.equipment_title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"equipment_title","hash":{},"data":data}) : helper)))
    + "</li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <li>Unidade: "
    + alias4(((helper = (helper = helpers.unit || (depth0 != null ? depth0.unit : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"unit","hash":{},"data":data}) : helper)))
    + "</li>\n            <li>Zona: "
    + alias4(((helper = (helper = helpers.zone || (depth0 != null ? depth0.zone : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"zone","hash":{},"data":data}) : helper)))
    + " </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "        <div class=\"form-group\" style=\"padding: 15px 0\">\n            <label for=\"validation-notes\">Nota de Validação</label>\n            <textarea "
    + ((stack1 = (helpers.lt || (depth0 && depth0.lt) || alias2).call(alias1,(depth0 != null ? depth0.state_id : depth0),3,{"name":"lt","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " name=\"validation-notes\" id=\"validation-notes\" rows=\"2\" placeholder=\"Introduza as notas da OT\"\n                style=\"resize:none\">"
    + container.escapeExpression(((helper = (helper = helpers.validation_notes || (depth0 != null ? depth0.validation_notes : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"validation_notes","hash":{},"data":data}) : helper)))
    + "</textarea>\n        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " disabled ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<header class=\"page-header\">\n    <h5 style=\"padding:10px\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.number : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h5>\n\n    <section id=\"details-box\" class=\"ot-details-specs\">\n        <div class=\"box\">\n            <h4 class=\"underline\">Detalhes da OT</h4>\n"
    + ((stack1 = container.invokePartial(partials["order-details-partial"],depth0,{"name":"order-details-partial","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n    </section>\n\n    <ul>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.equipment_title : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.unit : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </ul>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.display_notes : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</header>\n\n<form action=\"\">\n    <div class=\"step-progress\">\n    </div>\n\n    <section class=\"tab-content\">\n        <div class=\"order-step\"></div>\n        <div class=\"step-buttons\"></div>\n    </section>\n</form>\n";
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}