var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "    <i class=\"fa fa-check\" aria-hidden=\"true\" style='color: #53a784'></i>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <i class=\"fa fa-times\" aria-hidden=\"true\" style='color: #e6614f'></i>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "<td>\n  "
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\n</td>\n\n<td>\n  "
    + alias4(((helper = (helper = helpers.typified_group || (depth0 != null ? depth0.typified_group : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typified_group","hash":{},"data":data}) : helper)))
    + "\n</td>\n\n<td>\n  "
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.work_type : depth0)) != null ? stack1.title : stack1), depth0))
    + "\n</td>\n\n<td>\n  "
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.zone : depth0)) != null ? stack1.title : stack1), depth0))
    + "\n  <br>\n  <span>\n    "
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.zone : depth0)) != null ? stack1.route : stack1), depth0))
    + "\n  </span>\n</td>\n\n<td align='center'>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}