var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "<table class=\"bordered table-routines\">\n  <thead>\n    <tr>\n      <th class=\"col-sm-1 col-md-1\">Ordem</th>\n      <th class=\"col-sm-4 col-md-5\">Descrição</th>\n      <th class=\"col-sm-2 col-md-2\">Pedido Por</th>\n      <th class=\"col-sm-2 col-md-2\" style=\"text-align: center\">Data</th>\n      <th class=\"col-sm-1 col-md-1\" style=\"text-align: center\">Estado</th>\n      <th class=\"col-sm-1 col-md-1\" style=\"text-align: center\">Acção</th>\n    </tr>\n  </thead>\n  <tbody></tbody>\n</table>\n\n<div class='row notifications-bottom'>\n  <div class=\"col-xs-6\">\n    <button class=\"button button-primary action-dismiss-all\">\n      <span>Remover Todas</span>\n    </button>\n  </div>\n\n  <div class=\"col-xs-6 notifications-help-box\">\n    <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n    <div class=\"\" style=\"margin-left:10px\">\n      <span>Na listagem acima, apenas são apresentados 50 resultados, estando</span>\n      <span>os mesmos ordenados de forma decrescente por data de criação.</span>\n      <span>Para poder ver os restantes, deve ir tomando ações sobre os existentes.</span>\n      <span>Os pedidos removidos desaparecem de imediato, enquanto que os pedidos</span>\n      <span>marcados como lidos permanecerão durante 30 dias deste a data de criação.</span>\n    </div>\n  </div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"no-results\">\n    Não tem notificações.\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.notifications : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}