(function () {
    'use strict';

    var Radio = require('shim/radio');

    require('templates/calendars/calendar-operators');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/calendars/calendar-month'),
        types: {
            routine: 'event-routine',
            work_order: 'event-ot',
            alarm: 'event-alert',
            event: 'event-alert'
        },
        ui: {
            calendar: '#calendar',
            calendarNav: '.fc-button',
            users: '#users',
            monthControl: '.month',
            dayControl: '.day'
        },
        events: {
            'click @ui.calendarNav': 'onMonthChange'
        },
        behaviors: {
            UserFilters: {}
        },
        collectionEvents: {
            'add': 'addEvent',
            'remove': 'removeEvent',
            'reset': 'onReset',
            'change': 'onChange'
        },

        initialize: function (options) {
            options = options || {};
            this.orders = options.orders;
            this.users = options.users;
            this.begin_date = options.begin_date;
            this.user = options.user || Radio.global.request('app:session').get('user').get('_id');
            this.collection = options.orders;
        },

        onRender: function () {

            this.ui.calendar.fullCalendar(this._getCalendarOptions());
            this.ui.calendar.fullCalendar('addEventSource', this.collection.toJSON());

            window.setTimeout(function () { // Fix for not displaying when loaded
                this.ui.calendar.fullCalendar('render');
            }.bind(this), 0);
        },

        addEvent: function (event) {
            this.ui.calendar.fullCalendar('renderEvent', event.toJSON(), {stick: false});
        },

        removeEvent: function (event) {
            this.ui.calendar.fullCalendar('removeEvents', event.id);
        },

        onReset: function () {
            this.ui.calendar.fullCalendar('removeEventSource', this.collection.toJSON());
            this.ui.calendar.fullCalendar('removeEvents');
            this.ui.calendar.fullCalendar('addEventSource', this.collection.toJSON());
        },

        onChange: function (model) {
            var $event = this.$('.fc-event[data-category-id=' + model.get('order_category_id') + ']');
            if (model.get('display')) {
                $event.show();
            } else {
                $event.hide();
            }
        },

        onMonthChange: function (e) {
            e.preventDefault();

            var $el = $(e.currentTarget);
            if ($el.is('button')) {
                var route = '/calendar/month/' + this.ui.calendar.fullCalendar('getDate').format('YYYY/MM') + '?user_id=' + this.user;
                Radio.global.command('router:calendar:navigate', route, {trigger: true});
            }
        },

        onOrdersFiltered: function () {
            this.collection = this.orders;
            this.render();
        },

        templateHelpers: function () {
            var today = moment();
            return {
                user: this.user,
                year: today.format('YYYY'),
                month: today.format('MM'),
                users: function () {
                    return this.users.toJSON();
                }.bind(this)
            };
        },

        _getCalendarOptions: function () {
            var startDate = moment(this.begin_date) || moment().date(1);
            return {
                defaultDate: startDate,
                displayEventTime: false,
                header: {
                    left: 'prev,title,next',
                    center: '',
                    right: ''
                },

                locale: 'pt',   // FIXME: app constant
                startParam: 'start_date_preview',
                endParam: 'end_date_preview',
                eventLimit: true,
                eventLimitText: 'eventos',

                eventLimitClick: function (cellInfo) {
                    this._goToDailyView(cellInfo.date);
                    return false;
                }.bind(this),

                dayClick: function (date) {
                    this._goToDailyView(date);
                    return false;
                }.bind(this),

                // eventClick: function (calEvent) {
                //     this._goToDailyView(calEvent.start_date_preview);
                //     return false;
                // }.bind(this),

                eventRender: function (event, element) {
                    // element.attr('href', '#/calendar/day/' + moment(event.start_date_preview).format('YYYY/MM/DD'));
                    element.attr('href', '#/orders/' + event.id);
                    element.attr('data-category-id', event.order_category_id);
                    element.addClass('event-circle').addClass(this.types[event.order_category_slug || 'work_order']);
                }.bind(this)
            };
        },

        _goToDailyView: function (date) {
            Radio.global.request('app:states').calendar.type = "daily";

            var user = this.user,
                route = '/calendar/day/' + moment(date).format('YYYY/MM/DD') + '?user_id=' + user;
            Radio.global.command('router:calendar:navigate', route, {trigger: true});
        }
    });
})();
