(function () {
    'use strict';

    var Radio = require('shim/radio'),
        BaseObject = require('controllers/base-object'),

        CreateOrderView = require('views/orders/create-order-view'),
        CreateOrderEquipmentsView = require('views/orders/create-order-equipments-view'),
        OrderTypifications = require('collections/order-typifications'),
        WorkTypes = require('collections/work-types'),
        Zones = require('collections/zones'),
        EquipmentFamilies = require('collections/equipment-families'),
        Users = require('collections/users'),
        Order = require('models/order'),
        NotificationModel = require('models/notification');

    module.exports = BaseObject.extend({
        radio: {
            'families:get:equipment': 'onGetEquipmentForFamily',
            'equipments:get:units': 'onGetUnitsForEquipment',
            'view:create:order': 'onValidateOrder'
        },

        //Initialize Controller
        initialize: function (options) {
            BaseObject.prototype.initialize.apply(this, arguments);
            options = options || {};
            this.typifications = options.typifications || new OrderTypifications();
            this.workTypes = options.work_types || new WorkTypes();
            this.zones = options.zones || new Zones();
            this.equipmentFamilies = options.equipment_families || new EquipmentFamilies();
            this.equipments = options.equipments.vCollection({}, this);
            this.units = options.units;
            this.userGroups = options.groups;
            this.users = options.users;
            this.orders = options.orders;
            this.order = new Order();
        },

        //Render Main View and SubViews
        render: function () {
            var self = this,
                view = self.getMainView();

            //We have to listen for destroy to prevent memory leak on leaving this object
            //But if we are creating an order, we stop listening until we render the view again.
            self._stopListenToDestroy(self);

            //Reset and Render MainView
            self.show(view, self);

            //Render MainView Equipment View
            if (Radio.global.request('app:session').get('user').get('role_id') !== 5) {
                self.region.currentView.showChildView('equipments', new CreateOrderEquipmentsView({
                    equipment_families: this.equipmentFamilies
                }));
            }
        },
        getMainView: function () {
            var self = this;

            //We need to create a fresh order model to prevent unneeded attributes,
            // but we need to bind it with the controller to transpose attributes between view and controller
            this.order = new Order();

            return new CreateOrderView({
                model: this.order,
                typifications: self.typifications,
                work_types: self.workTypes,
                zones: self.zones,
                user_groups: self.userGroups,
                users: self.users
            });
        },

        //RADIO TRIGGERS
        onGetEquipmentForFamily: function (id) {
            var self = this;
            self.equipments.updateFilterParams({'equipment_family_id': id});
            self.region.currentView.getRegion('equipments').currentView.triggerMethod('equipment:fetched', self.equipments);
        },
        onGetUnitsForEquipment: function (id) {
            var self = this;
            self.region.currentView.getRegion('equipments').currentView.triggerMethod('unit:fetched', self.units.byEquipment({'equipment_id': id}));
        },

        //ORDER VALIDATION
        onValidateOrder: function (data, method) {
            var self = this,
                user = Radio.global.request('app:session').get('user');

            if (user.is('operator')) {
                data.assign_ot = 'user_ids';
                data.user_ids = user.get('_id');
            }

            if (user.is('solicitant')) {
              data.is_ticket = true
            }

            if (data.user_group_id) {
              data.user_group_title =  Radio.store.request('get:userGroups').findWhere({_id: parseInt(data.user_group_id)}).get('title')
            };

            if (data.user_ids) {
              if (!_.isArray(data.user_ids)) {data.user_ids = [data.user_ids]};
              var assignedUsers = new Users();
              if (!!data.user_ids) {
                data.user_ids.forEach(id => assignedUsers.push(Radio.store.request('get:users').get(id)))
              }
              data.users = assignedUsers;
            }

            const image = this.order.get('image');
            const has_image = !!this.order.get('image');

            this.order.clear({silent: true});
            this.order.set(self.filterDummy(data));
            this.order.set({image: image, has_image: has_image});

            if (this.order.isValid(true)) {
                self.region.currentView.triggerMethod('validation:success');
                self.onCreateOrder(this.order, method);
            }
            else {
                self.region.currentView.triggerMethod('undo:trigger');
            }
        },

        filterDummy: function (data) {
            var id;
            _(['equipment_family_id', 'equipment_id', 'unit_id']).each(function (key) {
                if ((id = /(\d*)-dummy/.exec(data[key]))) {
                    data.dummy = key;
                    if (key !== 'unit_id' || _.isString(data[key])) {
                        data[key] = id[1];
                    }

                }
            });
            return data;
        },

        onCreateOrder: function (order, method) {
            if (method == "create") {
              this.createOrder(order, method, this.successfullyCreatedMsg.bind(this));
            } else {
              this.createOrder(order, method, this.createAndExecute.bind(this));
            }
        },

        createOrder: function (order, method, actionWhenOrderCreated) {
            var self = this;
            order.save()
                 .always(function () {
                    self.orders.add(order);
                    actionWhenOrderCreated(order);
                    if (method == "create") {
                      self.create_notification(order);
                      self.render();
                    }
                })
        },

        successfullyCreatedMsg: function (order) {
            //Display Success Message
            let msg;
            if ( order.get("is_ticket") ) {
              msg = this.createdTicketMessage(order.id);
            } else {
              msg = this.createdOrderMessage(order.id);
            }
            Radio.global.command('alerts:show', {msg: msg});
        },

        createAndExecute: function (order) {
            //Redirect to Order
            Radio.global.command('router:orders:navigate', ['orders', order.id].join('/'), {trigger: true});
        },

        createdOrderMessage: function  (id) {
          if (id) {
            return '<a href=#/orders/'+id+'> <b>Ordem de trabalho '+id+'</b> criada com sucesso</a>'
          } else {
            return 'Ordem de trabalho <b>guardada</b> para posterior criação'
          }
        },

        createdTicketMessage: function  (id) {
          if (id) {
            return 'Pedido '+id+' criado com sucesso</a>'
          } else {
            return 'Pedido guardada para posterior criação'
          }
        },

        create_notification: function (order) {
          if (order.get('is_ticket')){
            let newNotification = new NotificationModel({
              kind: "Novo Pedido",
              state: "unread",
              order_id: this.order.id,
              rootzone_id: order.get('rootzone_id')
            });

            newNotification.save().then(
              _ => Radio.store.request('get:notifications').add(newNotification)
            );
          }
        }
    });
})();
