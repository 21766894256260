'use strict';

var orderDetailsPartial = require('templates/orders/order-details-partial'),
    orderCollaborators = require('templates/orders/order-collaborators'),
    materialRow = require('templates/orders/material-row'),
    materialRowSelect = require('templates/orders/material-row-select'),
    materialRequestsRow = require('templates/materials/material-requests/material-requests-row'),
    materialRequestsRowSelect = require('templates/materials/material-requests/material-requests-row-select');

Handlebars.registerPartial('order-details-partial', orderDetailsPartial);
Handlebars.registerPartial('order-collaborators', orderCollaborators);
Handlebars.registerPartial('material-row', materialRow);
Handlebars.registerPartial('material-row-select', materialRowSelect);
Handlebars.registerPartial('material-requests-row', materialRequestsRow);
Handlebars.registerPartial('material-requests-row-select', materialRequestsRowSelect);
