(function () {
  'use strict';

  var DoOrderButtonsView = require('./do-order-buttons-view');

  module.exports = DoOrderButtonsView.extend({
    steps: [
      { // Notas
        prev: 'Voltar à Página Inicial da OT',
        next: 'Ir para Confirmação'
      },
      { // Confirmação
        prev: 'Voltar para Notas',
        next: 'Submeter'
      }
    ],

    isValidStep: function (step) {
      return step >= 0 && step <= 3;
    }
  });
})();
