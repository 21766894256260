(function () {
  'use strict';

  var Radio = require('shim/radio'),
      EquipmentCollection = require('collections/equipments');

  module.exports = Marionette.ItemView.extend({
    template: require('templates/settings/typified-orders/typified-order-equipment'),

    initialize: function (options = {}) {
      this.typification = options.typification;
      this.equipmentFamilies = options.equipmentFamilies;
      this.allEquipment = options.allEquipment;
    },

    ui: {
      equipmentFamily: '.equipment_family_id'
    },

    events: {
      'change @ui.equipmentFamily': 'onEquipmentFamilyChange'
    },

    onBeforeRender: function () {
      this.filterCollection();
    },

    onEquipmentFamilyChange: function () {
      let new_family_id = parseInt($(".equipment_family_id").val(), 10);
      if ( new_family_id === null ) { return; }
      this.typification.set({equipment_family: {id: new_family_id}});
      this.filterCollection();
      this.render();
    },

    templateHelpers: function () {
      let equipment = this.typification.get('equipment')

      return {
        familiesList:  this.equipmentFamilies.models,
        familyId:      this.getEquipmentFamilyId(),
        equipmentId:   equipment ? equipment.id : 1
      }
    },

    filterCollection: function () {
      let notApplicable = this.allEquipment.where({_id: 1}),
          family = this.typification.get('equipment_family'),
          filteredModels;

      if ( family ) {
        let filterdEquipment = this.allEquipment.where({equipment_family_id: family.id});
        filteredModels = notApplicable.concat(filterdEquipment);
      } else if ( this.typification.get('equipment') ) {
        filteredModels = this.allEquipment.findWhere({_id: this.typification.get('equipment').id})
      } else {
        filteredModels = notApplicable;
      }
      this.collection.reset(filteredModels);
    },

    getEquipmentFamilyId: function () {
      if ( this.typification.get('equipment_family') ) {
        return this.typification.get('equipment_family').id;
      } else if ( this.typification.get('equipment') ) {
        return this.typification.get('equipment').equipment_family_id;
      } else {
        return -1
      }
    },
  });
})();
