/* global module, require */
(function () {
  'use strict';

  var Radio = require('shim/radio');

  module.exports = Marionette.LayoutView.extend({
    template: require('templates/materials/material-orders/material-orders'),
    tagName: 'div',
    className: 'section-box',

    regions: {
      view:     '.view',
      filters:  '.filters',
    },

    ui: {
      addNewMaterial:           '.add-new-material',
      addToMaterial:            '.add-to-request',
      viewMaterialsRequests:    '.view-materials-requests',
      printMaterialOrders:      '.print-material-orders',
      viewNewMaterialsRequests: '.view-new-materials-requests'
    },

    events: {
      'click @ui.addNewMaterial':           'onAddNewMaterial',
      'click @ui.addToMaterial':            'onAddToRequest',
      'click @ui.viewMaterialsRequests':    'onViewMaterialsRequestsClicked',
      'click @ui.viewNewMaterialsRequests': 'onViewNewMaterialsRequestsClicked',
      'click @ui.printMaterialOrders':      'onPrintMaterialOrders'
    },

    templateHelpers: function () {
      return {
        viewName: 'Encomendas de Materiais'
      }
    },

    onViewMaterialsRequestsClicked: function (event) {
      event.preventDefault();

      var resource = 'router:materials:navigate',
          route    = 'material_requests';

      Radio.global.command(resource, route, { trigger: true });
    },

    onViewNewMaterialsRequestsClicked: function (event) {
      event.preventDefault();

      var resource = 'router:materials:navigate',
          route    = 'new_material_requests';

      Radio.global.command(resource, route, { trigger: true });
    },

    onPrintMaterialOrders: function (event) {
      event.preventDefault();
      Radio.global.trigger('materialOrders:print');
    }
  });
})();
