var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "  <div style=\"margin:10px 20px\">\n      <b>Descrição</b>: "
    + container.escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data}) : helper)))
    + "\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "  <div style=\"margin:10px 20px\">\n      <b>Notas</b>:"
    + container.escapeExpression(((helper = (helper = helpers.notes || (depth0 != null ? depth0.notes : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"notes","hash":{},"data":data}) : helper)))
    + "\n  </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.order_code || (depth0 != null ? depth0.order_code : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"order_code","hash":{},"data":data}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.order_id || (depth0 != null ? depth0.order_id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"order_id","hash":{},"data":data}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    return "  <div style=\"margin-right:20px\">\n    <a class=\"view-order\" style=\"margin-left:5px\">\n      <button class=\"button button-primary\">Ver Pedido</button>\n    </a>\n    <a class=\"edit-order\" style=\"margin-left:5px\">\n      <button class=\"button button-primary\">Editar Pedido</button>\n    </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"box\" style=\"margin:20px\">\n  <header class=\"page-header\" style=\"margin:20px\">\n    <h3>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n  </header>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.notes : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  <div style=\"margin:10px 20px\">\n    <b>Número</b>: "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.order_code : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\n  </div>\n\n  <div style=\"margin:10px 20px\">\n    <b>Tipo de Notificação</b>: "
    + alias4(((helper = (helper = helpers.kind || (depth0 != null ? depth0.kind : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"kind","hash":{},"data":data}) : helper)))
    + "\n  </div>\n\n  <div style=\"margin:10px 20px\">\n      <b>Pedido por</b>: "
    + alias4(((helper = (helper = helpers.created_by || (depth0 != null ? depth0.created_by : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"created_by","hash":{},"data":data}) : helper)))
    + "\n  </div>\n\n  <div style=\"margin:10px 20px\">\n      <b>Criado em</b>: "
    + alias4((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.created_at : depth0),{"name":"moment","hash":{"format":"YYYY-MM-DD HH:mm"},"data":data}))
    + "\n  </div>\n\n  <div style=\"margin:10px 20px\">\n      <b>Atualizado em</b>: "
    + alias4((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.updated_at : depth0),{"name":"moment","hash":{"format":"YYYY-MM-DD HH:mm"},"data":data}))
    + "\n  </div>\n</div>\n\n<div style=\"display:flex;justify-content:space-between\">\n  <a href=\"#notifications\" style=\"margin-left:20px\">\n    <button class=\"button button-primary\">Voltar</button>\n  </a>\n\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.kind : depth0),"Novo Pedido",{"name":"eq","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}