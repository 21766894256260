/* global module, require */
(function () {
  'use strict';

  module.exports = Marionette.LayoutView.extend({

    template: require('templates/main/tasks'),
    tagName: 'div',
    className: 'section-box',

    regions: {
      filters: '.filters',
      view: '.view'
    }
  });
})();
