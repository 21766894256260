(function () {
    'use strict';

    var api = require('shim/api'),

        Dashboard1 = require('models/dashboards/dashboard1');

    module.exports = Backbone.Collection.extend({
        model: Dashboard1,
        url: api.urlRoot + '/dashboards1',

        initialize: function () {
            this.add(new Dashboard1());
            this.add(new Dashboard1());
            this.add(new Dashboard1());
            this.add(new Dashboard1());
            this.add(new Dashboard1());
            this.add(new Dashboard1());
        },

        parse: function (response) {
            return response;
        }
    });

})();
