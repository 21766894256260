(function() {
  'use strict';

  var Radio = require('shim/radio'),

      SettingsController = require('controllers/settings/settings-controller'),
      TypifiedOrdersController = require('controllers/settings/typified-orders-controller'),
      TypifiedOrderController = require('controllers/settings/typified-order-controller'),
      AllUsersCollection  = require('collections/allUsers');

  module.exports = Marionette.Controller.extend({
    initialize: function(options) {
      options = options || {};
      this.rootzones = options.rootzones;
      this.workTypes = options.workTypes;
      this.equipmentFamilies = options.equipmentFamilies;
      this.typifications = options.typifications;
      this.equipment = options.equipment;
      this.units = options.units;
      this.zones = options.zones;
    },

    showSettings: function() {
      new SettingsController().showSettings();
    },

    changePassword: function() {
      new SettingsController().changePassword();
    },

    rootzoneSettings: function() {
      new SettingsController().rootzoneSettings();
    },

    usersSettings: function() {
      var allUsers = new AllUsersCollection;
      allUsers.fetch().then(function () {
        new SettingsController({allUsers}).usersSettings();
      });
    },

    showTypifiedOrders: function() {
      new TypifiedOrdersController({
        typifications: this.typifications
      }).showTypifiedOrders();
    },

    createTypifiedOrder: function () {
      new TypifiedOrderController({
        typifications:      this.typifications,
        workTypes:          this.workTypes,
        equipmentFamilies:  this.equipmentFamilies,
        equipment:          this.equipment,
        units:              this.units,
        zones:              this.zones
      }).createTypifiedOrder();
    },

    showTypifiedOrderById: function(id) {
      id = parseInt(id) || id;

      if (!this.typifications.findWhere({ _id: id }))
          return;

      new TypifiedOrderController({
        typification:       this.typifications.get(id),
        typifications:      this.typifications,
        workTypes:          this.workTypes,
        equipmentFamilies:  this.equipmentFamilies,
        equipment:          this.equipment,
        units:              this.units,
        zones:              this.zones
      }).showTypifiedOrderById();
    }
  });
})();
