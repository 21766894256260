(function () {
    'use strict';

    var BaseRouter = require('routers/base-router');

    module.exports = BaseRouter.extend({
        forbidden: {
            'notifications/:id': ['solicitant', 'operator', 'admin'],
        },

        appRoutes: {
            'notifications/:id': 'showNotificationById',
        }
    });
})();
