var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "              <div class=\"col-xs-6 col-sm-4 col-md-6 col-lg-6\">Colaborador</div>\n              <div class=\"col-xs-4 col-sm-4 col-md-3 col-lg-3\" align=\"center\">Dia</div>\n              <div class=\"col-xs-2 col-sm-4 col-md-3 col-lg-3\" align=\"center\">Tempo</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "              <div class=\"col-sm-4 col-md-4 col-lg-5\">Colaborador</div>\n              <div class=\"col-sm-4 col-md-3 col-lg-3\" align=\"center\">Dia</div>\n              <div class=\"col-sm-3 col-md-3 col-lg-3\" align=\"center\">Tempo</div>\n              <div></div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].readOnly : depths[1]),{"name":"unless","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),0,{"name":"eq","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["order-collaborators"],depth0,{"name":"order-collaborators","hash":{"id":(depth0 != null ? depth0.id : depth0),"date":(depth0 != null ? depth0.date : depth0),"time":(depth0 != null ? depth0.time : depth0),"active_id":(depth0 != null ? depth0.user_id : depth0)},"data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["order-collaborators"],depth0,{"name":"order-collaborators","hash":{"extra":"extra-collab","id":(depth0 != null ? depth0.id : depth0),"date":(depth0 != null ? depth0.date : depth0),"time":(depth0 != null ? depth0.time : depth0),"active_id":(depth0 != null ? depth0.user_id : depth0)},"data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <div class=\"row time-assignment-readonly\">\n                        <div class=\"col-xs-6 col-sm-4 col-md-6 col-lg-6\">\n                            "
    + alias4(((helper = (helper = helpers.user || (depth0 != null ? depth0.user : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user","hash":{},"data":data}) : helper)))
    + "\n                            <input name='collab-report-id' value='"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "' type='hidden'/>\n                            <input name='collaborators' value='"
    + alias4(((helper = (helper = helpers.user_id || (depth0 != null ? depth0.user_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data}) : helper)))
    + "' type='hidden'/>\n                        </div>\n\n                        <div class=\"col-xs-4 col-sm-4 col-md-3 col-lg-3\" align=\"center\">\n                            "
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "\n                            <input name='collab-report-date' value='"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "' type='hidden'/>\n                        </div>\n\n                        <div class=\"col-xs-2 col-sm-4 col-md-3 col-lg-3\" align=\"center\">\n                            "
    + ((stack1 = (helpers.momentTime || (depth0 && depth0.momentTime) || alias2).call(alias1,(depth0 != null ? depth0.time : depth0),{"name":"momentTime","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                            <input name='collab-report-time' value='"
    + ((stack1 = (helpers.momentTime || (depth0 && depth0.momentTime) || alias2).call(alias1,(depth0 != null ? depth0.time : depth0),{"name":"momentTime","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "' type='hidden'/>\n                        </div>\n                    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["order-collaborators"],depth0,{"name":"order-collaborators","hash":{"active_id":(depth0 != null ? depth0.user_id : depth0),"date":(depth0 != null ? depth0.today : depth0),"time":"0"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    return "            <div style=\"margin-top: 20px;\">\n                <div colspan=\"2\" style='pointer-events:none; border-bottom:0;'></div>\n                <div colspan=\"2\" class=\"pull-right more\" style='border-bottom:0;'>\n                  <a href=\"#\" class=\"button button-md button-secondary add-remove-collaborator-btn\">\n                    <i class=\"fa fa-plus-circle\" aria-hidden=\"true\"></i>\n                    <span>Adicionar colaborador</span>\n                  </a>\n                </div>\n            </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return " style=\"pointer-events: none; opacity: 0.4;\" ";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.verified : depth0),"0",{"name":"eq","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"21":function(container,depth0,helpers,partials,data) {
    return "\n                checked ";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.verified : depth0),"3",{"name":"eq","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.verified : depth0),"2",{"name":"eq","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.verified : depth0),"1",{"name":"eq","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"29":function(container,depth0,helpers,partials,data) {
    return "pointer-events: none; opacity: 0.4;";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", buffer = 
  "<fieldset>\n    <legend>Registo de Tempos</legend>\n    <div id=\"time-assignment-info\">\n        <div class=\"row time-assignment-header\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.readOnly : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.readOnly : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n\n";
  stack1 = ((helper = (helper = helpers.interventions || (depth0 != null ? depth0.interventions : depth0)) != null ? helper : alias2),(options={"name":"interventions","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.interventions) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.readOnly : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</fieldset>\n\n<fieldset>\n    <legend style=\"margin-top:0px\">Resumo da Intervenção</legend>\n    "
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n\n    <div class=\"verification-title\">\n      <label>Verificações:</label>\n    </div>\n\n    <div class=\"input-field verification\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.notEditable : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n        <div class=\"radio radio-danger col-md-3\" style=\"margin-top:0\";>\n            <input class=\"radio-change\" type=\"radio\" id=\"radio3\" name=\"verified\" value=\"0\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasFilledVerified : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <label for=\"radio3\">Verifiquei Tudo</label>\n        </div>\n\n        <div class=\"radio radio-danger col-md-3\">\n            <input class=\"radio-change\" type=\"radio\" id=\"radio4\" name=\"verified\" value=\"3\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasFilledVerified : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <label for=\"radio4\">Não Verifiquei Tudo</label>\n        </div>\n\n        <div class=\"radio radio-danger col-md-3\">\n            <input class=\"radio-change\" type=\"radio\" id=\"radio5\" name=\"verified\" value=\"2\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasFilledVerified : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <label for=\"radio5\">Não Realizada</label>\n        </div>\n\n        <div class=\"radio radio-danger col-md-3\">\n            <input class=\"radio-change\" type=\"radio\" id=\"radio6\" name=\"verified\" value=\"1\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasFilledVerified : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <label for=\"radio6\">Em curso</label>\n        </div>\n    </div>\n\n    <div id=\"notes-group\" class=\"input-group col-xs-12\" style=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.notEditable : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        <textarea name=\"notes\" id=\"notes\" cols=\"30\" rows=\"2\" style=\"height: auto\" placeholder=\"Indique um motivo\">"
    + container.escapeExpression(((helper = (helper = helpers.notes || (depth0 != null ? depth0.notes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"notes","hash":{},"data":data}) : helper)))
    + "</textarea>\n        <label for=\"notes\" class=\"sr-only\">Notas da Unidade</label>\n    </div>\n</fieldset>\n";
},"usePartial":true,"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}