(function () {
    'use strict';

    var api = require('shim/api'),

        Task = require('models/task');

    module.exports = Backbone.Collection.extend({
        model: Task,
        store: 'tasks',
        urlRoot: function () {
            return [api.urlRoot, 'orders', this.get('order_id'), 'tasks'].join('/');
        },

        _some: function(states){
            return _.some(this.models, function (task) {
                return _.contains(states, task.get('state'))
            });
        },

        _every: function(states){
            return _.every(this.models, function (task) {
                return _.contains(states, task.get('state'))
            })
        }

    });

})();
