(function () {
    'use strict';

    var Radio = require('shim/radio'),
        dateGroupedCollection = require('collections/date-grouped-collection');

    module.exports = Marionette.CompositeView.extend({
        template: require('templates/calendars/calendar-day'),
        emptyView: require('./calendar-day-empty-view'),
        childView: require('./calendar-day-group-view'),
        childViewContainer: '.panel-group',
        childViewOptions: function () {
            return {
                bypass: this.bypass
            };
        },

        ui: {
            users: '#users'
        },

        behaviors: {
            Radio: {
                'calendar:daily:set:bypass': 'onSetBypass'
            }
        },

        initialize: function (options) {
            this.users = options.users;
            this.today = options.today || new Date();
            this.user = options.user;
            this.bypass = options.bypass; // bypass clicking order/event
            this.orders = options.orders;
            this.collection = dateGroupedCollection({originalCollection: this.orders, today: this.today, addLate: true, view: this});
        },

        onSetBypass: function (bypass) {
            this.bypass = bypass;
        },

        onOrdersFiltered: function () {
            this.collection = dateGroupedCollection({originalCollection: this.orders, today: this.today, addLate: true, view: this});
            this.render();
        },

        onRender: function () {
            if (this.collection.length) {
                this.$childViewContainer.attr('id', 'accordion').addClass('calendar-list');
            }
        },

        templateHelpers: function () {
            var today = moment();
            return {
                user: this.user === 'me' ? Radio.global.request('app:session').get('user').get('_id') : this.user,
                year: today.format('YYYY'),
                month: today.format('MM'),
                users: this.users.toJSON()
            };
        }
    });
})();
