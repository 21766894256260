(function () {
    'use strict';

    var api = require('shim/api'),

        Dashboard3 = require('models/dashboards/dashboard3');

    module.exports = Backbone.Collection.extend({
        model: Dashboard3,
        url: api.urlRoot + '/dashboards3',

        initialize: function () {
            this.add(new Dashboard3());
            this.add(new Dashboard3());
            this.add(new Dashboard3());
            this.add(new Dashboard3());
            this.add(new Dashboard3());
            this.add(new Dashboard3());
        },

        parse: function (response) {
            return response;
        }
    });

})();
