(function () {
  'use strict';

  var Radio = require('shim/radio'),
      PAGE_SIZE = 20;

  module.exports = Marionette.CompositeView.extend({
    template: require('templates/settings/users-list'),
    childView: require('./users-list-item-view'),
    childViewContainer: 'tbody',
    ui: {
      page: '.page',
      pagination: '.dropup'
    },
    events: {
      'click @ui.page': 'onPageClick'
    },
    initialize: function () {
      this.unpaginatedCollection = this.collection;
      this.filteredCollection = this.unpaginatedCollection;
      this.currentPage = 0;
      this._setPage();
    },
    onRender() {
      //Auto scroll pagination dropdown to correct position
      //Top => (current-1) --> (current+1) --> [current+(n+1)]
      this.ui.pagination.on('shown.bs.dropdown', (ev) => {
        let $target = $(ev.currentTarget).find('ul'),
            page = this.currentPage - 2,
            topScroll = (page < 0) ? 0 : page;

        $target.scrollTop(topScroll * 26);
      })
    },
    templateHelpers: function () {
      var totalPages = Math.ceil(this.filteredCollection.length / PAGE_SIZE),
          pages = _.times(totalPages, _.identity)

      return {
        currentPage: this.currentPage,
        pages: pages,
        showPageDropdown: pages.length > 1,
        showStateFilters: true
      };
    },
    onPageClick: function (e) {
      e.preventDefault();
      var $el = $(e.currentTarget);
      this.currentPage = $el.data('page');
      this._setPage();
      this.render();
    },
    _setPage: function () {
      var pageIDX = this.currentPage * PAGE_SIZE;
      this.collection = new Backbone.Collection((this.filteredCollection.models).slice(pageIDX, pageIDX + PAGE_SIZE));
    }
  });
})();
