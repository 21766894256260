(function () {
    'use strict';

    var BaseObject = require('controllers/base-object'),
        Radio = require('shim/radio'),

        SubmittedView = require('views/orders/submitted-view');

    module.exports = BaseObject.extend({
        initialize: function (options) {
            BaseObject.prototype.initialize.apply(this, arguments);
            options = options || {};
            this.order = options.order;
            this.task = options.task;
        },

        render: function () {
            this.showBind(new SubmittedView(this._options()));
        },

        _options: function (mode) {
            return {
                model: this.order,
                task: this.task,
                mode: mode,
                lastMenu: Radio.global.request('get:lastmenu') || 'main:calendar'
            };
        }
    });
})();
