(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.Behavior.extend({
        events: {
            'change @ui.user_input': 'onUserChanged',
            'change @ui.user_group_input': 'onUserGroupChanged',
            'change @ui.calendar_user_input': 'onCalendarUserChanged'
        },

        _filters: function(){
            return {
                filters: Radio.global.request('app:filters'),
                currentFilters: this._getCurrentFilters()
            };
        },

        onUserChanged: function () {
            //Get Filters
            var options = this._filters();

            //Update Global Filter
            options.filters.user_id = options.currentFilters.user_id;

            //Update View
            Radio.global.trigger('filter:orders:change', options.currentFilters);
        },

        onUserGroupChanged: function () {
            //Get Filters
            var options = this._filters();

            //Update Global Filter
            options.filters.user_group_id = options.currentFilters.user_group_id;

            //Update View
            Radio.global.trigger('filter:orders:change', options.currentFilters)
        },

        onCalendarUserChanged: function () {
            Radio.global.trigger('calendar:display:user', {user_id: parseInt($('#calendar_user_input').val(), 10)});
        },

        _getCurrentFilters: function () {
            return {
                user_id: parseInt($('#user_input').val(), 10),
                user_group_id: parseInt($('#user_group_input').val(), 10)
            };
        }
    });
})();
