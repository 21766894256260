(function () {
    'use strict';

    var api = require('shim/api'),

        Zone = require('models/zone');

    module.exports = Backbone.Collection.extend({
        model: Zone,
        url: api.urlRoot + '/zones',
        store: 'zones',

        parse: function (response) {
            return response.zones;
        }
    });
})();
