(function () {
  'use strict';

  var BaseRouter = require('routers/base-router');

  module.exports = BaseRouter.extend({
    forbidden: {
      'material_requests(/)':       ['solicitant', 'operator', 'admin', 'dashboard'],
      'new_material_requests(/)':   ['solicitant', 'operator', 'admin', 'dashboard'],
      'material_orders(/)':         ['solicitant', 'operator', 'admin', 'dashboard'],
      'material_orders/:id':        ['solicitant', 'operator', 'admin', 'dashboard'],
      'material_orders/edit/:id':   ['solicitant', 'operator', 'admin', 'dashboard']
    },

    appRoutes: {
      'material_requests(/)':     'showMaterialRequests',
      'new_material_requests(/)': 'showNewMaterialRequests',
      'material_orders(/)':       'showMaterialOrders',
      'material_orders/:id':      'showMaterialOrderById',
      'material_orders/edit/:id': 'editMaterialOrder'
    }
  });
})();
