(function () {
  'use strict';

  var LoginRouter = require('routers/login-router'),
      LoginController = require('controllers/login-object'),

      DashboardsRouter = require('routers/dashboards-router'),
      DashboardsApi = require('apis/dashboards-api'),

      OrdersRouter = require('routers/orders-router'),
      OrdersApi = require('apis/orders-api'),

      MainRouter = require('routers/main-router'),
      MainApi = require('apis/main-api'),

      CalendarRouter = require('routers/calendar-router'),
      CalendarApi = require('apis/calendar-api'),

      SettingsRouter = require('routers/settings-router'),
      SettingsApi = require('apis/settings-api'),

      NotificationsRouter = require('routers/notifications-router'),
      NotificationsApi = require('apis/notifications-api'),

      MaterialsRouter = require('routers/materials-router'),
      MaterialsApi = require('apis/materials-api');

  module.exports = function (app) {
    return {
      loginRouter: new LoginRouter({
        controller: new LoginController()
      }),

      dashboardsRouter: new DashboardsRouter({
        controller: new DashboardsApi({
          rootzones: app.rootzones
        })
      }),

      mainRouter: new MainRouter({
        controller: new MainApi({
          users:         app.users,
          orders:        app.orders,
          issues:        app.issues,
          notifications: app.notifications,
          groups:        app.userGroups
        })
      }),

      calendarRouter: new CalendarRouter({
        controller: new CalendarApi({
          users:          app.users,
          userGroups:     app.userGroups,
          notifications:  app.notifications,
          orders:         app.orders
        })
      }),

      orderRouter: new OrdersRouter({
        controller: new OrdersApi({
          users:              app.users,
          orders:             app.orders,
          groups:             app.userGroups,
          typifications:      app.typifications,
          work_types:         app.workTypes,
          equipment_families: app.equipmentFamilies,
          equipments:         app.equipments,
          units:              app.units,
          zones:              app.zones,
          materials:          app.materials,
          categories:         app.materialCategories
        })
      }),

      settingsRouter: new SettingsRouter({
        controller: new SettingsApi({
          rootzones:          app.rootzones,
          zones:              app.zones,
          typifications:      app.typifications,
          workTypes:          app.workTypes,
          equipmentFamilies:  app.equipmentFamilies,
          equipment:          app.equipments,
          units:              app.units
        })
      }),

      notificationRouter: new NotificationsRouter({
        controller: new NotificationsApi({
          notifications: app.notifications
        })
      }),

      materialsRouter: new MaterialsRouter({
        controller: new MaterialsApi({
          materials:  app.materials,
          categories: app.materialCategories
        })
      })
    };
  };
})();
