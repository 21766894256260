(function () {
    'use strict';

    var api = require('shim/api'),
        Radio = require('shim/radio'),
        OfflineQueue = require('offline/offline-queue');

    require('offline/offline-sync');

    var OfflineController = Marionette.Object.extend({
        url: api.isAlive,
        spinnerOn: false,

        initialize: function () {
            Radio.global.reply('offline:check:state', this.checkState, this);
            Radio.global.on('request:pushed', this.onRequestPushed, this);
            Radio.global.on('offline:check:queue', this.onCheckQueue, this);

            new OfflineQueue();
        },

        onBeforeDestroy: function () {
            Radio.global.stopReplying('offline:check:state', this.checkState, this);
            Radio.global.off('request:pushed', this.onRequestPushed, this);
            Radio.global.off('offline:check:queue', this.onCheckQueue, this);
        },

        checkState: function () {
            return this._ping();
        },

        onRequestPushed: function () {
            this._checkSpinner();
        },

        onCheckQueue: function () {
            this._checkSpinner();
        },

        _ping: function () {
            return $.get(this.url);
        },

        _checkSpinner: function () {
            var count = Radio.global.request('queue:size');
            if (count > 0) {
                this.spinnerOn = true;
                Radio.global.trigger('spinner:on', {count: count});
            }
        }
    });

    var controller = null;

    Radio.global.comply('offline:initialize', function () {
        if (controller === null) {
            console.log('Initializing Offline Controller');
            controller = new OfflineController();
        }
    });

    Radio.global.comply('offline:terminate', function () {
        if (controller !== null) {
            console.log('Terminating Offline Controller');
            controller.destroy();
            controller = null;
        }
    });

    module.exports = OfflineController;
})();
