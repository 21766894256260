'use strict';

var BaseObject = require('controllers/base-object'),
    ReportsView = require('views/main/reports/reports-view.js'),
    ReportModel = require('models/report'),
    ReportsCollection = require('collections/reports');


module.exports = BaseObject.extend({

    initialize: function () {
        BaseObject.prototype.initialize.apply(this, arguments);

        //Fetch reports
        const reports = new ReportsCollection();
        reports.fetch()
            .done(() => {
                this.renderView(reports);
            })
    },

    renderView(collection) {
        $('.search-ot-group').hide();
        //Pluck available unique years. eg. [2016, 2017]
        const years = [...new Set(collection.map(m => m.get('year')))];
        this.show(new ReportsView({ collection, years }), this);
    }

});
