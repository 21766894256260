var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<p>\n    <button href=\"#\" class=\"button button-default button-md-pad button-previous\">\n        <i class=\"fa fa-chevron-left\"></i><span>Voltar à Página Inicial da OT</span>\n    </button>\n    <span class=\"pull-right\">\n    <button class=\"button button-primary button-md-pad button-unapproved\"><i class=\"fa fa-chevron-left\"></i><span>DummyText</span></button>\n    <button class=\"button button-primary button-md-pad button-next\"><span>Ir para os Parâmetros</span><i\n        class=\"fa fa-chevron-right\"></i></button>\n  </span>\n</p>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}