var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    Em Atraso ("
    + container.escapeExpression(((helper = (helper = helpers.size || (depth0 != null ? depth0.size : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data}) : helper)))
    + ")\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    "
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),{"name":"moment","hash":{"format":"dddd","lang":"pt"},"data":data}))
    + " ("
    + alias3(((helper = (helper = helpers.size || (depth0 != null ? depth0.size : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"size","hash":{},"data":data}) : helper)))
    + ")\n    <span>"
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),{"name":"moment","hash":{"format":"DD MMMM","lang":"pt"},"data":data}))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=helpers.blockHelperMissing, buffer = 
  "      <li id=\"order-line-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_declined_verification : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_ticket : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n        <a href=\"#orders/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" state=\""
    + alias4(((helper = (helper = helpers.state_id || (depth0 != null ? depth0.state_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"state_id","hash":{},"data":data}) : helper)))
    + "\" type=\""
    + alias4(((helper = (helper = helpers.order_category_id || (depth0 != null ? depth0.order_category_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"order_category_id","hash":{},"data":data}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\">\n          <div style=\"margin-bottom:15px\">\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.work_type_id : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "            <b>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</b>\n            <br/>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.work_type_title : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasNotesInOrderOrInUnits : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </a>\n\n";
  stack1 = ((helper = (helper = helpers.isNotOperatorAndSolicitant || (depth0 != null ? depth0.isNotOperatorAndSolicitant : depth0)) != null ? helper : alias2),(options={"name":"isNotOperatorAndSolicitant","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isNotOperatorAndSolicitant) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        <div class=\"ot-summary-details\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_declined_verification : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_declined_validation : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n          <span>"
    + alias4((helpers.getDateForOrderItem || (depth0 && depth0.getDateForOrderItem) || alias2).call(alias1,(depth0 != null ? depth0.start_date_preview : depth0),(depth0 != null ? depth0.end_date_real : depth0),{"name":"getDateForOrderItem","hash":{"format":"ddd, DD MMM","ang":"pt"},"data":data}))
    + " &nbsp;\n            <i aria-hidden=\"true\" class=\"fa fa-calendar\"></i>\n          </span>\n\n          <span>\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.spentTime : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.program(36, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + " &nbsp;\n            <i aria-hidden=\"true\" class=\"fa fa-clock-o\"></i>\n          </span>\n\n";
  stack1 = ((helper = (helper = helpers.getUsers || (depth0 != null ? depth0.getUsers : depth0)) != null ? helper : alias2),(options={"name":"getUsers","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.getUsers) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_image : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasMaterials : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.priority : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.notDone : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.material_assignment_status : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depths[1] != null ? depths[1].ot_type : depths[1]),"D",{"name":"eq","hash":{},"fn":container.program(56, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depths[1] != null ? depths[1].ot_type : depths[1]),"V",{"name":"eq","hash":{},"fn":container.program(59, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depths[1] != null ? depths[1].ot_type : depths[1]),"S",{"name":"eq","hash":{},"fn":container.program(61, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "          style=\"background: #FFF9C4\"\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          "
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.work_type_id : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n          ";
},"9":function(container,depth0,helpers,partials,data) {
    return "style=\"background: #d2e1eb\"";
},"11":function(container,depth0,helpers,partials,data) {
    return "            [Pedido]\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.number : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            "
    + container.escapeExpression(((helper = (helper = helpers.number || (depth0 != null ? depth0.number : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"number","hash":{},"data":data}) : helper)))
    + " -\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            "
    + alias4(((helper = (helper = helpers.equipment_title || (depth0 != null ? depth0.equipment_title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"equipment_title","hash":{},"data":data}) : helper)))
    + " | "
    + alias4(((helper = (helper = helpers.work_type_title || (depth0 != null ? depth0.work_type_title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"work_type_title","hash":{},"data":data}) : helper)))
    + "\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.work_type_title : depth0),"Preventivo",{"name":"eq","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            ("
    + container.escapeExpression(((helper = (helper = helpers.period_title || (depth0 != null ? depth0.period_title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"period_title","hash":{},"data":data}) : helper)))
    + ")\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "          <div class=\"order-notes\">\n            <i aria-hidden=\"true\" class=\"fa fa-file-text-o\"></i>\n            &nbsp;\n            <span>"
    + ((stack1 = ((helper = (helper = helpers.formatNotes || (depth0 != null ? depth0.formatNotes : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"formatNotes","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n          </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isDoneValidatedOrVerified : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.time_deviation || (depth0 != null ? depth0.time_deviation : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"time_deviation","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.time_deviation) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "              <div class=\"ot-time-deviation\">\n                    <span>Desvio</span>\n\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.kind : depth0),"negative",{"name":"eq","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.kind : depth0),"none",{"name":"eq","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.kind : depth0),"positive",{"name":"eq","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    <span style=\"font-size:12px;\">Face ao tempo previsto</span>\n              </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                      <div  class=\"negative-deviation\">\n                        <i class=\"fa fa-arrow-down \" aria-hidden=\"true\"></i>\n                        <span>"
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data}) : helper)))
    + "</span>\n                      </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                      <div  class=\"\">\n                        <span>"
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data}) : helper)))
    + "</span>\n                      </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                      <div  class=\"positive-deviation\">\n                        <i class=\"fa fa-arrow-up\" aria-hidden=\"true\"></i>\n                        <span>"
    + container.escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data}) : helper)))
    + "</span>\n                      </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "            <span>Não Fechada &nbsp;\n              <i aria-hidden=\"true\" class=\"fa fa-exclamation-triangle\"></i>\n            </span>\n";
},"32":function(container,depth0,helpers,partials,data) {
    return "            <span>Não Validada &nbsp;\n              <i aria-hidden=\"true\" class=\"fa fa-exclamation-triangle\"></i>\n            </span>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.spentTime || (depth0 != null ? depth0.spentTime : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"spentTime","hash":{},"data":data}) : helper)));
},"36":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.duration || (depth0 != null ? depth0.duration : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"duration","hash":{},"data":data}) : helper)));
},"38":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <span>"
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)))
    + "  &nbsp;<i aria-hidden=\"true\" class=\"fa fa-users\"></i></span>\n";
},"40":function(container,depth0,helpers,partials,data) {
    return "            <span>Tem Imagem &nbsp;<i aria-hidden=\"true\" class=\"fa fa-file-image-o\"></i></span>\n";
},"42":function(container,depth0,helpers,partials,data) {
    return "            <span>Tem materiais &nbsp;<i aria-hidden=\"true\" class=\"fa fa-file-text-o\"></i></span>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "            <span "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.priority : depth0)) != null ? stack1.value : stack1),1,{"name":"eq","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n              Prioridade: "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.priority : depth0)) != null ? stack1.value : stack1), depth0))
    + " ("
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.priority : depth0)) != null ? stack1.shortText : stack1), depth0))
    + ") &nbsp;\n              <i class=\"fa fa-first-order\" aria-hidden=\"true\"></i>\n            </span>\n";
},"45":function(container,depth0,helpers,partials,data) {
    return " style=\"color: red;\" ";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.all_materials_received : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.waits_material_delivery : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.waits_material_order : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"48":function(container,depth0,helpers,partials,data) {
    return "              <span style=\"color:#016f38\">Encomenda Recebida &nbsp;<i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i></span>\n";
},"50":function(container,depth0,helpers,partials,data) {
    return "              <span style=\"color:#d82a2a;\">Aguarda entrega &nbsp;<i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i></span>\n";
},"52":function(container,depth0,helpers,partials,data) {
    return "              <span style=\"color:#d82a2a;\">Por encomendar &nbsp;<i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i></span>\n";
},"54":function(container,depth0,helpers,partials,data) {
    return "            <span style=\"color:#B40043\">Material sem stock &nbsp;<i aria-hidden=\"true\" class=\"fa fa-exclamation-circle\"></i></span>\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <div class=\"checkbox checkbox-primary checkbox-container\">\n            <input type=\"checkbox\" class=\"order checkbox-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" id=\"checkbox-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSelected : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <label for=\"checkbox-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">&nbsp</label>\n          </div>\n";
},"57":function(container,depth0,helpers,partials,data) {
    return "\n            checked ";
},"59":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <div class=\"button-container\">\n          <div class=\"button button-success btn-dashboard dashboard-item-btn validate-on-dashboard\" data-id=\""
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n            <i aria-hidden=\"true\" class=\"fa fa-check\"></i> Validar\n          </div>\n        </div>\n";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isClosedAndHasMaterialIssues : depth0),{"name":"unless","hash":{},"fn":container.program(62, data, 0),"inverse":container.program(67, data, 0),"data":data})) != null ? stack1 : "");
},"62":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasNotesInOrderOrInUnits : depth0),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.program(65, data, 0),"data":data})) != null ? stack1 : "");
},"63":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <div class=\"btn-group button-container\">\n                <div>\n                  <button type=\"button\" class=\"btn btn-success btn-before-dropdown-dashboard verify-on-dashboard\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                    <i aria-hidden=\"true\" class=\"fa fa-check\"></i> Fechar\n                  </button>\n                </div>\n                <div>\n                  <button type=\"button\" class=\"btn btn-success btn-dropdown-dashboard dropdown-toggle\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n                    <span class=\"caret\" style=\"color: white\"></span>\n                    <span class=\"sr-only btn-success\">Toggle Dropdown</span>\n                  </button>\n                  <ul class=\"dropdown-menu dropdown-menu-right\">\n                    <li><a class=\"dropdown-options verify-on-dashboard\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" href=\"#\">Fechar</a></li>\n                    <li><a class=\"dropdown-options verify-and-issue-on-dashboard\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" href=\"#\">Fechar+Assunto</a></li>\n                  </ul>\n                </div>\n              </div>\n";
},"65":function(container,depth0,helpers,partials,data) {
    var helper;

  return "              <div class=\"button-container\">\n                <div class=\"button button-success btn-dashboard verify-on-dashboard\" data-id=\""
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)))
    + "\" >\n                  <i aria-hidden=\"true\" class=\"fa fa-check\"></i> Fechar\n                </div>\n              </div>\n";
},"67":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"btn-group button-container\">\n            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, buffer = 
  "<div class=\"panel-heading\">\n  <h4 class=\"panel-title\">\n    <a href=\"#"
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),{"name":"moment","hash":{"format":"YYYYMMDD","lang":"pt"},"data":data}))
    + "\" title=\""
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),{"name":"moment","hash":{"format":"dddd","lang":"pt"},"data":data}))
    + "\" data-toggle=\"collapse\"\n    class=\"collapsed paneltitle\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isLate : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "  </a>\n</h4>\n</div>\n\n<div id=\""
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),{"name":"moment","hash":{"format":"YYYYMMDD","lang":"pt"},"data":data}))
    + "\" class=\"panel-collapse collapse panelbody\">\n  <div class=\"panel-body\">\n    <ul class=\"day-view\">\n";
  stack1 = ((helper = (helper = helpers.items || (depth0 != null ? depth0.items : depth0)) != null ? helper : alias2),(options={"name":"items","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.items) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </ul>\n  </div>\n</div>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}