(function () {
    'use strict';

    const Compress = require('compress.js');

    module.exports = Marionette.Behavior.extend({

        events: {
            'click @ui.imageUpload': 'onImageClicked',
            'change @ui.fileInput': 'onImageChanged'
        },

        onImageClicked: function () {
            this.view.ui.fileInput.trigger('click');
        },

        onImageChanged: function (event) {
            let avatar = event.currentTarget.files[0];
            if (avatar) {
                this.compress(avatar);
            }
        },

        compress: function (avatar) {
            const compress = new Compress(), { view } = this;
            compress.compress([avatar], {
                size: .2, // the max size in MB, defaults to 2MB
                quality: 1, // the quality of the image, max is 1
            }).then(data => {
                view.uploadImage(data[0].prefix + data[0].data)
            });
        }

    });
})();
