(function () {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: require('templates/alerts'),

    initialize: function (options) {
      this.options = options;
    },

    behaviors: {
      Radio: {
        'alert:dynamic:increase': 'dynamicIncrease'
      }
    },
    dynamicIncrease: function(a){
      this.$('.progress-bar').css({width:Math.floor(a*100)+'%'});
      this.$('.progress-bar').text(Math.floor(a*100)+'%');
    },

    serializeData: function () {
      return this.options;
    }
  });
})();
