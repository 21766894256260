(function () {
    'use strict';

    const api = require('shim/api');

    module.exports = Backbone.Collection.extend({
        model: require('models/report'),
        url: api.urlRoot + '/reports',
        store: 'reports',

        parse: function ({ reports }) {
            return reports;
        }
    });
})();
