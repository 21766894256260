(function () {
  'use strict';

  var Radio = require('shim/radio');

  module.exports = Marionette.ItemView.extend({
    template: require('templates/main/issues-list-item'),
    tagName: 'tr',
    ui: {
      closeIssue: '.close-issue',
      clipboard: '.btn-clipboard'
    },
    events: {
      'click @ui.closeIssue': 'onCloseIssueClicked',
      'click @ui.clipboard':  'onClipboardButtonClick'
    },
    modelEvents: {
      "change": "render"
    },

    templateHelpers: function () {
      return {
        formatIssue: function (text){
          if (!text) { return; }

          let formattedDescription = "",
              issuesDescriptions = text.split('[Notas da Unidade]'),
              orderNotes = issuesDescriptions[0].trim(),
              tasksNotes = "";

          if (issuesDescriptions.length > 1 && !!issuesDescriptions[1]) {
            tasksNotes = issuesDescriptions[1].trim().split(/\n/g);
            tasksNotes = tasksNotes.map( taskNote => taskNote.trim() );
            tasksNotes = tasksNotes.filter( taskNote => !!taskNote );
            tasksNotes = tasksNotes.join("<br>").replace("> Não aplicável","");
            if (!orderNotes) {tasksNotes = "Notas nas seguintes unidades:<br>" + tasksNotes}
          }

          formattedDescription = orderNotes;
          formattedDescription += !!formattedDescription ? "<br>" + tasksNotes : tasksNotes;
          return new Handlebars.SafeString(formattedDescription);
        }
      };
    },

    onCloseIssueClicked: function (e) {
      e.preventDefault();
      let issue = Radio.store.request('get:issues').get(e.currentTarget.dataset.id);
      issue.closeIssue();
    },

    onClipboardButtonClick: function (e) {
      var $temp = $("<input>");
      var element = $(e.currentTarget).parents("tr").children(".description");

      $("body").append($temp);
      $temp.val( $(element).text() ).select();
      document.execCommand("copy");
      $temp.remove();
    }
  });
})();
