(function () {
  'use strict';

  var api = require('shim/api'),
      vCollection = require('collections/mixins/virtual-collection'),
      MaterialOrder = require('models/material-order');

  module.exports = Backbone.Collection.extend({
    model:  MaterialOrder,
    url:    api.urlRoot + '/material_orders',
    store:  'materialOrders',
    comparator: 'id',

    constructor: function () {
        Backbone.Collection.apply(this, arguments);
        this.vCollection = vCollection.bind(this);
    },

    parse: function (response) {
      return response.material_orders;
    },

    generatePDF: function (materialOrderIds, currentFilters) {
      let self    = this,
          url     = this.url + '/generate_list',
          options = {
              url:  url,
              type: 'POST',
              data:  JSON.stringify({material_order_ids: materialOrderIds,  filters: currentFilters}),
              contentType: 'application/json'
          };

      return (self.sync || Backbone.sync).call(self, null, self, options);
    }
  });
})();
