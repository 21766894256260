(function () {
  'use strict';

  var api = require('shim/api');

  module.exports = Backbone.Model.extend({
    idAttribute: 'id',
    urlRoot: api.urlRoot + '/material_orders',

    validation: {
      status: {
        required: true,
        msg: 'Selecione um estado.'
      },
      company: {
        required: function () {
          return this.get('status') !== 'Pendente';
        },
        msg: 'Insira o nome da empresa.'
      },
      due_date: {
        required: function () {
          return this.get('status') !== 'Pendente'
        },
        msg: 'Insira a data prevista de entrega.'
      },
      closed_date: {
        required: function () {
          return this.get('status') === 'Fechado'
        },
        msg: 'Defina a data de entrega.'
      }
    },

    printDetails: function () {
      let self    = this,
          url     = this.urlRoot + '/' + this.id + '/print_details',
          options = {
              url:  url,
              type: 'POST',
              contentType: 'application/json'
          };

      return (self.sync || Backbone.sync).call(self, null, self, options);
    }
  });
})();
