(function () {
  'use strict';

  var BaseObject = require('controllers/base-object'),
      Radio      = require('shim/radio'),

      TypifiedOrdersLayout = require('layouts/typified-orders-layout'),
      TypifiedOrdersView   = require('views/settings/typified-orders/typified-orders-list-view'),
      FiltersView          = require('views/main/filters-view');

  module.exports = BaseObject.extend({
    radio: {
      'filter:typified-orders:change': 'onFiltersChange'
    },

    initialize: function (options = {}) {
      BaseObject.prototype.initialize.apply(this, arguments);
      this.typifications = options.typifications;
      this.currentFilters = Radio.global.request('app:filters');
      this.sanitizeFilters(this.currentFilters);
      this.filteredCollection = this.typifications.vCollection(this.currentFilters, this);
    },

    showTypifiedOrders: function(options = {}){
      $('.search-ot-group').show();
      
      var typifiedOrdersLayout = new TypifiedOrdersLayout()

      //Render MainView
      this.show(typifiedOrdersLayout, this);

      //Render SubViews
      typifiedOrdersLayout.showChildView('view', new TypifiedOrdersView({
        collection: this.filteredCollection
      }));

      typifiedOrdersLayout.showChildView('filters', new FiltersView({
        typifiedOrdersCategories:   this.typifications.getCategories(),
        showTypifiedOrderCategory:  true,
      }));

      Radio.global.trigger('anim-loading:hide');
    },

    sanitizeFilters: function (filters) {
      var merged_filters =  _.merge( this.currentFilters, filters, function (a, b) {
        if (_.isArray(a) || b === false) return b;
      });

      this.currentFilters = _.pick(merged_filters, function (value) {
        return value !== false && value != '-1';
      });
    },

    onFiltersChange: function (options) {
      this.sanitizeFilters(options);

      this.filteredCollection.updateFilterParams(this.currentFilters);
    }
  });
})();
