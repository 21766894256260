'use strict';

// USAGE
//  Switch-case for Handlebars:
//  {{#switch var}}
//    {{#when 'value1'}}...{{/when}}
//    {{#when 'value2' 'value3' ... 'valueN'}}...{{/when}}
//    {{else}}
//      ...
//  {{/switch}}
Handlebars.registerHelper("switch", function (param, obj) {
  var res;
  this._isSwitch = true;
  this._switchparam = param;
  res = obj.fn(this, obj);
  if (!this._switchdone) {
    res = obj.inverse(this, obj);
  }
  delete this._isSwitch;
  delete this._switchdone;
  delete this._switchparam;
  return res;
});

Handlebars.registerHelper("getStatusIcon", function (status) {
  switch(status) {
    case "complete":
    return "<i aria-hidden='true' class='i-state-complete fa fa-check'></i>";
    break;
    case "uncompleted":
    return "<i aria-hidden='true' class='i-state-uncompleted fa fa-exclamation-triangle'></i>";
    break;
    case "unrealized":
    return "<i aria-hidden='true' class='i-state-unrealized fa fa-times'></i>";
    break;
    default:
    return "<i aria-hidden='true' class='i-state-ongoing fa fa-cogs'></i>";
  }
});

Handlebars.registerHelper("when", function () {
  var obj;
  if (!(this._isSwitch != null)) {
    throw new Error("When statement outside a switch.");
  }
  if (this._switchdone) {
    return;
  }
  obj = Array.prototype.pop.apply(arguments);
  if (_.contains(arguments, this._switchparam)) {
    this._switchdone = true;
    return obj.fn(this, obj);
  }
});

// USAGE
//  JSON Stringify for Handlebars:
//  {{{json object}}}
Handlebars.registerHelper('json', function(context) {
  return JSON.stringify(context);
});

// HB-Helpers Equal{eq} extended to support different type comparison
// ex: Compare string with integer
Handlebars.registerHelper('eqx', function(a, b, options) {
  if (arguments.length === 2) {
    options = b;
    b = options.hash.compare;
  }
  if (a == b) {
    return options.fn(this);
  }
  return options.inverse(this);
});

//Implementation of a simple for cicle
Handlebars.registerHelper('times', function(n, block) {
  let accum = '';
  for(let i = 0; i < n; ++i) { accum += block.fn(i); }
  return accum;
});

//Concact 2 strings
Handlebars.registerHelper('concat', function(prefix, id) {
  return (prefix + id);
});

//Implementation of logical operations
Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
  switch (operator) {
    case '==':
    return (v1 == v2) ? options.fn(this) : options.inverse(this);
    case '===':
    return (v1 === v2) ? options.fn(this) : options.inverse(this);
    case '!=':
    return (v1 != v2) ? options.fn(this) : options.inverse(this);
    case '!==':
    return (v1 !== v2) ? options.fn(this) : options.inverse(this);
    case '<':
    return (v1 < v2) ? options.fn(this) : options.inverse(this);
    case '<=':
    return (v1 <= v2) ? options.fn(this) : options.inverse(this);
    case '>':
    return (v1 > v2) ? options.fn(this) : options.inverse(this);
    case '>=':
    return (v1 >= v2) ? options.fn(this) : options.inverse(this);
    case '&&':
    return (v1 && v2) ? options.fn(this) : options.inverse(this);
    case '||':
    return (v1 || v2) ? options.fn(this) : options.inverse(this);
    default:
    return options.inverse(this);
  }
});

//Implementation of getting date when spent time exists
Handlebars.registerHelper('getDateForOrderItem', function (start_date_preview, end_date_real, params) {
  const { format: dateFormat } = params.hash;
  return moment(end_date_real ? end_date_real : start_date_preview).format(dateFormat);
});

//Implementation of getting status from order
Handlebars.registerHelper('getIssueStatus', function (status) {
  return status ? "Fechado" : "Aberto";
});

// Convert time in minutes to hh:mm
Handlebars.registerHelper('momentTime', function (time) {
  //value in minutes
  time = Math.round(time/60);
  if (time < 60) {
    return "00:" + moment.duration(time, "minutes").format("mm");
  }
  return moment.duration(time, "minutes").format("HH:mm");
});

//Capitalize string
Handlebars.registerHelper('toTitleCase', function (string) {
  return string.replace(/\w\S*/g, function(txt){
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
});
