(function () {
    'use strict';
    
    module.exports = Marionette.Behavior.extend({
        events: {
            'focusout #start_date_preview': 'onStartDateChange',
            'focusout #end_date_preview': 'onEndDateChange'
        },

        onStartDateChange: function () {
            var initDate = moment($('#start_date_preview').val()),
                endDate = moment($('#end_date_preview').val());

            if (!this.options.usedDateHelper) {
                $('#end_date_preview').val(initDate.add(parseInt(this.view.timeToPersist)).format('YYYY-MM-DD'));
                this.options.usedDateHelper = true;
            }
            else if (endDate.isBefore(initDate)) {
                $('#end_date_preview').val(initDate.format('YYYY-MM-DD'));
            }

        },

        onEndDateChange: function () {
            var initDate = moment($('#start_date_preview').val()),
                endDate = moment($('#end_date_preview').val());

            if (!this.options.usedDateHelper) {
                $('#start_date_preview').val(endDate.subtract(parseInt(this.view.timeToPersist)).format('YYYY-MM-DD'));
                this.options.usedDateHelper = true;
            }
            else if (initDate.isAfter(endDate)) {
                $('#start_date_preview').val(endDate.format('YYYY-MM-DD'));
            }

        }
    });
})();
