(function(){
  'use strict';
  var isNull = require('underscore').isNull,
      isUndefined = require('underscore').isUndefined;

  module.exports.removeBreaks = removeBreaks;

  function removeBreaks(el){
    if(isNull(el) || isUndefined(el)){
      return el;
    }
    return el.replace(/<br\/>/g,'\n');
  }
})();
