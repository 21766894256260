(function () {
    'use strict';

    module.exports = Marionette.LayoutView.extend({
        template: require('templates/calendars/calendar'),
        tagName: 'div',
        className: 'section-box',

        regions: {
            filters: '.filters',
            summary: '.summary',
            view: '.view'
        },

        ui: {
            monthControl: '.month',
            dayControl: '.day'
        },

        behaviors: {
            CalendarControls: {}
        }
    });
})();
