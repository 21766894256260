(function () {
    'use strict';

    module.exports = Marionette.ItemView.extend({
        template: require('templates/orders/submitted'),

        initialize: function (options) {
            options = options || {};
            this.task = options.task;
            this.lastMenu = options.lastMenu;

            this.status = this.task ? this.task.get('state') : this.model.get('state');
            this.mode = !options.task;
        },

        templateHelpers: function () {
            var action, interventions, report, time;

            //If Task - Fetch Task Info
            if (this.task) {
                interventions = this.task.get('interventions_attributes') || this.task.get('interventions');
                report = interventions && interventions[0] ? interventions[0].time : false;
                time = report ? moment.duration(report).humanize() : false;
            }

            //Define Action
            if (this.status === 'verified' || this.status === 'validated') {
                action = this.status === 'verified' ? 'verificada' : 'validada';
            }
            else {
                action = 'submetida';
            }

            return {
                mode: this.mode,
                action: action,
                type: this.task ? 'Unidade' : 'Ordem de Trabalho',
                subtitle: this.task ? this.task.get('unit').get('title') : this.model.get('title'),
                time: time,
                lastMenu: (this.lastMenu).split(':')[1]
            };
        }
    });
})();
