/* global module, require */
(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.LayoutView.extend({
        template: require('templates/settings/users'),
        tagName: 'div',
        className: 'section-box',
        regions: {
          filters: '.filters',
          view: '.view'
        },
        ui: {
          usersWithAccess:   '#users-access',
          usersWithNoAccess: '#users-no-access',
          resetUsersFilter:  '.reset-filters'
        },
        events: {
          'click @ui.usersWithAccess':    'onUsersWithAccessChecked',
          'click @ui.usersWithNoAccess':  'onUsersWithNoAccessChecked',
          'click @ui.resetUsersFilter':   'onResetFilters'
        },

        onUsersWithAccessChecked () {
          let options = {
            withAccess: $('.users-access').is(':checked'),
            withNoAccess: $('.users-no-access').is(':checked'),
            filter: "open"
          };

          Radio.global.trigger('user:filter:access', options );
        },

        onUsersWithNoAccessChecked () {
          let options = {
            withAccess: $('.users-access').is(':checked'),
            withNoAccess: $('.users-no-access').is(':checked'),
            filter: "open"
          };
          
          Radio.global.trigger('user:filter:access', options );
        },

        onResetFilters () {
          Radio.global.trigger('user:filter:reset');
        }
    });
})();
