var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=helpers.helperMissing;

  return "<div class=\"box\" style=\"margin:20px\">\n  <header class=\"page-header\" style=\"margin:20px;margin-bottom:60px\">\n    <h3>Tipificação</h3>\n  </header>\n\n  <form action=\"#\">\n    <div class=\"view\"></div>\n\n    <div class=\"equipment\"></div>\n\n    <div class=\"checkbox checkbox-ticket\" style=\"margin: 20px\">\n      <input type=\"checkbox\" id=\"active\" name=\"active\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      <label for=\"active\">Activa? </label>\n    </div>\n  </form>\n\n  <hr style=\"margin-top:40px\">\n\n  <div style=\"margin:10px 20px\">\n      <b>Criado por</b>: "
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.creator : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n  </div>\n\n  <div style=\"margin:10px 20px\">\n      <b>Criado em</b>: "
    + alias2((helpers.moment || (depth0 && depth0.moment) || alias3).call(alias1,(depth0 != null ? depth0.created_at : depth0),{"name":"moment","hash":{"format":"DD-MM-YYYY HH:mm"},"data":data}))
    + "\n  </div>\n\n  <div style=\"margin:10px 20px\">\n      <b>Última atualização em</b>: "
    + alias2((helpers.moment || (depth0 && depth0.moment) || alias3).call(alias1,(depth0 != null ? depth0.updated_at : depth0),{"name":"moment","hash":{"format":"DD-MM-YYYY HH:mm"},"data":data}))
    + "\n  </div>\n</div>\n\n<div style=\"display:flex;\">\n  <a href=\"#settings/typified_orders\" style=\"margin-left:20px\">\n    <button class=\"button button-primary\">\n      <i class=\"fa fa-arrow-left\" aria-hidden=\"true\"></i>\n      <span>Voltar</span>\n    </button>\n  </a>\n  <a style=\"margin-left:10px\">\n    <button class=\"button button-success save-typified-order\">\n      <i class=\"fa fa-floppy-o\" aria-hidden=\"true\"></i>\n      <span>Gravar</span>\n    </button>\n  </a>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}