/* global module, require */
(function () {
  'use strict';

  let Radio = require('shim/radio');

  module.exports = Marionette.LayoutView.extend({
    template: require('templates/settings/typified-orders/typified-order'),
    tagName: 'div',
    className: 'section-box',

    regions: {
      view:      '.view',
      equipment: '.equipment'
    },

    ui: {
      saveTypifiedOrder: '.save-typified-order'
    },

    events: {
      'click @ui.saveTypifiedOrder': 'onSaveTypifiedOrder'
    },

    initialize: function () {
      Backbone.Validation.bind(this, {model: this.model});

      this.user = Radio.global.request('app:session').get('user');
    },

    onSaveTypifiedOrder: function (){
      let option = {},
          data = _.extend(
            {active: $('#active').is(":checked")},
            this.$el.serializeObject()
          );

      if ( data.equipment_id === '1' ) {
        data.equipment_id = null
      }

      if ( data.equipment_family_id === '-1' ) {
        data.equipment_family_id = null
      }

      $('#active').isChecked
      if ( !this.model.id ) {
        data.rootzone_id = this.user.get('rootzone_id');
        data.user_id = this.user.get('_id');
      } else {
        option = {patch: true};
      }

      this.model.set(data);
      if (this.model.isValid(true)) {
        this.model.save(data, option).then(function () {
          this.collection.add(this.model);
          Radio.global.command('router:materials:navigate', 'settings/typified_orders');
        }.bind(this))
      }
    }
  });
})();
