(function () {
    'use strict';

    var api = require('shim/api'),

        RootZone = require('models/rootzone');

    module.exports = Backbone.Collection.extend({
        model: RootZone,
        url: api.urlRoot + '/rootzones',
        store: 'rootzones',

        parse: function (response) {
            return response.rootzones;
        }
    });
})();
