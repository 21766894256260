(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.Behavior.extend({
        events: {
            'click @ui.filter_wt':                    'onFilterWtChange',
            'click @ui.filter_pry':                   'onFilterPryChange',
            'click @ui.filter_st':                    'onFilterStChange',
            'click @ui.filter_origin':                'onFilterOriginChange',
            'click @ui.filter_material_dependence':   'onFilterMaterialDependenceChange',
            'click @ui.filter_material_order_status': 'onFilterMaterialOrderStatusChange'
        },

        onFilterWtChange: function (e) {
            var filters = Radio.global.request('app:filters'),
                selectedWorkTypes;

            //Change All Filter Checkbox State
            this._allFilterState($('#filter-wt-all'), $(e.target), 'wt');

            //Retrieve & Persist Filters
            selectedWorkTypes = this._getWorkTypeSelectedFilters();
            filters.work_type_id = selectedWorkTypes.work_type_id;

            //Filter Orders
            Radio.global.trigger('filter:orders:change', selectedWorkTypes);
        },

        onFilterPryChange: function (e) {
            var filters = Radio.global.request('app:filters'),
                selectedOptions;

          selectedOptions = this._getPrioritiesSelectedFilters();
          filters.priority = selectedOptions.priority;
          Radio.global.trigger('filter:orders:change', selectedOptions);
        },

        onFilterStChange: function (e) {
            var filters = Radio.global.request('app:filters'),
                selectedStates;

            //Change All Filter Checkbox State
            this._allFilterState($('#filter-st-all'), $(e.target), 'st');

            //Retrieve & Persist Filters
            selectedStates = this._getStateSelectedFilters();
            filters.order_state_id = selectedStates.order_state_id;

            //Filter Orders
            Radio.global.trigger('filter:orders:change', selectedStates);
        },

        onFilterMaterialOrderStatusChange: function (e) {
          var filters = Radio.global.request('app:filters'),
              selectedOptions;

          //Change All Filter Checkbox State
          this._allFilterState($('#filter-material-order-status-all'), $(e.target), 'material-order-status');

          selectedOptions = this._getMaterialOrderStatusSelectedFilters();
          filters.order_material_dependence = selectedOptions.order_material_dependence;
          Radio.global.trigger('filter:material-orders:change', selectedOptions);
        },

        onFilterOriginChange: function (e) {
            var filters = Radio.global.request('app:filters'),
                selectedOrigins;

            //Retrieve & Persist Filters
            selectedOrigins = this._getOriginSelectedFilters();
            filters.order_origin = selectedOrigins.order_origin;
            //Filter Orders
            Radio.global.trigger('filter:orders:change', selectedOrigins);
        },

        onFilterMaterialDependenceChange: function () {
          var filters = Radio.global.request('app:filters'),
              selectedOptions;

          selectedOptions = this._getMaterialDependenceSelectedFilters();
          filters.order_material_dependence = selectedOptions.order_material_dependence;
          Radio.global.trigger('filter:orders:change', selectedOptions);
        },

        _getOriginSelectedFilters: function () {
            var selectedFilters = [];
            $('.filter-origin').each(function () {
                var $target = $(this),
                    value = $target.val();
                if ($target.is(':checked')) {
                    selectedFilters.push(value);
                }
            });
            if (selectedFilters.length === 0) selectedFilters = -1;
            return {order_origin: selectedFilters};
        },

        _getPrioritiesSelectedFilters: function () {
            var selectedFilters = [];
            $('.filter-material-dependence').each(function () {
                var $target = $(this),
                    value = $target.val();
                if ($target.is(':checked')) {
                    selectedFilters.push(value);
                }
            });
            if (selectedFilters.length === 0) selectedFilters = -1;
            return {order_material_dependence: selectedFilters};
        },

        _getPrioritiesSelectedFilters: function () {
            var selectedFilters = [];
            $('.filter-pry').each(function () {
                var $target = $(this),
                    value = $target.val();
                if ($target.is(':checked')) {
                    selectedFilters.push(value);
                }
            });
            if (selectedFilters.length === 0) selectedFilters = -1;
            return {priority: selectedFilters};
        },

        _getMaterialOrderStatusSelectedFilters: function () {
            var selectedFilters = [];
            $('.filter-material-order-status').each(function () {
                var $target = $(this),
                    value = $target.val();
                if ($target.is(':checked')) {
                    selectedFilters.push(value);
                }
            });
            return { material_order_status: selectedFilters };
        },

        _getStateSelectedFilters: function () {
            var selectedFilters = [];
            $('.filter-st').each(function () {
                var $target = $(this),
                    value = $target.val();
                if ($target.is(':checked') && value > -1) {
                    selectedFilters.push(parseInt(value, 10));
                }
            });
            if (selectedFilters.length === 0) selectedFilters = 0;
            return {order_state_id: selectedFilters};
        },

        _getWorkTypeSelectedFilters: function () {
            var selectedFilters = [];
            $('.filter-wt').each(function () {
                var $target = $(this),
                    value = $target.val();
                if ($target.is(':checked') && value != -1) {
                    selectedFilters.push(value);
                }
            });
            if (selectedFilters.length === 0) selectedFilters = 0;
            return {work_type_id: selectedFilters};
        },

        _allFilterState: function ($all, $el, type) {
            //Check If target is All Checkbox
            if ($all[0] === $el[0]) {
                $('.filter-' + type).not($all).prop('checked', $all.is(':checked'))
            }
            else {
                // Modify All Checkbox state given selected filters
                if (!$el.is(':checked')) {
                    $all.prop('checked', false);
                }
                else if ($el.is(':checked') && $('.filter-' + type + ':checked').not($all).length === $('.filter-' + type).not($all).length) {
                    $all.prop('checked', true);
                }
                $el.prop('checked', $el.is(':checked'));
            }
        }

    });
})();
