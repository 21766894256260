(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.LayoutView.extend({
        el: '#app',
        template: require('templates/root'),

        regions: {
            main: '#main'
        },

        initialize: function () {
            Radio.global.reply('current:layout', function () {
                return this.main.currentView;
            }, this);
        },

        switchLayout: function (layout) {
            if (!this.main.currentView || layout.cid !== this.main.currentView.cid) {
                this.main.show(layout);
            }
        }
    });
})();
