(function () {
    'use strict';

    var Radio = require('shim/radio'),
        BaseObject = require('controllers/base-object'),
        CreateOrderEquipmentsView = require('views/orders/create-order-equipments-view'),
        EditOrderView = require('views/orders/edit-order-view'),
        WorkTypes = require('collections/work-types'),
        Zones = require('collections/zones'),
        Users = require('collections/users'),
        EquipmentFamilies = require('collections/equipment-families');

    module.exports = BaseObject.extend({
        radio: {
            'families:get:equipment': 'onGetEquipmentForFamily',
            'equipments:get:units': 'onGetUnitsForEquipment',
            'view:edit:order': 'onValidateOrder'
        },

        //Initialize Object
        initialize: function (options) {
            BaseObject.prototype.initialize.apply(this, arguments);
            options = options || {};
            this.workTypes = options.work_types || new WorkTypes();
            this.zones = options.zones || new Zones();
            this.equipmentFamilies = options.equipment_families || new EquipmentFamilies();
            this.equipments = options.equipments.vCollection({}, this);
            this.units = options.units;
            this.userGroups = options.groups;
            this.users = options.users;
            this.order = options.order;
        },

        //Render Main View
        render: function () {
            var self = this,
                view = self.getView();

            //Render MainView
            self.show(view, self);

            //Render MainView Equipment View
            if (!self.order.has('work_type_id')) {
                self.region.currentView.showChildView('equipments', new CreateOrderEquipmentsView({
                    equipment_families: this.equipmentFamilies
                }));
            }
        },

        getView: function () {
            var self = this;
            return new EditOrderView({
                model: self.order,
                work_types: self.workTypes,
                zones: self.zones,
                user_groups: self.userGroups,
                users: self.users
            });
        },

        //RADIO TRIGGERS
        onGetEquipmentForFamily: function (id) {
            var self = this;
            self.equipments.updateFilterParams({'equipment_family_id': id});
            self.region.currentView.getRegion('equipments').currentView.triggerMethod('equipment:fetched', self.equipments);
        },
        onGetUnitsForEquipment: function (id) {
            var self = this;
            self.region.currentView.getRegion('equipments').currentView.triggerMethod('unit:fetched', self.units.byEquipment({'equipment_id': id}));
        },

        //Order validation
        onValidateOrder: function (data) {
            var user = Radio.global.request('app:session').get('user');

            if (user.is('operator')) { // to bypass validations, this won't do a thing to the API
                data.assign_ot = 'user_ids';
                data.user_ids = user.get('_id');
            }

            //Parse to Integer some IDS
            data.work_type_id = parseInt(data.work_type_id) || undefined;
            data.zone_id = parseInt(data.zone_id) || undefined;
            data.user_group_id = parseInt(data.user_group_id) || undefined;

            this.order.set( this.filterDummy(data) );
            if (this.order.isValid(true)) {
                this.onEditOrder();
            }
        },

        filterDummy: function (data) {
            var id;
            ['equipment_family_id', 'equipment_id', 'unit_id'].forEach(function (key) {
                if ((id = /(\d*)-dummy/.exec(data[key]))) {
                    data.dummy = key;
                    if (key !== 'unit_id' || _.isString(data[key])) {
                        data[key] = id[1];
                    }
                }
            });
            return data;
        },

        //Edit Order
        onEditOrder: function () {
            var self = this;
            if (self.order.get('assign_ot') === 'user_group_id') {
                self.order.set('state', 'pending');
                self.order.set('user_ids', null);
                self.order.set('users', null);
            }
            else if (self.order.get('assign_ot') === 'user_ids') {
                self.order.set('state', 'pending');
                self.order.set('user_group_id', null);
                self.order.set('user_group_title', null);
            }
            else {
                self.order.set('user_ids', null);
                self.order.set('users', null);
                self.order.set('user_group_id', null);
                self.order.set('user_group_title', null);
            }

            self.order.save()
                .always(function () {
                    //Unrequest OT
                    self.order.unset('isRequest');

                    //Check if OT was blocked & redirect
                    if(self.order.has('is_blocked')){
                        Radio.store.request('get:orders').remove(self.order);
                        Radio.global.command('router:main:navigate', 'dashboard', {trigger: true});
                        return;
                    }

                    Radio.global.command('router:orders:navigate', ['orders', self.order.get('id')].join('/'), {trigger: true});
                    window.scrollTo(0, 0);
                });
        },

    });
})();
