"use strict"

module.exports = filterBy;

function filterBy(params, view) {
    params = params || {};

    var filteredCollection,

        internationalizeWord = function (word) {
            return word.toLowerCase()
                .replace(/[àáâãäå]/g, "a")
                .replace(/[èéê]/g, "e")
                .replace(/[ìíî]/g, "i")
                .replace(/[òóôõ]/g, "o")
                .replace(/[ùúû]/g, "u")
                .replace(/[ç]/g, "c");
        },

        search_text = function (model, word) {
          var isTitleMatch = false,
              isDescritionMatch = false,
              isNameMatch = false,
              isCompanyMatch = false;

          if ( model.get('title') ) {
            isTitleMatch = internationalizeWord(model.get('title')).indexOf(internationalizeWord(word)) >= 0
          }
          if ( model.get('description') ) {
            isDescritionMatch = internationalizeWord(model.get('description')).indexOf(internationalizeWord(word)) >= 0
          }
          if ( model.get('name') ) {
            isNameMatch = internationalizeWord(model.get('name')).indexOf(internationalizeWord(word)) >= 0
          }
          if ( model.get('company') ) {
            isCompanyMatch = internationalizeWord(model.get('company')).indexOf(internationalizeWord(word)) >= 0
          } else if ( word === "") {
            if ( !model.get('company') && word === "") {
              isCompanyMatch = true
            }
          }

          try {
            return model.get('number').indexOf(word) >= 0 || isTitleMatch || isDescritionMatch || isNameMatch || isCompanyMatch
          }
          catch (err) {
            return isTitleMatch || isDescritionMatch || isNameMatch || isCompanyMatch
          }
        },

        filterArrayCompare = function (model, key, filterArray) {
            var workTypesFilter = function () {
                    var defaultWorkTypes = ['Preventivo', 'Rotina', 'Correctivo', 'Operação'];
                    return _.some(filterArray, function (value) {
                        if (value === "Outros") {
                            return !_.some(defaultWorkTypes, function (order_type) {
                                if (!model.get(key)){ return;}
                                return model.get(key).startsWith(order_type);
                            });
                        }
                        return model.get(key) && model.get(key).startsWith(value);
                    });
                },
                originFilter = function () {
                    return _.some(filterArray, function (value) {
                      if (value === "pedido") {
                        return model.get("is_ticket") === true;
                      }
                    });
                },
                materialDependenceFilter = function () {
                    return _.some(filterArray, function (value) {
                      if (value === "Por encomendar") {
                        return model.get("waits_material_order");
                      } else if (value === "Aguarda entrega") {
                        return model.get("waits_material_delivery") === true;
                      } else if (value === "Encomenda Recebida") {
                        return model.get("all_materials_received") === true;
                      } else if (value === "Outros") {
                        return !_.values(_.pick(model.toJSON(),
                          ['waits_material_order', 'waits_material_delivery', 'all_materials_received'])).some((v) => v);
                      }
                    });
                },
                materialOrderStatusFilter = function () {
                    return _.some(filterArray, function (value) {
                      return model.get("status") === value
                    });
                },
                someFilter = function () {
                  return _.some(filterArray, function (value) {
                      if (key === 'state_id' && value === 6) {
                        return model.get('work_type_id') === null;
                      }
                      if (value == -1) {
                        return model.get(key) === null;
                      }
                      return model.get(key) == value;
                    });
                };

            switch (key) {
                case 'material_order_status':
                  return materialOrderStatusFilter();
                  break;
                case 'work_type_id':
                    key = 'work_type_title';
                    return workTypesFilter();
                    break;
                case 'order_origin':
                    return originFilter();
                    break;
                case 'order_material_dependence':
                    return materialDependenceFilter();
                    break;
                case 'order_state_id':
                    key = 'state_id';
                case 'state':
                default:
                    return someFilter();
                    break;
            }
        },

        compareDates = function (model, beginDate, endDate) {
            var date = moment(model.get('date'));
            return date.isSameOrAfter(beginDate) && date.isSameOrBefore(endDate);
        },

        searchForUser = function (model, userID) {
            return _.some(model.get('users').models, function (value) {
                return value.get('id') == userID || value.get('_id') == userID;
            });
        },

        filterFun = function (filters) {
            return function (model) {
                return _.every(filters, function (value, key) {
                    //Null Values, may be possible to remove
                    if (value == '-1' || key === 'end_date') return true;
                    //Search Key for specific order
                    if (key == 'search_text') return search_text(model, value);
                    //For work_types
                    if (_.isArray(value)) return filterArrayCompare(model, key, value);
                    //Compare Dates
                    if (key === 'begin_date') return compareDates(model, filters.begin_date, filters.end_date);
                    //Search for User
                    if (key === 'user_id') return searchForUser(model, filters.user_id);
                    //Default
                    return model.get(key) === value || model.get('_id') == '1';
                })
            }
        };

    filteredCollection = new Backbone.VirtualCollection(this, {
        comparator: this.comparator,
        filter: filterFun(params),
        destroy_with: view
    });

    filteredCollection.updateFilterParams = function (filters) {
        this.updateFilter(filterFun(filters));
    }.bind(filteredCollection);

    return filteredCollection;
}
