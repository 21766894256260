(function () {
  'use strict';

  var Radio = require('shim/radio');

  module.exports = Marionette.Behavior.extend({
    events: {
      'change @ui.typified_order_category_input': 'onFilterTypifiedOrderCategory',
    },

    onFilterTypifiedOrderCategory: function () {
      //Update View
      Radio.global.trigger('filter:typified-orders:change', this._getCurrentFilters());
    },

    _getCurrentFilters: function () {
      return {
        typified_group: $('#typified_order_category').val(),
      };
    }
  });
})();
