(function() {
    'use strict';

    var Radio = require('shim/radio'),
        DashboardsController = require('controllers/dashboards/dashboards-object');

    module.exports = Marionette.Controller.extend({
        showDashboards: function() {
            Radio.global.trigger('anim-loading:show');
            setTimeout(() => {
                const rootzone = this.options.rootzones.findWhere({ _id: parseInt(localStorage.getItem('rootzone_id')) });
                new DashboardsController({ rootzone }).showDashboards();
            }, 10);
        }
    });
})();
