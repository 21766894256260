var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<header class=\"page-header view-according-device\">\n    <div class=\"bs-callout bs-callout-default\">\n        <i aria-hidden=\"true\" class=\"fa fa-users\"></i>\n        <h4>Utilizadores</h4>\n    </div>\n\n    <details id=\"filter-details\">\n\n        <summary class=\"summary_click\">\n            <i class=\"fa fa-filter\" aria-hidden=\"true\"></i>\n            <span>FILTROS</span>\n            <div class=\"pull-right\" style=\"padding-right: 5px\">\n                <button class=\"btn btn-primary reset-filters\">Limpar filtros</button>\n            </div>\n        </summary>\n        <div class=\"container\" style=\"width:auto;padding-top: 10px\">\n          <div class=\"row state-filters\">\n              <div class=\"col-sm-12 filter-topic\">\n                  PERMISSÃO\n              </div>\n              <div class=\"col-sm-12\" style=\"padding-top: 10px;display:flex\">\n                <div class=\"checkbox checkbox-primary checkbox-container\" style=\"margin: 0px 10px\">\n                  <input type=\"checkbox\" class=\"users-access\" id=\"users-access\" checked=\"checked\">\n                  <label for=\"users-access\">Permitidos</label>\n                </div>\n\n                <div class=\"checkbox checkbox-primary checkbox-container\" style=\"margin: 0px 10px\">\n                  <input type=\"checkbox\" class=\"users-no-access\" id=\"users-no-access\" checked=\"checked\">\n                  <label for=\"users-no-access\">Não Permitidos</label>\n                </div>\n              </div>\n          </div>\n        </div>\n    </details>\n</header>\n\n<section class=\"content view view-according-device\"></section>\n\n<section class=\"rotate-phone\" align=\"center\" style=\"margin-top:40px\">\n  <i class=\"fa fa-repeat fa-5x\" aria-hidden=\"true\" style=\"margin-bottom:20px\"></i>\n  <p>Por favor rode o telefone</p>\n</section>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}