var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<header class=\"page-header view-according-device\">\n      <div class=\"bs-callout bs-callout-default\">\n        <i aria-hidden=\"true\" class=\"fa fa-cart-arrow-down\"></i>\n        <h4>Gestão de Materiais</h4>\n\n        <div class=\"view-options\">\n          <button type=\"button\" class=\"btn btn-default btn-dropdown-view dropdown-toggle\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n            <span style=\"text-decoration:underline\">"
    + container.escapeExpression(((helper = (helper = helpers.viewName || (depth0 != null ? depth0.viewName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"viewName","hash":{},"data":data}) : helper)))
    + "</span>\n            <span class=\"caret\"></span>\n            <span class=\"sr-only btn-default\">Toggle Dropdown</span>\n          </button>\n          <ul class=\"dropdown-menu dropdown-menu-right\">\n            <li><a class=\"dropdown-options view-materials-requests\">Necessidades de Materiais</a></li>\n            <li><a class=\"dropdown-options view-new-materials-requests\">Necessidades de Materiais Não Cadastrados</a></li>\n          </ul>\n        </div>\n      </div>\n\n      <form action=\"\" class=\"filters\"></form>\n\n</header>\n\n<section class=\"content view view-according-device\"></section>\n\n<section class=\"rotate-phone\" align=\"center\" style=\"margin-top:40px\">\n  <i class=\"fa fa-repeat fa-5x\" aria-hidden=\"true\" style=\"margin-bottom:20px\"></i>\n  <p>Por favor rode o telefone</p>\n</section>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}