(function () {
  'use strict';

  var MaterialRequestsView = require('views/materials/material-orders/all-material-requests-view');

  module.exports = Marionette.CompositeView.extend({
    template: require('templates/materials/material-orders/material-order-requests'),
    childView: require('./material-order-requests-list-item-view'),
    childViewContainer: 'div.material-request-item',
    childViewOptions:  function () {
      return {
        materialOrder:       this.model,
        collection:          this.collection,
        allMaterialRequests: this.options.allMaterialRequests
      };
    },
    tagName: 'div',
    className: 'box',

    ui: {
      addNewMaterialRequest:  '.add-new-material-request',
      saveNewMaterialRequest: '.save-new-material-request'
    },

    events: {
      'click @ui.addNewMaterialRequest':  'onAddNewMaterialRequest',
      'click @ui.saveNewMaterialRequest': 'onSaveNewMaterialRequest'
    },

    initialize: function () {
      this.materialRequestsSelectBoxRegion;
    },

    onRender: function () {
      if (this.collection.length === 0){
        $('.delete-material-order').show();
      };
    },

    onAddNewMaterialRequest: function (event) {
      event.preventDefault();

      this.materialRequestsSelectBoxRegion = new Marionette.Region({
        el: $('.all-material-request-items')
      });

      var materialRequestsSelectBoxView = new MaterialRequestsView({
        collection: this.options.allMaterialRequests
      });

      this.materialRequestsSelectBoxRegion.show(
        materialRequestsSelectBoxView
      )

      this.ui.saveNewMaterialRequest.show();
      this.ui.addNewMaterialRequest.hide();
    },

    onSaveNewMaterialRequest: function (event) {
      event.preventDefault();

      var id = $('.new-material-select-box option:selected').val();
      if ( id === '') { return }

      const material_request_ids = this.collection.pluck('id').concat(parseInt(id));

      this.model.save({material_order: {material_request_ids}}, {patch: true}).then(function () {
        this.collection.add(this.model.get('materials'));
        this.options.allMaterialRequests.remove(parseInt(id));
        $('.delete-material-order').hide();
        this.ui.saveNewMaterialRequest.hide();
        this.ui.addNewMaterialRequest.show();
        this.materialRequestsSelectBoxRegion.currentView.destroy();
      }.bind(this));
    }
  });
})();
