var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "              <span style='color:#53a784;font-weight: bold'>Activo</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "              <span style='color:#e6614f;font-weight: bold'>Não Activo</span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"section-box\">\n    <header class=\"page-header\">\n        <div class=\"bs-callout bs-callout-default\">\n            <h4>Definições do Edifício</h4>\n        </div>\n    </header>\n\n    <div class=\"box settings-box\">\n        <h5 style=\"font-size:20px;\">Logo da rootzone</h5>\n        <div class=\"section-row\">\n            <address>\n                <div class=\"avatar-container logo\" align=\"center\">\n                    <img src=\""
    + alias4(((helper = (helper = helpers.image_logo || (depth0 != null ? depth0.image_logo : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image_logo","hash":{},"data":data}) : helper)))
    + "\" alt=\"Avatar\" class=\"image logo\">\n                    <div class=\"overlay\">\n                        <div class=\"text\"><i class=\"fa fa-camera\" aria-hidden=\"true\"></i></div>\n                    </div>\n                </div>\n            </address>\n        </div>\n    </div>\n\n    <div class=\"box settings-box\">\n        <h5 style=\"font-size:20px;\">Configuração dos Semáforos</h5>\n        <form class=\"row\">\n\n            <div class=\"form-group col-xs-12 col-md-4\" style=\"text-align:center\">\n                <label style=\"padding-left: 38px;\">Inferior ou igual</label>\n                <div class=\"input-group\">\n                    <span class=\"input-group-addon\" id=\"green-semaphore\">\n                        <i class=\"fa fa-lightbulb-o\" aria-hidden=\"true\"></i>\n                    </span>\n                    <input type=\"number\" class=\"form-control\" value=\""
    + alias4(((helper = (helper = helpers.sem_green || (depth0 != null ? depth0.sem_green : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sem_green","hash":{},"data":data}) : helper)))
    + "\" name=\"sem_green\" placeholder=\"Dias em verde\" aria-describedby=\"basic-addon1\">\n                </div>\n            </div>\n\n            <div class=\"form-group col-xs-12 col-md-4\" style=\"text-align:center\">\n                <label style=\"padding-left: 38px;\">Entre</label>\n                <div class=\"input-group\">\n                    <span class=\"input-group-addon\" id=\"yellow-semaphore\">\n                        <i class=\"fa fa-lightbulb-o\" aria-hidden=\"true\"></i>\n                    </span>\n                    <input type=\"text\" class=\"form-control\" value=\""
    + alias4(((helper = (helper = helpers.sem_yellow || (depth0 != null ? depth0.sem_yellow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sem_yellow","hash":{},"data":data}) : helper)))
    + "\" name=\"sem_yellow\" placeholder=\"Dias em amarelo\" aria-describedby=\"basic-addon1\"\n                        style=\"text-align:center; color: gray;\" disabled>\n                </div>\n            </div>\n\n            <div class=\"form-group col-xs-12 col-md-4\" style=\"text-align:center\">\n                <label style=\"padding-left: 38px;\">Superior ou igual</label>\n                <div class=\"input-group\">\n                    <span class=\"input-group-addon\" id=\"red-semaphore\">\n                        <i class=\"fa fa-lightbulb-o\" aria-hidden=\"true\"></i>\n                    </span>\n                    <input type=\"number\" class=\"form-control\" value=\""
    + alias4(((helper = (helper = helpers.sem_red || (depth0 != null ? depth0.sem_red : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sem_red","hash":{},"data":data}) : helper)))
    + "\" name=\"sem_red\" placeholder=\"Dias em vermelho\" aria-describedby=\"basic-addon1\">\n                </div>\n            </div>\n        </form>\n    </div>\n\n    <div class=\"box settings-box\">\n        <h5 style=\"font-size:20px;\">Armazém</h5>\n        <form class='row'>\n          <div class=\"form-group col-xs-4\" align=\"center\">\n            <label for=\"material_budget\" style=\"display:inline-block\">Orçamento Anual (€):</label>\n            <input type=\"number\" min=\"0\" name=\"material_budget\" id=\"material_budget\"\n                   value=\""
    + alias4(((helper = (helper = helpers.material_budget || (depth0 != null ? depth0.material_budget : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"material_budget","hash":{},"data":data}) : helper)))
    + "\" placeholder=\"Inserir valor\"\n                   style=\"width:200px;display:inline-block;border-radius: 0;border-width:0 0 1px 0; padding:0; height:27px\">\n          </div>\n          <div class=\"col-xs-4\" align=\"center\">\n            <label for=\"material_budget\">Gestão de Armazém (MantTest):</label>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.inventory_management : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n          <div class=\"col-xs-4\" align=\"center\">\n            <label for=\"material_budget\">Gestão de Materiais:</label>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.material_management : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </form>\n    </div>\n\n    <div>\n      <button type=\"submit\" class=\"button button-primary\" >\n        <i class=\"fa fa-floppy-o\" aria-hidden=\"true\"></i>\n        <span>Gravar</span>\n      </button>\n    </div>\n</div>\n\n\n<input type=\"file\" class=\"file\" accept=\".jpg,.jpeg,.png\" style=\"display:none\">\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}