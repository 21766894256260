'use strict';

var Radio = require('shim/radio'),
    BaseObject = require('controllers/base-object'),
    DashboardsLayout = require('layouts/dashboards-layout');

module.exports = BaseObject.extend({

    initialize(options) {
        BaseObject.prototype.initialize.apply(this, arguments);
    },

    showDashboards() {
        let rootzone = this.options.rootzone,
            dashboardsLayout = new DashboardsLayout({ rootzone });

        //Render MainView
        this.show(dashboardsLayout, this);

        Radio.global.trigger('anim-loading:hide');
    }
});