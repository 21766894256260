(function () {
  'use strict';

  var BaseObject = require('controllers/base-object'),
      Radio = require('shim/radio'),

      HelpView = require('views/main/help-view');

  module.exports = BaseObject.extend({
    initialize: function (options) {
      BaseObject.prototype.initialize.apply(this, arguments);
      options = options || {};
      this.user = Radio.global.request('app:session').get('user')
    },

    showHelp: function () {
      $('.search-ot-group').hide();
      this.show(new HelpView({user: this.user}), this);
    }
  });
})();
