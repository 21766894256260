(function () {
    'use strict';

    var Radio = require('shim/radio'),
        EquipmentTemplate = require('templates/orders/create/order-create-equipments'),
        UnitTemplate = require('templates/orders/create/order-create-units');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/orders/create/order-create-families'),
        events: {
            'change #equipment': 'onChangeEquipments',
            'change #equipment_families': 'onChangeFamilies',
            'click #select_all': 'onSelectAllUnits'
        },
        ui: {
            equipments: '#equipment-container',
            units: '#unit-container'
        },
        behaviors: {
            Radio: {
                'typification:selected': 'onTypificationSelected'
            }
        },

        initialize: function (options) {
            options = options || {};
            this.equipmentFamilies = options.equipment_families;
        },

        onTypificationSelected: function (equipment, equipment_family) {
            var self = this;
            var $families = self.$('#equipment_families');

            self.equipment = equipment;
            self.equipment_family = equipment_family;
            if (self.equipment !== null) {
                if(self.equipment.slug == "dummy")
                    $families.val(self.equipment.equipment_family_id + "-dummy");
                else
                    $families.val(self.equipment.equipment_family_id);
            }
            $families.change();
        },

        onChangeFamilies: function (ev) {
            var self = this;

            self.ui.units.empty();
            var $target = $(ev.target).find(":selected"),
                id = $target.data('id');
            if (id < 0 || $target.data('dummy')) {
                self.ui.equipments.empty();
            } else {
                Radio.global.trigger('families:get:equipment', id);
            }
        },

        onChangeEquipments: function (ev) {
            var self = this;

            var $target = $(ev.target).find(':selected'),
                id = $target.data('id');
            if (id < 0 || $target.data('dummy')) {
                self.ui.units.empty();
            } else {
                Radio.global.trigger('equipments:get:units', id);
            }
        },

        onEquipmentFetched: function (equipments) {
            var self = this,
                selected = self.equipment ? self.equipment.id : null;

            this.ui.equipments.html(EquipmentTemplate({equipments: equipments.toJSON(), selected: selected}));
            if (selected !== null) {
                self.$('#equipment').change();
            }

            if (self.equipment_family !== null && self.equipment === null)
                $('#equipment option').eq(1).prop('selected', true);
        },

        onUnitFetched: function (units) {
               if (units.length > 0) {
                this.ui.units.html(UnitTemplate({units: units.toJSON()}));

                this._selectDummyCheckBox();
            }
        },

        _selectDummyCheckBox: function () {
            var unitsCheckboxes = $("#units-checkboxes [type='checkbox']:not([value='1-dummy'])"),
                dummyCheckbox = $("#units-checkboxes [value='1-dummy']");
            dummyCheckbox.prop('checked', true);
            unitsCheckboxes.prop('disabled', true);
        },

        onSelectAllUnits: function () {
            var unitsCheckboxes = $("#units-checkboxes [type='checkbox']:not([value='1-dummy'])"),
                dummyCheckbox = $("#units-checkboxes [value='1-dummy']");

            if (unitsCheckboxes.filter(':checked').length === unitsCheckboxes.length) {
                unitsCheckboxes.prop('checked', false);
                dummyCheckbox.prop('checked', true);
                unitsCheckboxes.prop('disabled', true);
                dummyCheckbox.prop('disabled', false);
            }
            else {
                unitsCheckboxes.prop('checked', true);
                dummyCheckbox.prop('checked', false);
                unitsCheckboxes.prop('disabled', false);
                dummyCheckbox.prop('disabled', true);
            }
            return false;
        },

        templateHelpers: function () {
            var self = this;
            return {
                equipment_families: function () {
                    return self.equipmentFamilies.toJSON();
                }
            };
        }
    });
})();
