var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " Criar Pedido\n                    ";
},"3":function(container,depth0,helpers,partials,data) {
    return "Criar Ordem de Trabalho ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <fieldset>\n                    <legend>Tipificação</legend>\n                    <div class=\"row\">\n                        <section class=\"col-xs-12 box-checkbox\">\n                            <div class=\"col-xs-12 col-sm-3\">\n                                <div class=\"form-group\">\n                                    <div class=\"radio radio-danger\">\n                                        <input type=\"radio\" id=\"typified\" name=\"typification\" value=\"true\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.typifications : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                                        <label for=\"typified\">Tipificada</label>\n                                    </div>\n                                    <div class=\"radio radio-danger\">\n                                        <input type=\"radio\" id=\"non-typified\" name=\"typification\" value=\"false\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.typifications : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                                        <label for=\"non-typified\">Não tipificada</label>\n                                    </div>\n                                </div>\n                            </div>\n\n                            <div class=\"form-group col-xs-12 col-sm-9 typifications\">\n                                <lable>&nbsp;</lable>\n                                <select class=\"select2\" name=\"typifications\" id=\"typifications\">\n                                    <option value=\"-1\"> - Selecção de tipificação -</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.typificationsList : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                </select>\n                            </div>\n                        </section>\n\n                    </div>\n                </fieldset>\n\n";
},"6":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                                        <optgroup label='"
    + container.escapeExpression(((helper = (helper = helpers.key || (data && data.key)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"key","hash":{},"data":data}) : helper)))
    + "'></optgroup>\n"
    + ((stack1 = helpers.each.call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                                          <option value=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                <input type=\"hidden\" name=\"typification\" value=\"false\">\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = 
  "                    <div class=\"form-group col-xs-12\" typification>\n                        <label for=\"work_type\">Tipo de Trabalho</label>\n                        <select class=\"input-group\" name=\"work_type_id\" id=\"work_type\">\n                            <option value=\"\" data-id=\"-1\"> - Selecção Tipo de Trabalho -</option>\n";
  stack1 = ((helper = (helper = helpers.work_types || (depth0 != null ? depth0.work_types : depth0)) != null ? helper : alias2),(options={"name":"work_types","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.work_types) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "                        </select>\n                    </div>\n\n                <!--Priority-->\n";
  stack1 = ((helper = (helper = helpers.isNotOperatorAndSolicitant || (depth0 != null ? depth0.isNotOperatorAndSolicitant : depth0)) != null ? helper : alias2),(options={"name":"isNotOperatorAndSolicitant","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isNotOperatorAndSolicitant) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"14":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                                <option value=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\" data-name=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"form-group col-xs-12\" style=\"display: none;\" priority>\n                        <label for=\"priority\">Prioridades</label>\n                        <select class=\"input-group\" name=\"priority\" id=\"priority\">\n                            <option value=\"\" data-id=\"-1\"> - Selecção Prioridade -</option>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.priorities : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </select>\n                    </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                            <option value=\""
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data}) : helper)))
    + "\" data-name=\""
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data}) : helper)))
    + " - "
    + alias4(((helper = (helper = helpers.longText || (depth0 != null ? depth0.longText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longText","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                            <option value=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\" data-name=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "                <fieldset>\n                    <legend>Atribuição</legend>\n                    <div class=\"col-xs-12 box-checkbox\" style=\"padding:0\">\n                        <div class=\"col-xs-12 col-sm-4\">\n                            <div class=\"col-xs-6 col-sm-12 radio radio-danger\">\n                                <input type=\"radio\" id=\"user_group\" name=\"assign_ot\" value=\"user_group_id\" class=\"assign-ot\">\n                                <label for=\"user_group\">A grupo</label>\n                            </div>\n                            <div class=\"col-xs-6 col-sm-12 radio radio-danger\">\n                                <input type=\"radio\" id=\"user_operator\" name=\"assign_ot\" value=\"user_ids\" class=\"assign-ot\">\n                                <label for=\"user_operator\">A operadores</label>\n                            </div>\n                        </div>\n                        <div class=\"col-xs-12 col-sm-8\">\n                            <div class=\"assign-container input-group\">\n                            </div>\n                        </div>\n                    </div>\n                </fieldset>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = 
  "                <fieldset>\n                    <legend>Agendamento</legend>\n\n                    <!--Begin Date-->\n                    <div class=\"form-group col-xs-12 col-md-6\" style=\"margin-right:0\">\n                        <label for=\"start_date_preview\">Data de início</label>\n                        <input type=\"date\" name=\"start_date_preview\" id=\"start_date_preview\" placeholder=\"Início\">\n                    </div>\n\n                    <!--End Date-->\n";
  stack1 = ((helper = (helper = helpers.isNotOperatorAndSolicitant || (depth0 != null ? depth0.isNotOperatorAndSolicitant : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"isNotOperatorAndSolicitant","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.isNotOperatorAndSolicitant) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </fieldset>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"form-group col-xs-12 col-md-6\">\n                            <label for=\"end_date_preview\">Data de fim</label>\n                            <input type=\"date\" name=\"end_date_preview\" id=\"end_date_preview\" placeholder=\"Final\">\n                        </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "                <input type=\"date\" name=\"start_date_preview\" id=\"start_date_preview\" style=\"display:none\">\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "Criar Pedido";
},"30":function(container,depth0,helpers,partials,data) {
    return "Criar";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.isNotSolicitant || (depth0 != null ? depth0.isNotSolicitant : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"isNotSolicitant","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.isNotSolicitant) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"33":function(container,depth0,helpers,partials,data) {
    return "                        <a href=\"#\" class=\"button button-secondary button-lg-pad do-order\">Criar e Realizar</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = 
  "<div class=\"section-box\">\n    <header class=\"page-header\">\n        <div class=\"bs-callout bs-callout-default\">\n            <i class=\"fa fa-file-text-o\" aria-hidden=\"true\"></i>\n            <h4>\n                ";
  stack1 = ((helper = (helper = helpers.isSolicitant || (depth0 != null ? depth0.isSolicitant : depth0)) != null ? helper : alias2),(options={"name":"isSolicitant","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isSolicitant) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n            </h4>\n        </div>\n    </header>\n\n    <div class=\"box\">\n        <form action=\"#\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showTypifications : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "\n            <fieldset>\n                <legend>Descrição</legend>\n\n                <div class=\"form-group col-xs-12\">\n                    <label for=\"title\">Título</label>\n                    <textarea name=\"title\" id=\"title\" placeholder=\"Introduza o título\" rows=\"1\" style=\"resize:none\"></textarea>\n                </div>\n\n                <!--Description-->\n                <div class=\"form-group col-xs-12\">\n                    <label for=\"description\">Descrição</label>\n                    <textarea name=\"description\" placeholder=\"Introduza a descrição\" rows=\"1\" style=\"resize:none\"></textarea>\n                </div>\n\n                <!--Notes-->\n                <div class=\"form-group col-xs-12\">\n                    <label for=\"notes\">Notas</label>\n                    <textarea name=\"notes\" id=\"notes\" placeholder=\"Introduza notas\" rows=\"1\" style=\"resize:none\"></textarea>\n                </div>\n\n                <!--Work type-->\n";
  stack1 = ((helper = (helper = helpers.isNotSolicitant || (depth0 != null ? depth0.isNotSolicitant : depth0)) != null ? helper : alias2),(options={"name":"isNotSolicitant","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isNotSolicitant) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                <!--Location-->\n                <div class=\"form-group col-xs-12\">\n                    <label for=\"zone\">Localização</label>\n                    <select name=\"zone_id\" id=\"rootzone\" class='select2' style='width: 100%;'>\n                        <option value=\"\" data-id=\"-1\"> - Selecção Localização -</option>\n";
  stack1 = ((helper = (helper = helpers.zones || (depth0 != null ? depth0.zones : depth0)) != null ? helper : alias2),(options={"name":"zones","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.zones) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "                    </select>\n                </div>\n\n                <!--Image-->\n                <div class=\"form-group col-xs-12\">\n                    <label for=\"otImage\">Imagem</label>\n                    <input id=\"otImage\" type=\"file\" class=\"file\" accept=\".jpg,.jpeg\">\n                </div>\n            </fieldset>\n\n";
  stack1 = ((helper = (helper = helpers.isNotOperatorAndSolicitant || (depth0 != null ? depth0.isNotOperatorAndSolicitant : depth0)) != null ? helper : alias2),(options={"name":"isNotOperatorAndSolicitant","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isNotOperatorAndSolicitant) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.isNotSolicitant || (depth0 != null ? depth0.isNotSolicitant : depth0)) != null ? helper : alias2),(options={"name":"isNotSolicitant","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isNotSolicitant) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.isSolicitant || (depth0 != null ? depth0.isSolicitant : depth0)) != null ? helper : alias2),(options={"name":"isSolicitant","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isSolicitant) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n            <fieldset id=\"equipments\" typification></fieldset>\n\n            <p class=\"order-btn-group\">\n                <button type=\"submit\" class=\"button button-primary button-lg-pad create-order\">\n                    ";
  stack1 = ((helper = (helper = helpers.isSolicitant || (depth0 != null ? depth0.isSolicitant : depth0)) != null ? helper : alias2),(options={"name":"isSolicitant","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isSolicitant) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                </button>\n";
  stack1 = ((helper = (helper = helpers.isNotManager || (depth0 != null ? depth0.isNotManager : depth0)) != null ? helper : alias2),(options={"name":"isNotManager","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isNotManager) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </p>\n\n        </form>\n    </div>\n\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}