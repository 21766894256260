(function () {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: require('templates/materials/material-orders/all-material-requests-item'),
    tagName: 'option',
    attributes: function () {
      return {
        "value": this.model.id
      }
    }
  });
})();
