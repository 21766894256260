(function () {
  'use strict';

  var Radio = require('shim/radio'),
      OrderModel = require('models/order'),
      MaterialSelection = require('templates/materials/material-requests/material-requests-row-select'),
      MaterialOrderModel = require('models/material-order');

  module.exports = Marionette.ItemView.extend({
    template: require('templates/materials/material-requests/material-requests-list-item'),
    tagName: 'tr',
    attributes: function () {
      return {
        "data-id": this.model.id
      }
    },

    ui: {
      changeQuantity: '.change-qty',
      saveQuantity:   '.save-qty',
      removeQuantity: '.remove-qty',
      orderLink:      '.order-link',
      checkbox:       '.checkbox'
    },

    events: {
      'click @ui.changeQuantity': 'onChangeQuantityClicked',
      'click @ui.saveQuantity':   'onSaveQuantityClicked',
      'click @ui.removeQuantity': 'onRemoveQuantityClicked',
      'click @ui.orderLink':      'onOrderLinkClicked',
      'click @ui.checkbox':       'onCheckboxClicked'
    },

    modelEvents: {
      change: 'render',
    },

    templateHelpers: function () {
      return {
        atOrderDetails: Backbone.history.getFragment().includes('order')
      }
    },

    onChangeQuantityClicked: function (event) {
      event.preventDefault();

      Radio.global.trigger('anim-loading:show');
      $('#purchase-qty-' + this.model.id).prop('disabled', false);
      $('#change-qty-' + this.model.id).hide();
      $('#save-qty-' + this.model.id).show();
      if (!this.model.get('material_id')) {
        let template = $(MaterialSelection({})),
            materials = Radio.store.request('get:materials').models;
        this.$('.material-request-info').empty().append(template);
        _.each(materials, (material) => {
          let option = new Option(`${material.get('code')} | ${material.get('title')}`, material.get('_id'));
          $(`tr[data-id='${this.model.get('id')}'] .material-request-info > select`).append(option);
        })
        let width = $(`tr[data-id='${this.model.get('id')}'] .material-request-info > select`).css('width')
        $(`tr[data-id='${this.model.get('id')}'] .material-request-info > select`).select2();
        $(`tr[data-id='${this.model.get('id')}'] .material-request-info .select2-selection`).css('width', width)
      }
      Radio.global.trigger('anim-loading:hide');
    },

    onSaveQuantityClicked: function (event) {
      event.preventDefault();

      $('#purchase-qty-' + this.model.id).prop('disabled', true);
      $('#change-qty-' + this.model.id).show();
      $('#save-qty-' + this.model.id).hide();

      let qtyToPurchase = $('#purchase-qty-' + this.model.id).val(),
          selectedMaterialId = $(`tr[data-id='${this.model.get('id')}'] .material-request-info > select`).val();

      if (_.isEmpty(selectedMaterialId)) {
        return this.model.save({ purchase_qty: qtyToPurchase }, { patch: true });
      };

      let orderId = this.model.get('order_id'),
          material = Radio.store.request('get:materials').find({ _id: selectedMaterialId });

      if (orderId) {
        let order = Radio.store.request('get:orders').find({ id: orderId });
        this.createOrderMaterialAssignment(order, {
          id: null,
          stock_id: parseInt(material.get('stock_id')),
          quantity: parseInt(qtyToPurchase) || 0,
          material_id: selectedMaterialId,
          order_id: orderId,
          date: moment().format('YYYY-MM-DD'),
          user_id: this.model.get('requester_id'),
          is_material_available: material.get('quantity') >= (parseInt(qtyToPurchase) || 0)
        }).done(() => {
          let materials = this.model.collection;
          this.onRemoveQuantityClicked().then(() => {
            materials.fetch().then(() => {
              order.fetch();
            })
          })
        });
      } else {
        this.model.save({
          new_item_name: null,
          new_item_unit: null,
          material_id: selectedMaterialId,
          purchase_qty: qtyToPurchase
        })
      }
    },

    createOrderMaterialAssignment(order, attrs) {
      let orderMaterialAssignments = order.get('material_assignments');
      orderMaterialAssignments.push(attrs);
      order.set({ material_assignments_attributes: orderMaterialAssignments });
      return order.save();
    },

    onRemoveQuantityClicked: function (event) {
      if (event) {
        event.preventDefault();
        var r = confirm('Tem a certeza de que pretende eliminar?');
        if (r == false) { return; }
      }
      return this.model.destroy({wait: true})
    },

    onOrderLinkClicked: function (event) {
      event.preventDefault();

      if (this.model.get('order_id') === "n/a") {return;}

      var route = 'orders/' + this.model.get('order_id'),
          orders = Radio.store.request('get:orders');

      if (orders.findWhere({id: this.model.get('order_id')})) {
        return Radio.global.command('router:orders:navigate', route, {trigger: true});
      }

      Radio.global.trigger('anim-loading:show');
      var order = new OrderModel({id: this.model.get('order_id')});
      order.fetch().then(function () {
        orders.add(order);
        Radio.global.command('router:orders:navigate', route, {trigger: true});
        Radio.global.trigger('anim-loading:hide');
      })
    },

    onCheckboxClicked: function () {
      if ( $('input:checked').length > 0 ) {
        $('.add-to-material-order').show();
        $('.add-new-material').hide();
      } else {
        $('.add-to-material-order').hide();
        $('.add-new-material').show();
      }
    }
  });
})();
