(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/orders/do-order-buttons'),

        steps: [
            { // Verificações
                prev: 'Voltar à Página Inicial da OT',
                next: 'Ir para Parâmetros'
            },
            { // Parâmetros
                prev: 'Voltar às Verificações',
                next: 'Ir para Notas'
            },
            { // Notas
                prev: 'Voltar aos Parâmetros',
                next: 'Ir para Confirmação'
            },
            { // Confirmação
                prev: 'Voltar para Notas',
                next: 'Submeter'
            }
        ],

        behaviors: {
            Radio: {
                'do:order:change:step': 'onOrderStep'
            }
        },

        events: {
            'click .button-previous': 'onPreviousButtonClicked',
            'click .button-next': 'onNextButtonClicked',
            'click .button-unapproved': 'onUnapprovedButtonClicked'
        },

        initialize: function (options) {
            this.step = 1;
            if (options.steps) {
                this.steps = options.steps;
            }
        },

        onOrderStep: function (option) {
            if (this.steps[this.step - 1]) {
                this.step = option.step;

                var text = this.steps[this.step - 1];

                if (text.prev)
                    this.$('.button-previous > span').text(text.prev).show();
                else
                    this.$('.button-previous').hide();


                if (text.next)
                    this.$('.button-next').show().find('span').text(text.next);
                else
                    this.$('.button-next').hide();


                if (text.unapproved)
                    this.$('.button-unapproved').show().find('span').text(text.unapproved);
                else
                    this.$('.button-unapproved').hide();


            }
        },

        isValidStep: function (step) {
            return step >= 0 && step <= 5;
        },


        onPreviousButtonClicked: function (ev) {
            ev.preventDefault();
            if (this.isValidStep(this.step - 1)) {
                Radio.global.trigger('do:order:prev:step', {});
            }
        },

        onNextButtonClicked: function (ev) {
            ev.preventDefault();
            if (this.isValidStep(this.step + 1)) {
                Radio.global.trigger('do:order:next:step', {});
            }
        },

        onUnapprovedButtonClicked: function (ev) {
            ev.preventDefault();
            if (this.isValidStep(this.step + 1)) {
                Radio.global.trigger('do:order:unapproved:step', {});
            }
        }

    });
})();
