(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/calendars/calendar-day-group'),
        tagName: 'div',
        className: 'panel panel-default',
        events: {
            'click .bypass': 'onBypassClicked'
        },
        initialize: function (options) {
            this.isLate = options.model.get('isLate');
            this._renderHammerSize = this._renderHammerSize.bind(this);
            setTimeout(this._renderHammerSize, 100)
        },

        // just preventing a refresh
        onBypassClicked: function () {
            return false;
        },

        _renderHammerSize: function () {
            var size = this.model.toJSON().models.length;
            this.$el.find('.size-hammer').html("(" + size + ")");
        },

        templateHelpers: function () {
            var self = this;
            return {
                bypass: self.options.bypass,

                size: function () {
                    return this.length;
                },
                items: function () {
                    var items = this.map(function (e) {
                        return _.tap(e.toJSON(),
                            function (a) {
                                a.priority = _.find(Radio.global.request('app:priorities'), { 'value': e.get('priority') })
                            });
                    });
                    return items;
                },
                isLate: function(){
                    return self.isLate;
                }
            };
        }
    });
})();
