(function () {
  'use strict';

  var Radio = require('shim/radio');

  module.exports = Marionette.ItemView.extend({
    template: require('templates/materials/material-orders/material-order-edit'),
    tagName: 'div',
    className: 'section-box',

    ui: {
      saveMaterialOrder: '.save-material-order',
      changeStatus:      '.status'
    },

    events: {
      'click @ui.saveMaterialOrder': 'onSaveMaterialOrder',
      'change @ui.changeStatus': 'onChangeStatus'
    },

    initialize: function () {
      Backbone.Validation.bind(this, {model: this.model});
    },

    templateHelpers: function () {
      return {
        statusOptions: ['Pendente', 'Encomendado', 'Fechado']
      }
    },

    onShow: function () {
      this.changeFormAccordingToStatus();
    },

    onSaveMaterialOrder: function (event) {
      var data = this.$el.serializeObject();

      this.model.set(data);
      if (this.model.isValid(true)) {
        this.model.save({patch: true}).then(function () {
          var route = ['material_orders', this.model.id].join('/');
          Radio.global.command('router:materials:navigate', route);
        }.bind(this))
      }

    },

    onChangeStatus: function () {
      this.changeFormAccordingToStatus();
    },

    changeFormAccordingToStatus: function () {
      if ( $('.status').val() === "Fechado" ) {
        $('.due-date-container').hide();
        $('.closed-date-container').show();
      } else {
        $('.due-date-container').show();
        $('.closed-date-container').hide();
      }
    }
  });
})();
