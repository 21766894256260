(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/login-form'),
        tagName: 'div',
        className: 'login-box',

        ui: {
            form: 'form',
            email: 'input[name=email]',
            password: 'input[name=password]',
            button: 'button[type="submit"]'
        },

        events: {
            'submit @ui.form': 'onSubmit'
        },

        initialize: function () {
            Backbone.Validation.bind(this);
        },

        onBeforeDestroy: function () {
            Backbone.Validation.unbind(this);
        },

        onRender: function () {
            switch (this.model.get('state')) {

                case this.model.AUTH_STATE.DEFAULT:
                    this.ui.email.focus();
                    break;

                case this.model.AUTH_STATE.PENDING:
                    this.ui.email.prop('disabled', true);
                    this.ui.password.prop('disabled', true);
                    this.ui.button.prop('disabled', true);
                    break;

                case this.model.AUTH_STATE.FAILED:
                    this.ui.email.prop('disabled', false);
                    this.ui.password.prop('disabled', false);
                    this.ui.button.prop('disabled', false);
                    break;

                default:
                    break;
            }
        },

        onShow: function () {
            if (this.model.get('state') === this.model.AUTH_STATE.DEFAULT) {
                this.ui.email.focus();
            }
        },

        onSubmit: function (ev) {
            ev.preventDefault();

            this.model.set({
                'email': this.ui.email.val(),
                'password': this.ui.password.val()
            });

            if (this.model.isValid(true)) {
                Radio.global.command('session:validate', this.model);
            }
            else {
                Radio.global.command('alerts:show', {
                    msg: 'Os campos <b>Email</b> e <b>Password</b> não podem estar vazios!',
                    type: 'danger'
                });
            }


        }
    });
})();
