/* global module, require */
(function () {
  'use strict';

  var Radio = require('shim/radio'),
      MaterialOrderModel = require('models/material-order');

  module.exports = Marionette.LayoutView.extend({
    template: require('templates/materials/material-requests/material-requests'),
    tagName: 'div',
    className: 'section-box',

    regions: {
      create: '.create',
      view:   '.view',
    },

    ui: {
      modalContent:          '.modal-content',
      addNewMaterial:        '.add-new-material',
      addToMaterial:         '.add-to-request',
      viewMaterialsOrders:   '.view-materials-orders',
      addMaterialsToOrders:  '.add-to-material-order',
      printMaterialRequests: '.print-material-requests',
      viewNewMaterialsRequests: '.view-new-materials-requests',
      viewMaterialsRequests: '.view-materials-requests'
    },

    events: {
      'click @ui.addNewMaterial':        'onAddNewMaterial',
      'click @ui.addToMaterial':         'onAddToRequest',
      'click @ui.viewMaterialsOrders':   'onViewMaterialsOrdersClicked',
      'click @ui.addMaterialsToOrders':  'onAddMaterialsToOrders',
      'click @ui.printMaterialRequests': 'onPrintMaterialRequests',
      'click @ui.viewNewMaterialsRequests': 'onViewNewMaterialsRequestsClicked',
      'click @ui.viewMaterialsRequests': 'onViewMaterialsRequestsClicked'
    },

    initialize (opts) {
      this.isNewMaterials = opts.isNewMaterials
    },

    templateHelpers: function () {
      return {
        viewName: 'Necessidades de Materiais',
        isNewMaterials: this.isNewMaterials
      }
    },

    onViewMaterialsRequestsClicked: function (event) {
      event.preventDefault();

      var resource = 'router:materials:navigate',
          route = 'material_requests';

      Radio.global.command(resource, route, { trigger: true });
    },

    onViewMaterialsOrdersClicked: function (event) {
      event.preventDefault();

      var resource = 'router:materials:navigate',
          route    = 'material_orders';

      Radio.global.command(resource, route, { trigger: true });
    },

    onViewNewMaterialsRequestsClicked: function (event) {
      event.preventDefault();

      var resource = 'router:materials:navigate',
          route = 'new_material_requests';

      Radio.global.command(resource, route, { trigger: true });
    },

    onAddMaterialsToOrders: function (event) {
      event.preventDefault();

      var selectMaterialRequestsIds = $('table').find('input[type="checkbox"]:checked').map(function () {
          return $(this).data('id')
      }).get()

      if ( _.isEmpty(selectMaterialRequestsIds) ) {
        return;
      }

      var materialOrder = new MaterialOrderModel(),
          user = Radio.global.request('app:session').get('user'),
          data = {
            user_id: user.id,
            material_request_ids: selectMaterialRequestsIds,
            status: 'Pendente'
          };

      materialOrder.set({material_order: data});
      materialOrder.save().then(function (response) {
        var route = ['material_orders', 'edit', response.id].join('/')
        Radio.global.command('router:materials:navigate', route, {trigger: true})
      }.bind(this));
    },

    onPrintMaterialRequests: function (event) {
      event.preventDefault();

      Radio.global.trigger('materialRequests:print', this);
    },

    buildModal: function () {
      $('#helperModal .modal-dialog').empty().append(this.ui.modalContent);
    }
  });
})();
