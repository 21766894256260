var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return " "
    + container.escapeExpression(((helper = (helper = helpers.company || (depth0 != null ? depth0.company : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"company","hash":{},"data":data}) : helper)))
    + " ";
},"3":function(container,depth0,helpers,partials,data) {
    return " n.d. ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.code : depth0),"[Novo]",{"name":"eq","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + ": <b>"
    + alias4(((helper = (helper = helpers.purchase_qty || (depth0 != null ? depth0.purchase_qty : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"purchase_qty","hash":{},"data":data}) : helper)))
    + "</b> "
    + alias4(((helper = (helper = helpers.metric || (depth0 != null ? depth0.metric : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"metric","hash":{},"data":data}) : helper)))
    + "\n    <br>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "<b>[Novo]</b>";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    "
    + container.escapeExpression(((helper = (helper = helpers.real_price || (depth0 != null ? depth0.real_price : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"real_price","hash":{},"data":data}) : helper)))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.estimated_price : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    "
    + container.escapeExpression(((helper = (helper = helpers.estimated_price || (depth0 != null ? depth0.estimated_price : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"estimated_price","hash":{},"data":data}) : helper)))
    + "\n    <span style=\"display:block;font-size:13px\"><i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i> Estimativa</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "    n.d.\n  ";
},"15":function(container,depth0,helpers,partials,data) {
    return "\"background-color:#d82a2a;color:white\"";
},"17":function(container,depth0,helpers,partials,data) {
    return "\"background-color:#f8c741;color:white\"";
},"19":function(container,depth0,helpers,partials,data) {
    return "\"background-color:#016f38;color:white\"";
},"21":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression((helpers.moment || (depth0 && depth0.moment) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.closed_date : depth0),{"name":"moment","hash":{"format":"DD/MM/YYYY"},"data":data}))
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.due_date : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression((helpers.moment || (depth0 && depth0.moment) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.due_date : depth0),{"name":"moment","hash":{"format":"DD/MM/YYYY"},"data":data}))
    + "\n    <span style='display:block;font-size:13px'>(previsão)</span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, buffer = 
  "<td align=\"center\">\n  "
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "/"
    + alias4((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.created_at : depth0),{"name":"moment","hash":{"format":"YY"},"data":data}))
    + "\n</td>\n\n<td  align=\"center\">\n  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.company : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n</td>\n\n<td>\n";
  stack1 = ((helper = (helper = helpers.materials || (depth0 != null ? depth0.materials : depth0)) != null ? helper : alias2),(options={"name":"materials","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.materials) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</td>\n\n<td align=\"right\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.real_price : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n\n<td align=\"center\">\n  "
    + alias4(((helper = (helper = helpers.requester || (depth0 != null ? depth0.requester : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"requester","hash":{},"data":data}) : helper)))
    + "\n  <br>\n  "
    + alias4((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.created_at : depth0),{"name":"moment","hash":{"format":"DD/MM/YYYY"},"data":data}))
    + "\n</td>\n\n<td align=\"center\" style=\n  "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),"Pendente",{"name":"eq","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),"Encomendado",{"name":"eq","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),"Fechado",{"name":"eq","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n  "
    + alias4(((helper = (helper = helpers.status || (depth0 != null ? depth0.status : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data}) : helper)))
    + "\n</td>\n\n<td align=\"center\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.closed_date : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}