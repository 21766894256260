(function () {
    'use strict';

    var Radio = require('shim/radio'),

        Orders = require('collections/orders'),
        CalendarMonthlyController = require('controllers/calendars/calendar-monthly-object'),
        CalendarDailyController = require('controllers/calendars/calendar-daily-object');

    module.exports = Marionette.Controller.extend({
        initialize: function (options) {
            options = options || {};
            this.users = options.users;
            this.userGroups = options.userGroups;
            this.notifications = options.notifications;
            this.orders = options.orders;
            this.user = Radio.global.request('app:session').get('user').get('_id');
        },

        //Show Daily Calendar
        showCalendar: function (year, month, day) {
            var self = this;

            //Set LastMenu
            Radio.global.command('set:lastmenu', 'main:calendar');

            Radio.global.trigger('anim-loading:show');
            setTimeout(function () {
                month = parseInt(month, 10) - 1;
                var user = self._getUser(),
                    options = _.defaults({orders: self._orderForUser(user)}, self._getOptions(user.get('_id')));

                new CalendarDailyController(options).showCalendar(year, month, day);
            }, 10);
        },

        //Show Monthly Calendar
        showCalendarMonthToday: function () {
            var today = moment();

            //Set LastMenu
            Radio.global.command('set:lastmenu', 'main:calendar');

            this.showCalendarMonth(today.year(), today.month() + 1);
        },
        showCalendarMonth: function (year, month) {
            var self = this;
            Radio.global.trigger('anim-loading:show');
            setTimeout(function () {
                month = parseInt(month, 10) - 1;
                var user = self._getUser(),
                    options = _.defaults({orders: self._orderForUser(user)}, self._getOptions(user.get('_id')));

                new CalendarMonthlyController(options).showCalendar(year, month);
            }, 10);
        },

        //Change User Calendar
        showCalendarUser: function () {
            var self = this;
            Radio.global.trigger('anim-loading:show');
            setTimeout(function () {
                var today = moment(),
                    year = today.get('year'),
                    month = today.get('month'),
                    user = self._getUser(),
                    options = _.defaults({orders: self._orderForUser(user)}, self._getOptions(user.get('_id')));

                new CalendarMonthlyController(options).showCalendar(year, month);
            }, 10);
        },
        _getUser: function () {
            var id = parseInt(this._urlParam('user_id'));
            return Radio.store.request('get:users').findWhere({_id: id}) || Radio.global.request('app:session').get('user');
        },

        _getOptions: function (id) {
            return {
                notifications: this.notifications,
                orders: this.orders,
                users: this.users,
                userGroups: this.userGroups,
                today: new Date(),
                user: id
            };
        },
        _orderForUser: function (user) {
            var self = this,
                states = ['ongoing', 'pending', 'complete'],
                models = self.orders.vCollection({user_id: user.get('_id'), state: states}, self).models,
                groups;

            if (user.get('user_groups').length > 0) {
                groups = _.map(user.get('user_groups'), function(val){return val.id});
                models = models.concat(self.orders.vCollection({user_group_id: groups, state: states}, self).models);
            }

            return new Orders(_.uniq(models));
        },
        _urlParam: function (name) {
            var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
            if (results == null) {
                return null;
            }
            else {
                return results[1] || 0;
            }
        }

    });
})();
