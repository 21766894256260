(function () {
    'use strict';

    var api = require('shim/api'),

        Unit = require('models/unit');

    module.exports = Backbone.Collection.extend({
        model: Unit,
        url: api.urlRoot + '/units',
        store: 'units',

        fetchFromEquipment: function (equipment) {
            var url = this.url + '?equipment_id=' + equipment.get('_id');
            return this.fetch({reset: true, url: url, skip_store: true});
        },

        parse: function (response) {
            return response.units;
        },

        byEquipment: function (filters) {
            var self = this;

            return new this.constructor(
                self.filter(function (model) {
                    return _.every(filters, function (value, key) {
                        return model.get(key) == '1' || model.get(key) == value;
                    })
                })
            );
        }
    });
})();
