var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "            <header id=\"header\"></header>\n            <section id=\"alerts\" class=\"alert-container\"></section>\n            <section id=\"loading\"></section>\n            <section id=\"content\" class=\"section-row\"></section>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <header id=\"header\" class=\"main-header\"></header>\n            <section id=\"alerts\" class=\"alert-container\"></section>\n            <section id=\"loading\"></section>\n            <section id=\"content\" class=\"section-row main-content\"></section>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = 
  "<aside class=\"main-nav-bar navmenu-fixed-left\">\n    <h1>\n        <img src=\"../images/logo-white.png\">\n    </h1>\n    <nav id=\"menu\"></nav>\n</aside>\n\n<input type=\"checkbox\" id=\"nav-trigger\" class=\"nav-trigger\">\n\n<div class=\"main-column canvas\">\n    <div class=\"main-container\">\n        <section id=\"loading-modal\"></section>\n";
  stack1 = ((helper = (helper = helpers.isDashboard || (depth0 != null ? depth0.isDashboard : depth0)) != null ? helper : alias2),(options={"name":"isDashboard","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isDashboard) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helper = (helper = helpers.isNotDashboard || (depth0 != null ? depth0.isNotDashboard : depth0)) != null ? helper : alias2),(options={"name":"isNotDashboard","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isNotDashboard) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n</div>\n\n<!-- Helper Modal -->\n<div class=\"modal fade\" id=\"helperModal\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"helperModal\">\n    <div class=\"modal-dialog\" role=\"document\" style=\"z-index:9999\"></div>\n</div>\n<div class=\"modal fade\" id=\"secondaryHelperModal\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"helperModal\">\n  <div class=\"modal-dialog\" role=\"document\" style=\"z-index:9999\"></div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}