(function () {
    'use strict';

    var BaseRouter = require('routers/base-router');

    module.exports = BaseRouter.extend({
        forbidden: {
            'dashboards(/)': ['solicitant', 'operator']
        },

        appRoutes: {
            'dashboards(/)': 'showDashboards'
        }
    });
})();
