(function () {
  'use strict';

  var BaseRouter = require('routers/base-router');

  module.exports = BaseRouter.extend({
    forbidden: {
      'settings/rootzone(/)': ['solicitant', 'admin', 'operator', 'foreman'],
      'settings/users(/)':    ['solicitant', 'admin', 'operator', 'foreman'],

      'settings/typified_orders(/)':        ['solicitant', 'admin', 'operator', 'foreman'],
      'settings/typified_orders/edit/:id':  ['solicitant', 'admin', 'operator', 'foreman'],
      'settings/typified_orders/create':    ['solicitant', 'admin', 'operator', 'foreman']
    },
    appRoutes: {
      'settings(/)':                 'showSettings',
      'settings/change-password(/)': 'changePassword',
      'settings/rootzone(/)':        'rootzoneSettings',
      'settings/users(/)':           'usersSettings',

      'settings/typified_orders(/)':       'showTypifiedOrders',
      'settings/typified_orders/edit/:id': 'showTypifiedOrderById',
      'settings/typified_orders/create':   'createTypifiedOrder'
    }
  });
})();
