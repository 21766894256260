(function () {
    'use strict';

    var HeaderView = require('views/header-view'),
        MenuView = require('views/menu-view'),
        AnimLoadingView = require('views/anim-loading-view');

    module.exports = Marionette.LayoutView.extend({
        className: 'wrapper',
        template: require('templates/app'),
        tagName: 'div',

        ui: {
            canvas: '.canvas',
            loading: '.loading-template'
        },

        events: {
            'click @ui.canvas': 'onClickCanvas'
        },

        regions: {
            header: '#header',
            content: '#content',
            menu: '#menu',
            animload: '#loading-modal'
        },

        initialize: function (options) {
            this.notifications = options.notifications;
        },

        onBeforeShow: function () {
            this.notifications.fetch().done(function () {
                this.getRegion('header').show(new HeaderView({collection: this.notifications}));
                this.getRegion('menu').show(new MenuView());
                this.getRegion('animload').show(new AnimLoadingView());
            }.bind(this));
        },

        onClickCanvas: function (e) {
            var $cb = $('.nav-trigger'),
                $target = $(e.target);

            if ($cb.is(':checked') && !$target.is('label') && !($target.is('i') && $target.hasClass('fa-bars'))) {
                $cb.prop('checked', false);
            }
        }
    });
})();
