(function () {
  'use strict';

  var Radio = require('shim/radio'),

      MaterialRow = require('templates/orders/material-row'),
      MaterialRowSelect = require('templates/orders/material-row-select');

  module.exports = Marionette.ItemView.extend({
    template: require('templates/orders/order-material'),

    ui: {
      'select2':        '.materials',
      'materialsTable': '#material-rows',
      'materialHidden': '#material-id-hidden'
    },

    events: {
      'change .categories':     'onCategoryChange',
      'change .materials':      'onMaterialChange',
      'change .mutate-change':  'onMaterialRowChange',
      'click .remove-material': 'onRemoveMaterial',
      'click .add-material':    'onAddMaterial',
      'click .add-new-material': 'onAddNewMaterial',
      'click .save-materials':  'onSaveMaterials'
    },

    initialize: function (options) {
      this.materials = options.materials;
      this.count = 1;
      this.materialHasChanges = false;
    },

    onBeforeShow: function () {
      this.ui.select2.select2({ placeholder: ' - Selecione Material - ' });
    },

    templateHelpers: function () {
      return {
        categories:             this.collection.toJSON(),
        materials:              this.materials.toJSON(),
        canEditMaterials:       this.model.get('state_id') < 3,
        hasMaterialAssignments: this.hasMaterialAssignments()
      };
    },

    onCategoryChange: function (ev) {
      var $el = $(ev.target),
      materialContainer = $el.closest('.material-row').find('.materials-select'),
      categoryID = $el.find(':selected').data('id'),
      filteredMaterials;

      if (categoryID === -1){
        filteredMaterials = this.materials.toJSON()
      } else {
        filteredMaterials = _.filter(this.materials.toJSON(), function (material) {
          return material.material_category_id == categoryID;
        });
      }

      materialContainer.html(MaterialRowSelect({ materials: filteredMaterials }));
      materialContainer.find('.materials').select2();
    },

    onMaterialChange: function (ev) {
      ev.preventDefault();

      var hiddenID = $(ev.target).find(':selected').data('material-id');
      this.ui.materialHidden.val(hiddenID);
    },

    onMaterialRowChange: function (ev) {
      ev.preventDefault();
      this.triggerMaterialChange(true);
    },

    onAddMaterial: function (ev) {
      ev.preventDefault();

      var template = $(MaterialRow({
        categories:       this.collection.toJSON(),
        materials:        this.materials.toJSON(),
        date:             moment(),
        canEditMaterials: true,
        isNewMaterial:    true
      }));
      template.find('.materials').select2();

      //Find if theres materials already
      if (this.ui.materialsTable.find('.material-row').length > 0) {
        this.ui.materialsTable.append(template);
      } else {
        this.ui.materialsTable.html(template);
      }

      this.triggerMaterialChange(true);
    },

    onAddNewMaterial: function (ev) {
      ev.preventDefault();
    },

    onRemoveMaterial: function (ev) {
      ev.preventDefault();
      $(ev.target).closest('.material-row').remove();
      this.triggerMaterialChange(true);
    },

    onSaveMaterials: function (ev) {
      ev.preventDefault();

      if (!this.materialHasChanges) { return; }

      this.onSetMaterialAssignments();

      this.model.save()
                .fail(function (xhr) {
                  if (xhr.readyState == 0) {
                    this.model.setOfflineMaterials();
                  }
                }.bind(this))
                .always(function () {
                  this.model.afterSync();
                  this.render();
                  this.ui.select2.select2();
                  this.triggerMaterialChange(false);
                }.bind(this));
    },

    onSetMaterialAssignments: function () {
      var newAndModifiedMaterials = [],
          removedMaterials;

      // Fetched Materials
      $('#material .material-row').each(function (key, value) {
        var data = $(value).serializeObject(),
            materialID = $(value).find('.materials :selected').data('material-id'),
            user = Radio.global.request('app:session').get('user');

        newAndModifiedMaterials.push({
          id:                    parseInt(data.id) || null,
          stock_id:              parseInt(data.material),
          quantity:              parseInt(data.amount) || 0,
          material_id:           materialID,
          order_id:              this.model.id,
          date:                  moment().format('YYYY-MM-DD'),
          user_id:               user.id,
          is_material_available: this.checkAvailability(data)
        })
      }.bind(this))

      //Get Removed Materials
      removedMaterials = _(this.model.get('material_assignments'))
        .reject(function (assignment) {
          return _.any(newAndModifiedMaterials, { id: assignment.id })
        })
        .map(function (assignment) {
          return {
            id:       assignment.id,
            _destroy: true
          }
        }).value()

      this.model.set({
        material_assignments_attributes: newAndModifiedMaterials.concat(removedMaterials)
      });
    },

    triggerMaterialChange: function (value) {
      this.materialHasChanges = value;
    },

    hasMaterialAssignments: function() {
      if ( this.model.has('material_assignments_attributes') ){
        return this.model.get('material_assignments_attributes')
      } else {
        return this.model.get('material_assignments')
      }
    },

    checkAvailability: function ( data) {
      var stockId = data.material,
          material = Radio.store.request('get:materials').findWhere({stock_id: stockId}),
          available_quantity = material ? material.get('quantity') : undefined,
          requested_quantity = parseInt(data.amount);

      return available_quantity >= requested_quantity ? true : false
    }
  });
})();
