(function () {
    'use strict';

    var Radio   = require('shim/radio'),
        config  = require('shim/config'),
        api     = require('shim/api');

    module.exports = Backbone.Model.extend({
        idAttribute: '_id',
        urlRoot: api.urlRoot + '/issues',
        store: 'issues',

        closeIssue: function () {
          let self = this

          return self.set({is_closed: true}).save()
                                            .always(self.updateIssue());
        },

        updateIssue: function () {
          var self = this;
          self.collection.set(self, { remove: false });
        }
    });
})();
