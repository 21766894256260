var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<section class=\"column toolbar\">\n    <div class=\"box\">\n        <time datetime=\""
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.todayDate : depth0),{"name":"moment","hash":{"format":"YYYY-MM-DD","lang":"pt"},"data":data}))
    + "\">\n            <span class=\"d\">"
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.todayDate : depth0),{"name":"moment","hash":{"format":"DD","lang":"pt"},"data":data}))
    + "</span>\n            <em>\n                <span class=\"wd\">"
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.todayDate : depth0),{"name":"moment","hash":{"format":"dddd","lang":"pt"},"data":data}))
    + "</span>\n                <span class=\"m-y\">"
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.todayDate : depth0),{"name":"moment","hash":{"format":"MMMM, YYYY","lang":"pt"},"data":data}))
    + "</span>\n            </em>\n        </time>\n        <a href=\"#/calendar/month\" title=\"Vista Mensal\" class=\"button-icon\">\n          <i aria-hidden=\"true\" class=\"fa fa-calendar\"></i>\n        </a>\n    </div>\n</section>\n\n<section class=\"column toolbar\">\n    <div class=\"box\">\n        <div class=\"lf\">\n            <span class=\"nr\">"
    + alias3(((helper = (helper = helpers.todayTasks || (depth0 != null ? depth0.todayTasks : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"todayTasks","hash":{},"data":data}) : helper)))
    + "</span>\n            <span class=\"desc\">Tarefas<span>para o dia</span></span>\n        </div>\n    </div>\n</section>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}