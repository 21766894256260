var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"red-semaphore\" />\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"yellow-semaphore\" />\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"green-semaphore\" />\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "*";
},"9":function(container,depth0,helpers,partials,data) {
    return "        <br>\n        <i style=\"color:#B9B9B9;\">* Os valores apresentados podem ser alterados através do Menu Definições. </i><br>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, alias5="function", alias6=helpers.blockHelperMissing, buffer = 
  "<div class=\"dashboard2-3\">\n    <div class=\"row visible-md visible-lg\">\n        <div class=\"col-md-4 col-sm-12 visible-md visible-lg\">\n            <h4>Preventivo e Rotinas</h4>\n        </div>\n        <div class=\"col-md-4 col-sm-12 visible-md visible-lg\">\n            <h4>Correctivo e Outros trabalhos</h4>\n        </div>\n        <div class=\"col-md-4 col-sm-12 visible-md visible-lg\">\n            <h4>Operação</h4>\n        </div>\n    </div>\n    <div class=\"row main-row\">\n        <p class=\"title-row\"> MENSAL </p>\n        <!-- Mensal tempo médio -->\n        <div class=\"col-md-4 col-sm-12\">\n            <h4 class=\"visible-sm visible-xs\">Preventivo e Rotinas</h4>\n            <div class=\"row\">\n                <div class=\"col-xs-4\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph1 : depth0)) != null ? stack1.semaphore : stack1),"red",{"name":"eq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph1 : depth0)) != null ? stack1.semaphore : stack1),"yellow",{"name":"eq","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph1 : depth0)) != null ? stack1.semaphore : stack1),"green",{"name":"eq","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"col-xs-8 semaphor-content\">\n                    <p class=\"details1\">Médio de Fecho OTs</p>\n                    <p class=\"details2\">"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.graph1 : depth0)) != null ? stack1.number_of_days : stack1), depth0))
    + " dias</p>\n                    <p class=\"details3\">"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.graph1 : depth0)) != null ? stack1.ot_done : stack1), depth0))
    + " fechadas</p>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-md-4 col-sm-12\">\n            <h4 class=\"visible-sm visible-xs\">Correctivo e Outros trabalhos</h4>\n            <div class=\"row\">\n                <div class=\"col-xs-4\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph2 : depth0)) != null ? stack1.semaphore : stack1),"red",{"name":"eq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph2 : depth0)) != null ? stack1.semaphore : stack1),"yellow",{"name":"eq","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph2 : depth0)) != null ? stack1.semaphore : stack1),"green",{"name":"eq","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"col-xs-8 semaphor-content\">\n                    <p class=\"details1\">Médio de Fecho OTs</p>\n                    <p class=\"details2\">"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.graph2 : depth0)) != null ? stack1.number_of_days : stack1), depth0))
    + " dias</p>\n                    <p class=\"details3\">"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.graph2 : depth0)) != null ? stack1.ot_done : stack1), depth0))
    + " fechadas</p>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-md-4 col-sm-12\">\n            <h4 class=\"visible-sm visible-xs\">Operação</h4>\n            <div class=\"row\">\n                <div class=\"col-xs-4\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph3 : depth0)) != null ? stack1.semaphore : stack1),"red",{"name":"eq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph3 : depth0)) != null ? stack1.semaphore : stack1),"yellow",{"name":"eq","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph3 : depth0)) != null ? stack1.semaphore : stack1),"green",{"name":"eq","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"col-xs-8 semaphor-content\">\n                    <p class=\"details1\">Médio de Fecho OTs</p>\n                    <p class=\"details2\">"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.graph3 : depth0)) != null ? stack1.number_of_days : stack1), depth0))
    + " dias</p>\n                    <p class=\"details3\">"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.graph3 : depth0)) != null ? stack1.ot_done : stack1), depth0))
    + " fechadas</p>\n                </div>\n            </div>\n        </div>\n    </div>\n    <!-- ACUMULADO  -->\n    <div class=\"row main-row\" style=\"background: #eeeeee\">\n        <p class=\"title-row\"> ACUMULADO </p>\n        <!-- Mensal tempo médio -->\n        <div class=\"col-md-4 col-sm-12\">\n            <h4 class=\"visible-sm visible-xs\">Preventivo e Rotinas</h4>\n            <div class=\"row\">\n                <div class=\"col-xs-4\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph4 : depth0)) != null ? stack1.semaphore : stack1),"red",{"name":"eq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph4 : depth0)) != null ? stack1.semaphore : stack1),"yellow",{"name":"eq","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph4 : depth0)) != null ? stack1.semaphore : stack1),"green",{"name":"eq","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"col-xs-8 semaphor-content\">\n                    <p class=\"details1\">Médio de Fecho OTs</p>\n                    <p class=\"details2\">"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.graph4 : depth0)) != null ? stack1.number_of_days : stack1), depth0))
    + " dias</p>\n                    <p class=\"details3\">"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.graph4 : depth0)) != null ? stack1.ot_done : stack1), depth0))
    + " fechadas</p>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-md-4 col-sm-12\">\n            <h4 class=\"visible-sm visible-xs\">Correctivo e Outros trabalhos</h4>\n            <div class=\"row\">\n                <div class=\"col-xs-4\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph5 : depth0)) != null ? stack1.semaphore : stack1),"red",{"name":"eq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph5 : depth0)) != null ? stack1.semaphore : stack1),"yellow",{"name":"eq","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph5 : depth0)) != null ? stack1.semaphore : stack1),"green",{"name":"eq","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"col-xs-8 semaphor-content\">\n                    <p class=\"details1\">Médio de Fecho OTs</p>\n                    <p class=\"details2\">"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.graph5 : depth0)) != null ? stack1.number_of_days : stack1), depth0))
    + " dias</p>\n                    <p class=\"details3\">"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.graph5 : depth0)) != null ? stack1.ot_done : stack1), depth0))
    + " fechadas</p>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-md-4 col-sm-12\">\n            <h4 class=\"visible-sm visible-xs\">Operação</h4>\n            <div class=\"row\">\n                <div class=\"col-xs-4\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph6 : depth0)) != null ? stack1.semaphore : stack1),"red",{"name":"eq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph6 : depth0)) != null ? stack1.semaphore : stack1),"yellow",{"name":"eq","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.graph6 : depth0)) != null ? stack1.semaphore : stack1),"green",{"name":"eq","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"col-xs-8 semaphor-content\">\n                    <p class=\"details1\">Médio de Fecho OTs</p>\n                    <p class=\"details2\">"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.graph6 : depth0)) != null ? stack1.number_of_days : stack1), depth0))
    + " dias</p>\n                    <p class=\"details3\">"
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.graph6 : depth0)) != null ? stack1.ot_done : stack1), depth0))
    + " fechadas</p>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n<div id=\"helpText\">\n    <p><b><u>Tempo Médio de Fecho:</u></b></p>\n    <ul>\n        <li>Ordens Fechadas:</li>\n        <p style=\"margin: 5px 0px\">Depois da data prevista: Data Real de Conclusão – Data Prevista de Inicio</p>\n        <p style=\"margin: 5px 0px\">Antes da data prevista: Data Real de Conclusão – Data Real de Inicio</p>\n        <li style=\"padding-top:5px;\">Ordens Abertas: Data Atual – Data Prevista de Inicio</li>\n    </ul>\n    <p>\n        <b><u>Critério Actual de Cores:";
  stack1 = ((helper = (helper = helpers.isManager || (depth0 != null ? depth0.isManager : depth0)) != null ? helper : alias2),(options={"name":"isManager","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias5 ? helper.call(alias1,options) : helper));
  if (!helpers.isManager) { stack1 = alias6.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "</u></b>\n        <div class=\"row\" style=\"text-align: center\">\n            <span class=\"col-xs-4 fg-green\">Verde &le; "
    + alias4(((helper = (helper = helpers.sem_green || (depth0 != null ? depth0.sem_green : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"sem_green","hash":{},"data":data}) : helper)))
    + "</span>\n            <span class=\"col-xs-4 fg-yellow\">"
    + alias4(((helper = (helper = helpers.sem_green || (depth0 != null ? depth0.sem_green : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"sem_green","hash":{},"data":data}) : helper)))
    + " < Amarelo < "
    + alias4(((helper = (helper = helpers.sem_red || (depth0 != null ? depth0.sem_red : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"sem_red","hash":{},"data":data}) : helper)))
    + " </span>\n            <span class=\"col-xs-4 fg-red\">Vermelho &ge; "
    + alias4(((helper = (helper = helpers.minus || (depth0 != null ? depth0.minus : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"minus","hash":{},"data":data}) : helper)))
    + alias4(((helper = (helper = helpers.sem_red || (depth0 != null ? depth0.sem_red : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"sem_red","hash":{},"data":data}) : helper)))
    + "</span>\n        </div>\n";
  stack1 = ((helper = (helper = helpers.isManager || (depth0 != null ? depth0.isManager : depth0)) != null ? helper : alias2),(options={"name":"isManager","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias5 ? helper.call(alias1,options) : helper));
  if (!helpers.isManager) { stack1 = alias6.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </p>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}