(function () {
    'use strict';

    var UserGroup = require('models/user-group'),
        api = require('shim/api');

    module.exports = Backbone.Collection.extend({
        model: UserGroup,
        url: api.urlRoot + '/user_groups',

        parse: function (response) {
            return response.user_groups;
        }
    });
})();
