(function () {
    'use strict';

    var BaseObject = require('controllers/base-object'),

        ToolsView = require('views/main/tools-view');

    module.exports = BaseObject.extend({

        showTools: function () {
            this.show(new ToolsView(), this);
        }

    });
})();
