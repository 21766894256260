(function () {
  'use strict';

  var MaterialRequestModel = require('models/material-request');

  module.exports = Marionette.ItemView.extend({
    template: require('templates/materials/material-orders/material-order-requests-item'),
    tagName: 'div',

    ui: {
      editQtCost:     '.edit-material-request',
      saveQtCost:     '.save-material-request',
      removeMaterial: '.remove-material-request',
    },

    events: {
      'click @ui.editQtCost':     'onEditQtCost',
      'click @ui.saveQtCost':     'onSaveQtCost',
      'click @ui.removeMaterial': 'onRemoveMaterial',
    },

    templateHelpers: function () {
      return {
        statusNotClosed: this.options.materialOrder.get('status') != 'Fechado'
      }
    },

    onEditQtCost: function (event) {
      event.preventDefault();

      var id = $(event.currentTarget).data('id');
      $('#unit-cost-' + id).prop('disabled', false);
      $('#supplied-qty-' + id).prop('disabled', false);
      $('#material-request-save-' + id).show();
      $('#material-request-edit-' + id).hide();
    },

    onSaveQtCost: function (event) {
      event.preventDefault();

      var id = $(event.currentTarget).data('id');
      $('#unit-cost-' + id).prop('disabled', true);
      $('#supplied-qty-' + id).prop('disabled', true);
      $('#material-request-save-' + id).hide();
      $('#material-request-edit-' + id).show();

      var supplied_qty = $('#supplied-qty-' + id).val(),
          unit_cost = $('#unit-cost-' + id).val(),
          data = {supplied_qty: supplied_qty, unit_cost: unit_cost}

      this.model.save(data,{patch: true}).then(function () {
        this.options.materialOrder.fetch()
      }.bind(this));
    },

    onRemoveMaterial: function (event) {
      event.preventDefault();

      var r = confirm('Tem a certeza de que pretende remover este material desta encomenda?');
      if (r == false) { return; }

      const material_request_ids = this.collection.pluck('id').filter(id => id !== this.model.id);

      this.options.materialOrder.save({material_order: {material_request_ids}}, {patch: true}).then(function () {
        this.collection.remove(this.model);

        if (this.collection.length === 0){
          $('.delete-material-order').show();
        };

        this.options.allMaterialRequests.add(this.model);
      }.bind(this));
    },
  });
})();
