'use strict';

require('./bootstrap');
require('shim/validation');
require('./helpers');
require('behaviors/behaviors');
require('offline/offline-controller');
require('controllers/alerts-controller');
require('pickerjs');

var Radio = require('shim/radio'),
    AppLayout = require('layouts/app-layout'),
    storage = require('storage/storage'),
    RootView = require('views/root-view'),
    Prefetcher = require('syncs/prefetcher'),
    Globals = require('shim/global-values'),
    api = require('shim/api');

var App = new Marionette.Application({

  renderLayout: function (layout) {
    if (!this.appLayout || this.appLayout.isDestroyed) {
      this.appLayout = new AppLayout({notifications: this.notifications});
    }
    layout = layout || this.appLayout;
    this.rootView.switchLayout(layout);
  },

  //Initiate Offline Sync and Storage
  init: function () {
    // Start Storage
    storage.init();
    //Start Offline Controller
    Radio.global.command('offline:initialize');
    //Search for queued items
    Radio.global.command('offline:sync');
  },

  afterSync: function(){
    Radio.global.command('alerts:show', {msg: 'A carregar dados.', type: 'purple', dynamic: true});

    this.load()
        .done(function(){
          $('#loading-splash-screen').remove();
          this.initializeRoutes();
        }.bind(this))
        .fail(dataLoadFailure)
        .progress(function (current, total) {
          Radio.global.trigger('alert:dynamic:increase', current/total);
        });
  },

  afterSyncSession: function(){
    this.load()
        .done(function () { Radio.global.command('router:main:navigate', 'dashboard', {trigger: true}); })
        .fail(dataLoadFailure)
        .progress(function (a, b) { Radio.global.trigger('alert:dynamic:increase', a / b); });
  },

  load: function () {
    //Set App Offline-Sync State True to prevent re-fetch
    this.isOfflineSynced = true;
    //Prefetch Collections
    return this.fetchOrders().then(orderPromises => {
      const bootstrapPromises = Prefetcher.prefetchAll(this.prefetch);
      return $.whenWithProgress([...bootstrapPromises, ...orderPromises]);
    });
  },

  // this is a little hacky, but it was a really easy way to fix the performance problem with orders.
  //
  // instead of loading just one huge file of dozens of megabytes, we split the load onto multiple requests.
  // this way, we get the same amount of data but in parallel
  //
  // the reason why we're only fetching 100 each time it's because some orders are huge individually, even a request
  // of only 100 orders can take over 10MB (while others will just take 100 KB)
  //
  // because we split them into 100 each time, the chances of getting a lot of huge orders on the same request are
  // lower. of course it's still possible that 1 request takes 25MB and all the others together only 1 MB.
  fetchOrders: function () {
    const perPage = 250;
    return this.orders.getTotal().then(data => {
      let promises = [];
      for (let i = 0; i < Math.ceil(data.total / perPage); i++) {
        promises.push(this.orders.fetch({
          data: { page: i, per_page: perPage },
          remove: false
        }));
      }
      return promises;
    });
  },

  initializeRoutes: function () {
    this.routers = require('routers')(this);

    if (Backbone.history) {
      Backbone.history.start();
    }
  }

});

App.addInitializer(function () {
  this.states = _.extend({}, Globals.states);
  this.priorities = _.extend({}, Globals.priorities);
  this.filters = _.extend({}, Globals.filters);
  this.showMenus = _.extend({}, Globals.menus);
  this.forbiddenRouteRedirect = _.extend({}, Globals.forbiddenRouteRedirect);

  this.rootView = new RootView();
  this.rootView.render();
  _.assign(this, Prefetcher.getNeeded());

  this.session.setMe();
  if (_.isEmpty(this.session.validateToken())) {
    $('#loading-splash-screen').remove();
    this.initializeRoutes();
    Radio.global.command('router:login:navigate', 'login', {trigger: true});
    this.init.bind(this);
    return;
  }

  this.session.getMe()
  .done(
    this.init.bind(this)
  )
  .fail(function () {
    this.initializeRoutes();
    $('#loading-splash-screen').remove();
    Radio.global.command('router:login:navigate', '' +
    'login', {trigger: true});
  }.bind(this));
});

//Helper Functions
function dataLoadFailure() {
  Radio.global.trigger('app:logout');
  Radio.global.command('router:login:navigate', 'login', {trigger: true});
  Radio.global.command('alerts:show', {msg: 'Ocorreu um erro no carregamento dos dados', type: 'danger'});
}

/**
*  --------------
* | RADIO CALLS  |
*  --------------
*/

//Session Init
Radio.global.on('session:init', function () {
  App.isOfflineSynced = false;
  App.init();
});

/**
* REQUESTS
*/
Radio.global.reply('main:region', function () {
  return App.appLayout.content;
});
Radio.global.reply("app:synced", function () {
  return App.isOfflineSynced;
});
Radio.global.reply("app:show:menus", function () {
  return App.showMenus;
});
Radio.global.reply("app:show:forbiddenRouteRedirect", function () {
  return App.forbiddenRouteRedirect;
});
Radio.global.reply("app:filters", function () {
  return App.filters;
});
Radio.global.reply("app:filters:reset", function () {
  return App.filters = _.extend({}, Globals.filters);
});
Radio.global.reply("app:states", function () {
  return App.states;
});
Radio.global.reply("app:priorities", function () {
  return App.priorities;
});
Radio.global.reply('app:session', function () {
  return App.session;
});
Radio.global.reply("dashboard:tab", function () {
  return App.dashboardTab;
});
Radio.global.reply('get:lastmenu', function () {
  return App.lastMenu;
});

/**
* COMMANDS
*/
Radio.global.comply('render:layout', function (layout) {
  App.renderLayout(layout);
});
Radio.global.comply('app:fetch:all', function () {
  App.afterSync();
});
Radio.global.comply('app:fetch:sessionInit', function () {
  App.afterSyncSession();
});
Radio.global.comply('dashboard:tab:changed', function (tab) {
  App.dashboardTab = tab;
});
Radio.global.comply('set:lastmenu', function (menu) {
  App.lastMenu = menu;
});

/**
* ROUTING CALLS
*/
Radio.global.comply('router:main:navigate', function (route, trigger) {
  App.routers.mainRouter.navigate(route, trigger);
  Radio.global.command('alerts:dismiss');
});
Radio.global.comply('router:calendar:navigate', function (route, trigger) {
  App.routers.calendarRouter.navigate(route, trigger);
  Radio.global.command('alerts:dismiss');
});
Radio.global.comply('router:orders:navigate', function (route, trigger) {
  App.routers.orderRouter.navigate(route, trigger);
  Radio.global.command('alerts:dismiss');
});
Radio.global.comply('router:login:navigate', function (route) {
  App.routers.loginRouter.navigate(route, {trigger: true});
  Radio.global.command('alerts:dismiss');
});
Radio.global.comply('router:dashboards:navigate', function (route) {
  App.routers.dashboardsRouter.navigate(route, {trigger: true});
  Radio.global.command('alerts:dismiss');
});
Radio.global.comply('router:notifications:navigate', function (route) {
  App.routers.notificationRouter.navigate(route, {trigger: true});
  Radio.global.command('alerts:dismiss');
});
Radio.global.comply('router:materials:navigate', function (route) {
  App.routers.materialsRouter.navigate(route, {trigger: true});
  Radio.global.command('alerts:dismiss');
});
Radio.global.comply('router:settings:navigate', function (route) {
  App.routers.settingsRouter.navigate(route, {trigger: true});
  Radio.global.command('alerts:dismiss');
});

module.exports = App;
