(function () {
    'use strict';

    _.extend(Backbone.Validation.validators, {
        dateValidator: function (value, attr, customValue, model) {

            // if (customValue === 'start' && !isValidStartDate(model)) {
            //     return 'A data de inicio tem de ser após a data actual';
            // }
            //
            // if (customValue === 'end' && !isValidEndDate(model)) {
            //     return 'A data de finalização tem de ser igual ou após a data de início';
            // }
        }
    });

    function isValidStartDate(model) {
        var today = moment(),
            start = moment(model.get('start_date_preview') || model.get('start_date'));

        return start.isSame(today, 'day') || start.isAfter(today, 'day');
    }

    function isValidEndDate(model) {
        var start = moment(model.get('start_date_preview') || model.get('start_date')),
            end = moment(model.get('end_date_preview') || model.get('end_date'));

        return end.isSame(start, 'day') || end.isAfter(start, 'day');
    }
})();
