(function () {
    'use strict';

    var Notification = require('models/notification'),
        api = require('shim/api');

    module.exports = Backbone.Collection.extend({
        url: api.urlRoot + '/notifications',
        model: Notification,
        comparator: 'date',

        parse: function (response) {
            return response.notifications;
        },

        discardAll: function () {
            this.each(function (model) {
                model.discard();
            });
            this.reset();
        }
    });
})();
