(function () {
    'use strict';

    var api = require('shim/api'),

        User = require('models/user');

    module.exports = Backbone.Collection.extend({
        model: User,
        url: api.urlRoot + '/users',
        store: 'users',
        comparator: 'name',

        parse: function (response) {
            return _.reject(response.users, {role_slug: 'solicitant'})
        },

        findByGroupId: function (id) {
            var group, results = [];

            if (!id) {
                return results;
            }

            this.filter(function (model) {
                group = _.find(model.get('user_groups'), { id: id });
                if (group) {
                    results.push(model.toJSON());
                }
            });

            return results;
        },
    });
})();
