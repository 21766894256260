(function () {
  'use strict';

  var api = require('shim/api');

  module.exports = Backbone.Model.extend({
    idAttribute: 'id',
    urlRoot: api.urlRoot + '/material_requests',
    validation: {
      purchase_qty: [{
          required: true,
          msg: 'Introduza uma quantidade'
        },
        {
          range: [1,100000],
          msg: 'A quantidade tem de ser > 0'
        }]
    }
  });
})();
