/* global module, require */
(function () {
    'use strict';

    module.exports = Marionette.LayoutView.extend({

        template: require('../templates/login'),
        tagName: 'div',
        className: 'wrapper',

        regions: {
            content: '#content'
        },

        setContentView: function (view) {
            this.content.show(view);
        },

        onRender: function () {
            $('body').addClass('login-page');
        },

        destroy: function () {
            $('body').removeClass('login-page');
        }

    });

})();
