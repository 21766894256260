var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <option value=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\" data-name=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"row material-row\">\n  <div class=\"form-group col-xs-12 col-sm-3 col-md-4\">\n    <label for=\"material-category\">Selecione Categoria</label>\n    <select class=\"categories form-control mutate-change\" name=\"material-category\">\n      <option value=\"-1\" data-id=\"-1\">Todos</option>\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda(((stack1 = (data && data.root)) && stack1.categories), depth0),{"name":"@root/categories","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </select>\n  </div>\n\n  <div class=\"form-group col-xs-12 col-sm-5 col-md-6 materials-select\">\n"
    + ((stack1 = container.invokePartial(partials["material-requests-row-select"],depth0,{"name":"material-requests-row-select","hash":{"material":(depth0 != null ? depth0.material : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n\n  <div class=\"form-group col-xs-12 col-sm-4 col-md-2\">\n    <label for=\"purchase-quantity-label\">Insira Quantidade</label>\n    <div name=\"purchase-quantity-label\" style=\"display:flex;padding:0px\">\n      <input  value=\""
    + alias4(((helper = (helper = helpers.purchase_qty || (depth0 != null ? depth0.purchase_qty : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"purchase_qty","hash":{},"data":data}) : helper)))
    + "\" name=\"purchase_qty\" type=\"number\" min=\"1\"\n              class=\"form-control mutate-change\" onkeypress=\"return event.charCode >= 48\"\n              placeholder=\"Qt.\">\n    </div>\n    <input type=\"hidden\" name=\"id\" value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}