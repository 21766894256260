var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <span class=\"label label-danger\">"
    + container.escapeExpression(((helper = (helper = helpers.status || (depth0 != null ? depth0.status : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"status","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <span class=\"label label-warning\">"
    + container.escapeExpression(((helper = (helper = helpers.status || (depth0 != null ? depth0.status : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"status","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <span class=\"label label-success\">"
    + container.escapeExpression(((helper = (helper = helpers.status || (depth0 != null ? depth0.status : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"status","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.company || (depth0 != null ? depth0.company : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"company","hash":{},"data":data}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    return "Por definir";
},"11":function(container,depth0,helpers,partials,data) {
    return "      <div>\n        <b>Data de Recepção</b>: "
    + container.escapeExpression((helpers.moment || (depth0 && depth0.moment) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.closed_date : depth0),{"name":"moment","hash":{"format":"DD-MM-YYYY"},"data":data}))
    + "\n      </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <div>\n        <b>Data Prevista de Entrega</b>: "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.due_date : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "\n      </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.moment || (depth0 && depth0.moment) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.due_date : depth0),{"name":"moment","hash":{"format":"DD-MM-YYYY"},"data":data}));
},"16":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <textarea readonly rows=\"3\">"
    + container.escapeExpression(((helper = (helper = helpers.notes || (depth0 != null ? depth0.notes : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"notes","hash":{},"data":data}) : helper)))
    + "</textarea>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "          Por definir\n";
},"20":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.purchase_order || (depth0 != null ? depth0.purchase_order : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"purchase_order","hash":{},"data":data}) : helper)));
},"22":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <textarea readonly rows=\"3\">"
    + container.escapeExpression(((helper = (helper = helpers.invoicing_data || (depth0 != null ? depth0.invoicing_data : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"invoicing_data","hash":{},"data":data}) : helper)))
    + "</textarea>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <span>"
    + container.escapeExpression(((helper = (helper = helpers.real_price || (depth0 != null ? depth0.real_price : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"real_price","hash":{},"data":data}) : helper)))
    + "</span>\n          <span>Custo Real</span>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.estimated_price : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <span>"
    + container.escapeExpression(((helper = (helper = helpers.estimated_price || (depth0 != null ? depth0.estimated_price : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"estimated_price","hash":{},"data":data}) : helper)))
    + "</span>\n          <span>Custo estimado</span>\n";
},"29":function(container,depth0,helpers,partials,data) {
    return "          <span>n.d.</span>\n          <span>Custo</span>\n        ";
},"31":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <span>"
    + container.escapeExpression(((helper = (helper = helpers.available_budget || (depth0 != null ? depth0.available_budget : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"available_budget","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "          <span>n.d.</span>\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "Duração Total";
},"37":function(container,depth0,helpers,partials,data) {
    return "Tempo desde abertura";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<section class=\"material-order-details\">\n  <div class=\"row row-eq-height\">\n    <div class=\"col-xs-9 data-container\">\n      <header class=\"page-header\" style=\"margin-bottom:40px\">\n        <h3>N.º <b>"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "/"
    + alias4((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.created_at : depth0),{"name":"moment","hash":{"format":"YY"},"data":data}))
    + "</b></h3>\n\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),"Pendente",{"name":"eq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),"Encomendado",{"name":"eq","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),"Fechado",{"name":"eq","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </header>\n\n      <div>\n        <b>Fornecedor</b>: "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.company : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "\n      </div>\n\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),"Fechado",{"name":"eq","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "\n      <div>\n        <b>Notas</b>:\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.notes : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "      </div>\n\n      <div>\n        <b>Ordem de Compra</b>: "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.purchase_order : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "\n      </div>\n\n      <div>\n        <b>Dados de Facturação</b>:\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.invoicing_data : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n\n    <div class=\"col-xs-3 summary-container\">\n      <div class=\"summary-data\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.real_price : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data})) != null ? stack1 : "")
    + "      </div>\n\n      <div class=\"summary-data\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.available_budget : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data})) != null ? stack1 : "")
    + "        <span>Dísponivel em Orçamento</span>\n      </div>\n\n      <div class=\"summary-data\">\n        <span>\n          "
    + alias4(((helper = (helper = helpers.duration || (depth0 != null ? depth0.duration : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"duration","hash":{},"data":data}) : helper)))
    + "\n        </span>\n        <span>\n          "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.status : depth0),"Fechado",{"name":"eq","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data})) != null ? stack1 : "")
    + "\n        </span>\n      </div>\n    </div>\n  </div>\n</section>\n\n\n<hr style=\"margin-top:40px\">\n\n<section>\n  <div style=\"margin:10px 0px\">\n    <b>Pedido por</b>: "
    + alias4(((helper = (helper = helpers.requester || (depth0 != null ? depth0.requester : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"requester","hash":{},"data":data}) : helper)))
    + "\n  </div>\n\n  <div style=\"margin:10px 0px\">\n    <b>Criado em</b>: "
    + alias4((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.created_at : depth0),{"name":"moment","hash":{"format":"DD-MM-YYYY HH:mm"},"data":data}))
    + "\n  </div>\n\n  <div style=\"margin:10px 0px\">\n    <b>Última atualização em</b>: "
    + alias4((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.updated_at : depth0),{"name":"moment","hash":{"format":"DD-MM-YYYY HH:mm"},"data":data}))
    + "\n  </div>\n</section>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}