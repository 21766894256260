var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, buffer = 
  "        <div class=\"single-note-container\">\n            <div class=\"notes-left-side\">\n              "
    + ((stack1 = (helpers.getStatusIcon || (depth0 && depth0.getStatusIcon) || alias2).call(alias1,(depth0 != null ? depth0.done_status : depth0),{"name":"getStatusIcon","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </div>\n\n            <div class='notes-right-side'>\n              <div class=\"notes-id\">\n";
  stack1 = ((helper = (helper = helpers.unit || (depth0 != null ? depth0.unit : depth0)) != null ? helper : alias2),(options={"name":"unit","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.unit) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "              </div>\n\n              <div class=\"notes-description\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.validation_notes : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.notes : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "              </div>\n        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <span><b>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</b></span>\n                <span>"
    + alias4(((helper = (helper = helpers.zone || (depth0 != null ? depth0.zone : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"zone","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                  <div class=\"note-text-container\">\n                    <i class=\"fa fa-address-book-o\" aria-hidden=\"true\"></i>\n                    <span>"
    + container.escapeExpression(((helper = (helper = helpers.validation_notes || (depth0 != null ? depth0.validation_notes : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"validation_notes","hash":{},"data":data}) : helper)))
    + "</span>\n                  </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                  <div class=\"note-text-container\">\n                    <i class=\"fa fa-users\" aria-hidden=\"true\"></i>\n                    <span>"
    + container.escapeExpression(((helper = (helper = helpers.notes || (depth0 != null ? depth0.notes : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"notes","hash":{},"data":data}) : helper)))
    + "</span>\n                  </div>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.tasks || (depth0 != null ? depth0.tasks : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"tasks","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.tasks) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.interventions || (depth0 != null ? depth0.interventions : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"interventions","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.interventions) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0._destroy : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=helpers.blockHelperMissing, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5=container.escapeExpression;

  return "                        <div class=\"summary-rows row\">\n                            <section class=\"col-sm-12 col-md-4 form-group\" style=\"height:21px\">\n                                <label>Unidade/Zona:</label>\n                                <div class=\"\" style=\"display=flex\">\n                                  <div class=\"form-field\">\n                                    "
    + ((stack1 = alias2.call(depth0,alias1((depths[1] != null ? depths[1].unit : depths[1]), depth0),{"name":"../unit","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                                  </div>\n                                  <div class=\"form-field\">\n"
    + ((stack1 = alias2.call(depth0,alias1((depths[1] != null ? depths[1].unit : depths[1]), depth0),{"name":"../unit","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                  </div>\n                                </div>\n                            </section>\n                            <section class=\"col-sm-12 col-md-4 form-group\">\n                                <label>Técnico:</label>\n                                <div class=\"form-field\">"
    + alias5(((helper = (helper = helpers.user || (depth0 != null ? depth0.user : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"user","hash":{},"data":data}) : helper)))
    + "</div>\n                            </section>\n                            <section class=\"col-sm-12 col-md-2 form-group\" style=\"text-align:center;\">\n                              <label>Data:</label>\n                                <div class=\"form-field\">"
    + alias5((helpers.moment || (depth0 && depth0.moment) || alias4).call(alias3,(depth0 != null ? depth0.date : depth0),{"name":"moment","hash":{"format":"DD/MM/YYYY","lang":"pt"},"data":data}))
    + "</div>\n                            </section>\n                            <section class=\"col-sm-12 col-md-2 form-group\" style=\"text-align:right;\">\n                                <label>Tempo:</label>\n                                <div class=\"form-field\">\n                                    "
    + ((stack1 = (helpers.momentTime || (depth0 && depth0.momentTime) || alias4).call(alias3,(depth0 != null ? depth0.time : depth0),{"name":"momentTime","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.gte || (depth0 && depth0.gte) || alias4).call(alias3,(depths[1] != null ? depths[1].state_id : depths[1]),3,{"name":"gte","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.program(29, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                                </div>\n                            </section>\n                        </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var helper;

  return " "
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data}) : helper)))
    + " ";
},"16":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                                    "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.zone : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.program(19, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper;

  return " "
    + container.escapeExpression(((helper = (helper = helpers.zone || (depth0 != null ? depth0.zone : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"zone","hash":{},"data":data}) : helper)))
    + " ";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    return " "
    + container.escapeExpression(container.lambda((depths[3] != null ? depths[3].zone_title : depths[3]), depth0))
    + " ";
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].done_status : depths[1]),"complete",{"name":"eq","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.program(24, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    return "                                    <i aria-hidden=\"true\" class=\"i-state-complete fa fa-check\"></i>\n";
},"24":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].done_status : depths[1]),"uncompleted",{"name":"eq","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.program(27, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    return "                                    <i aria-hidden=\"true\" class=\"i-state-uncompleted fa fa-exclamation-triangle\"></i>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "                                    <i aria-hidden=\"true\" class=\"i-state-unrealized fa fa-times\"></i>\n";
},"29":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? depths[1].state_id : depths[1]),2,{"name":"eq","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    return "                                    <i aria-hidden=\"true\" class=\"i-state-ongoing fa fa-cogs\"></i>\n";
},"32":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"row\" style=\"text-align: center; padding: 10px;\">Sem intervenções</div>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var helper;

  return " "
    + container.escapeExpression(((helper = (helper = helpers.spentTime || (depth0 != null ? depth0.spentTime : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"spentTime","hash":{},"data":data}) : helper)))
    + " ";
},"36":function(container,depth0,helpers,partials,data) {
    return " -- ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", buffer = 
  "<legend>Resumo</legend>\n\n<div class=\"notes-headers\">\n  <div>\n    <h5>Notas</h5>\n  </div>\n  <div class=\"badge-container\">\n    <span class=\"badge badge-notification\">"
    + container.escapeExpression(((helper = (helper = helpers.resume_occurs || (depth0 != null ? depth0.resume_occurs : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resume_occurs","hash":{},"data":data}) : helper)))
    + "</span>\n    <span> ocorrências </span>\n  </div>\n</div>\n\n<div id=\"notes row\" class=\"notes-container\">\n";
  stack1 = ((helper = (helper = helpers.tasks_notes_summary || (depth0 != null ? depth0.tasks_notes_summary : depth0)) != null ? helper : alias2),(options={"name":"tasks_notes_summary","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.tasks_notes_summary) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n\n<div class=\"notes-headers\">\n  <div>\n    <h5>Tempos</h5>\n  </div>\n</div>\n\n<div class='time-table'>\n  <div id=\"summary-times\" class=\"col-xs-12\">\n    <div class=\"row summary-titles\">\n        <section class=\"col-sm-12 col-md-4\">Unidade/Zona</section>\n        <section class=\"col-sm-12 col-md-4\">Técnico</section>\n        <section class=\"col-sm-12 col-md-2\" style=\"text-align:center;\">Data</section>\n        <section class=\"col-sm-12 col-md-2\" style=\"text-align:center;\">Tempo</section>\n    </div>\n\n    <section id=\"summary-rows\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasInterventions : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(32, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "    </section>\n\n    <section class=\"row summary-total\">\n        <section class=\"col-xs-6\" style=\"text-align:left;\"><b>Total:</b></section>\n        <section class=\"col-xs-6\" style=\"text-align:right;\">\n            <b>\n              "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasInterventions : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.program(36, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "  &nbsp; &nbsp; &nbsp;\n            </b>\n        </section>\n    </section>\n\n    <section style=\"margin-top:15px\">\n        <div class=\"col-xs-12 col-sm-6 col-md-12\">\n          <span class=\"col-xs-12 col-sm-12 col-md-3\"><i aria-hidden=\"true\" class=\"i-state-complete fa fa-check\"></i> Verificado</span>\n          <span class=\"col-xs-12 col-sm-12 col-md-3\"><i aria-hidden=\"true\" class=\"i-state-unrealized fa fa-times\"></i> Não realizado</span>\n          <span class=\"col-xs-12 col-sm-12 col-md-3\"><i aria-hidden=\"true\" class=\"i-state-uncompleted fa fa-exclamation-triangle\"></i> Incompleta</span>\n          <span class=\"col-xs-12 col-sm-12 col-md-3\"><i aria-hidden=\"true\" class=\"i-state-ongoing fa fa-gears\"></i> Em curso</span>\n        </div>\n\n        <div class=\"col-xs-12 col-sm-6 col-md-12\">\n          <span class=\"col-xs-12 col-sm-12 col-md-3\"><i aria-hidden=\"true\" class=\"fa fa-users\"></i> Nota unidade</span>\n          <span class=\"col-xs-12 col-sm-12 col-md-3\"><i aria-hidden=\"true\" class=\"fa fa-address-book-o\"></i> Nota validação</span>\n        </div>\n    </section>\n  </div>\n</div>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}