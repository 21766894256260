(function () {
    'use strict';

    module.exports = Backbone.Model.extend({
        idAttribute: '_id',
        defaults: {
            sem_green: 3,
            sem_red: 1,
            image: ''
        },
        validation: {
            sem_red: [
                {
                    required: function (val, attr, values) {
                        return values.semChange || false;
                    },
                    msg: 'Introduza o numero de dias'
                },
                {
                    fn: function (val, attr, values) {
                        return (parseInt(val) <= parseInt(values.sem_green)) ?
                            'O valor do semáforo vermelho tem que ser superior ao semáforo verde' : '';
                    }
                },
                {
                    fn: function (val, attr, values) {
                        return (parseInt(val) - parseInt(values.sem_green) <= 1) ?
                            'O intervalo de dias entre os semáforos tem de ser superior ao seleccionado' : '';
                    }
                }
            ],
            sem_green: [
                {
                    required: function (val, attr, values) {
                        return values.semChange || false;
                    },
                    msg: 'Introduza o numero de dias'
                },
                {
                    fn: function (val) {
                        return (parseInt(val) <= 0) ?
                            'O valor do semáforo verde não pode ser igual ou inferior a 0' : '';
                    }
                }
            ],
        },

        save: function (attrs, options) {
            options = options || {};
            attrs = attrs || _.clone(this.attributes);

            attrs = {
                rootzone: {
                    sem_green: parseInt(attrs.sem_green),
                    sem_red: parseInt(attrs.sem_red),
                    material_budget: parseInt(attrs.material_budget),
                    image: attrs.image
                }
            };

            options.data = JSON.stringify(attrs);
            options.contentType = 'application/json';

            return Backbone.Model.prototype.save.call(this, attrs, options);
        },
    });
})();
