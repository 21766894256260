(function () {
    'use strict';

    var AssignOrder = require('templates/orders/assign-order');

    module.exports = Marionette.Behavior.extend({
        events: {
            'change @ui.assign': 'onAssignOrder'
        },

        onAssignOrder: function (ev) {
            var type = $(ev.target).val(),
                values = type === 'user_group_id' ? this.view.userGroups : this.view.users,
                selected = [],
                multiple = 'false';

            if (this.view.model != undefined) {
                if (type === 'user_group_id') {
                    if (this.view.model.has('user_group_id'))
                        selected = [this.view.model.get('user_group_id')];
                }
                else if (type === 'user_ids') {
                    if (this.view.model.has('users') && this.view.model.get('users').length > 0) {
                        this.view.model.get('users').models.forEach(function (user) {
                            selected.push(user.get('_id'))
                        });
                    }
                }
            }

            if (type == 'no_assign') {
                this.$(this.options.container).html('');
            }
            else {
                if (type === 'user_ids') multiple = 'true';
                var container = this.$(this.options.container);
                container.html(AssignOrder({ type: type, values: values.toJSON(), multiple: multiple }));
                container.find('select').val(selected).select2();
                this.$('.assign-button').show();
            }
        }
    });
})();
