require('handlebars-helpers')({
    handlebars: Handlebars
});

//Backbone Shims
Backbone.$ = $;
Backbone.Radio = require('backbone.radio');
require('backbone.localstorage');
require('backbone-virtual-collection');
require('backbone.groupedcollection');
require("backbone-model-file-upload");
require('fullcalendar');
require('backbone-validation');
require('backbone.routefilter');
require('moment/locale/pt');
require('select2');
require('shim/backbone.sync');
require('bootstrap.js')

$.fn.select2.defaults.set("theme", "bootstrap");

$.whenWithProgress = function (arrayOfPromises) {
    var cntr = 0, defer = $.Deferred();
    arrayOfPromises.forEach(function (e) {
        e.done(function () { defer.notify(++cntr, arrayOfPromises.length); })
    });
    jQuery.when.apply(jQuery, arrayOfPromises).done(function () {
        defer.resolveWith(null, arguments);
    });
    return defer.promise();
};

$(document).ready(function () {

    var autosize = require('autosize');

    // HIDE ADDRESS BAR
    if (navigator.userAgent.match(/Android/i)) {
        window.scrollTo(0, 1);
    }

    // SHOW/HIDE DETAILS BOX OT
    $('#show-details').click(function () {
        $('#details-box').toggle();
        return false;
    });

    $('.nav-tabs a').click(function (e) {
        e.preventDefault();
        $(this).tab('show');
    });

    // Autosize Textareas
    autosize($('textarea#title'));

    // Remove undo-warning
    $('.undo-warning a').click(function () {
        $('.undo-warning').fadeOut('fast');
        return false;
    });

    $('.undo-warning').delay(2400).fadeOut(400);


    // Nav Menu
    $('#menu-trigger').click(function () {
        $('#nav-trigger').toggleClass('checked');
    });

    $("body").on('click', ".collapse-text", function () {
        $(this).toggleClass('active');
    });
});

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};
