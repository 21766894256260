(function () {
    'use strict';

    var BaseRouter = require('routers/base-router');

    module.exports = BaseRouter.extend({
        forbidden: {
            'orders/create':                ['admin'],
            'orders/edit/:id':              ['solicitant', 'admin'],
            'orders/:id':                   ['solicitant', 'admin'],
            'orders/:id/do(/:task)':        ['solicitant', 'admin'],
            'orders/:id/validate(/:task)':  ['solicitant', 'admin', 'operator'],
            'orders/:id/verify(/:task)':    ['solicitant', 'admin', 'operator']
        },

        appRoutes: {
            'orders/create':                          'createOrder',
            'orders/edit/:id':                        'editOrder',
            'orders/:id':                             'showOrderById',
            'orders/:id/submitted':                   'orderSubmitted',
            'orders/:id/do(/:task)/submitted':        'orderTaskSubmitted',
            'orders/:id/do(/:task)':                  'doOrderTask',
            'orders/:id/validate(/:task)/submitted':  'orderTaskValidated',
            'orders/:id/validate(/:task)':            'validateTask',
            'orders/:id/verify(/:task)/submitted':    'orderTaskVerified',
            'orders/:id/verify(/:task)':              'verifyTask'
        }
    });
})();
