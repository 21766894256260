var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"section-box\">\n    <header class=\"page-header\" style=\"text-align:center;\">\n        <h3>Alterar Password</h3>\n    </header>\n    <section style=\"display:flex;\">\n        <div class=\"box col-xs-12 col-sm-6\" style=\"margin:0 auto;\">\n            <form id=\"change-password\" action=\"#\" method=\"post\" novalidate>\n                <fieldset>\n                    <div class=\"form-group\">\n                        <label for=\"actual_password\">Password atual</label>\n                        <input type=\"password\" id=\"actual_password\" name=\"actual_password\" placeholder=\"Introduza a password atual\" required value=\"\">\n\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"new_password\">Nova password</label>\n                        <input type=\"password\" id=\"new_password\" name=\"new_password\" placeholder=\"Introduza a nova password\" required value=\"\">\n                    </div>\n                    <div class=\"form-group\">\n                        <label for=\"confirm_password\">Repetir nova password</label>\n                        <input type=\"password\" id=\"confirm_password\" name=\"confirm_password\" placeholder=\"Repita a nova password\" required value=\"\">\n                    </div>\n                </fieldset>\n                <div class=\"input-group group-no-label\">\n                    <div class=\"input-field\">\n                        <button type=\"submit\" class=\"button button-primary\">Mudar password</button>\n                    </div>\n                </div>\n            </form>\n        </div>\n    </section>\n</div>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}