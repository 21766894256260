(function() {
  'use strict';

  var Radio = require('shim/radio'),

      MaterialRequestsCollection = require('collections/material-requests'),
      MaterialOrdersCollection = require('collections/material-orders'),
      MaterialOrdersModel = require('models/material-order'),
      MaterialRequestsController = require('controllers/materials/material-requests-controller'),
      MaterialOrdersController = require('controllers/materials/material-orders-controller'),
      MaterialOrderController = require('controllers/materials/material-order-controller');

  module.exports = Marionette.Controller.extend({
    initialize: function(options) {
      options = options || {};
      this.materials  = options.materials;
      this.categories = options.categories;
    },

    showMaterialRequests: function() {
      Radio.global.trigger('anim-loading:show');

      var materialRequests = new MaterialRequestsCollection();
      materialRequests.fetch().then(function () {
        var materialRequestsController = new MaterialRequestsController({
          materialRequests: materialRequests,
          materials:        this.materials,
          categories:       this.categories
        });
        materialRequestsController.showMaterialRequests();
      }.bind(this));
    },

    showNewMaterialRequests () {
      Radio.global.trigger('anim-loading:show');

      var materialRequests = new MaterialRequestsCollection();
      materialRequests.fetchNew().then(function () {
        var materialRequestsController = new MaterialRequestsController({
          materialRequests: materialRequests,
          materials:        this.materials,
          categories:       this.categories
        });
        materialRequestsController.showMaterialRequests({ isNewMaterials: true });
      }.bind(this));
    },

    showMaterialOrders: function() {
      Radio.global.trigger('anim-loading:show');

      var materialOrders = new MaterialOrdersCollection();
      materialOrders.fetch().then(function () {
        var materialOrdersController = new MaterialOrdersController({ materialOrders });
        materialOrdersController.showMaterialOrders();
      }.bind(this));
    },

    showMaterialOrderById: function (id) {
      Radio.global.trigger('anim-loading:show');

      var materialOrder = new MaterialOrdersModel({id: parseInt(id) || id});
      var materialRequests = new MaterialRequestsCollection();
      materialOrder.fetch().then(function () {
        materialRequests.fetch().then(function () {
          var materialOrderController = new MaterialOrderController({
            materialOrder:    materialOrder,
            materialRequests: materialRequests
          });
          materialOrderController.showMaterialOrderById();
        })
      })
    },

    editMaterialOrder: function (id, options) {
      Radio.global.trigger('anim-loading:show');

      var materialOrder = new MaterialOrdersModel({id: parseInt(id) || id});
      materialOrder.fetch().then(function () {
        var materialOrderController = new MaterialOrderController({
          materialOrder: materialOrder
        });
        materialOrderController.editMaterialOrder();
      })
    },
  });
})();
