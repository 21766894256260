(function () {
  'use strict';

  var Radio = require('shim/radio')

  module.exports = Marionette.ItemView.extend({
    template: require('templates/settings/typified-orders/typified-order-view'),

    templateHelpers: function () {
      return {
        workTypes:  this.options.workTypes.models,
        zones:      this.options.zones.models
      }
    },
  });
})();
