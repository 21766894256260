(function () {
    'use strict';

    var Radio = require('shim/radio'),
        BaseObject = require('controllers/base-object'),
        AlertsView = require('views/alerts-view');

    var controller = null;

    var AlertsRegion = new Marionette.Region({
            el: '#alerts'
        });

    var AlertsController = BaseObject.extend({
        initialize: function (options) {
            this.region = AlertsRegion;
            this.options = _.defaults(options, {type: 'success'});
        },

        showAlert: function () {
            this.show(new AlertsView(this.options), this);
        },

        dismissAlert: function () {
            AlertsRegion.empty();
        }
    });

    Radio.global.comply('alerts:show', function (options) {
        if (controller !== null) {
            controller.dismissAlert();
        }

        controller = new AlertsController(options);
        controller.showAlert();
    });

    Radio.global.comply('alerts:dismiss', function () {
        if (controller !== null) {
            controller.dismissAlert();
            controller = null;
        }
    });

    module.exports = AlertsController;
})();
