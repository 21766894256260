(function () {
  'use strict';

  var BaseObject = require('controllers/base-object'),
      Radio      = require('shim/radio'),
      api        = require('shim/api'),

      MaterialRequestsCollection = require('collections/material-requests'),
      MaterialOrderLayout       = require('layouts/materials/material-order-layout'),
      MaterialOrderDetailsView  = require('views/materials/material-orders/material-order-details-view'),
      MaterialOrderRequestsView = require('views/materials/material-orders/material-order-requests-list-view'),
      MaterialOrderEditView     = require('views/materials/material-orders/material-order-edit-view');

  module.exports = BaseObject.extend({
    initialize: function (options = {}) {
      BaseObject.prototype.initialize.apply(this, arguments);

      this.materialOrder = options.materialOrder;
      this.materialRequests = options.materialRequests;
    },

    showMaterialOrderById: function () {
      $('.search-ot-group').hide();
      var materialOrderLayout = new MaterialOrderLayout({
        model: this.materialOrder
      });
      //Render MainView
      this.showBind(materialOrderLayout);
      //Render SubViews
      materialOrderLayout.showChildView('view', new MaterialOrderDetailsView({
        model: this.materialOrder
      }));
      materialOrderLayout.showChildView('requests', new MaterialOrderRequestsView({
        model:               this.materialOrder,
        collection:          new MaterialRequestsCollection(this.materialOrder.get('materials')),
        allMaterialRequests: this.materialRequests
      }));

      Radio.global.trigger('anim-loading:hide');
    },

    editMaterialOrder: function () {
      //Render View
      this.show(new MaterialOrderEditView({
        model: this.materialOrder
      }), this);

      Radio.global.trigger('anim-loading:hide');
    }
  });
})();
