(function () {
    'use strict';

    var Behaviors = {
        Radio: require('./radio-behavior'),
        Collaborators: require('./collaborators-behavior'),
        UserFilters: require('./user-filters-behavior'),
        DateFilters: require('./date-filters-behavior'),
        OrdersFilters: require('./orders-filters-behavior'),
        EquipmentOrdersFilters: require('./orders-equipment-filters-behavior'),
        OrdersAssign: require('./order-assign-behavior'),
        CalendarControls: require('./calendar-controls-behavior'),
        ImageUpload: require('./image-upload-behavior'),
        DateChange: require('./date-change-behavior'),
        AvatarUpload: require('./avatar-upload-behavior'),
        TypifiedOrders: require('./typified-orders-filters-behavior')
    };

    Marionette.Behaviors.behaviorsLookup = function () {
        return Behaviors;
    };
})();
