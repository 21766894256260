var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <option value=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\" data-name=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\"\n        "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depths[1] != null ? depths[1].active_id : depths[1]),(depth0 != null ? depth0._id : depth0),{"name":"eq","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "> "
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n      </option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " selected=true ";
},"4":function(container,depth0,helpers,partials,data) {
    return "";
},"6":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"text-right add-remove-collaborator-btn\">\n      <a href=\"#\" class=\"remove remove-button button button-md button-primary\">\n        <i class=\"fa fa-trash\" aria-hidden=\"true\"></i>\n        <span>Remover</span>\n      </a>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"row assign-to "
    + alias4(((helper = (helper = helpers.extra || (depth0 != null ? depth0.extra : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"extra","hash":{},"data":data}) : helper)))
    + " time-assignment-body\">\n\n  <input name=\"collab-report-id\" type=\"hidden\" class=\"field-small\" value='"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "'/>\n\n  <div class=\"col-xs-12 col-sm-4 col-md-4 col-lg-5\">\n    <select name=\"collaborators\" class=\"collaborators\">\n      <option value=\"\" disabled selected hidden>Seleccionar</option>\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda(((stack1 = (data && data.root)) && stack1.operators), depth0),{"name":"@root/operators","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </select>\n  </div>\n\n  <div class=\"col-xs-12 col-sm-4 col-md-4 col-lg-3 date date-assignment date-and-time-container\">\n    <input name=\"collab-report-date\" class=\"form-control  js-date-picker\" type=\"text\" value='"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "'/>\n    <span class=\"input-group-addon\">\n      <span class=\"fa fa-calendar\"></span>\n    </span>\n  </div>\n\n  <div class=\"col-xs-12 col-sm-4 col-md-4 col-lg-4 date time-assignment date-and-time-container\">\n    <input name=\"collab-report-time\" type=\"text\" class=\"form-control js-time-picker collab-report-time\" value='"
    + ((stack1 = (helpers.momentTime || (depth0 && depth0.momentTime) || alias2).call(alias1,(depth0 != null ? depth0.time : depth0),{"name":"momentTime","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "'/>\n    <span class=\"input-group-addon\">\n      <span class=\"fa fa-clock-o\"></span>\n    </span>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.extra : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n</div>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}