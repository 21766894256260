'use strict';

var Radio = require('shim/radio');

var oldBbSync = Backbone.sync;
Backbone.sync = function (method, model, opts) {
    var xhr, dfd;

    if (opts.patch) method = 'update';

    dfd = $.Deferred();

    var session = Radio.global.request('app:session'),
        token = session.get('token');

    //Generate token link
    if (opts && token) {
        opts.headers = { 'Authorization': 'Bearer ' + token };
    }

    if (opts) {
        dfd.then(opts.success, opts.error);
    }

    if (method === 'patch') opts.type = 'PUT';

    xhr = oldBbSync(method, model, _.omit(opts, 'success', 'error'));

    xhr.done(dfd.resolve);

    xhr.fail(function (err) {

        var store = _.result(model, 'store'), storableData;

        // Only save to queue is order or task
        // & Error states is 0 (no internet connection found)
        if (connectionError(err.readyState) && _.contains(['orders', 'tasks'], store)) {

            storableData = _.extend({ id: model.id }, JSON.parse(opts.data));

            //Store additional info if request is type: task
            if (store === 'tasks')
                storableData = _.extend({
                    order_id: model.get('order_id'),
                    unit_id: model.get('unit_id')
                }, storableData);

            Radio.global.command('queue:push:request', { method: method, model: model, storableData: storableData, store: store });

            //Emulate API Behavior if request is type: order
            if (store === 'orders')
                model.emulateAPIBehavior(_.clone(storableData.order));
        }

        dfd.reject.apply(xhr, arguments);
    });

    return dfd.promise();
};

function connectionError(errorID) {
    //ErrorID 0 means couldn't connect to API (Offline)
    return errorID === 0;
}
