(function () {
    'use strict';

    $.fn.serializeObject = function (forceArray=false) {
        var result = {};
        var extend = function (element) {
            var node = result[element.name];

            // If node with same name exists already, need to convert it to an array as it
            // is a multi-value field (i.e., checkboxes)
            if ('undefined' !== typeof node && node !== null) {
                if ($.isArray(node)) {
                    node.push(element.value);
                } else {
                    result[element.name] = [node, element.value];
                }
            } else {
                result[element.name] = forceArray ? [element.value] : element.value;
            }
        };

        if (this.find('form').length > 0) {
            _.each(this.find('form').serializeArray(), extend);
        } else {
            _.each(this.find('input,select').serializeArray(), extend);
        }

        return result;
    };

    _.extend(Backbone.Validation.callbacks, {
        valid: function (view, attr, selector) {
            var $el = view.$('[' + selector + '=' + attr + ']'),
                $group = $el.closest('.form-group, .input-field, .checkbox');

            $group.removeClass('smm-error-field');
            $group.find('.help-block').remove();
        },

        invalid: function (view, attr, error, selector) {
            var $el = view.$('[' + selector + '=' + attr + ']'),
                $group = $el.closest('.form-group, .input-field, .checkbox');

            $group.removeClass('smm-error-field').find('.help-block').remove();
            $group.addClass('smm-error-field');
            $group.append('<div class="help-block smm-inline-error">' + error + '</div>');
        }
    });
})();
