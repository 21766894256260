var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " class=\"active\" ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <li role=\"presentation\" "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"supervisor",{"name":"eq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                <a href=\"#supervisor\" aria-controls=\"supervisor\" role=\"tab\" data-toggle=\"tab\">\n                    Por Fechar (<span id=\"supervisor-badge\">"
    + alias4(((helper = (helper = helpers.totalSupervisor || (depth0 != null ? depth0.totalSupervisor : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalSupervisor","hash":{},"data":data}) : helper)))
    + "</span>)\n                </a>\n                <a href=\"#supervisor\" aria-controls=\"supervisor\" role=\"tab\" data-toggle=\"tab\">\n                    <i class=\"fa fa-check\" aria-hidden=\"true\"></i>\n                    <span id=\"distribute-badge\">("
    + alias4(((helper = (helper = helpers.totalSupervisor || (depth0 != null ? depth0.totalSupervisor : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalSupervisor","hash":{},"data":data}) : helper)))
    + ")</span>\n                </a>\n            </li>\n            <li style='float: right'>\n                <a class=\"btn refresh-button\">\n                  <i aria-hidden=\"true\" class=\"fa fa-refresh\"></i>\n                </a>\n                <a class=\"btn refresh-button\">\n                    <i aria-hidden=\"true\" class=\"fa fa-refresh\"></i>\n                </a>\n            </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "active";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div role=\"tabpanel\" class=\"tab-pane supervisor-panel "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.activeTab : depth0),"supervisor",{"name":"eq","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"supervisor\">supervisor\n            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=helpers.blockHelperMissing, buffer = 
  "<header class=\"page-header\">\n    <div class=\"bs-callout bs-callout-default\">\n        <i aria-hidden=\"true\" class=\"fa fa-cogs\"></i>\n        <h4>Desktop</h4>\n    </div>\n\n    <form class=\"tasks-filters filters\"></form>\n</header>\n\n<div class=\"content\">\n\n    <div class=\"content-filters\"></div>\n\n    <!-- Nav tabs -->\n    <ul class=\"nav nav-tabs\" role=\"tablist\">\n        <li role=\"presentation\" "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"distribute",{"name":"eq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <a href=\"#distribute\" aria-controls=\"distribute\" role=\"tab\" data-toggle=\"tab\">\n                Por Distribuir (<span id=\"distribute-badge\">"
    + alias4(((helper = (helper = helpers.totalUnassigned || (depth0 != null ? depth0.totalUnassigned : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalUnassigned","hash":{},"data":data}) : helper)))
    + "</span>)\n            </a>\n            <a href=\"#distribute\" aria-controls=\"distribute\" role=\"tab\" data-toggle=\"tab\">\n                <i class=\"fa fa-user-times\" aria-hidden=\"true\"></i>\n                <span id=\"distribute-badge\">("
    + alias4(((helper = (helper = helpers.totalUnassigned || (depth0 != null ? depth0.totalUnassigned : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalUnassigned","hash":{},"data":data}) : helper)))
    + ")</span>\n            </a>\n        </li>\n        <li role=\"presentation\" "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"pending",{"name":"eq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <a href=\"#pending\" aria-controls=\"pending\" role=\"tab\" data-toggle=\"tab\">\n                Pendentes (<span id=\"pending-badge\">"
    + alias4(((helper = (helper = helpers.totalPending || (depth0 != null ? depth0.totalPending : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalPending","hash":{},"data":data}) : helper)))
    + "</span>)\n            </a>\n            <a href=\"#pending\" aria-controls=\"pending\" role=\"tab\" data-toggle=\"tab\">\n                <i class=\"fa fa-spinner\" aria-hidden=\"true\"></i>\n                <span id=\"distribute-badge\">("
    + alias4(((helper = (helper = helpers.totalPending || (depth0 != null ? depth0.totalPending : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalPending","hash":{},"data":data}) : helper)))
    + ")</span>\n            </a>\n        </li>\n        <li role=\"presentation\" "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"validate",{"name":"eq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <a href=\"#validate\" aria-controls=\"validate\" role=\"tab\" data-toggle=\"tab\">\n                Por Validar (<span id=\"validate-badge\">"
    + alias4(((helper = (helper = helpers.totalDone || (depth0 != null ? depth0.totalDone : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalDone","hash":{},"data":data}) : helper)))
    + "</span>)\n            </a>\n            <a href=\"#validate\" aria-controls=\"validate\" role=\"tab\" data-toggle=\"tab\">\n               <i class=\"fa fa-thumbs-up\" aria-hidden=\"true\"></i>\n               <span id=\"distribute-badge\">("
    + alias4(((helper = (helper = helpers.totalDone || (depth0 != null ? depth0.totalDone : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalDone","hash":{},"data":data}) : helper)))
    + ")</span>\n            </a>\n        </li>\n";
  stack1 = ((helper = (helper = helpers.isManager || (depth0 != null ? depth0.isManager : depth0)) != null ? helper : alias2),(options={"name":"isManager","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isManager) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </ul>\n\n    <!-- Tab panes -->\n    <div class=\"tab-content\">\n        <div class=\"content-filters\"></div>\n        <div role=\"tabpanel\" class=\"tab-pane distribute-panel "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"distribute",{"name":"eq","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"distribute\">por distribuir\n        </div>\n        <div role=\"tabpanel\" class=\"tab-pane pending-panel "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"pending",{"name":"eq","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"pending\">\n            pendentes\n        </div>\n        <div role=\"tabpanel\" class=\"tab-pane validation-panel "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.activeTab : depth0),"validate",{"name":"eq","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"validate\">\n            validação\n        </div>\n";
  stack1 = ((helper = (helper = helpers.isManager || (depth0 != null ? depth0.isManager : depth0)) != null ? helper : alias2),(options={"name":"isManager","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isManager) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}