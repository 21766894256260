(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/main/equipments-filter'),
        ui: {
            equipment_families: '#equipment_families',
            equipment: '#equipment'
        },
        behaviors: {
            EquipmentOrdersFilters: {}
        },

        initialize: function (options) {
            this.filters = Radio.global.request('app:filters');
            this.equipmentFamilies = options.equipmentFamilies;
            this.selectedEquipmentFamily = this.filters.equipment_family_id;
            this.equipments = options.equipments.vCollection(null, null, this);
            this.equipments.updateFilterParams({equipment_family_id: this.filters.equipment_family_id});
            this.selectedEquipment = this.filters.equipment_id;
        },

        templateHelpers: function () {
            var isDummy = (this.selectedEquipmentFamily === undefined || this.selectedEquipmentFamily === -1) ? false : this.equipmentFamilies.get(this.selectedEquipmentFamily).get('slug') === 'dummy';

            return {
                equipments: this.equipments.toJSON(),
                equipment_families: this.equipmentFamilies.toJSON(),
                selectedEquipment: this.selectedEquipment,
                selectedEquipmentFamily: this.selectedEquipmentFamily,
                selectedEquipmentFamilyIsDummy: isDummy
            };
        }
    });
})();
