(function () {
    'use strict';
    let Radio = require('shim/radio');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/main/tasks-list-item'),
        tagName: 'tr',
        attributes : function () {
            return {
                'data-target' : this._getEndpoint()
            };
        },
        classes: ['routine', 'alerts', 'ot'],
        endpoints: ['orders', 'notifications', 'orders'],
        classes_v2: ['pending', 'ongoing', 'done', 'other'],
        ui: {
          showImages: '.show-image'
        },
        events:{
          'click @ui.showImages': 'onShowImageClicked',
        },
        modelEvents: {
            'change': 'onChange'
        },

        onChange: function (model) {
            if (model.get('display')) {
                this.$el.show();
            } else {
                this.$el.hide();
            }

            this.render();
        },

        templateHelpers: function () {
          return {
            priority: _.find(Radio.global.request('app:priorities'), { 'value': this.model.get('priority') }),
            notDone: ['pending', 'ongoing', 'complete'].includes(this.model.get('state'))
          }
        },

        /*deprecated*/
        _getEndpoint: function () {
            return ['#', this.endpoints[this.model.get('order_category_id') - 1] || 'orders', this.model.get('id')].join('/');
        },

        _getClass: function () {
            return this.classes[this.model.get('order_category_id') - 1];
        },

        _getClass_v2: function () {
            var state_id = this.model.get('state_id');
            if (state_id > 3)
                return this.classes_v2[3];
            return this.classes_v2[state_id];
        },

        onShowImageClicked: function (event) {
          event.preventDefault();
          event.stopPropagation();

          this.model.fetch().done(function () {
            this.model.set({display:true});
          }.bind(this));
        }
    });
})();
