(function () {
    'use strict';

    module.exports = Backbone.Model.extend({

        defaults: {
            'comment': {},
            'chapters': 5
        },

    });
})();
