var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <div class=\"row material-row\">\n\n    <div class=\"form-group col-xs-12 col-sm-3 col-md-4\">\n      <label for=\"material-category\">Selecione Categoria</label>\n      <select class=\"categories form-control mutate-change\" name=\"material-category\">\n        <option value=\"-1\" data-id=\"-1\">Todos</option>\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda(((stack1 = (data && data.root)) && stack1.categories), depth0),{"name":"@root/categories","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </select>\n    </div>\n\n    <div class=\"form-group col-xs-12 col-sm-5 col-md-6 materials-select\">\n"
    + ((stack1 = container.invokePartial(partials["material-row-select"],depth0,{"name":"material-row-select","hash":{"material":(depth0 != null ? depth0.material : depth0)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"form-group col-xs-12 col-sm-4 col-md-2\">\n      <label for=\"amount-label\">Insira Quantidade</label>\n      <div name=\"amount-label\" style=\"display:flex;padding:0px\">\n        <input  value=\""
    + alias4(((helper = (helper = helpers.amount || (depth0 != null ? depth0.amount : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"amount","hash":{},"data":data}) : helper)))
    + "\" name=\"amount\" type=\"number\" min=\"1\"\n                class=\"form-control mutate-change\" onkeypress=\"return event.charCode >= 48\"\n                placeholder=\"Qt.\">\n\n        <div class=\"edit-buttons-order-materials edit-btns\" name=\"remove-order\">\n          <button type=\"button\" class=\"button button-primary remove-material\">\n            <i class=\"fa fa-trash\" aria-hidden=\"true\"></i>\n            <span></span>\n          </button>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isNewMaterial : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n      </div>\n      <input type=\"hidden\" name=\"id\" value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    </div>\n  </div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <option value=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\" data-name=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "          <i class=\"fa fa-check\" aria-hidden=\"true\" style=\"color: #53a784;\"></i>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n  <div class=\"row material-row\">\n    <div class=\"materials-select form-group col-xs-8\">\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda(((stack1 = (data && data.root)) && stack1.materials), depth0),{"name":"@root/materials","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"form-group col-xs-2\" style=\"text-align:center\">\n      "
    + container.escapeExpression(((helper = (helper = helpers.amount || (depth0 != null ? depth0.amount : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"amount","hash":{},"data":data}) : helper)))
    + "\n    </div>\n    <div class=\"form-group col-xs-2\" style=\"text-align:center;\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.manttest_id : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "      "
    + ((stack1 = (helpers.eqx || (depth0 && depth0.eqx) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.stock_id : depth0),(depths[1] != null ? depths[1].material : depths[1]),{"name":"eqx","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    return "      <i class=\"fa fa-check\" aria-hidden=\"true\" style=\"color: #53a784;\"></i>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "      <i class=\"fa fa-times\" aria-hidden=\"true\" style=\"color: red;\"></i>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.canEditMaterials),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}