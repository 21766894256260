(function () {
    'use strict';

    module.exports = Marionette.ItemView.extend({
        template: require('templates/orders/order-summary'),
        ui: {},
        events: {},

        initialize: function (options) {
        },

        templateHelpers: function () {
            var tasks_notes_summary = _.filter(this.model.get('tasks').toJSON(),
                function (task) {
                    return (task.state_id >= 2 && (!_.isEmpty(task.notes) || !_.isEmpty(task.validation_notes)));
                }),
                resumeOccurs = tasks_notes_summary.length,
                tasks = this.model.get('tasks').toJSON(),
                hasTasks = !(tasks.length === 1 && _.isEmpty(tasks[0].unit)),
                hasInterventions = _.some(this.model.get('tasks').models, function (model) { return model.get('interventions').length > 0 });

            return {
                hasTasks: hasTasks,
                hasInterventions: hasInterventions,
                tasks_notes_summary: tasks_notes_summary,
                resume_occurs: resumeOccurs,
                tasks: tasks,
                spentTime: this.model.spentTime(),
                zone_title: this.model.get('zone_title')
            };
        }
    });
})();
