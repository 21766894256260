(function () {
  'use strict';

  module.exports = Marionette.CompositeView.extend({
    template: require('templates/materials/material-orders/all-material-requests'),
    childView: require('./all-material-requests-item-view'),
    childViewContainer: 'select',
    ui: {
      materials: 'select'
    },

    onBeforeShow: function () {
      this.ui.materials.select2({
        id: '',
        placeholder: ' - Selecione Material - ',
        allowClear: true
      });
    },
  });
})();
