(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.Behavior.extend({
        events: {
            'click @ui.dayControl': 'onDayClicked',
            'click @ui.monthControl': 'onMonthClicked'
        },

        onDayClicked: function (ev) {
            ev.preventDefault();
            Radio.global.request('app:states').calendar.type = "daily";

            var user = this.view.filters.currentView.user,
                route = '/calendar/day/?user_id=' + user;
            Radio.global.command('router:calendar:navigate', route, {trigger: true});
        },

        onMonthClicked: function (ev) {
            ev.preventDefault();
            Radio.global.request('app:states').calendar.type = "month";

            var user = this.view.filters.currentView.user,
                route = '/calendar/month/?user_id=' + user;
            Radio.global.command('router:calendar:navigate', route, {trigger: true});
        }
    });
})();
