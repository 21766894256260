(function () {
  'use strict';

  var api = require('shim/api'),
      vCollection = require('collections/mixins/virtual-collection'),

      MaterialRequest = require('models/material-request');

  module.exports = Backbone.Collection.extend({
    model:  MaterialRequest,
    url:    api.urlRoot + '/material_requests',
    store:  'materialRequests',
    comparator: function(item) {
      return [item.get("code"), item.get("created_at")]
    },

    constructor: function () {
        Backbone.Collection.apply(this, arguments);
        this.vCollection = vCollection.bind(this);
    },

    parse: function (response) {
      return response.material_requests;
    },

    fetchNew () {
      return Backbone.Collection.prototype.fetch.call(this, { url: this.url + '/new_materials' });
    },

    generatePDF: function () {
      let url     = this.url + '/generate_list',
          options = {
            url:  url,
            type: 'POST',
            data:  JSON.stringify({material_request_ids: this.pluck('id')}),
            contentType: 'application/json'
          };

      return (this.sync || Backbone.sync).call(this, null, this, options);
    }
  });
})();
