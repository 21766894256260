(function () {
    'use strict';

    var config = require('shim/config'),
        Radio = require('shim/radio');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/orders/order-units'),
        tagName: 'tr',
        className: 'todo',

        ui: {
            units: '[name="validate_unit_id[]"]'
        },

        events: {
            'click .validate': 'onValidateClicked',
            'click .verify': 'onVerifyClicked',
            'click .validateverify': 'onValidateVerifyClicked',
            'click .no_validate': 'onNoValidateClicked',
            'click .no_verify': 'onNoVerifyClicked',
            'click .btn-offline-check': 'verifyOfflineState'
        },

        initialize: function (options) {
            this.order = options.order;
        },

        templateHelpers: function () {
            return {
                order_id: this.order.get('id'),
                display_controls: this._displayValidationControls(),
                enable_controls: this._enableControls(),
                is_done: this._isDone(),
                getZoneTitle: function(zoneRoute) {
                  if (zoneRoute) {
                    return Radio.store.request('get:zones').findWhere({route: zoneRoute}).get('title');
                  } else {
                    return this.order.get('zone_title');
                  }
                }.bind(this)
            };
        },

        //Transitions
        onValidateClicked: function (ev) {
            ev.preventDefault();
            Radio.global.trigger('order:transition:next', this.model, 'validate', ['validated']);
        },
        onVerifyClicked: function (ev) {
            ev.preventDefault();
            Radio.global.trigger('order:transition:next', this.model, 'verify', ['verified']);
        },
        onValidateVerifyClicked: function (ev) {
            ev.preventDefault();
            Radio.global.trigger('order:gtransition:next', this.model);
        },
        onNoValidateClicked: function (ev) {
            ev.preventDefault();
            this.model.set({ done_status: 'ongoing' });
            Radio.global.trigger('order:transition:back', this.model, 'no_validate');
        },
        onNoVerifyClicked: function (ev) {
            ev.preventDefault();
            Radio.global.trigger('order:transition:back', this.model, 'no_verify');
        },

        verifyOfflineState: function (ev) {
            var canEditOffline = ev.currentTarget.getAttribute('data-editOffline') || false;

            if (isNaN(this.model.id) && !canEditOffline) {
                ev.preventDefault();
                Radio.global.command('alerts:show', {
                    msg: 'Não pode editar esta unidade até ter sincronizado com o servidor!',
                    type: 'danger'
                });
                window.scrollTo(0, 0);
            }
        },

        //Controls
        _displayValidationControls: function () {
            return (this.order.get('state_title') === config.getStatus('done').title ||
                this.order.get('state_id') > config.getStatus('done').id) &&
                this.order.get('state_id') !== config.getStatus('unassigned').id;
        },

        _enableControls: function () {
            return this.order.get('state_title') === config.getStatus('complete').title ||
                this.order.get('state_id') < config.getStatus('done').id ||
                this.order.get('state_id') === config.getStatus('unassigned').id;
        },

        _isDone: function () {
            return this.order.state_id === config.getStatus('done').id ||
                this.order.state_id === config.getStatus('validated').id ||
                this.order.state_id === config.getStatus('verified').id;
        }
    });
})();
