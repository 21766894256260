(function () {
    'use strict';

    var Radio = require('shim/radio'),
        api = require('shim/api'),
        Orders = require('collections/orders'),
        OfflineTmpOrdersCollection = new Orders,

        //Known Item Types
        types = {
            event: require('models/event'),
            tasks: require('models/task'),
            orders: require('models/order'),
            notification: require('models/notification')
        },

        //Real Model ID's
        IDS = {};

    //Check Queue For Offline Sync
    function sync() {

        checkAlive()
            .done(function () {
                //Pop Queue Item
                var request = Radio.global.request('queue:pop:request'), type;

                //Checks If There's Items In Queue
                if (request === null) { return terminate(); }

                //Start "Sync" Spinner
                Radio.global.trigger('spinner:progress', { count: Radio.global.request('queue:size') });

                //Checks For Request Type
                type = /(\w*)/.exec(request.model.type)[0];
                if (types.hasOwnProperty(type)) {
                    startSync(request, type);   //Starts Item Sync
                } else {
                    //Returns Unknown Type And Resync
                    sync();
                }
            })
            .fail(function () {
                terminate();
            });

    }

    //Start Offline Sync If Items In Queue
    function startSync(request, type) {
        var model = new types[type](request.model.attrs),
            syncCallback = _.noop;

        console.log('SYNC\'ING: ' + request.method + ' ' + type, request.model.attrs);

        //Reset Model ID If It Starts With "tmp-"
        if (startsWith(model.attributes.id, 'tmp-')) {
            syncCallback = resetId(model);
        }

        //Call Request Method On Model
        model[request.method].call(model, null, { force_ajax: true, offline_save: true, request_type: request.type })
            .done(function () {
                if (Radio.store.request('get:orders').isFetched) {
                  Radio.store.command('save:model', model.store, model)
                } else {
                  OfflineTmpOrdersCollection.push(model);
                }
            })
            .fail(function (error) {
                if (error.readyState == 0)  //Means cant contact API (Offline)
                    Radio.global.command('queue:unqueue:request', request);
            })
            .always(syncCallback)
            .always(sync);  //Resync to search for more queue items
    }

    //Resets Model ID
    function resetId(model) {
        if (model.store === 'tasks') {
            return handleTask(model);
        } else if (model.store === 'orders') {
            return handleOrder(model);
        }
    }

    //Handles New ID Attribution To Order
    function handleOrder(model) {
        var attrs = model.attributes,
            id = attrs.id,
            realId = IDS[id];
        if (realId) {
            model.set('id', realId);
            return _.noop;
        } else {
            model.set('id', undefined);
            return function (response) {
                Radio.store.trigger('remove:orders', id);
                IDS[id] = response.id;
            };
        }
    }

    //Handles New ID Attribution To Task
    function handleTask(model) {
        var order_id = IDS[model.attributes.order_id],
            unit_id = model.attributes.unit_id,
            unit_id_filter = function (e) {
                return e.get('unit').get('id') === unit_id;
            },
            order;

        if (Radio.store.request('get:orders').isFetched) {
            order = Radio.store.request('get:orders').get(order_id)
        } else {
            order = OfflineTmpOrdersCollection.get(order_id)
        }

        var task = order.get('tasks').filter(unit_id_filter)[0];

        model.set('order_id', order_id);
        model.set('id', task.get('id'));
        return function (response) {
            task.set(response);
        };
    }

    //Checks Beginning Of String
    function startsWith(string, text) {
        if (!_.isString(string)) {
            return false;
        }
        return string.indexOf(text) === 0;
    }

    //Terminates "Sync" ALert
    function terminate() {
        Radio.global.trigger('spinner:off');
        Radio.global.trigger('offline:check:queue');

        var isAppSynced = Radio.global.request("app:synced");

        if (_.isUndefined(isAppSynced))
            Radio.global.command("app:fetch:all")
        else if (!isAppSynced)
            Radio.global.command("app:fetch:sessionInit")
    }

    function checkAlive() {
        return $.get(api.isAlive);
    }

    //Starts Sync Progress If Offline State Detected
    Radio.global.comply('offline:sync', function () {
        Radio.global.request('offline:check:state').done(sync);
    });
})();
