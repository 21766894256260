(function () {
    'use strict';

    var LoadingView = Marionette.ItemView.extend({
        template: require('templates/loading'),
        tagName: 'div',
        className: 'loading',
        serializeData: function () {
            return {msg: this.options.msg};
        }
    });

    module.exports = LoadingView;
})();
