(function () {
    'use strict';

    var Radio = require('shim/radio'),
        removeBreaks = require('helpers/html-helper').removeBreaks,
        Compress = require('compress.js');

    require('bootstrap-fileinput');
    require('bootstrap-fileinput/js/locales/pt');
    require('bootstrap-fileinput/themes/explorer/theme');

    module.exports = Marionette.LayoutView.extend({
      template: require('templates/orders/order-edit'),

      regions: {
        equipments: '#equipments'
      },
      ui: {
        'assign':       '.assign-ot',
        'select2':      '.select2',
        'fileInput':    '[type="file"]',
        'preview':      '.fileinput-image-preview',
        'modalContent': '.modal-content'
      },
      behaviors: {
        OrdersAssign: {
          container: '.assign-container'
        },
        DateChange: {
          usedDateHelper: false
        }
      },
      events: {
        'click .units-na':      'onCkNaClicked',
        'click .units-ck':      'onCkClicked',
        'click .edit-order':    'onEditOrderClicked',
        'click .kv-file-zoom':  'putImageModal',
        'change #work_type': 'onWorktypeSelected',
      },

      initialize: function (options) {
        options = options || {};

        this.workTypes = options.work_types;
        this.zones = options.zones;
        this.userGroups = options.user_groups;
        this.users = options.users;
        this.usedDateHelper = false;
        Backbone.Validation.bind(this);
      },

      onRender: function () {
        this.timeToPersist = moment($('#end_date_preview', this.el).val()).diff($('#start_date_preview', this.el).val());

        //Render Image modal if OT has image
        $('#helperModal .modal-dialog')
          .empty()
          .append(this.ui.modalContent)
      },

      onBeforeDestroy: function () {
        Backbone.Validation.unbind(this);
      },

      onShow: function () {
        var self = this;
        //Assignment Group
        $('input:checked').trigger('change');

        //Other Groups
        this.ui.select2.select2();

        var imageTag=null;
        if (self.model.get('image')) {
          imageTag = "<img src='" + self.model.get('image') + "' class='file-preview-image kv-preview-data' style='width:auto;height:60px'>";
        }

        //Bootstrap fileinput field
        this.ui.fileInput.fileinput({
          language: 'pt',
          theme: 'explorer',
          showUpload: false,
          previewFileType: 'any',
          initialPreview: [
            imageTag
          ],
          layoutTemplates: {
            progress: '',
            actionDelete: '',
            actionZoom: '<button id="image-upload-button" type="button" class="kv-file-zoom {zoomClass}" title="{zoomTitle}">{zoomIcon}</button>',
            btnBrowse: '<div tabindex="500" class="btn btn-default btn-file"{status}>{icon}{label}</div>'
          }
        }).on('change', function (ev) {
          var compress = new Compress();
          compress.compress([ev.target.files[0]], {
            size: .2, // the max size in MB, defaults to 250kB
            quality: 1, // the quality of the image, max is 1,
            maxWidth: 600, // the max width of the output image;
            resize: true, // defaults to true, set false if you do not want to resize the image width and height
          }).then(function (data) {
            $('.file-preview-image').attr('src', data[0].prefix + data[0].data);
            self.model.set({image: data[0].prefix + data[0].data, has_image: true});
          })
        });

        if (!self.model.get('has_image')) {
          $('.file-caption-name').val('Select ficheiro...')
        }

        $('.fileinput-remove').on('click', function(event) {
          self.model.set({image: null, has_image: null});
        });
      },

      //Events And Triggers
      onCkNaClicked: function () {
        if ($(".units-na").is(":checked"))
          $(".units-ck").prop('disabled', true);
        else
          $(".units-ck").prop('disabled', false);
      },

      onCkClicked: function () {
        if ($(".units-ck:checked").length == 0)
          $(".units-na").prop('disabled', false);
        else
          $(".units-na").prop('disabled', true);
      },

      onEditOrderClicked: function (ev) {
        ev.preventDefault();
        var data = _.extend({ update: true }, this.$el.serializeObject(), {
          order_category_id: Radio.global.request('category:id', 'work_order'),
          order_category_slug: 'work_order'
        });

        if (data.is_blocked) {
          var msg = (this.model.has('isRequest')) ? 'Marcou este pedido como rejeitado' : 'Marcou esta OT para ser bloqueada';
          if (confirm("-- " + msg + " --\n\nTem a certeza que pretende continuar? Esta acção é irreversível!")) {
            Radio.global.trigger('view:edit:order', data);
          }
          return;
        }

        Radio.global.trigger('view:edit:order', data);
      },

      getSelectedUserOrGroup: function () {
        var userCollection = this.model.get('users');

        if (this.model.has('user_group_id'))
          return this.model.get('user_group_id');

        if (userCollection && !_.isEmpty(userCollection.models) && userCollection.first().has('id')) {
          return userCollection.first().get('id');
        }

        return '';
      },

      templateHelpers: function () {
        var work_type_id = this.model.get('work_type_id'),
            workType = Radio.store.request('get:workTypes').find({ _id: work_type_id }) ,
            showPriorities = workType ? !_.some(['otin', 'reventiv'],
              _.include, workType.get('title')) : null;

        return {
          priorities: Radio.global.request('app:priorities'),
          showPriorities: showPriorities,
          canChangeWorkType: !_.contains(['Preventivo', 'Rotinas'], this.model.get('work_type_title')),
          description: removeBreaks(this.model.get('description')),
          notes: removeBreaks(this.model.get('notes')),
          equipment: (this.model.get('tasks').length > 0) ? this.model.get('tasks').models[0].get('unit').get('equipment_title') : null,

          type: this.model.has('user_group_id') ? 'user_group_id' : 'user_ids',
          values: this.model.has('user_group_id') ? this.userGroups.toJSON() : this.users.toJSON(),
          selected: this.getSelectedUserOrGroup(),
          multiple: this.model.has('user_group_id') ? 'false' : 'true',

          set_start_date: this.model.get('start_date_preview') ? moment(this.model.get('start_date_preview')).format('YYYY-MM-DD') : '',
          set_end_date: this.model.get('end_date_preview') ? moment(this.model.get('end_date_preview')).format('YYYY-MM-DD') : '',
          tasks: this.model.get('tasks').toJSON(),
          work_types: this.workTypes.toJSON(),
          zones: this.zones.toJSON(),
          getZoneTitle: function(zoneRoute) {
            if (zoneRoute) {
              return Radio.store.request('get:zones').findWhere({route: zoneRoute}).get('title');
            } else {
              return this.model.get('zone_title');
            }
          }.bind(this)
        };
      },

      putImageModal: function () {
        $('.file-zoom-content').attr('src', $('.file-preview-image').attr('src'))
      },
  });
})();
