(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.LayoutView.extend({
        template: require('templates/orders/order'),
        tagName: 'div',
        className: 'section-box',
        events: {
            'click .submit-order':  'onSubmitOrderClicked',
            'submit':               'onSubmitOrderClicked'
        },
        regions: {
            details: '#details',
            equipment: '#equipment',
            material: '#material',
            materialRequests: '#material-requests',
            summary: '#summary',
            create: '.create-material-requests'
        },
        ui: {
            submitBtn: '.submit-order',
            materialRequestsModalContent: '#modal-material-request-content'
        },

        initialize: function (options) {
          this.hasMaterials = options.hasMaterials;
        },

        //After Render PAge
        onRender: function () {
            window.scrollTo(0, 0);
        },

        //Submit Button Trigger
        onSubmitOrderClicked: function (ev) {
            ev.preventDefault();
            Radio.global.trigger('order:submit');
        },

        //Template Variables/Functions
        templateHelpers: function () {
            var self = this;
            return {
                tabTitle: function () {
                    if (self.model.get('tasks').length > 0) {
                        return 'Equipamento/Unidades';
                    }
                    if (self.model.get('equipment_title') !== null) {
                        return 'Equipamento';
                    }
                    return self.model.get('title');
                },

                showSubmitBtn: function () {
                    //Offline Task Done doesn't change order status, so we have to update its status here
                    //todo: make changes offline

                    // Completed
                    if (self.model.get('state') == 'complete') {
                        return 'block';
                    }
                    else if (self.model.get('state') == 'done') {
                        if (self.model.get('tasks')._every(['validated', 'verified'])) return 'block';
                    }
                    else if (self.model.get('state') == 'validated') {
                        if (self.model.get('tasks')._every(['verified'])) return 'block';
                    }

                    return 'none';
                },
                hasMaterials: this.hasMaterials
            };
        },

        buildMaterialRequestsModal () {
          $('#secondaryHelperModal .modal-dialog').empty().append(this.ui.materialRequestsModalContent);
        }
    });

})();
