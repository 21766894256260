(function () {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: require('templates/settings/typified-orders/typified-orders-list-item'),
    tagName: 'tr',
    attributes : function () {
      return {
        'data-target' : this.model.id
      };
    },
  });
})();
