(function () {
    'use strict';

    var api = require('shim/api'),

        MaterialCategory = require('models/material-category');

    module.exports = Backbone.Collection.extend({
        model: MaterialCategory,
        url: api.urlRoot + '/material_categories',
        store: 'materialCategories',

        parse: function (response) {
            return response.material_categories;
        }
    });
})();
