(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.Behavior.extend({
        events: {
            'change @ui.equipment_families': 'onEquipmentFamiliesChanged',
            'change @ui.equipment': 'onEquipmentChanged'
        },

        _filters: function(){
            return {
                filters: Radio.global.request('app:filters'),
                currentFilters: this._getCurrentFilters()
            };
        },

        onEquipmentChanged: function () {
            //Get Filters
            var options = this._filters();

            //Update Global Filter
            options.filters.equipment_id = options.currentFilters.equipment_id;

            //Update View
            this.view.selectedEquipment = options.currentFilters.equipment_id;
            Radio.global.trigger('filter:orders:change', options.currentFilters);
        },

        onEquipmentFamiliesChanged: function () {
            //Get Filters
            var options = this._filters();

            //Update Global Filter
            options.filters.equipment_family_id = options.currentFilters.equipment_family_id;
            options.filters.equipment_id = -1;

            //Update View
            this.view.selectedEquipment = -1;
            this.view.selectedEquipmentFamily = options.currentFilters.equipment_family_id;
            this.view.equipments.updateFilterParams({equipment_family_id: options.currentFilters.equipment_family_id});
            this.view.render();
            Radio.global.trigger('filter:orders:change', options.currentFilters);
        },

        _getCurrentFilters: function () {
            var equipment_id = parseInt(this.view.ui.equipment.val(), 10),
                equipment_family_id = parseInt(this.view.ui.equipment_families.val(), 10);

            return {
                equipment_id: (equipment_family_id === -1) ? -1 : equipment_id,
                equipment_family_id: equipment_family_id
            };
        }
    });
})();
