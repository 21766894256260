(function () {
    'use strict';

    var Dashboard4 = require('models/dashboards/dashboard4'),
        api = require('shim/api');

    module.exports = Backbone.Collection.extend({
        model: Dashboard4,
        url: api.urlRoot + '/dashboards4',

        initialize: function () {},

        parse: function (response) {
            return response;
        }
    });

})();
