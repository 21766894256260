(function () {
    'use strict';

    var api = require('shim/api'),
        Issue = require('models/issue'),
        vCollection = require('collections/mixins/virtual-collection');

    module.exports = Backbone.Collection.extend({
        model: Issue,
        url: api.urlRoot + '/issues',
        store: 'issues',
        comparator: '_id',

        constructor: function () {
            Backbone.Collection.apply(this, arguments);
            this.vCollection = vCollection.bind(this);
        },

        parse: function (response) {
            return response.issues;
        }
    })
    ;
})();
