var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = 
  "      <p>Grupos: ";
  stack1 = ((helper = (helper = helpers.user_groups || (depth0 != null ? depth0.user_groups : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"user_groups","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.user_groups) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</p>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data}) : helper)));
},"4":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"box settings-box\">\n    <h5>Gerais</h4>\n    <div class=\"section-row\">\n      <a href=\"#/settings/rootzone\">Definições do Edifício</a>\n      <a href=\"#/settings/users\">Gerir Utilizadores</a>\n      <a href=\"#/settings/typified_orders\">Gerir Tipificações de Ordens</a>\n    </div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<div class=\"section-box\" style=\"position:relative\">\n  <header class=\"page-header settings-header\">\n    <div class=\"bs-callout bs-callout-default\">\n      <i aria-hidden=\"true\" class=\"fa fa-cog\"></i>\n      <h4>Definições</h4>\n    </div>\n\n    <div class='sync-info'>\n      Último sync: "
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.last_sync : depth0),{"name":"moment","hash":{"format":"DD/MM/YYYY HH:mm"},"data":data}))
    + "\n    </div>\n  </header>\n\n  <div class=\"box settings-box\">\n    <h5>Pessoais</h4>\n    <div class=\"section-row\">\n      <p>Imagem:</p>\n      <address>\n        <div class=\"avatar-container\">\n          <img src=\""
    + alias3(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" alt=\"Avatar\" class=\"image\">\n          <div class=\"overlay\">\n            <div class=\"text\"><i class=\"fa fa-camera\" aria-hidden=\"true\"></i></div>\n          </div>\n        </div>\n      </address>\n      <p>Nome: "
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</p>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.user_groups : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <hr>\n      <a href=\"#/settings/change-password\">Mudar palavra-passe</a>\n    </div>\n  </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showRootZoneSettings : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n<input type=\"file\" class=\"file\" accept=\".jpg,.jpeg,.png\" style=\"display:none\">\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}