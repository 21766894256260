
(function () {
  'use strict';

  var Radio = require('shim/radio'),

      DashboardController     = require('controllers/main/dashboard-object'),
      OperatorsController     = require('controllers/main/operators-controller'),
      TasksController         = require('controllers/main/tasks-object'),
      IssuesController        = require('controllers/main/issues-object'),
      NotificationsController = require('controllers/main/notifications-controller'),
      ReportsController       = require('controllers/main/reports-object'),
      HelpController          = require('controllers/main/help-controller');

  module.exports = Marionette.Controller.extend({
    initialize: function (options) {
      options = options || {};
      this.orders = options.orders;
      this.issues = options.issues;
      this.users  = options.users;
      this.notifications = options.notifications;
      this.groups = options.groups;
    },

    showDashboard() {
      Radio.global.trigger('anim-loading:show');
      setTimeout(() => {
        new DashboardController({
          orders: this.orders,
          users:  this.users,
          groups: this.groups,
          notifications: this.notifications
        }).showDashboard();
      }, 100);
    },

    showTasks() {
      Radio.global.trigger('anim-loading:show');
      setTimeout(() => {
          new TasksController({ orders: this.orders, notifications: this.notifications }).showTasks();
      }, 100);
    },

    showIssues() {
      Radio.global.trigger('anim-loading:show');
      setTimeout(() => {
        new IssuesController({ issues: this.issues }).showIssues();
      }, 100);
    },

    showNotifications() {
      Radio.global.trigger('anim-loading:show');
      setTimeout(() => {
        new NotificationsController({ notifications: this.notifications }).showNotifications();
      }, 100);
    },

    showOperators() {
      new OperatorsController({ users: this.users }).showOperators();
    },

    showReports() {
      new ReportsController();
    },

    showHelp() {
      new HelpController().showHelp();
    }
  });
})();
