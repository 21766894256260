(function () {
    'use strict';

    var Event = require('models/event'),
        config = require('shim/config'),
        api = require('shim/api');

    module.exports = Backbone.Collection.extend({
        model: Event,
        url: api.urlRoot + '/orders',
        store: 'events',

        parse: function (response) {
            response = response.orders || response.events; // events is offline one
            return _(response).filter(this._event).map(this._setState.bind(this)).value();
        },

        _event: function (el) {
            return el.order_category_slug === 'event';
        },

        _setState: function (response) {
            var status = config.getStatus(response.state);

            response.state_title = status.title;
            response.state_id = status.id;
            return response;
        }
    });
})();
