(function () {
    'use strict';

    var Radio = require('shim/radio'),
        Users = require('collections/users'),
        EquipmentView = require('views/main/equipment-filters-view');

    module.exports = Marionette.LayoutView.extend({
        template: require('templates/filters'),
        regions: {
            equipmentFilters: '.equipment-filters'
        },
        ui: {
            filter: '.filter',
            stateFilters: '.state-filters',
            worktypeFilters: '.worktype-filters',
            originFilters: '.origin-filters',
            materialDependenceFilters: '.material-dependence-filters',
            materialOrderStatusFilters: '.material-order-status-filters',
            user_input: '#user_input',
            user_group_input: '#user_group_input',
            date_begin_input: '#date_begin_input',
            date_end_input: '#date_end_input',
            calendar_user_input: '#calendar_user_input',
            filter_st: '.filter-st',
            filter_wt: '.filter-wt',
            filter_pry: '.filter-pry',
            filter_origin: '.filter-origin',
            filter_material_dependence: '.filter-material-dependence',
            filter_material_order_status: '.filter-material-order-status',
            typified_order_category_input: '#typified_order_category',

            summary: '.summary_click',
            reset_filters: '.reset_filters',
            printPDF: '.print-pdf'
        },
        events: {
            'click @ui.summary': 'onSummaryClick',
            'click @ui.reset_filters': 'onResetFiltersClick',
            'click @ui.printPDF': 'onPrintPDFClick'
        },
        behaviors: {
            OrdersFilters: {},
            DateFilters: {},
            UserFilters: {},
            TypifiedOrders: {}
        },

        initialize: function (options) {
            this.filters = Radio.global.request('app:filters');
            this.equipmentFamilies = options.equipmentFamilies;
            this.equipments = options.equipments;
            this.users = options.users;
            this.userGroups = options.userGroups;
            this.user = options.user;
            this.typifiedOrdersCategories = options.typifiedOrdersCategories

            this.showStateFilters = options.showStateFilters || false;
            this.showWorkTypesFilters = options.showWorkTypesFilters || false;
            this.showPriorities = options.showPriorities || false;
            this.priorities = options.priorities || false;
            this.showOriginFilters = options.showOriginFilters || false;
            this.showMaterialDependence = options.showMaterialDependence || false;
            this.showDatesFilters = options.showDatesFilters || false;
            this.showUserGroups = options.showUserGroups || false;
            this.showPrintButton = options.showPrintButton || false;
            this.showMaterialOrderStatus = options.showMaterialOrderStatus || false
            this.isMaterialOrder = options.isMaterialOrder || false
            this.showTypifiedOrderCategory = options.showTypifiedOrderCategory || false
            this._prepareDefaultDates();
        },

        onShow: function () {
            if ( this.equipments ) {
              this.equipmentFilters.show(new EquipmentView({
                equipmentFamilies: this.equipmentFamilies,
                equipments: this.equipments
              }));
            }
        },

        onRender: function () {
            $('#filter-details').prop('open', true);

            /**
             * Filters [To refactor]
             */
            //Select User/Group (Dont seem to work on template)
            this.ui.user_input.find("option[value='" + this.filters.user_id + "']").attr("selected", "selected");
            this.ui.user_group_input.find("option[value='" + this.filters.user_group_id + "']").attr("selected", "selected");

            this._uncheckNonFiltered('order_state_id', 'stateFilters', 'st');
            this._uncheckNonFiltered('work_type_id', 'worktypeFilters', 'wt');
        },

        templateHelpers: function () {
            return {
                users:                      this.users ? this.users.toJSON() : {},
                userGroups:                 this.userGroups ? this.userGroups.toJSON() : {},
                user:                       this.user,
                typifiedOrdersCategories:   this.typifiedOrdersCategories,
                showStateFilters:           this.showStateFilters,
                showWorkTypesFilters:       this.showWorkTypesFilters,
                showPriorities:             this.showPriorities,
                priorities:                 this.priorities,
                showOriginFilters:          this.showOriginFilters,
                showMaterialDependence:     this.showMaterialDependence,
                showMaterialOrderStatus:    this.showMaterialOrderStatus,
                showDatesFilters:           this.showDatesFilters,
                showUserGroups:             this.showUserGroups,
                selectedBeginDate:          this.beginDate,
                selectedEndDate:            this.endDate,
                showPrintButton:            this.showPrintButton,
                showTypifiedOrderCategory:  this.showTypifiedOrderCategory
            };
        },

        _prepareDefaultDates: function () {
            this.beginDate = (this.filters.begin_date == -1) ? "0000-00-00" : this.filters.begin_date;
            this.endDate = (this.filters.end_date == -1) ? "0000-00-00" : this.filters.end_date;
        },

        onResetFiltersClick: function (ev) {
            ev.preventDefault();
            ev.stopPropagation();
            this._resetUIComponents();
            let route;

            if ( location.hash === "#/settings/typified_orders" ) {
              route = 'filter:typified-orders:change'
            } else if ( location.hash === "#material_orders" ){
              route = 'filter:material-orders:change'
            } else {
              route = 'filter:orders:change'
            }
            Radio.global.trigger(route, Radio.global.request('app:filters:reset'));
        },

        _resetUIComponents: function () {
            //Reset User and Group User filter
            $('#user_input').val(-1);
            $('#user_group_input').val(-1);
            //Reset Equipment and Family Equipment filter
            $('#equipment_families').val(-1);
            $('#equipment').val(-1);
            $('#equipment').prop('disabled', true);

            //Reset CheckBoxes
            $('.filters [type=checkbox]').prop('checked', true);
            $('#filter-origin-ticket').prop('checked', false);

            //Reset Dates
            $('.filters [type=date]').val("0000-00-00");

            //Reset Search Engine
            $('#search-ot').val('');
        },

        //To persist Check Status on render
        _uncheckNonFiltered: function (key, type, id) {
            var self = this;

            if (self.filters[key] != -1) {
                self.ui[type].find('input[id^="filter-' + id + '"]').each(function () {
                    var el = $(this),
                        item = (key == 'order_state_id') ? parseInt(el.val(), 10) : el.val();
                    if (!_.contains(self.filters[key], item)) {
                        el.prop('checked', false);
                    }
                })
            }
        },

        onPrintPDFClick: function (event) {
          event.preventDefault();

          if ( this.isMaterialOrder ) {
            Radio.global.trigger('materialOrders:print');
          } else {
            var extendedFilters = _.extend(this.filters, {search_text: $('#search-ot').val()})
            Radio.global.trigger('print:orders', extendedFilters);
          }
        }
    });
})();
