(function () {
    'use strict';

    var Radio = require('shim/radio'),
        UserModel = require('models/user'),
        api = require('shim/api');

    module.exports = Backbone.Model.extend({
        urlRoot: api.urlRoot + '/users/login',

        defaults: {
            email: '',
            password: '',
            state: '',
            lastError: '',
            rootzone_id: localStorage.getItem('rootzone_id'),
            user: null,
            // FIXME:  Store the token in the device's Account Manager or keychain.
            token: localStorage.getItem('auth-token')
        },

        validation: {
            email: [
                {
                    required: true,
                    msg: 'Please enter an email address' // TODO: I18N
                },
                {
                    pattern: 'email',
                    msg: 'Please enter a valid email' // TODO: I18N
                }
            ],
            password: {
                required: true,
                msg: 'Please enter a password' // TODO: I18N
            }
        },

        /**
         * Constructor.
         */
        initialize: function () {
            this.user = null;
            this.lastError = null;

            this.AUTH_STATE = {
                DEFAULT: 'Not Authenticated',      // TODO: I18N
                PENDING: 'Pending Authentication', // TODO: I18N
                SUCCESS: 'Authentication Success', // TODO: I18N
                FAILED: 'Authentication Failure'  // TODO: I18N
            };

            this.on('change:token', this.onChangeToken, this);
        },

        setMe: function () {
            this.set('user', new UserModel({_id: 'me'}));
        },

        getMe: function () {
            var self = this;
            return this.get('user').fetch()
                .done(function(){
                    self.set({
                        state: self.AUTH_STATE.SUCCESS
                    });
                });
        },

        validateToken: function () {
            var token = localStorage.getItem('auth-token');
            return token;
        },

        /**
         * Validate the user with the remote service.
         */
        loginValidate: function () {
            var options;

            if (!this.get('token') && !this.isValid(true)) {
                return;
            }

            options = {
                user: {
                    email: this.get('email'),
                    password: this.get('password')
                }
            };

            this.set('state', this.AUTH_STATE.PENDING);

            $.ajax({
                url: this.urlRoot,
                contentType: 'application/json',
                dataType: 'json',
                type: 'POST',
                data: JSON.stringify(options),
                context: this,

                success: function (data) {
                    Radio.global.trigger('session:valid', data);
                },

                error: function (error) {
                    Radio.global.trigger('session:error', error);
                }
            });
        },

        /**
         * Sets session data on successful login.
         * @param {Object} data Response data.
         */
        setData: function (data) {
            this.set({
                state: this.AUTH_STATE.SUCCESS,
                password: '',
                rootzone_id: data.rootzone_id,
                token: data.token
            });

            this.set('user', new UserModel(data));
            return this.get('user').fetch();
        },

        /**
         * Sets session data on login error.
         * @param {Object} error Request error.
         */
        setError: function (error) {
            var lastErrorMessage = '';

            this.lastError = error;

            if (error.responseJSON && error.responseJSON.error) {
                lastErrorMessage = error.responseJSON.error.message || '';
            } else {
                lastErrorMessage = error.statusText;
            }

            this.set({
                state: this.AUTH_STATE.FAILED,
                lastError: lastErrorMessage,
                token: ''
            });
        },

        /**
         * Clear the session data.
         */
        logout: function () {
            this.set({
                state: this.AUTH_STATE.DEFAULT,
                user: null,
                token: '',
                password: ''
            });
        },

        /**
         * Valid session state check.
         * @return {Boolean} Whether the session is valid.
         */
        isLoggedIn: function () {
            return this.get('state') === this.AUTH_STATE.SUCCESS;
        },

        /**
         * Token change handler.
         */
        onChangeToken: function () {
            // FIXME: Store the token in the device's Account Manager or keychain.
            localStorage.setItem('auth-token', this.get('token'));
        }
    });
})();
