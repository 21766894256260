var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.unit : depth0)) != null ? stack1.title : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return "n/a";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.state : depth0),"verified",{"name":"eq","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.state : depth0),"validated",{"name":"eq","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.state : depth0),"done",{"name":"eq","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.state_title || (depth0 != null ? depth0.state_title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"state_title","hash":{},"data":data}) : helper)));
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.display_controls : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <a href=\"#\" class=\"button button-danger no_verify\">\n          <i aria-hidden=\"true\" class=\"fa fa-chevron-left\"></i>\n          <span> Não fechar</span>\n        </a>\n\n        <a href=\"#\" class=\"button button-success verify\">\n          <i aria-hidden=\"true\" class=\"fa fa-check\"></i>\n          <span> Fechar</span>\n        </a>\n\n        <a href=\"#orders/"
    + alias4(((helper = (helper = helpers.order_id || (depth0 != null ? depth0.order_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"order_id","hash":{},"data":data}) : helper)))
    + "/verify/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"button button-white check\">\n          <i aria-hidden=\"true\" class=\"fa fa-eye\"></i>\n        </a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        "
    + container.escapeExpression(((helper = (helper = helpers.state_title || (depth0 != null ? depth0.state_title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"state_title","hash":{},"data":data}) : helper)))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.display_controls : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <a href=\"#\" class=\"button button-danger no_validate\">\n            <i aria-hidden=\"true\" class=\"fa fa-chevron-left\"></i>\n            <span> Não validar</span>\n        </a>\n\n        <a href=\"#\" class=\"button button-success validate\">\n            <i aria-hidden=\"true\" class=\"fa fa-check\"></i>\n            <span> Validar</span>\n        </a>\n\n        <a href=\"#orders/"
    + alias4(((helper = (helper = helpers.order_id || (depth0 != null ? depth0.order_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"order_id","hash":{},"data":data}) : helper)))
    + "/validate/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"button button-white check link-to\">\n            <i aria-hidden=\"true\" class=\"fa fa-eye\"></i>\n        </a>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.state : depth0),"verified",{"name":"eq","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.state : depth0),"validated",{"name":"eq","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.state : depth0),"done",{"name":"eq","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.display_controls : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.isNotOperator || (depth0 != null ? depth0.isNotOperator : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"isNotOperator","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.isNotOperator) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <span>"
    + alias4(((helper = (helper = helpers.state_title || (depth0 != null ? depth0.state_title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"state_title","hash":{},"data":data}) : helper)))
    + "</span>\n          <a href=\"#orders/"
    + alias4(((helper = (helper = helpers.order_id || (depth0 != null ? depth0.order_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"order_id","hash":{},"data":data}) : helper)))
    + "/validate/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"button button-white check link-to\">\n            <i aria-hidden=\"true\" class=\"fa fa-eye\"></i>\n          </a>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.display_controls : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.isNotOperator || (depth0 != null ? depth0.isNotOperator : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"isNotOperator","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.isNotOperator) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <a href=\"#\" class=\"button button-danger no_validate\">\n            <i aria-hidden=\"true\" class=\"fa fa-chevron-left\"></i>\n            <span> Não validar</span>\n          </a>\n\n          <a href=\"#\" class=\"button button-success validate\">\n            <i aria-hidden=\"true\" class=\"fa fa-check\"></i>\n            <span> Validar</span>\n          </a>\n\n          <a href=\"#orders/"
    + alias4(((helper = (helper = helpers.order_id || (depth0 != null ? depth0.order_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"order_id","hash":{},"data":data}) : helper)))
    + "/validate/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"button button-white check link-to\">\n            <i aria-hidden=\"true\" class=\"fa fa-eye\"></i>\n          </a>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "        "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.state : depth0),"verified",{"name":"eq","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.state : depth0),"validated",{"name":"eq","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "Fechada";
},"28":function(container,depth0,helpers,partials,data) {
    return "Validada";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.state : depth0),"pending",{"name":"eq","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.state : depth0),"ongoing",{"name":"eq","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.state : depth0),"done",{"name":"eq","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <a href=\"#orders/"
    + alias4(((helper = (helper = helpers.order_id || (depth0 != null ? depth0.order_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"order_id","hash":{},"data":data}) : helper)))
    + "/do/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"button button-state-pending\" data-editOffline=\"true\">\n          <i aria-hidden=\"true\" class=\"fa fa-wrench\"></i>\n          <span> "
    + alias4(((helper = (helper = helpers.state_title || (depth0 != null ? depth0.state_title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"state_title","hash":{},"data":data}) : helper)))
    + "</span>\n        </a>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <a href=\"#orders/"
    + alias4(((helper = (helper = helpers.order_id || (depth0 != null ? depth0.order_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"order_id","hash":{},"data":data}) : helper)))
    + "/do/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"button button-state-ongoing\">\n          <i aria-hidden=\"true\" class=\"fa fa-cogs\"></i>\n          <span> "
    + alias4(((helper = (helper = helpers.state_title || (depth0 != null ? depth0.state_title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"state_title","hash":{},"data":data}) : helper)))
    + "</span>\n        </a>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.done_status : depth0),"complete",{"name":"eq","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(38, data, 0),"data":data})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <a href=\"#orders/"
    + alias4(((helper = (helper = helpers.order_id || (depth0 != null ? depth0.order_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"order_id","hash":{},"data":data}) : helper)))
    + "/do/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"button button-state-complete\">\n            <i aria-hidden=\"true\" class=\"fa fa-check\"></i>\n            <span> Terminada</span>\n          </a>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.done_status : depth0),"uncompleted",{"name":"eq","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(41, data, 0),"data":data})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <a href=\"#orders/"
    + alias4(((helper = (helper = helpers.order_id || (depth0 != null ? depth0.order_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"order_id","hash":{},"data":data}) : helper)))
    + "/do/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"button button-state-uncompleted\">\n              <i aria-hidden=\"true\" class=\"fa fa-exclamation-triangle\"></i>\n              <span> Terminada</span>\n            </a>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <a href=\"#orders/"
    + alias4(((helper = (helper = helpers.order_id || (depth0 != null ? depth0.order_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"order_id","hash":{},"data":data}) : helper)))
    + "/do/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"button button-state-unrealized\">\n              <i aria-hidden=\"true\" class=\"fa fa-times\"></i>\n              <span> Não realizada</span>\n            </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=helpers.blockHelperMissing, buffer = 
  "<div class='unit-zone-area' style='padding:10px !important'>\n  <b>"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.unit : depth0)) != null ? stack1.title : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</b>\n  <p style=\"margin:0px\">"
    + alias3((helpers.getZoneTitle || (depth0 && depth0.getZoneTitle) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.unit : depth0)) != null ? stack1.zone_route : stack1),{"name":"getZoneTitle","hash":{},"data":data}))
    + "</p>\n</div>\n\n<div data-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"button-set\" style=\"margin-right:10px\">\n";
  stack1 = ((helper = (helper = helpers.isManager || (depth0 != null ? depth0.isManager : depth0)) != null ? helper : alias2),(options={"name":"isManager","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!helpers.isManager) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.isSupervisor || (depth0 != null ? depth0.isSupervisor : depth0)) != null ? helper : alias2),(options={"name":"isSupervisor","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!helpers.isSupervisor) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.isOperator || (depth0 != null ? depth0.isOperator : depth0)) != null ? helper : alias2),(options={"name":"isOperator","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!helpers.isOperator) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.enable_controls : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}