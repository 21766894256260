(function () {
  'use strict';

  var BaseObject = require('controllers/base-object'),
      Radio      = require('shim/radio'),

      OrderTypification = require('models/order-typification'),
      EquipmentCollection = require('collections/equipments'),

      OrderTypificationLayout = require('layouts/typified-order-layout'),
      TypifiedOrderEditView = require('views/settings/typified-orders/typified-order-view'),
      TypifiedOrderEquipmentEditView = require('views/settings/typified-orders/typified-order-equipment-view'),
      EquipmentView = require('views/orders/create-order-equipments-view');


  module.exports = BaseObject.extend({
    initialize: function (options = {}) {
      BaseObject.prototype.initialize.apply(this, arguments);
      this.typification = options.typification ? options.typification : new OrderTypification;
      this.typifications = options.typifications;
      this.workTypes = options.workTypes;
      this.equipmentFamilies = options.equipmentFamilies;
      this.equipment = options.equipment;
      this.units = options.units;
      this.zones = options.zones;
    },

    createTypifiedOrder: function() {
      this.typification.set('active', true)

      var orderTypificationLayout = new OrderTypificationLayout({
        model: this.typification,
        collection: this.typifications
      });

      //Render MainView
      this.showBind(orderTypificationLayout);

      //Render SubViews
      orderTypificationLayout.showChildView('view', new TypifiedOrderEditView({
        model:              this.typification,
        collection:         this.typifications,
        workTypes:          this.workTypes,
        zones:              this.zones
      }));

      orderTypificationLayout.showChildView('equipment', new TypifiedOrderEquipmentEditView({
        collection:         new EquipmentCollection(),
        allEquipment:       this.equipment,
        typification:       this.typification,
        equipmentFamilies:  this.equipmentFamilies,
      }));

      Radio.global.trigger('anim-loading:hide');
    },

    showTypifiedOrderById: function(options = {}){
      var orderTypificationLayout = new OrderTypificationLayout({
        model: this.typification,
        collection: this.typifications
      });

      //Render MainView
      this.showBind(orderTypificationLayout);

      //Render SubViews
      orderTypificationLayout.showChildView('view', new TypifiedOrderEditView({
        model:              this.typification,
        collection:         this.typifications,
        workTypes:          this.workTypes,
        zones:              this.zones
      }));

      orderTypificationLayout.showChildView('equipment', new TypifiedOrderEquipmentEditView({
        collection:         new EquipmentCollection(),
        allEquipment:       this.equipment,
        typification:       this.typification,
        equipmentFamilies:  this.equipmentFamilies,
      }));

      Radio.global.trigger('anim-loading:hide');
    }
  });
})();
