(function () {
  'use strict';

  var DoOrderProgressView = require('./do-order-progress-view');

  module.exports = DoOrderProgressView.extend({
    template: require('templates/orders/do-order-progress-simplified'),
    isValidStep: function (step) {
      return step >= 1 && step <= 2;
    }
  });
})();
