(function () {
    'use strict';

    var api = require('shim/api'),
        Order = require('models/order'),
        vCollection = require('collections/mixins/virtual-collection');

    module.exports = Backbone.Collection.extend({
        model: Order,
        comparator: 'start_date_preview',
        url: api.urlRoot + '/orders',
        isFetched: false,
        store: 'orders',

        constructor: function () {
            Backbone.Collection.apply(this, arguments);
            this.vCollection = vCollection.bind(this);
        },

        parse: function (response) {
            this.isFetched = true;
            return response.orders;
        },

        getTotal: function (opts) {
            const options = Object.assign({}, {
                url: api.urlRoot + '/orders/total',
                type: 'GET'
            }, opts);

            return (this.sync || Backbone.sync).call(this, null, this, options);
        },

        groupBy: function (params) {
            var _params = params || {};

            return Backbone.GroupedCollection.buildGroupedCollection({
                collection: this,
                groupBy: function (order) {
                    if (_params.type === 'date') {
                        var groupDate = new Date(order.get(_params.groupBy));
                        groupDate.setHours(0, 0, 0, 0);
                        return groupDate.getTime();
                    }
                    return false;
                }
            });
        },

        generatePDF: function (order_ids, selectedFilters) {
          let self    = this,
              url     = this.url + '/generate_list',
              options = {
                  url:  url,
                  type: 'POST',
                  data:  JSON.stringify({order_ids: order_ids, filters: selectedFilters}),
                  contentType: 'application/json'
              };

          return (self.sync || Backbone.sync).call(self, null, self, options);
        }
    })
})();
