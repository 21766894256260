(function () {
    'use strict';

    var Radio = require('shim/radio'),
        BaseObject = require('controllers/base-object'),

        CalendarLayout = require('layouts/calendar-layout'),
        CalendarFiltersView = require('views/main/filters-view'),
        CalendarSummaryView = require('views/calendars/calendar-summary-view'),
        CalendarMonthView = require('views/calendars/calendar-month-view');

    module.exports = BaseObject.extend({
        filters: {},
        radio: {
            'calendar:display:user': 'onUserChange',
            'filter:orders:change': 'onFiltersChange'
        },

        initialize: function (options) {
            BaseObject.prototype.initialize.apply(this, arguments);
            options = options || {};
            this.filterBlackList = ['user_id', 'begin_date', 'end_date'];
            this.currentFilters = _.omit(Radio.global.request('app:filters'), this.filterBlackList);
            this.orders = options.orders.vCollection(null, this);
            this.users = options.users;
            this.userGroups = options.userGroups;
            this.today = options.today || moment();
            this.user = options.user;
            this.notifications = options.notifications;
            this.equipmentFamilies = Radio.store.request('get:equipmentFamilies');
            this.equipments = Radio.store.request('get:equipments');
        },

        sanitizeFilters: function (filters) {
            var mergedFilters = _.merge( this.currentFilters, filters, function (a, b) {
                                    if (_.isArray(a) || b === false) return b;
                                });

            this.currentFilters = _.pick(mergedFilters, function (value) {
                                      return value !== false && value != '-1';
                                  });
        },

        //Render View
        showCalendar: function (year, month) {
            var self = this;

            //Set Date
            if (year !== undefined && month !== undefined) {
                self.today.setYear(year);
                self.today.setMonth(month);
            }
            self.today.setDate(1);

            //Render View
            self._showView();
        },
        _showView: function () {
            Radio.global.command('convert:alarms', this.notifications, this.orders);

            var self = this,
                calendarLayout = self._createCalendarLayout();

            calendarLayout.showChildView('view', new CalendarMonthView({
                begin_date: self.today,
                orders: self.orders,
                users: self.users,
                user: self.user
            }));

            Radio.global.trigger('anim-loading:hide');

        },
        _createCalendarLayout: function () {
            var self = this,
                calendarLayout = new CalendarLayout();

            //Render Main View
            self.show(calendarLayout, self);

            //Render SubViews
            calendarLayout.showChildView('filters', new CalendarFiltersView({
                equipmentFamilies: self.equipmentFamilies, equipments: self.equipments,
                users: self.users, userGroups: self.userGroups,
                user: self.user === 'me' ? Radio.global.request('app:session').get('user').get('_id') : self.user,
                showStateFilters: true,
                showWorkTypesFilters: true,
                showPriorities: true,
                priorities: Radio.global.request('app:priorities'),
                showOriginFilters: true,
                showDatesFilters: false,
                showUserGroups: false
            }));
            calendarLayout.showChildView('summary', new CalendarSummaryView({
                collection: self.orders,
                today: new Date(),
                user_id: self.user,
                users: self.users
            }));

            return calendarLayout;
        },

        onUserChange: function (params) {
            var route = '/calendar/month/?user_id=' + params.user_id;
            Radio.global.command('router:calendar:navigate', route, {trigger: true});
        },
        onFiltersChange: function (options) {
            this.sanitizeFilters(options);
            this.orders.updateFilterParams(this.currentFilters);
            this.region.currentView.view.currentView.triggerMethod('orders:filtered');
        },
        _skipPrefetch: function (user_id) {
            var user = Radio.global.request('app:session').get('user');
            return user.get('role_slug') === 'operator' && user_id !== user.get('_id');
        }
    });
})();
