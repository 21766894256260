(function () {
    'use strict';

    var Radio = require('shim/radio')

    module.exports = Marionette.LayoutView.extend({
        template: require('templates/dashboard/dashboard'),
        tagName: 'div',
        className: 'section-box',
        behaviors: {
            Radio: {
                'dashboard:filters:changed': 'onFiltersChanged'
            }
        },

        regions: {
            content:          '.tab-content',
            taskFilters:      '.tasks-filters',
            tabs:             '#mega-tabs-bleri',

            contentfilters:   '.content-filters',
            distributePanel:  '.distribute-panel',
            pendingPanel:     '.pending-panel',
            validationPanel:  '.validation-panel',
            supervisorPanel:  '.supervisor-panel'
        },

        ui: {
            tab:            '[data-toggle="tab"]',
            tabs:           '.nav-tabs',
            refreshButton:  '.refresh-button'
        },

        events:{
          'click @ui.tab':            'onDashboardTabChanged',
          'click @ui.refreshButton':  'onRefreshButtonClick'
        },

        initialize: function () {
            this.filters = {};
        },

        /**
         * Refresh orders numbers
         */
        onOrdersAtributionChanged: function () {
            var self = this,
                counts = Radio.global.request('get:orders:counter');
            self.$('#distribute-badge').text(counts.totalUnassigned);
            self.$('#pending-badge').text(counts.totalPending);
            self.$('#validate-badge').text(counts.totalDone);
            self.$('#supervisor-badge').text(counts.totalSupervisor);
        },

        onApllyState: function () {
            Radio.global.trigger('filter:dashboard:tab:change', {
                state_id: Radio.global.request('app:states').dashboard.state_id
            });
        },

        onDashboardTabChanged: function(el){
            //Change Active Dashboard Tab
            Radio.global.command('dashboard:tab:changed', $(el.target).attr('aria-controls'));
        },

        /**
         * Send to Template: Number os orders by type
         *
         * @returns {{totalPending: *, totalOnGoing: (status.ongoing|{title, id}|number), totalDistribute: (status.unassigned|{title, id}|number), totalDone: (*|number), totalComplete: (*|number), totalSupervisor: (status.validated|{title, id}|number)}}
         */
        templateHelpers: function () {
            var self = this,
                counts = Radio.global.request('get:orders:counter');

            return {
                activeTab: Radio.global.request('dashboard:tab') || 'distribute',
                totalUnassigned: counts.totalUnassigned,
                totalPending: counts.totalPending,
                totalDone: counts.totalDone,
                totalSupervisor: counts.totalSupervisor
            }
        },

        /**
         * Update Dashboard-Tab counter on Filters Changed
         *
         * @param filters
         */
        onFiltersChanged: function (filters) {
            var self = this;
            self.filters = _.omit(filters, ['state_id']);
            self.onOrdersAtributionChanged();
        },

        onRefreshButtonClick: function (e) {
          e.preventDefault();
          Backbone.history.loadUrl( Backbone.history.fragment );
        },

    });
})();
