var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"row\" style=\"margin-bottom:5px; margin-top: -30px;\">\n            <div class=\"btn-group\" role=\"group\" style=\"margin: 0 35px 0 auto; float:right;\">\n                <button type=\"button\" class=\"btn button-default btn-left\" style=\"margin-right: 0; padding-left: 10px; padding-right: 5px;\">\n                    <i class=\"fa fa-angle-left\" aria-hidden=\"true\"></i>\n                </button>\n                <button type=\"button\" class=\"btn button-default btn-right\" style=\"margin-right: 0; padding-left: 10px; padding-right: 5px;\">\n                    <i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i>\n                </button>\n            </div>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=helpers.helperMissing, alias5="function", alias6=helpers.blockHelperMissing, buffer = 
  "                <div class=\"row row-padding\">\n                    <div class=\"col-md-1 details1\">\n                        <div class=\"background-details\">\n                            <div>\n                                <img src=\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.photo_url : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\" />\n                            </div>\n                            <p class=\"p1\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "</p>\n                            <p class=\"p2\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.group : stack1), depth0))
    + "</p>\n                        </div>\n                    </div>\n                    <div class=\"col-md-3 details2\">\n                        <div class=\"background-details\">\n                            <label>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.ots : depth0)) != null ? stack1.done_perc : stack1), depth0))
    + "<span>%</span></label>\n                            <div class=\"progress-div\">\n                                <div class=\"progress\">\n                                    <div class=\"progress-bar progress-bar-success\" role=\"progressbar\" aria-valuenow=\"4\" aria-valuemin=\"10\" aria-valuemax=\"20\"\n                                        style=\"width: "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.ots : depth0)) != null ? stack1.done_perc : stack1), depth0))
    + "%;\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.ots : depth0)) != null ? stack1.done : stack1), depth0))
    + "/"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.ots : depth0)) != null ? stack1.total_ot : stack1), depth0))
    + "</div>\n                                </div>\n                            </div>\n                            <div class=\"row row-details\">\n                                <div class=\"col-md-12\">\n";
  stack1 = ((helper = (helper = helpers.ot_done || (depth0 != null ? depth0.ot_done : depth0)) != null ? helper : alias4),(options={"name":"ot_done","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias5 ? helper.call(alias1,options) : helper));
  if (!helpers.ot_done) { stack1 = alias6.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "                                </div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-md-3 details3\">\n                        <div class=\"background-details\">\n                            <label>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.ots : depth0)) != null ? stack1.ongoing_perc : stack1), depth0))
    + "<span>%</span></label>\n                            <div class=\"progress-div\">\n                                <div class=\"progress\">\n                                    <div class=\"progress-bar progress-bar-warning\" role=\"progressbar\" aria-valuenow=\"4\" aria-valuemin=\"4\" aria-valuemax=\"20\"\n                                        style=\"width: "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.ots : depth0)) != null ? stack1.ongoing_perc : stack1), depth0))
    + "%;\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.ots : depth0)) != null ? stack1.ongoing : stack1), depth0))
    + "/"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.ots : depth0)) != null ? stack1.total_ot : stack1), depth0))
    + "</div>\n                                </div>\n                            </div>\n                            <div class=\"row row-details\">\n                                <div class=\"col-md-12\">\n";
  stack1 = ((helper = (helper = helpers.ot_ongoing || (depth0 != null ? depth0.ot_ongoing : depth0)) != null ? helper : alias4),(options={"name":"ot_ongoing","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias5 ? helper.call(alias1,options) : helper));
  if (!helpers.ot_ongoing) { stack1 = alias6.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "                                </div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-md-3 details4\">\n                        <div class=\"background-details\">\n                            <label>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.ots : depth0)) != null ? stack1.todo_perc : stack1), depth0))
    + "<span>%</span></label>\n                            <div class=\"progress-div\">\n                                <div class=\"progress\">\n                                    <div class=\"progress-bar progress-bar-danger\" role=\"progressbar\" aria-valuenow=\"4\" aria-valuemin=\"10\" aria-valuemax=\"20\"\n                                        style=\"width: "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.ots : depth0)) != null ? stack1.todo_perc : stack1), depth0))
    + "%;\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.ots : depth0)) != null ? stack1.todo : stack1), depth0))
    + "/"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.ots : depth0)) != null ? stack1.total_ot : stack1), depth0))
    + "</div>\n                                </div>\n                            </div>\n                            <div class=\"row row-details\">\n                                <div class=\"col-md-12\">\n";
  stack1 = ((helper = (helper = helpers.ot_todo || (depth0 != null ? depth0.ot_todo : depth0)) != null ? helper : alias4),(options={"name":"ot_todo","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias5 ? helper.call(alias1,options) : helper));
  if (!helpers.ot_todo) { stack1 = alias6.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "                                </div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-md-2 details5\">\n                        <div class=\"background-details\">\n                            <h5>Próximos Trabalhos </h5>\n";
  stack1 = ((helper = (helper = helpers.ot_future || (depth0 != null ? depth0.ot_future : depth0)) != null ? helper : alias4),(options={"name":"ot_future","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias5 ? helper.call(alias1,options) : helper));
  if (!helpers.ot_future) { stack1 = alias6.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                        </div>\n                    </div>\n                </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.photo_url : stack1), depth0))
    + " ";
},"6":function(container,depth0,helpers,partials,data) {
    return " https://i.imgur.com/FrN6054.png ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                                        <p class=\"p1-order\">\n                                            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.code : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                                            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                                        </p>\n                                        <p class=\"p2-order\">"
    + container.escapeExpression(((helper = (helper = helpers.work_type || (depth0 != null ? depth0.work_type : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"work_type","hash":{},"data":data}) : helper)))
    + "\n                                            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.frequency : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                                            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.equipment : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                                        </p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.code || (depth0 != null ? depth0.code : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"code","hash":{},"data":data}) : helper)))
    + " ";
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return " "
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data}) : helper)));
},"13":function(container,depth0,helpers,partials,data) {
    var helper;

  return " ("
    + container.escapeExpression(((helper = (helper = helpers.frequency || (depth0 != null ? depth0.frequency : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"frequency","hash":{},"data":data}) : helper)))
    + ") ";
},"15":function(container,depth0,helpers,partials,data) {
    var helper;

  return "- "
    + container.escapeExpression(((helper = (helper = helpers.equipment || (depth0 != null ? depth0.equipment : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"equipment","hash":{},"data":data}) : helper)));
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                                <p class=\"p1-order\">\n                                    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.code : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                                    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                                </p>\n                                <p class=\"p2-order\">"
    + container.escapeExpression(((helper = (helper = helpers.work_type || (depth0 != null ? depth0.work_type : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"work_type","hash":{},"data":data}) : helper)))
    + "\n                                    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.frequency : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                                    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.equipment : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                                </p>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, buffer = 
  "<div class=\"dashboard4\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.isAuto : depth0),"off",{"name":"eq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"row dash4-header\">\n        <div class=\"col-md-1\">\n        </div>\n        <div class=\"col-md-3\">\n            <h4>Realizadas</h4>\n        </div>\n        <div class=\"col-md-3\">\n            <h4>Em curso </h4>\n        </div>\n        <div class=\"col-md-5\">\n            <h4>Por Realizar</h4>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-md-12 col-padding\">\n";
  stack1 = ((helper = (helper = helpers.page_collection || (depth0 != null ? depth0.page_collection : depth0)) != null ? helper : alias2),(options={"name":"page_collection","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.page_collection) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </div>\n    </div>\n</div>\n\n<div id=\"helpText\">\n    <p>Visualização no máximo de 5 OTs mais recentes à data atual.</p>\n    <ul>\n        <li>OTs em Curso: OTs prevista para o dia atual e OTs em Curso.</li>\n        <li style=\"padding-top:5px;\">OTs por Realizar: 1ª coluna as OTs em atraso (ao dia atual) a 2ª coluna Próximos Trabalhos (dias seguintes).</li>\n    </ul>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}