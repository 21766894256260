(function () {
  'use strict';

  var api = require('shim/api');

  module.exports = Backbone.Model.extend({
    idAttribute: '_id',
    urlRoot: api.urlRoot + '/order_typifications',

    validation: {
      title: {
        required: true,
        msg: 'Insira uma descrição.'
      },
      typified_group: {
        required: true,
        msg: 'Insira o nome de uma categoria/forma de agrupar tipificações.'
      },
      work_type_id: {
        required: true,
        msg: 'Selecione um tipo de trabalho.'
      },
      zone_id: {
        required: true,
        msg: 'Selecione uma zona.'
      },
      equipment_family_id: {
        required: true,
        msg: 'Selecione uma zona.'
      }
    }
  });
})();
