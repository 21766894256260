(function () {
  'use strict';

  var api   = require('shim/api');

  module.exports = Backbone.Model.extend({
    urlRoot:     api.urlRoot + '/notifications',
    idAttribute: '_id',

    store: function () {
       return ['notification', this.get('_id')].join('/');
     },

     read: function () {
       return this.get('state') !== 'read' ? this._update('read') : false;
     },

     discard: function () {
       if (this.get('state') === 'discard') {
        return;
       }
       this.save({notification: {transition: 'discard'}});
     },

     _update: function (transition, options) {
       this.set({ state: transition });

       var attrs = { notification: { transition: transition } };

       options             = options || {};
       options.data        = JSON.stringify(attrs);
       options.contentType = 'application/json';

       return Backbone.Model.prototype.save.call(this, attrs, options);
     }
  });
})();
