(function () {
  'use strict';

  var Radio = require('shim/radio');

  module.exports = Marionette.ItemView.extend({
    template: require('templates/settings/users-list-item'),
    tagName: 'tr',

    ui: {
      resetPassword:        '.reset-password',
      userAccessManagenent: '.user-access'
    },

    events: {
      'click @ui.resetPassword':        'onResetPasswordClicked',
      'click @ui.userAccessManagenent': 'onUserAcessClicked'
    },

    modelEvents: {
      "change": "render"
    },

    onResetPasswordClicked: function (event) {
      event.preventDefault();

      this.model.resetPassword().done(function(){
        Radio.global.command('alerts:show', {
          msg: 'Password alterada com sucesso'
        });
      }).fail(function(){
        Radio.global.command('alerts:show', {
          msg: 'Não foi possível alterar a passord. Volte a tentar.',
          type: 'danger'
        });
      });
    },

    onUserAcessClicked: function (event) {
      var checkbox_state = $(".checkbox-"+this.model.get('_id')).is(':checked')
      this.model.set({ access: checkbox_state})

      if (this.model.isValid(true)) {
          Radio.global.trigger('user:update:access', this.model);
      }
    },

  });
})();
