(function () {
    'use strict';

    var Radio = require('shim/radio'),
        OrderModel = require('models/order');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/main/notifications-details'),
        tagName: 'div',
        className: 'section-box',

        ui: {
          viewOrder: '.view-order',
          editOrder: '.edit-order'
        },

        events: {
          'click @ui.viewOrder': 'onViewOrderClicked',
          'click @ui.editOrder': 'onEditOrderClicked'
        },

        templateHelpers: function () {
            return {
              model: this.model
            }
        },

        onViewOrderClicked: function () {
          var orderId = this.model.get('order_id');
          this.fetchAndNavigate(`/orders/${orderId}`, orderId);
        },

        onEditOrderClicked: function () {
          const orderId = this.model.get('order_id');
          this.fetchAndNavigate(`/orders/edit/${orderId}`, orderId);
        },

        fetchAndNavigate: function (route, orderId) {
          if ( !orderId ) { return }

          var resource = 'router:orders:navigate',
              orders   = Radio.store.request('get:orders');

          if ( orders.findWhere({id: orderId}) ){
            return Radio.global.command(resource, route, { trigger: true });
          }

          Radio.global.trigger('anim-loading:show');
          var order = new OrderModel({id: orderId});
          order.fetch().then(function () {
            orders.add(order);
            Radio.global.command(resource, route, { trigger: true });
            Radio.global.trigger('anim-loading:hide');
          });
        }
    });
})();
