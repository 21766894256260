(function () {
    'use strict';

    var Radio = require('shim/radio'),
        methods = {
            create: 'save',
            update: 'save'
        };

    var OfflineQueue = function () {
        this.queue = Radio.store.request('load:queue', this.modelIt()) || [];
        Radio.global.comply('queue:unqueue:request', this.unqueueRequest, this);
        Radio.global.comply('queue:push:request', this.push, this);
        Radio.global.reply('queue:pop:request', this.pop, this);
        Radio.global.reply('queue:size', this.count, this);
    };

    OfflineQueue.prototype = {
        count: function () {
            return this.queue.length;
        },

        push: function (req) {
            if (req.store === 'orders') {
                decorateOrdersRequest(req);
            }

            if (req.store === 'tasks') {
                let duplicatedReqIndex = duplicatedTaskIndexOnQueue(req);
                if (duplicatedReqIndex >= 0) {
                    this.queue.splice(duplicatedReqIndex, 1)
                }
            }

            var request = {
                type: req.method,
                method: getMethod(req.method),
                model: {
                    type: req.store,
                    attrs: req.storableData
                }
            };

            this.queue.push(request);
            //Save Request in LocalStorage Queue
            Radio.store.command('save:queue', this.modelIt());
            Radio.global.trigger('request:pushed');
        },

        //Updates Queue?
        pop: function () {
            //Remove first array element and put it into variable
            var request = this.queue.shift();

            //Save new shifted array and return shifted element
            if (request !== undefined) {
                Radio.store.command('save:queue', this.modelIt());
                return request;
            }

            //If no queue elements
            return null;
        },

        unqueueRequest: function (request) {
            this.queue.unshift(request);
            Radio.store.command('save:queue', this.modelIt());
        },

        modelIt: function () {
            return {
                store: 'queue',
                toJSON: function () {
                    return this.queue;
                }.bind(this)
            };
        }
    };

    function getMethod(method) {
        return methods[method];
    }

    function generateRandomId(x) {
        return 'tmp-' + (new Date()).valueOf().toString() + x;
    }

    function decorateOrdersRequest(req) {
        if (req.method === 'create' && !req.model.get('id')) {
            var rndID = generateRandomId("-order");

            //Set tmp id for both storableData & current model
            req.storableData.id = rndID;
            req.model.set('id', rndID);

            //Set tmp id for storableData
            _.each(req.storableData.order.tasks_attributes, function (e, i) {
                e.id = generateRandomId("-task-" + i);
            });
        }
    }

    function duplicatedTaskIndexOnQueue(req) {
      // If there is already queued an element related to the same task,
      // the previous item will be replaced by the current, to avoid duplications
      var enqueuedTasks = Radio.store.request('load:queue')

      if ( enqueuedTasks && enqueuedTasks.length>0 ){
          for (var index=0; index < enqueuedTasks.length; index++) {
              if (enqueuedTasks[index].model.attrs.id === req.storableData.id) {
                  return index;
                  break;
              }
          }
      }
    }

    module.exports = OfflineQueue;

})();
