(function () {
    'use strict';

    var api = require('shim/api'),

        WorkType = require('models/work-type');

    module.exports = Backbone.Collection.extend({
        model: WorkType,
        url: api.urlRoot + '/work_types',
        store: 'work_types',

        parse: function (response) {
            return response.work_types;
        }
    });
})();
