/* global module, require */
(function () {
  'use strict';

  var Radio = require('shim/radio');

  module.exports = Marionette.LayoutView.extend({
    template: require('templates/materials/material-orders/material-order'),
    tagName: 'div',
    className: 'section-box',

    regions: {
      view:     '.view',
      requests: '.requests'
    },

    ui: {
      deleteMaterialOrder: '.delete-material-order',
      printMaterialOrder: '.print-material-order'
    },

    events: {
      'click @ui.deleteMaterialOrder':  'onDeleteMaterialOrder',
      'click @ui.printMaterialOrder':   'onPrintMaterialOrder'
    },

    initialize: function (options) {
      options = options || {};
      this.model = options.model;
    },

    templateHelpers: function () {
      return {
        noMaterials: _.isEmpty(this.model.get('materials'))
      }
    },

    onDeleteMaterialOrder: function (event) {
      event.preventDefault();
      Radio.global.trigger('anim-loading:show');

      this.model.destroy({wait:true}).then(function () {
        Radio.global.command('router:materials:navigate', 'material_orders/');
        Radio.global.trigger('anim-loading:hide');
      })
    },

    onPrintMaterialOrder: function () {
      event.preventDefault();
      Radio.global.trigger('anim-loading:show');

      this.model.printDetails().done(function(data){
        Radio.global.trigger('anim-loading:hide');
        window.open(data.url);
      });
    }
  });
})();
