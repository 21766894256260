'use strict';

var Radio = require('shim/radio');

//Check for Operator
Handlebars.registerHelper("isOperator", function(options) {
    return Radio.global.request('app:session').get('user').get('role_id') === 1
        ? options.fn(this) : options.inverse(this);

});

Handlebars.registerHelper("isNotOperator", function(options) {
    return Radio.global.request('app:session').get('user').get('role_id') !== 1
        ? options.fn(this) : options.inverse(this);
});

//Check for Supervidor
Handlebars.registerHelper("isSupervisor", function(options) {
    return Radio.global.request('app:session').get('user').get('role_id') === 2
        ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper("isNotSupervisor", function(options) {
    return Radio.global.request('app:session').get('user').get('role_id') !== 2
        ? options.fn(this) : options.inverse(this);
});

//Check for Manager
Handlebars.registerHelper("isManager", function(options) {
    return Radio.global.request('app:session').get('user').get('role_id') === 3
        ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper("isNotManager", function(options) {
    return Radio.global.request('app:session').get('user').get('role_id') !== 3
        ? options.fn(this) : options.inverse(this);
});

//Check for Dashboard
Handlebars.registerHelper("isDashboard", function(options) {
    return Radio.global.request('app:session').get('user').get('role_id') === 4
        ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper("isNotDashboard", function(options) {
    return Radio.global.request('app:session').get('user').get('role_id') !== 4
        ? options.fn(this) : options.inverse(this);
});

//Check for Solicitant
Handlebars.registerHelper("isSolicitant", function(options) {
    return Radio.global.request('app:session').get('user').get('role_id') === 5
        ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper("isNotSolicitant", function(options) {
    return Radio.global.request('app:session').get('user').get('role_id') !== 5
        ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper("isNotOperatorAndSolicitant", function(options) {
    return Radio.global.request('app:session').get('user').get('role_id') !== 1 && Radio.global.request('app:session').get('user').get('role_id') !== 5
        ? options.fn(this) : options.inverse(this);
});
