(function () {
    'use strict';

    var api = require('shim/api');

    module.exports = Backbone.Model.extend({
        urlRoot: api.urlRoot + '/dashboards2',
        defaults: {
            number_of_days: 0.0,
            ot_done: 0,
            semaphore: ''
        }
    });
})();
