(function () {
    'use strict';

    var BaseRouter = require('routers/base-router');

    module.exports = BaseRouter.extend({

        forbidden: {
            '':                 ['solicitant', 'admin', 'operator'],
            'dashboard(/)':     ['solicitant', 'admin', 'operator'],
            'tasks(/)':         ['admin'],
            'issues(/)':        ['admin'],
            'notifications(/)': [],
            'operators(/)':     ['solicitant', 'admin', 'operator'],
            'reports(/)':       ['solicitant', 'admin', 'operator', 'foreman'],
            'help(/)':          []
        },

        appRoutes: {
            '':                 'showDashboard',
            'dashboard(/)':     'showDashboard',
            'tasks(/)':         'showTasks',
            'issues(/)':        'showIssues',
            'notifications(/)': 'showNotifications',
            'operators(/)':     'showOperators',
            'reports(/)':       'showReports',
            'help(/)':          'showHelp'
        }
    });
})();
