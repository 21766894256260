(function () {
  'use strict';

  var Radio = require('shim/radio'),
      Compress = require('compress.js');

  require('bootstrap-fileinput');
  require('bootstrap-fileinput/js/locales/pt');
  require('bootstrap-fileinput/themes/explorer/theme');

  require('select2');

  module.exports = Marionette.LayoutView.extend({
    template: require('templates/orders/order-create'),

    regions: {
      equipments: '#equipments'
    },
    ui: {
      'assign': '.assign-ot',
      'typifications': '.typifications',
      'select2': '.select2',
      'startDate': '#start_date_preview',
      'endDate': '#end_date_preview',
      'fileInput': '[type="file"]',
      'preview': '.fileinput-image-preview',
      'modalContent': '.modal-content'
    },
    behaviors: {
      OrdersAssign: {
        container: '.assign-container'
      },
      DateChange: {
        usedDateHelper: true
      }
    },

    events: {
      'change [name="typification"]': 'onTypificationChange',
      'change #typifications': 'onTypificationSelected',
      'change #work_type': 'onWorktypeSelected',
      'click .do-order': 'onDoClicked',
      'submit': 'onSubmit',
      'click .units-na': 'onCkNaClicked',
      'click .units-ck': 'onCkClicked',
      'click .kv-file-zoom': 'putImageModal'
    },

    //Initializers
    initialize: function (options) {
      options = options || {};
      Backbone.Validation.bind(this);
      this.typifications = options.typifications.getAllActive();
      this.hasTypifications = (Radio.global.request('app:session').get('user').not('solicitant') && options.typifications.length > 0);
      this.workTypes = options.work_types;
      this.zones = options.zones;
      this.userGroups = options.user_groups;
      this.users = options.users;
    },
    onBeforeDestroy: function () {
      Backbone.Validation.unbind(this);
    },

    onRender: function () {
      //Default Date Attribution [Today]
      this.ui.startDate.val(this._getToday());
      this.ui.endDate.val(this._getToday());

      //Render Image modal if OT has image
      $('#helperModal .modal-dialog').empty().append(this.ui.modalContent)
    },

    //Show Events
    onShow: function () {
      var self = this
      // Block fields if typification
      if (this.hasTypifications){
        $('[typification]').addClass('content-not-editable');
      }

      // Default zone if solicitation
      if (Radio.global.request('app:session').get('user').is('solicitant')) {
        $("#rootzone").val($("#rootzone option:eq(1)").val());
      }

      //Select2 all select fields
      this.ui.select2.select2();

      //Bootstrap fileinput field
      this.ui.fileInput.fileinput({
        language: 'pt',
        theme: 'explorer',
        showUpload: false,
        previewFileType: 'any',
        layoutTemplates: {
          btnBrowse: '<div tabindex="500" class="btn btn-default btn-file"{status}>{icon}{label}</div>'
        }
      }).on('change', function (ev) {
        const compress = new Compress();
        compress.compress([ev.target.files[0]], {
          size: 0.2, // the max size in MB, defaults to 2MB
          quality: 1, // the quality of the image, max is 1
          maxWidth: 600, // the max width of the output image;
          resize: true // defaults to true, set false if you do not want to resize the image width and height
        }).then(data => {
          $('.file-preview-image').attr('src', data[0].prefix + data[0].data);
          self.model.set({image: data[0].prefix + data[0].data, has_image: true});
        });
      });

      $('.fileinput-remove').on('click', function(event) {
        self.model.set({image: null, has_image: false});
      });
    },

    //Events And Triggers
    onCkNaClicked: function () {
      $(".units-ck").prop('disabled', $(".units-na").is(":checked"));
    },

    onCkClicked: function () {
      $(".units-na").prop('disabled', $(".units-ck:checked").length !== 0);
    },

    onValidationSuccess: function () {
      $('body').animate({ scrollTop: 0 }, 500);
    },

    onTypificationChange: function () {
      this.ui.typifications.toggle();
      $('[typification]').toggleClass('content-not-editable')
    },

    onTypificationSelected: function (ev) {
      var selected = parseInt($(ev.currentTarget).find('option:selected').val(), 10),
      isSelected = selected > -1,
      typification = this.typifications.get(selected),
      title = isSelected ? typification.get('title') : '',
      work_type = isSelected ? typification.get('work_type').id : -1,
      zone = isSelected ? typification.get('zone').id : -1,
      equipment = isSelected ? typification.get('equipment') : null,
      equipment_family = isSelected ? typification.get('equipment_family') : null;

      $('#work_type').val(-1);
      $('#rootzone').val(-1);
      $('#equipment_families').val(-1);

      $('#title').val(title).text(title);
      $('#work_type').val(work_type).trigger('change');
      $('#rootzone').val(zone);
      if (equipment_family != null)
      $('#equipment_families').val(equipment_family.id);

      //Selectify Elements Again
      this.ui.select2.select2();

      $('#start_date_preview').val(this._getToday());
      if ($('#end_date_preview').length > 0)
      $('#end_date_preview').val(this._getToday());

      Radio.global.trigger('typification:selected', equipment, equipment_family);
    },

    onWorktypeSelected: function (ev) {
      var id = parseInt($(ev.currentTarget).find('option:selected').val(), 10),
          workType = Radio.store.request('get:workTypes').find({ _id: id}).get('title');

      if (_.some(['otin', 'reventiv'], _.include, workType)) {
        this.$('#priority').parent().css('display', 'none')
      } else{
        this.$('#priority').parent().css('display', '')
      }
    },

    _getToday: function () {
      var now = new Date();
      var day = ("0" + now.getDate()).slice(-2);
      var month = ("0" + (now.getMonth() + 1)).slice(-2);

      return now.getFullYear() + '-' + (month) + '-' + (day);
    },

    //Submit Form
    onSubmit: function (ev) {
      ev.preventDefault();
      $('.create-order').attr('disabled', 'disabled');
      $('.do-order').attr('disabled', 'disabled');

      this.triggerValidation('view:create:order', 'create');
    },
    onDoClicked: function (ev) {
      ev.preventDefault();
      $('.create-order').attr('disabled', 'disabled');
      $('.do-order').attr('disabled', 'disabled');

      this.triggerValidation('view:create:order', 'do');
    },
    onUndoTrigger: function () {
      $('.create-order').removeAttr('disabled');
      $('.do-order').removeAttr('disabled');
    },

    triggerValidation: function (ev, method) {
      var self = this,
      data = _.extend({}, self.$el.serializeObject(), {
        order_category_id: Radio.global.request('category:id', 'work_order'),
        order_category_slug: 'work_order'
      });

      Radio.global.trigger(ev, data, method);
    },

    putImageModal: function () {
      $('.file-zoom-content').attr('src', $('.file-preview-image').attr('src'))
    },

    //Template Helpers
    templateHelpers: function () {
      var typifications = this.hasTypifications ? this.typifications.toJSON() : false,
      typificationsList = this.hasTypifications ? this.composeList() : false,
      user = Radio.global.request('app:session').get('user');

      return {
        priorities: Radio.global.request('app:priorities'),
        typifications: typifications,
        typificationsList: typificationsList,
        work_types: this.workTypes.toJSON(),
        zones: this.zones.toJSON(),
        showTypifications: this.typifications.length > 0 && user.not('solicitant')
      };
    },

    composeList: function () {
      let list = _.groupBy(this.typifications.toJSON(), 'typified_group'),
      orderedList = {};

      Object.keys(list).sort().forEach(function(key) {
        orderedList[key] = list[key];
      });

      return orderedList
    }
  });
})();
