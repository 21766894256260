(function () {
    'use strict';

    var config = require('shim/config'),
        api = require('shim/api'),
        Radio = require('shim/radio'),
        Unit = require('models/unit');

    module.exports = Backbone.Model.extend({
        store: 'tasks',
        urlRoot: function () {
            return [api.urlRoot, 'orders', this.get('order_id'), 'tasks'].join('/')
        },

        defaults: { unit: new Unit(), interventions: [], state: 'pending' },

        whitelist: [
            'notes',
            'validation_notes',
            'unit_id',
            'image_before',
            'image_after',
            'done_status',
            'interventions_attributes',
            'readings_attributes',
            'transition'
        ],

        //Class initializer
        initialize: function (attr) {
            this._fillState(attr.state);
            var unit = attr.unit ? new Unit(attr.unit) : Radio.store.request('get:units').get(attr.unit_id);
            this.set('unit', unit);
        },

        //Save Task
        save: function (attrs, options) {
            options = options || {};
            attrs = attrs || _.extend({}, this.attributes);

            if (options.offline_save) {
              attrs = _.extend(attrs, this.attributes.task);
            } else {
              //Handling images that where not loaded in offline mode
              if (attrs.has_image_before && !attrs.image_before) {
                  this.whitelist.splice((this.whitelist.indexOf('image_before')),1)
              }
              if (attrs.has_image_after && !attrs.image_after) {
                  this.whitelist.splice((this.whitelist.indexOf('image_after')),1)
              }
              attrs = _.pick(attrs, this.whitelist);
              attrs.unit_id = this.get('unit').get('id');
              attrs = { task: attrs };
            }

            options.data = JSON.stringify(attrs);
            options.contentType = 'application/json';

            return Backbone.Model.prototype.save.call(this, attrs, options);
        },

        //Parse Task
        parse: function (response) {
            if (response.state) {
                var status = config.getStatus(response.state || 'pending');
                response.state_title = status.title;
                response.state_id = status.id;
            }
            response.unit = new Unit(response.unit);
            return response;
        },

        //Convert task to JSON (prevent UNITS not being in JSON Format)
        toJSON: function () {
            var attributes = _.clone(this.attributes);
            _.omit(attributes, 'task');
            attributes.unit = this.get('unit').toJSON();
            return attributes;
        },

        //Check If Task Has Readings
        hasVerifications: function () {
            return !_.isEmpty(this.get('readings'));
        },

        //Task Submit Transition
        _transitionTask: function (order_id, transition) {
            this.set({
                order_id: order_id,
                transition: transition,
                state: config.fromTransition(transition)
            }).offlineStateUpdate();

            return this;
        },

        // Filter
        filterByDoneStatus: function (filters) {
            return _.contains(filters, this.get('done_status'));
        },

        // Update Task State (For Offline Update)
        cleanTmpAttributes: function () {
            this.set({ interventions_attributes: [] });
            this.unset('task[image_before]');
            this.unset('task[image_after]');
        },

        offlineStateUpdate: function () {
            //Offline Interventions Parse
            this.set({ interventions: this.get('interventions_attributes') });

            //Fill State
            this._fillState(this.get('state'));
        },

        _fillState: function (state) {
            var status = config.getStatus(state || 'pending');
            this.set({
                state_title: status.title,
                state_id: status.id
            });
        },

        isState (state) {
            return this.get('state') === state;
        },

        _some: function (states) {
            var self = this;
            return _.some(self.models, function (task) {
                return _.contains(states, task.get('state'))
            });
        },

        _every: function (states) {
            var self = this;
            return _.every(self.models, function (task) {
                return _.contains(states, task.get('state'))
            })
        }

    });

})();
