(function () {
    'use strict';

    var status = {
            unassigned: {
                title: 'Não atribuida',
                id: 0
            },

            pending: {
                title: 'Por realizar',
                id: 1
            },

            ongoing: {
                title: 'Em curso',
                id: 2
            },

            complete: {
                title: 'Completa',
                id: 2
            },

            done: {
                title: 'Terminada',
                id: 3
            },

            validated: {
                title: 'Validada',
                id: 4
            },

            verified: {
                title: 'Verificada',
                id: 5
            },

            read: {
                title: 'Lida',
                id: 6
            },

            unread: {
                title: 'Não lida',
                id: 7
            }
        },
        doneStatus = {
            complete: {
                title: "Realizada",
                id: 0
            },
            unrealized: {
                title: "Não realizada",
                id: 1
            },
            uncompleted: {
                title: "Não Verifiquei Tudo",
                id: 2
            }
        },
        transitions = {
            save_order: 'ongoing',
            submit_order: 'done',
            validate: 'validated',
            no_validate: 'ongoing',
            verify: 'verified',
            no_verify: 'done'
        };

    module.exports = {
        getStatus: function (id) {
            if (status.hasOwnProperty(id)) {
                return status[id];
            }

            return {};
        },

        isToDo: function(id){
            var notDoneIDS = [status.pending.id, status.ongoing.id, status.complete.id];
            return _.contains(notDoneIDS, id);
        },

        getOrderStatuses: function () {
            return [
                status.pending,
                status.ongoing,
                status.done
            ]
        },
        getTaskFilters: function () {
            return [
                {
                    title: 'Todos',
                    id: -1
                },
                {
                    title: 'Por realizar',
                    id: 0
                },
                {
                    title: 'Em curso',
                    id: 1
                },
                {
                    title: 'Terminados',
                    id: 2,
                    text: 'Terminada,Incompleta'
                },
                {
                    title: 'Não realizados',
                    id: 3,
                    text: 'Não realizada'
                }
            ]
        },
        fromTransition: function (transition) {
            if (transition && transitions.hasOwnProperty(transition)) {
                return transitions[transition];
            }

            return false;
        }
    };
})();
