(function () {
    'use strict';

    var EquipmentFamily = require('models/equipment-family'),
        api = require('shim/api');

    module.exports = Backbone.Collection.extend({
        model: EquipmentFamily,
        url: api.urlRoot + '/equipment_families',
        store: 'equipmentFamilies',

        parse: function (response) {
            return response.equipment_families;
        }
    });
})();
