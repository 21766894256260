var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "        <option "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,(depths[1] != null ? depths[1].status : depths[1]),{"name":"eq","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, buffer = 
  "<div class=\"box\" style=\"margin:20px\">\n  <header class=\"page-header\" style=\"margin:20px;margin-bottom:60px\">\n    <h3>Encomenda de Materiais N.º <b>"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "</b></h3>\n  </header>\n\n  <form action=\"#\">\n    <div class=\"form-group\" style=\"margin:20px 20px\">\n      <label for=\"status\">Estado:</label>\n      <select name=\"status\" class=\"form-control status\" style=\"width:200px\">\n";
  stack1 = ((helper = (helper = helpers.statusOptions || (depth0 != null ? depth0.statusOptions : depth0)) != null ? helper : alias2),(options={"name":"statusOptions","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.statusOptions) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </select>\n    </div>\n\n    <div class=\"form-group\" style=\"margin:10px 20px\">\n      <label for=\"company\">Fornecedor:</label>\n      <input type=\"text\" name=\"company\" id=\"company\" value=\""
    + alias4(((helper = (helper = helpers.company || (depth0 != null ? depth0.company : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"company","hash":{},"data":data}) : helper)))
    + "\"\n             placeholder=\"Inserir nome do fornecedor\" style=\"text-align:left;width:200px;font-size:15px;height:35px\">\n    </div>\n\n    <div class=\"form-group closed-date-container\" style=\"margin:20px 20px\">\n      <label for=\"closed_date\">Data de Entrega:</label>\n      <input type=\"date\" name=\"closed_date\" class=\"closed_date\" value=\""
    + alias4((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.closed_date : depth0),{"name":"moment","hash":{"format":"YYYY-MM-DD"},"data":data}))
    + "\"\n             placeholder=\"Inserir data\" style=\"width:200px;font-size:15px;height:35px\">\n    </div>\n\n    <div class=\"form-group due-date-container\" style=\"margin:20px 20px\">\n      <label for=\"due_date\">Data Prevista de Entrega:</label>\n      <input type=\"date\" name=\"due_date\" id=\"due_date\" value=\""
    + alias4((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.due_date : depth0),{"name":"moment","hash":{"format":"YYYY-MM-DD"},"data":data}))
    + "\"\n             placeholder=\"Inserir data\" style=\"width:200px;font-size:15px;height:35px\">\n    </div>\n\n    <div class=\"form-group\" style=\"margin:10px 20px\">\n      <label for=\"notes\">Notas:</label>\n      <textarea name=\"notes\" id=\"notes\" placeholder=\"Introduza notas\"\n      rows=\"3\" style=\"width:50%;font-size:15px\">"
    + alias4(((helper = (helper = helpers.notes || (depth0 != null ? depth0.notes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"notes","hash":{},"data":data}) : helper)))
    + "</textarea>\n    </div>\n\n    <div class=\"form-group\" style=\"margin:10px 20px\">\n      <label for=\"purchase_order\">Ordem de Compra:</label>\n      <input type=\"text\" name=\"purchase_order\" id=\"purchase_order\" value=\""
    + alias4(((helper = (helper = helpers.purchase_order || (depth0 != null ? depth0.purchase_order : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"purchase_order","hash":{},"data":data}) : helper)))
    + "\"\n             placeholder=\"Inserir ordem de compra\" style=\"text-align:left;width:200px;font-size:15px;height:35px\">\n    </div>\n\n    <div class=\"form-group\" style=\"margin:10px 20px\">\n      <label for=\"invoicing_data\">Dados de Faturação:</label>\n      <textarea name=\"invoicing_data\" id=\"title\" placeholder=\"Introduza dados de faturação\"\n                rows=\"3\" style=\"width:50%;font-size:15px\">"
    + alias4(((helper = (helper = helpers.invoicing_data || (depth0 != null ? depth0.invoicing_data : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"invoicing_data","hash":{},"data":data}) : helper)))
    + "</textarea>\n    </div>\n  </form>\n\n  <hr style=\"margin-top:40px\">\n\n  <div style=\"margin:10px 20px\">\n      <b>Pedido por</b>: "
    + alias4(((helper = (helper = helpers.requester || (depth0 != null ? depth0.requester : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"requester","hash":{},"data":data}) : helper)))
    + "\n  </div>\n\n  <div style=\"margin:10px 20px\">\n      <b>Criado em</b>: "
    + alias4((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.created_at : depth0),{"name":"moment","hash":{"format":"DD-MM-YYYY HH:mm"},"data":data}))
    + "\n  </div>\n\n  <div style=\"margin:10px 20px\">\n      <b>Última atualização em</b>: "
    + alias4((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.updated_at : depth0),{"name":"moment","hash":{"format":"DD-MM-YYYY HH:mm"},"data":data}))
    + "\n  </div>\n</div>\n\n<div style=\"display:flex;\">\n  <a href=\"#material_orders\" style=\"margin-left:20px\">\n    <button class=\"button button-primary\">\n      <i class=\"fa fa-arrow-left\" aria-hidden=\"true\"></i>\n      <span>Voltar</span>\n    </button>\n  </a>\n  <a style=\"margin-left:10px\">\n    <button class=\"button button-success save-material-order\">\n      <i class=\"fa fa-floppy-o\" aria-hidden=\"true\"></i>\n      <span>Gravar</span>\n    </button>\n  </a>\n</div>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}