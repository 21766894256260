(function () {
    'use strict';

    var Location = require('models/location'),
        config = require('shim/config'),
        api = require('shim/api');

    module.exports = Backbone.Collection.extend({
        model: Location,
        url: api.urlRoot + '/locations'
    });
})();
