(function () {
    'use strict';

    var Radio       = require('shim/radio'),
        IssueModel  = require('models/issue');

    module.exports = Marionette.ItemView.extend({
        template:   require('templates/dashboard/dashboard-item'),
        tagName:    'div',
        className:  'panel panel-default',

        ui: {
            'atag':               '.paneltitle',
            'bodytag':            '.panelbody',
            validateOrder:        '.validate-on-dashboard',
            verifyOrder:          '.verify-on-dashboard',
            verifyAndCreateIssue: '.verify-and-issue-on-dashboard'
        },

        events: {
            'click input.order':              'onClick',
            'click @ui.validateOrder':        'onValidateOrderClicked',
            'click @ui.verifyOrder':          'onVerifyOrderClicked',
            'click @ui.verifyAndCreateIssue': 'onVerifyAndCreateIssueClicked'
        },

        onClick: function (ev) {
            if ($('#' + ev.target.id).is(":checked"))
                $('.' + ev.target.id).prop('checked', true);
            else
                $('.' + ev.target.id).prop('checked', false);
        },
        templateHelpers: function () {
            var self = this;
            return {
                ot_type: self.options.ottype,
                items: function () {
                    var items = this.map(function (e) {
                        return _.tap(e.toJSON(),
                            function (a) {
                                a.hasNotesInUnits = e.hasNotesInUnits();
                                a.hasNotesInOrderOrInUnits = e.hasNotesInOrderOrInUnits();
                                a.hasMaterials = e.hasMaterials();
                                a.hasNotesAndMaterials = a.hasNotesInUnits && a.hasMaterials;
                                a.spentTime = e.spentTime();
                                a.getUsers = e.getUsers();
                                a.formatNotes = e.formatNotes();
                                a.isSelected = _.contains(self.options.selected, e.get('id'));
                                a.isClosedAndHasMaterialIssues = e.isClosedAndHasMaterialIssues();
                                a.isDoneValidatedOrVerified = e.isDoneValidatedOrVerified();
                                a.notDone = ['pending', 'ongoing', 'complete'].includes(e.get('state'));
                                a.priority = _.find(Radio.global.request('app:priorities'), { 'value': e.get('priority') })
                            });
                    });
                    return items;
                },
                groupDate: function () {
                    return this.date;
                },
                isLate: function () {
                    //noinspection JSUnresolvedFunction
                    return moment().isAfter(this.date, 'day');
                },
            };
        },
        /**
         * Multiple Validation and Verification
         */
        onValidateOrderClicked: function (e) {
            Radio.global.trigger('anim-loading:show')
            e.preventDefault();
            let order = Radio.store.request('get:orders').get(e.currentTarget.dataset.id);
            return order.validateOrder({
                success: function(response){
                 this.submitAction(response, "validate");
                }.bind(order),

                error: function(xhr) {
                 this.tryAgain();
                }.bind(order)
            });
        },

        onVerifyOrderClicked: function (e) {
            Radio.global.trigger('anim-loading:show')
            e.preventDefault();
            let order = Radio.store.request('get:orders').get(e.currentTarget.dataset.id);
            return order.verifyOrder({
                success: function(response){
                    this.submitAction(response, "supervisor");
                }.bind(order),

                error: function(xhr) {
                    this.tryAgain();
                }.bind(order)
            });
        },

        onVerifyAndCreateIssueClicked: function (e) {
            Radio.global.trigger('anim-loading:show')
            e.preventDefault();
            let order = Radio.store.request('get:orders').get(e.currentTarget.dataset.id);

            if ( order.get('notes') ) {
                let newIssue = new IssueModel({
                    description:  order.get('notes'),
                    is_closed:    false,
                    order_id:     order.get('id'),
                    rootzone_id:  order.get('rootzone_id'),
                    user_id:      Radio.global.request('app:session').get('user').id
                });
                newIssue.save().then( _ => Radio.store.request('get:issues').add(newIssue) );
            }

            return order.verifyOrder({
                success: function(response){
                    this.submitAction(response, "supervisor");
                }.bind(order),

                error: function(xhr) {
                    this.tryAgain();
                }.bind(order)
            });
        }
    });
})();
