(function () {
  'use strict';

  const api = require('shim/api');

  module.exports = Marionette.LayoutView.extend({
    template: require('templates/main/help'),

    templateHelpers: function () {
      return {
        apiURL:                     api.imgRoot,
        orders_create_ticket:         'help/orders_create_ticket.pdf',
        orders_create:                'help/orders_create.pdf',
        orders_assign:                'help/orders_assign.pdf',
        orders_do:                    'help/orders_do.pdf',
        orders_validate:              'help/orders_validate.pdf',
        orders_close:                 'help/orders_close.pdf',
        orders_typification:          'help/orders_typification.pdf',
        orders_no_parameters:         'help/orders_no_parameters.pdf',
        materials_new_request:        'help/materials_new_request.pdf',
        materials_new_order:          'help/materials_new_order.pdf',
        materials_budget:             'help/materials_budget.pdf',
        issues_why:                   'help/issues_why.pdf',
        issues_create:                'help/issues_create.pdf',
        reports_generate:             'help/reports_generate.pdf',
        reports_add_comments:         'help/reports_add_comments.pdf',
        reports_add_relevant_orders:  'help/reports_add_relevant_orders.pdf',
        reports_delete:               'help/reports_delete.pdf',
        reports_regenerate:           'help/reports_regenerate.pdf',
        operators_view:               'help/operators_view.pdf',
        operators_calendar:           'help/operators_calendar.pdf',
        dashboards_overview:          'help/dashboards_overview.pdf',
        dashboards_change_triggers:   'help/dashboards_change_triggers.pdf',
        users_add_user:               'help/users_add_user.pdf',
        users_deactivation:           'help/users_deactivation.pdf',
        users_password_reset:         'help/users_password_reset.pdf',
        users_change_password:        'help/users_change_password.pdf'
      }
    },


  });
})();
