(function () {
  'use strict';

  var Radio = require('shim/radio'),
      MaterialRowSelect = require('templates/orders/material-row-select');

  module.exports = Marionette.LayoutView.extend({
    template: require('templates/materials/material-requests/material-requests-create'),

    ui: {
      materials:              '.materials',
      categories:             '.categories',
      createMaterialsRequest: '.save-materials-request',
      cancelMaterialsRequest: '.cancel-materials-request',
      newMaterialItem:        '#new-material-item'
    },

    events: {
      'change @ui.categories':            'onCategoryChange',
      'click @ui.createMaterialsRequest': 'onCreateMaterialsRequest',
      'click @ui.cancelMaterialsRequest': 'onCancelMaterialsRequest',
      'click @ui.newMaterialItem':        'onNewMaterialItem'
    },

    initialize: function (options) {
      options = options || {};
      Backbone.Validation.bind(this, {model: this.model});

      this.model      = options.model;
      this.collection = options.collection;
      this.materials  = options.materials;
      this.categories = options.categories;
      this.order      = options.order;
    },

    templateHelpers: function () {
      return {
        materials:  this.materials.toJSON(),
        categories: this.categories.toJSON(),
        atOrderView: this.order
      }
    },

    onBeforeShow: function () {
      this.ui.materials.select2({
        id: '',
        placeholder: ' - Selecione Material - ',
        allowClear: true
      });
    },

    onCategoryChange: function (event) {
      var $el = $(event.target),
          materialContainer = $el.closest('.material-row').find('.materials-select'),
          categoryID = $el.find(':selected').data('id'),
          filteredMaterials;

      if (categoryID === -1){
        filteredMaterials = this.materials.toJSON()
      } else {
        filteredMaterials = _.filter(this.materials.toJSON(), function (material) {
          return material.material_category_id == categoryID;
        });
      }

      materialContainer.html(MaterialRowSelect({ materials: filteredMaterials }));
      materialContainer.find('.materials').select2();
    },

    onCancelMaterialsRequest: function (event) {
      event.preventDefault();
      $('#helperModal').modal('hide');
    },

    onCreateMaterialsRequest: function (event) {
      event.preventDefault();

      var newMaterial = this.onSetMaterialRequests();

      this.model.set(newMaterial);
      if (this.model.isValid(true)) {
        this.model.save().then(function () {
          if (!this.order) {
            this.collection.add(this.model);
            Radio.global.trigger('materialRequest:added', this);
            $('#helperModal').modal('hide');
          } else {
            this.order.fetch().then(() => {
              $('#secondaryHelperModal').modal('hide');
              Radio.global.trigger('order:render');
            });
          }
        }.bind(this));
      }
    },

    onNewMaterialItem: function (event) {
      var isNewItem = $(event.currentTarget).is(':checked');

      if ( isNewItem ) {
        $('#material-info').hide();
        $('#new-item').show();
      } else {
        $('#material-info').show();
        $('#new-item').hide();
      }
    },

    onSetMaterialRequests: function () {
      var data = $('.material-row').serializeObject(),
          materialID = $('.material-row .materials :selected').data('material-id'),
          user = Radio.global.request('app:session').get('user');

      if (document.getElementById('new-material-item').checked) {
        return {
          new_item_name:  data.new_item_name,
          purchase_qty:   data.new_item_qty,
          new_item_unit:  data.new_item_unit,
          user_id:        user.id,
          rootzone_id:    user.get('rootzone_id')
        }
      };

      if (this.order) {
        return {
          new_item_name:  data.new_item_name,
          purchase_qty:   data.new_item_qty,
          new_item_unit:  data.new_item_unit,
          user_id:        user.id,
          rootzone_id:    user.get('rootzone_id'),
          order_id:       this.order.get('id'),
          suggested_qty:  data.new_item_qty
        }
      };

      return {
        material_id:  materialID,
        user_id:      user.id,
        purchase_qty: data.purchase_qty,
        rootzone_id:    user.get('rootzone_id')
      }
    }
  });
})();
