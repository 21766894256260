var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <option value="
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + " "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.id : depth0),((stack1 = (depths[1] != null ? depths[1].work_type : depths[1])) != null ? stack1.id : stack1),{"name":"eq","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.title : stack1), depth0))
    + "</option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " selected ";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <option value="
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + " "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.id : depth0),((stack1 = (depths[1] != null ? depths[1].zone : depths[1])) != null ? stack1.id : stack1),{"name":"eq","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.title : stack1), depth0))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=helpers.blockHelperMissing, buffer = 
  "<div class=\"form-group\" style=\"margin:10px 20px\">\n  <label for=\"title\">Descrição:</label>\n  <input type=\"text\" name=\"title\" id=\"title\" class='title' value=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\"\n         placeholder=\"Inserir Descrição\" style=\"text-align:left;font-size:15px;height:35px\">\n</div>\n\n<div class=\"form-group\" style=\"margin:10px 20px\">\n  <label for=\"typified_group\">Categoria:</label>\n  <input type=\"text\" name=\"typified_group\" id=\"typified_group\" value=\""
    + alias4(((helper = (helper = helpers.typified_group || (depth0 != null ? depth0.typified_group : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typified_group","hash":{},"data":data}) : helper)))
    + "\"\n         placeholder=\"Inserir Texto\" style=\"text-align:left;font-size:15px;height:35px\">\n</div>\n\n<div class=\"form-group\" style=\"margin:20px 20px\">\n  <label for=\"work_type_id\">Tipo de Trabalho:</label>\n  <select name=\"work_type_id\" class=\"form-control work_type_id\">\n    <option value=\"\">- Selecionar uma opção</option>\n";
  stack1 = ((helper = (helper = helpers.workTypes || (depth0 != null ? depth0.workTypes : depth0)) != null ? helper : alias2),(options={"name":"workTypes","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.workTypes) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "  </select>\n</div>\n\n<div class=\"form-group\" style=\"margin:20px 20px\">\n  <label for=\"zone_id\">Zona:</label>\n  <select name=\"zone_id\" class=\"form-control zone_id\">\n    <option value=\"\">- Selecionar uma opção</option>\n";
  stack1 = ((helper = (helper = helpers.zones || (depth0 != null ? depth0.zones : depth0)) != null ? helper : alias2),(options={"name":"zones","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.zones) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </select>\n</div>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}