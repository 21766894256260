(function () {
  'use strict';

  var Radio = require('shim/radio'),
      PAGE_SIZE = 20;

  module.exports = Marionette.CompositeView.extend({
    template: require('templates/settings/typified-orders/typified-orders-list'),
    childView: require('./typified-orders-list-item-view'),
    childViewContainer: 'tbody',
    ui: {
      tr:         'tr',
      page:       '.page',
      pagination: '.dropup'
    },

    events: {
      'click @ui.tr':   'onClickRow',
      'click @ui.page': 'onPageClick'
    },

    collectionEvents: {
      'change add remove': '_setPage'
    },

    initialize: function () {
      this.unpaginatedCollection = this.collection;
      this.filteredCollection = this.unpaginatedCollection;
      this.currentPage = 0;
      this._setPage();
      Radio.global.on('filter:typified-orders:change', this.onFilterChange, this);
    },

    onRender() {
      //Auto scroll pagination dropdown to correct position
      //Top => (current-1) --> (current+1) --> [current+(n+1)]
      this.ui.pagination.on('shown.bs.dropdown', (ev) => {
        let $target = $(ev.currentTarget).find('ul'),
            page = this.currentPage - 2,
            topScroll = (page < 0) ? 0 : page;

        $target.scrollTop(topScroll * 26);
      })
    },

    templateHelpers: function () {
      var totalPages = Math.ceil(this.filteredCollection.length / PAGE_SIZE),
          pages = _.times(totalPages, _.identity);

      return {
        currentPage: this.currentPage,
        pages: pages,
        showPageDropdown: pages.length > 1,
      };
    },

    onFilterChange: function () {
      this.filteredCollection = this.unpaginatedCollection;
      this.currentPage = 0;
      this._setPage();
      this.render();
    },

    onBeforeDestroy: function () {
      Radio.global.off('filter:typified-orders:change', this.onFilterChange, this);
    },

    onClickRow: function (event) {
      var id = $(event.currentTarget).data('target'),
          route = `settings/typified_orders/edit/${id}`;

      Radio.global.command('router:settings:navigate', route);
    },

    onPageClick: function (event) {
      event.preventDefault();
      var $el = $(event.currentTarget);
      this.currentPage = $el.data('page');
      this._setPage();
      this.render();
    },

    _setPage: function () {
      var pageIDX = this.currentPage * PAGE_SIZE;
      this.collection = new Backbone.Collection((this.filteredCollection.models).slice(pageIDX, pageIDX + PAGE_SIZE));
      this.render();
    }
  });
})();
