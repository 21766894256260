(function () {
    'use strict';

    var BaseRouter = require('routers/base-router');

    module.exports = BaseRouter.extend({
        forbidden: {
            'calendar(/)': ['solicitant', 'admin'],
            'calendar/day(/)': ['solicitant', 'admin'],
            'calendar/day/:y/:m/:d': ['solicitant', 'admin'],
            'calendar/month(/)': ['solicitant', 'admin'],
            'calendar/month/:y/:m': ['solicitant', 'admin'],
            'calendar/user/:id': ['solicitant', 'admin']
        },

        appRoutes: {
            'calendar(/)': 'showCalendar',
            'calendar/day(/)': 'showCalendar',
            'calendar/day/:y/:m/:d': 'showCalendar',
            'calendar/month(/)': 'showCalendarMonthToday',
            'calendar/month/:y/:m': 'showCalendarMonth',
            'calendar/user/:id': 'showCalendarUser'
        }
    });
})();
