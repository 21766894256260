(function () {
    'use strict';

    var BaseObject = require('controllers/base-object'),
        Radio = require('shim/radio'),
        api = require('shim/api'),

        TasksLayout = require('layouts/tasks-layout'),
        TasksFilterView = require('views/main/filters-view'),
        TasksListView = require('views/main/tasks-list-view');

    module.exports = BaseObject.extend({
        radio: {
            'filter:orders:change': 'onFiltersChange',
            'print:orders':         'onPrintOrdersList'
        },

        initialize: function (options) {
            BaseObject.prototype.initialize.apply(this, arguments);
            options = options || {};
            this.orders = options.orders;
            this.equipmentFamilies = Radio.store.request('get:equipmentFamilies');
            this.equipments = Radio.store.request('get:equipments');
            this.users = Radio.store.request('get:users');
            this.userGroups = Radio.store.request('get:userGroups');
            this.notifications = options.notifications;
            this.currentFilters = Radio.global.request('app:filters');
            this.sanitizeFilters(this.currentFilters);
            this.filteredCollection = this.orders.vCollection(this.currentFilters, this);

            //Set LastMenu
            Radio.global.command('set:lastmenu', 'main:tasks');
        },

        sanitizeFilters: function (filters) {
            var merged_filters =  _.merge( this.currentFilters, filters, function (a, b) {
                                    if (_.isArray(a) || b === false) return b;
                                  });

            this.currentFilters = _.pick(merged_filters, function (value) {
                                    return value !== false && value != '-1';
                                  });
        },

        showTasks: function () {
            $('.search-ot-group').show();
            var self = this,
                tasksLayout = new TasksLayout();

            Radio.global.command('convert:alarms', self.notifications, self.orders);

            //Render MainView
            self.show(tasksLayout, this);

            //Render SubViews
            tasksLayout.showChildView('filters', new TasksFilterView({
                equipmentFamilies: self.equipmentFamilies,
                equipments: self.equipments, userGroups: self.userGroups, users: self.users,
                showStateFilters: true,
                showWorkTypesFilters: true,
                showPriorities: true,
                priorities: Radio.global.request('app:priorities'),
                showOriginFilters: true,
                showMaterialDependence: true,
                showDatesFilters: true,
                showUserGroups: true,
                showPrintButton: true
            }));
            tasksLayout.showChildView('view', new TasksListView({
                collection: self.filteredCollection
            }));

            Radio.global.trigger('anim-loading:hide');
            Radio.global.trigger('filter:orders:change', self.currentFilters);
        },

        onFiltersChange: function (options) {
            this.sanitizeFilters(options);
            this.filteredCollection.updateFilterParams(this.currentFilters);
            const disabled = _.isEmpty(this.filteredCollection.pluck('id'));
            $('.print-pdf').prop('disabled', disabled);
        },

        onPrintOrdersList: function (filters) {
          var orderIds = this.filteredCollection.pluck('id')
          if ( _.isEmpty(orderIds) ) {
            Radio.global.trigger('anim-loading:hide');
          } else {
            Radio.global.trigger('anim-loading:show');
            this.orders.generatePDF(orderIds, filters).done(function(data){
              Radio.global.trigger('anim-loading:hide');
              window.open(data.url);
            });
          }
        }

    });
})();
