var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " display:none";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"add-materials-modal\">\n  <legend>\n    <div style=\"display:flex;flex-flow:row;justify-content:space-between\">\n      <div>Adicionar Novo Material</div>\n      <div class=\"checkbox checkbox-primary\"\n        style=\"margin:0px;"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.atOrderView : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        <input type=\"checkbox\" id=\"new-material-item\" name=\"new-material-item\">\n        <label for=\"new-material-item\" style=\"font-size:15px\">Cadastrar Novo Material?</label>\n      </div>\n    </div>\n  </legend>\n\n  <div id=\"material-info\"\n    style=\"min-height:120px;"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.atOrderView : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <div class=\"row material-titles\">\n      <div class=\"col-sm-3 col-md-4\" style=\"padding-left:5px\">Categoria</div>\n      <div class=\"col-sm-5 col-md-6\" style=\"padding-left:5px\">Materiais</div>\n      <div class=\"col-sm-2 col-md-1\" style=\"text-align:right;padding-left:5px\">Qt.</div>\n      <div class=\"col-sm-2 col-md-1\"></div>\n    </div>\n\n    <div id=\"material-rows\">\n"
    + ((stack1 = container.invokePartial(partials["material-requests-row"],depth0,{"name":"material-requests-row","hash":{"amount":(depth0 != null ? depth0.quantity : depth0),"material":(depth0 != null ? depth0.stock_id : depth0)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n  </div>\n\n\n  <div id=\"new-item\"\n    style=\"min-height:120px;"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.atOrderView : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <div class=\"row material-titles\">\n      <div class=\"col-xs-8\" style=\"padding-left:5px\">Nome</div>\n      <div class=\"col-xs-2\" style=\"text-align:right;padding-left:5px\">Qt.</div>\n      <div class=\"col-xs-2\" style=\"text-align:right;padding-left:5px\">Unidade</div>\n    </div>\n\n    <div id=\"material-rows\">\n      <div class=\"row material-row\">\n        <div class=\"form-group col-xs-8\">\n          <input type=\"text\" name=\"new_item_name\" id=\"new_item_name\" value=\""
    + alias4(((helper = (helper = helpers.new_item_name || (depth0 != null ? depth0.new_item_name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"new_item_name","hash":{},"data":data}) : helper)))
    + "\"\n                 placeholder=\"Inserir nome do novo item\">\n        </div>\n        <div class=\"form-group col-xs-2\">\n          <input  value=\""
    + alias4(((helper = (helper = helpers.new_item_qty || (depth0 != null ? depth0.new_item_qty : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"new_item_qty","hash":{},"data":data}) : helper)))
    + "\" name=\"new_item_qty\" type=\"number\" min=\"1\"\n                  class=\"form-control mutate-change\" onkeypress=\"return event.charCode >= 48\"\n                  placeholder=\"Quantidade\">\n        </div>\n        <div class=\"form-group col-xs-2\">\n          <input  type=\"text\" name=\"new_item_unit\" value=\""
    + alias4(((helper = (helper = helpers.new_item_unit || (depth0 != null ? depth0.new_item_unit : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"new_item_unit","hash":{},"data":data}) : helper)))
    + "\"\n                  class=\"form-control mutate-change\" style=\"text-align:right\"\n                  placeholder=\"Unidades\">\n        </div>\n      </div>\n    </div>\n  </div>\n\n\n  <div class=\"material-rows-btns\" style=\"display:flex;justify-content:space-between\">\n    <button type=\"button\" class=\"button button-default cancel-materials-request\">\n      <i class=\"fa fa-times\" aria-hidden=\"true\"></i>\n      <span>Cancelar</span>\n    </button>\n    <button type=\"button\" class=\"button button-primary save-materials-request\">\n      <i class=\"fa fa-floppy-o\" aria-hidden=\"true\"></i>\n      <span>Guardar</span>\n    </button>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}