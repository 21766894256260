(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.Object.extend({

        initialize: function(){
            try{
                this.region = Radio.global.request('main:region');
            } catch(err){}
            this._radioBindings();
        },

        //Show View Into Main Region With Radio Unbinding
        show: function(view, self){
            this.showBind(view);
            this._listenToDestroy(self);
        },

        //Show View Into Main Region W/t Radio Unbinding
        showBind: function(view){
            this.region.reset();
            this.region.show(view);
        },

        //RADIO BINDINGS
        _radioBindings: function(){
            this._unbindRadio();
            this._bindRadio();
        },
        _bindRadio: function () {
            var self = this;
            if (!self.radio) {
                return;
            }
            _.each(self.radio, function (cb, ev) {
                if (typeof(cb) === 'function') {
                    Radio.global.on(ev, cb, self);
                } else {
                    Radio.global.on(ev, self[cb], self);
                }
            });
        },
        _unbindRadio: function () {
            var self = this;

            if (!self.radio) {
                return;
            }

            _.each(self.radio, function (cb, ev) {
                if (typeof(cb) === 'function') {
                    Radio.global.off(ev, cb, self);
                } else {
                    Radio.global.off(ev, self[cb], self);
                }
            });
        },

        //EVENTS
        _listenToDestroy: function(self){
            self.listenTo(self.region.currentView, 'destroy', function(){
                self._unbindRadio();
                self.destroy();
            });
        },
        _stopListenToDestroy: function(self){
            self.stopListening(self.region.currentView, 'destroy');
        }
    });
})();
