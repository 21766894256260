var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "                    Editar Solicitação\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                    Editar Ordem de Trabalho\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                                <label for=\"is_blocked\">Rejeitar Pedido</label>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                                <label for=\"is_blocked\">Bloquear OT</label>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, buffer = 
  "                    <select class=\"input-group\" name=\"work_type_id\" id=\"work_type\">\n                        <option value=\"\" data-id=\"-1\"> - Selecção Tipo de Trabalho -</option>\n";
  stack1 = ((helper = (helper = helpers.work_types || (depth0 != null ? depth0.work_types : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"work_types","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.work_types) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                    </select>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                            <option value=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\" data-name=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0._id : depth0),(depths[1] != null ? depths[1].work_type_id : depths[1]),{"name":"eq","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "selected";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                        <input value='"
    + alias4(((helper = (helper = helpers.work_type_title || (depth0 != null ? depth0.work_type_title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"work_type_title","hash":{},"data":data}) : helper)))
    + "' disabled type='text' for=\"work_type\"/>\n                        <input type='hidden' name=\"work_type_id\" id=\"work_type\" value=\""
    + alias4(((helper = (helper = helpers.work_type_id || (depth0 != null ? depth0.work_type_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"work_type_id","hash":{},"data":data}) : helper)))
    + "\" />                  <!-- </div> -->\n";
},"15":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <div class=\"form-group col-xs-12\" style=\"display: "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.showPriorities : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ";\" priority>\n                  <label for=\"priority\">Prioridades</label>\n                  <select class=\"input-group\" name=\"priority\" id=\"priority\">\n                    <option value=\"\" data-id=\"-1\"> - Selecção Prioridade -</option>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.priorities : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                  </select>\n                </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "none";
},"18":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                      <option value=\""
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data}) : helper)))
    + "\" data-name=\""
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.value : depth0),(depths[1] != null ? depths[1].priority : depths[1]),{"name":"eq","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data}) : helper)))
    + " - "
    + alias4(((helper = (helper = helpers.longText || (depth0 != null ? depth0.longText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longText","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                            <option value=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\" data-name=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0._id : depth0),(depths[1] != null ? depths[1].zone_id : depths[1]),{"name":"eq","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.image : depth0),{"name":"unless","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    return "                        <div style=\"margin-top:10px;margin-bottom:30px\">\n                          <span>\n                            <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n                            <b>Não é possível visualizar a imagem porque está em modo offline</b>\n                          </span>\n                        </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                <fieldset>\n                    <legend>Atribuição</legend>\n                    <div class=\"col-xs-12 box-checkbox\" style=\"padding:0\">\n                        <div class=\"col-xs-12 col-sm-4\">\n                            <div class=\"col-xs-6 col-sm-12 radio radio-danger\">\n                                <input type=\"radio\" id=\"user_group\" name=\"assign_ot\" value=\"user_group_id\" class=\"assign-ot\" "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.type : depth0),"user_group_id",{"name":"eq","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                                <label for=\"user_group\">A grupo</label>\n                            </div>\n                            <div class=\"col-xs-6 col-sm-12 radio radio-danger\">\n                                <input type=\"radio\" id=\"user_operator\" name=\"assign_ot\" value=\"user_ids\" class=\"assign-ot\" "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.type : depth0),"user_ids",{"name":"eq","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                                <label for=\"user_operator\">A operadores</label>\n                            </div>\n                        </div>\n                        <div class=\"col-xs-12 col-sm-8\">\n                            <div class=\"assign-container input-group\">\n                            </div>\n                        </div>\n                    </div>\n                </fieldset>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "\n                                    checked ";
},"28":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"30":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    <div class=\"form-group col-xs-12 col-md-6\">\n                        <label for=\"end_date_preview\">Data de fim</label>\n                        <input type=\"date\" name=\"end_date_preview\" id=\"end_date_preview\" placeholder=\"Final\" value=\""
    + container.escapeExpression(((helper = (helper = helpers.set_end_date || (depth0 != null ? depth0.set_end_date : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"set_end_date","hash":{},"data":data}) : helper)))
    + "\">\n                    </div>\n";
},"32":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression;

  return "                                    <tr>\n                                        <td>"
    + alias1(((depths[1] && depths[1].getZoneTitle) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.unit : depth0)) != null ? stack1.zone_route : stack1),{"name":"../getZoneTitle","hash":{},"data":data}))
    + " / <b>"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.unit : depth0)) != null ? stack1.title : stack1), depth0))
    + "</b></td>\n                                    </tr>\n";
},"34":function(container,depth0,helpers,partials,data) {
    return "                        Guardar Alterações\n";
},"36":function(container,depth0,helpers,partials,data) {
    return "                        Gravar\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=helpers.blockHelperMissing, buffer = 
  "<div class=\"section-box\">\n    <header class=\"page-header\">\n        <div class=\"bs-callout bs-callout-default\">\n            <i class=\"fa fa-file-text-o\" aria-hidden=\"true\"></i>\n            <h4>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRequest : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "            </h4>\n        </div>\n    </header>\n\n    <div class=\"box\">\n        <form action=\"#\">\n            <fieldset>\n                <legend>\n                    Descrição\n                    <span>\n                        <div class=\"checkbox\">\n                            <input type=\"checkbox\" id=\"is_blocked\" name=\"is_blocked\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRequest : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                        </div>\n                    </span>\n                </legend>\n\n                <div class=\"form-group col-xs-12\">\n                    <label for=\"title\">Título</label>\n                    <textarea name=\"title\" id=\"title\" placeholder=\"Introduza o título\" rows=\"1\" style=\"resize:none\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</textarea>\n                </div>\n\n                <!--Description-->\n                <div class=\"form-group col-xs-12\">\n                    <label for=\"description\">Descrição</label>\n                    <textarea name=\"description\" placeholder=\"Introduza a descrição\" rows=\"1\" style=\"resize:none\">"
    + alias4(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "</textarea>\n                </div>\n\n                <!--Notes-->\n                <div class=\"form-group col-xs-12\">\n                    <label for=\"notes\">Notas</label>\n                    <textarea name=\"notes\" id=\"notes\" placeholder=\"Introduza notas\" rows=\"1\" style=\"resize:none\">"
    + alias4(((helper = (helper = helpers.notes || (depth0 != null ? depth0.notes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"notes","hash":{},"data":data}) : helper)))
    + "</textarea>\n                </div>\n\n                <!--Work type-->\n                <div class=\"form-group col-xs-12\" typification>\n                    <label for=\"work_type\">Tipo de Trabalho</label>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.canChangeWorkType : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                </div>\n\n                <!--Priority-->\n";
  stack1 = ((helper = (helper = helpers.isNotOperatorAndSolicitant || (depth0 != null ? depth0.isNotOperatorAndSolicitant : depth0)) != null ? helper : alias2),(options={"name":"isNotOperatorAndSolicitant","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isNotOperatorAndSolicitant) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                <!--Location-->\n                <div class=\"form-group col-xs-12\">\n                    <label for=\"zone\">Localização</label>\n                    <select name=\"zone_id\" id=\"rootzone\" class='select2' style='width: 100%;'>\n                        <option value=\"\" data-id=\"-1\"> - Selecção Localização -</option>\n";
  stack1 = ((helper = (helper = helpers.zones || (depth0 != null ? depth0.zones : depth0)) != null ? helper : alias2),(options={"name":"zones","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.zones) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "                    </select>\n                </div>\n\n                <!--Image-->\n                <div class=\"form-group col-xs-12\">\n                    <label for=\"otImage\">Imagem</label>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_image : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    <input id=\"otImage\" type=\"file\" class=\"file\" accept=\".jpg,.jpeg\">\n                </div>\n            </fieldset>\n\n";
  stack1 = ((helper = (helper = helpers.isNotOperator || (depth0 != null ? depth0.isNotOperator : depth0)) != null ? helper : alias2),(options={"name":"isNotOperator","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isNotOperator) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n            <fieldset>\n                <legend>Agendamento</legend>\n\n                <!--Begin Date-->\n                <div class=\"form-group col-xs-12 col-md-6\" style=\"margin-right:0\">\n                    <label for=\"start_date_preview\">Data de início</label>\n                    <input type=\"date\" name=\"start_date_preview\" id=\"start_date_preview\" placeholder=\"Início\" value=\""
    + alias4(((helper = (helper = helpers.set_start_date || (depth0 != null ? depth0.set_start_date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"set_start_date","hash":{},"data":data}) : helper)))
    + "\">\n                </div>\n\n                <!--End Date-->\n";
  stack1 = ((helper = (helper = helpers.isNotOperatorAndSolicitant || (depth0 != null ? depth0.isNotOperatorAndSolicitant : depth0)) != null ? helper : alias2),(options={"name":"isNotOperatorAndSolicitant","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isNotOperatorAndSolicitant) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </fieldset>\n\n            <fieldset id=\"equipments\">\n                <div>\n                    <legend>Equipamento/Unidades</legend>\n                    <div id=\"equipment-container\">\n                        <div class=\"input-group\">\n                            <label for=\"equipment\">Equipamento</label>\n                            <div class=\"input-field\">"
    + alias4(((helper = (helper = helpers.equipment || (depth0 != null ? depth0.equipment : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"equipment","hash":{},"data":data}) : helper)))
    + "</div>\n                        </div>\n                    </div>\n                    <hr>\n                    <div id=\"unit-container\">\n                        <table class=\"table-striped\">\n                            <thead>\n                                <tr>\n                                    <th>Unidades Seleccionadas</th>\n                                </tr>\n                            </thead>\n                            <tbody>\n";
  stack1 = ((helper = (helper = helpers.tasks || (depth0 != null ? depth0.tasks : depth0)) != null ? helper : alias2),(options={"name":"tasks","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.tasks) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                            </tbody>\n                        </table>\n                    </div>\n                </div>\n            </fieldset>\n\n            <p class=\"order-btn-group\">\n                <button type=\"submit\" class=\"button button-primary button-lg-pad edit-order\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRequest : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.program(36, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                </button>\n            </p>\n\n        </form>\n    </div>\n\n</div>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}