(function () {
    'use strict';

    var DoOrderController = require('./do-order-object'),
        config = require('shim/config'),
        Radio = require('shim/radio'),

        DoOrderButtonsView = require('views/orders/do-order-buttons-view');

    module.exports = DoOrderController.extend({

        getButtonsView: function () {
            return new DoOrderButtonsView({
                steps: [
                    { // Verificações
                        prev: 'Voltar à Página Inicial da OT',
                        next: 'Ir para Parâmetros'
                    },
                    { // Parâmetros
                        prev: 'Voltar às Verificações',
                        next: 'Ir para Notas'
                    },
                    { // Notas
                        prev: 'Voltar aos Parâmetros',
                        next: 'Ir para Confirmação'
                    },
                    { // Confirmação
                        prev: 'Voltar para Notas',
                        next: 'Validar',
                        unapproved: 'Não Validar'
                    }
                ]
            });
        },

        //Validation Process
        _concludeProcess: function (transition) {
            transition = transition || 'validate';

            var self = this;

            this.task.set({
                validation_notes: $('#validation-notes').val()
            })
                ._transitionTask(this.order.get('id'), transition).save()
                .always(function(){
                    self.order.emulateStateMachine();
                    self._taskValidated();
                })
        },

        _taskValidated: function () {
            var self = this,
                route = ['orders', self.order.get('id'), 'validate', self.task.get('id'), 'submitted'].join('/');
            Radio.global.command('router:orders:navigate', route, {trigger: true});
        }
    });
})();
