(function () {
    'use strict';

    var Session             = require('models/session'),
        OrderTypifications  = require('collections/order-typifications'),
        WorkTypes           = require('collections/work-types'),
        Zones               = require('collections/zones'),
        EquipmentFamilies   = require('collections/equipment-families'),
        Equipments          = require('collections/equipments'),
        Units               = require('collections/units'),
        MaterialCategories  = require('collections/material-categories'),
        Materials           = require('collections/materials'),
        Orders              = require('collections/orders'),
        Issues              = require('collections/issues'),
        Users               = require('collections/users'),
        UserGroups          = require('collections/user-groups'),
        Notifications       = require('collections/notifications'),
        RootZones           = require('collections/rootzones'),
        Radio               = require('shim/radio'),
        STORE = {
            users:              new Users(),
            userGroups:         new UserGroups(),
            materialCategories: new MaterialCategories(),
            materials:          new Materials(),
            typifications:      new OrderTypifications(),
            workTypes:          new WorkTypes(),
            equipmentFamilies:  new EquipmentFamilies(),
            equipments:         new Equipments(),
            units:              new Units(),
            zones:              new Zones(),
            rootzones:          new RootZones(),
            issues:             new Issues(),
            notifications:      new Notifications()
        };

    /**
     * Create GET/REMOVE radio calls for each collection
     */
    _.forEach(STORE, function (store, storeName) {
        Radio.store.reply('get:' + storeName, store);
    });

    _.forEach(STORE, function (store, storeName) {
        Radio.store.on('remove:' + storeName, function (id) {
            store.remove(id);
        });
    });

    // order stuff will be done differently
    let orders = new Orders();
    Radio.store.reply('get:orders', orders);
    Radio.store.on('remove:orders', id => orders.remove(id));

    //Reset All Collection In Storage
    Radio.global.on('app:logout', function () {        
        _(_.values(STORE)).invoke('reset');
        orders.reset();
    });

    module.exports = {
        getNeeded: function () {
            return _.extend({}, STORE, {
                session: new Session(),
                prefetch: _.values(STORE)
            }, { orders });
        },
        prefetchAll: collections => collections.map(c => c.fetch())
    };
})();
