(function () {
    'use strict';

    var Radio = require('shim/radio'),
        api = require('shim/api'),

        LoginLayout = require('layouts/login-layout'),
        LoginFormView = require('views/login-form-view');

    module.exports = Marionette.Object.extend({
        initialize: function () {
            Radio.global.on('session:valid', this.onSessionValid, this);
            Radio.global.on('session:error', this.onSessionError, this);

            Radio.global.comply('session:validate', this.validate, this);
            Radio.global.comply('session:logout', this.logout, this);
        },

        /**
         * Login action.
         */
        login: function () {
            var self = this,
                session = this._getSession(),
                layout = new LoginLayout();

            if(session.isLoggedIn()){
                Radio.global.command('router:main:navigate', 'dashboard', {trigger: true});
                return;
            }

            self.loginview = new LoginFormView({model: session});

            Radio.global.command('render:layout', layout);
            layout.setContentView(self.loginview);
        },

        /**
         * Logout action.
         */
        logout: function () {
            this._getSession().logout();
            Radio.global.command('router:login:navigate', 'login', {trigger: true});
            Radio.global.command('offline:terminate');
            Radio.global.trigger('app:logout');
        },

        /**
         * Session validation handler.
         * @param {SessionModel} session Session model to validate.
         */
        validate: function () {
            var self = this;
            //Check If Internet Connection / API Alive
            $.ajax({
                url: api.isAlive,
                type: 'GET',
                context: self,
                success: function () {
                    self._getSession().loginValidate();
                    Radio.global.command('alerts:show', {msg: 'A autenticar...', type: 'info'});
                },
                error: function () {
                    Radio.global.command('alerts:show', {
                        type: 'danger',
                        msg: 'Não existe internet no dispositivo. Por favor active a internet e tente novamente.'
                    });
                    window.scrollTo(0, 0);
                }
            });
        },

        /**
         * Session validation success handler.
         * @param {Object} data Response data.
         */
        onSessionValid: function (data) {
            var self = this;
            Radio.global.command('offline:initialize');
            this._getSession().setData(data).done(function () {
                //Remove Login Form Inputs
                self.loginview.ui.email.hide();
                self.loginview.ui.password.hide();
                self.loginview.ui.button.text('Aguarde...');
                self.loginview.ui.button.attr('disabled', 'disabled');
                Radio.global.trigger('session:init');

                Radio.global.command('alerts:show', {msg: 'A carregar dados.', type: 'purple', dynamic: true});
            });
        },

        /**
         * Session validation error handler.
         * @param {Object} error Error data.
         */
        onSessionError: function (error) {
            this._getSession().setError(error);
            Radio.global.command('offline:terminate');
            Radio.global.command('alerts:show', {msg: 'Nome de utilizador ou palavra-passe erradas', type: 'danger'});
        },

        /**
         * Get user session.
         * @return {SessionModel} User session.
         */
        _getSession: function () {
            return Radio.global.request('app:session');
        }
    });
})();
