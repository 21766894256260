'use strict';

/**
 * Generates a MegaCollection With Order Collections of Each Week Day
 * Also: Possibility of Adding Late Orders
 *
 * @param options
 * @returns {exports.Collection}
 */
module.exports = function constructor(options) {
    var today = moment(options.today).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD'),
        view = options.view,
        isValidated = options.isValidated,
        originalCollection = options.originalCollection,
        megaCollection = new Backbone.Collection();

    //Check For Late Orders Addition
    if (options.addLate) {
        var lateGroupVirtualCollection = lateGroup(originalCollection, today, isValidated, view);
        if (lateGroupVirtualCollection.length > 0) {
            //Append Late Orders
            megaCollection.add(lateGroupVirtualCollection);
        }
    }

    //Append Week Order Collections
    megaCollection.add(newCollection(originalCollection, today, isValidated, view));
    return megaCollection;
};

//Generate Week Collections
function newCollection(collection, today, isValidated, view) {
    var acc = [];
    _(7).times(function (n) {
        var date = moment(today).add(n, 'days'),
            tmpCollection = new Backbone.VirtualCollection(collection, {
                filter: function (el) {
                    if (isValidated)
                        return date.isSame(moment(el.get('updated_at'), 'YYYY-MM-DD'));
                    else
                        return date.isSameOrAfter(el.get('start_date_preview')) && date.isSameOrBefore(el.get('end_date_preview'));
                },
                destroy_with: view
            });
        tmpCollection.isLate = false;
        tmpCollection.date = date.toDate();
        acc.push(tmpCollection);
    });
    return acc;
}

//Generate Late Collection
function lateGroup(originalCollection, date, isValidated, view) {
    date = moment(date);
    var res = new Backbone.VirtualCollection(originalCollection, {
        filter: function (el) {
            return isValidated ? date.isAfter(moment(el.get('updated_at'), 'YYYY-MM-DD')) : date.isAfter(el.get('end_date_preview'));
        },
        destroy_with: view
    });

    res.isLate = true;
    res.date = date.subtract(1, 'day').toDate();
    return res;
}
