(function () {
    'use strict';

    var config = require('shim/config'),
        Radio = require('shim/radio'),
        autosize = require('autosize');

    require('templates/orders/steps/verifications');

    module.exports = Marionette.LayoutView.extend({
        template: require('templates/orders/do-order'),
        className: 'section-box ot-form',

        regions: {
            progress: '.step-progress',
            step: '.order-step',
            buttons: '.step-buttons'
        },

        ui: {
            validation_notes: '#validation-notes'
        },

        events: {
            'click #show-details': 'onShowDetailsClicked',
            'click .validate': 'onValidateClicked',
            'click .verify': 'onVerifyClicked'
        },

        onShow: function () {
            autosize(this.ui.validation_notes);
        },

        onShowDetailsClicked: function (ev) {
            ev.preventDefault();
            this.$('#details-box').toggle();
        },

        onValidateClicked: function (ev) {
            ev.preventDefault();
            var notes = this.ui.validation_notes.val();
            Radio.global.trigger('do:order:validate', notes === '' ? null : notes);
        },

        onVerifyClicked: function (ev) {
            ev.preventDefault();
            var notes = this.ui.validation_notes.val();
            Radio.global.trigger('do:order:verify', notes === '' ? null : notes);
        },

        serializeData: function () {
            var task = this.options.task;

            return _.extend({}, this.model.toJSON(), {
                unit: task ? task.get('unit').get('title') : false,
                zone: task ? task.get('unit').get('zone') : false,
                tasks: this.collection.length,
                orders: this.collection.where({ order_category_id: Radio.global.request('category:id', 'work_order') }).length,
                routines: this.collection.where({ order_category_id: Radio.global.request('category:id', 'routine') }).length,
                alarms: this.collection.where({ order_category_id: Radio.global.request('category:id', 'alarm') }).length,
                display_notes: task ? !_.isEmpty(task.get('validation_notes')) || (this.model.get('state_id') >= config.getStatus('done').id && task.get('state_id') >= config.getStatus('done').id) : false,
                validate: task ? task.get('state_id') === config.getStatus('done').id : false,
                verify: task ? task.get('state_id') === config.getStatus('validated').id : false,
                validation_notes: task ? task.get('validation_notes') : false
            });
        }
    });
})();
