(function () {
    'use strict';

    var url = 'https://staging.api.siigo-maintenance.com';

    module.exports = {
        //DEVELOPMENT
        urlRoot: url + '/api',
        imgRoot: url,
        isAlive: url + '/api/alive'
    };

})();
