'use strict';

var Base = require('views/orders/steps/order-step-base-view'),
    autosize = require('autosize'),
    config = require('shim/config');

require('jquery-mask-plugin');
require('selectize');


module.exports = Base.extend({
    template: require('templates/orders/steps/order-step-four'),
    ui: {
        more: '.more:not(.disabled)',
        remove: '.remove',
        assign: '.assign-to',
        radioChange: '.radio-change',
        notesGroup: '#notes-group',
        notes: '#notes',
        collaborators: '.collaborators',
        time: '.collab-report-time'
    },

    events: {
        'click @ui.radioChange': 'onRadioChange'
    },

    err: {
        'report':             'Por favor indique o numero de horas feitas',
        'verified':           'Por favor indique se verificou ou se realizou todas as tarefas',
        'collaborators':      '[NOME] Por favor indique o nome do técnico que realizou o trabalho',
        'collab-report-time': '[Horas] Por favor indique o tempo feito em todas as colaborações',
        'collab-report-date': '[Dia] A data é inválida ou superior à data actual'
    },

    valid: {
        report: function (data) {
            return !_.isEmpty(data.report);
        },

        verified: function (data) {
            return _isFilledAndBoolean(data.verified);
        },

        'collab-report-id': _.constant(true),

        'collaborators': function (data) {
          return data['collaborators'].find(collabName => collabName === '') === undefined
        },

        'collab-report-date': function (data) {
            var dates = data['collab-report-date'];
            return !_(dates).any(_.isEmpty) && _.every(dates, function (date) {
                return moment(date).isSameOrBefore(moment());
            });
        },

        'collab-report-time': function (data) {
            var times = data['collab-report-time'];
            return _.every(times, function (time) {
                return moment.duration(time).isValid()
            });
        }
    },

    behaviors: {
        Collaborators: {
            template: require('templates/orders/order-collaborators'),
            newElementDefaultState: function () {
                return {
                    date: moment().format('YYYY-MM-DD'),
                    time: 0,
                    active_id: this.user.id
                };
            },
            multiple: true
        }
    },

    initialize: function (options) {
        this.user = options.user;
        this.order = options.order;
    },

    onRender: function () {
        this._prepareLayout();
    },

    onShow: function () {
        this.ui.time.mask("00:00", { placeholder: "__:__" });
        this.ui.collaborators.selectize()
        autosize(this.ui.notes);
        this.addDatePicker();
        this.addTimePicker();
    },

    addDatePicker: function () {
      document.querySelectorAll('.js-date-picker').forEach(function(el){
        return new Picker(el, {
          text: {
            title: 'Selecione a data',
            cancel: 'Cancelar',
          },
          format: 'YYYY-MM-DD',
        });
      });
    },

    addTimePicker: function () {
        document.querySelectorAll('.js-time-picker').forEach(function(el){
          return new Picker(el, {
            text: {
              title: 'Selecione Horas e Minutos',
              cancel: 'Cancelar',
            },
            format: 'HH:mm',
            increment: {
              minute: 5,
            }
          });
        });
    },

    isValid: function () {
        return this._isValid(this.parseData());
    },

    fillCurrentModelState: function () {
        return this._fillAttributes(this.parseData());
    },

    templateHelpers: function () {

        return {
            id: undefined,
            display: this.model.get('state') !== 'pending',
            interventions: this._getInterventions(),
            operators: this.collection.toJSON(),
            hasFilledVerified: !_.isUndefined(this.model.get('verified')),
            today: moment().format('YYYY-MM-DD'),
            user_id: this.user.get('_id'),
            readOnly: this.model.get('state_id') === config.getStatus('done').id || this.model.get('state_id') === config.getStatus('validated').id,
            notEditable: this.order.get('state_title') === config.getStatus('done').title || this.order.get('state_id') > config.getStatus('done').id
        };
    },

    onRadioChange: function (evt) {
        this.model.set('verified', evt.currentTarget.value);
        this._prepareLayout();
    },

    _prepareLayout: function () {
        if (this.model.get('verified') == 2 || this.model.get('verified') == 3) {
            this.ui.notesGroup.removeClass('hide');
            $('#notes').focus();
        } else {
            this.ui.notesGroup.addClass('hide');
        }
    },

    _getInterventions: function () {
        var previous = _.clone(this.model.get('interventions'));
        previous = _.reject(previous, function(intervention){
          return intervention._destroy;
        })
        previous = _.sortBy(previous, 'id')

        var unsaved  = this.model.get('interventions_attributes');
        unsaved = _.reject(unsaved, function(intervention){
          return intervention._destroy;
        })
        unsaved = _.sortBy(unsaved, 'id')

        return (unsaved && unsaved.length > 0) ? unsaved : previous;
    },

    parseData: function () {
      var data = this.$el.serializeObject(true);
      if (!!data.verified) { data.verified = data.verified[0] || ''; }
      return data
    },

    _isValid: function (data) {
        if (this._validate(data)) {
            this.model.trigger('validated:valid');
            this._fillAttributes(data);
            return true;
        }

        this.model.trigger('validated:invalid');
        return false;
    },

    _validate: function (data) {
        var isValid = true,
            $el,
            $group;

        _(data).keys().each(function (key) {
            if (!this.valid[key](data)) {
                $group = $('fieldset:last-child')
                $group.find('.help-block.' + key + '-error').remove();
                $group.append('<div class="help-block smm-inline-error ' + key + '-error" style="clear:both; float:left">' + this.err[key] + '</div>');

                isValid = false;
            } else {
                $el = $('[name=' + key + ']');
                $group = $el.closest('fieldset');

                $group.find('.help-block.' + key + '-error').remove();
            }
        }.bind(this));

        return isValid;
    },

    _fillAttributes: function (data) {
        this.model.set({
            notes: $('#notes').val(),
            interventions_attributes: this._fillInterventionAttributes(data),
            verified: data.verified || undefined
        });
    },

    _fillInterventionAttributes: function (data) {
        var interventions,
            newAndModifiedInterventions,
            removedInterventions;

        interventions = _.zip(data['collaborators'],
                              data['collab-report-time'],
                              data['collab-report-date'],
                              data['collab-report-id']
                            );

        newAndModifiedInterventions = _.map(interventions, function (el) {
            var user_id = parseInt(el[0], 10);
            if (!user_id) {return;}
            return {
                id: parseInt(el[3], 10) || null,
                user_id: parseInt(el[0], 10),
                user: this.collection.get(user_id).get('name'),
                time: moment.duration(el[1]).asSeconds(),
                date: el[2]
            };
        }.bind(this));

        newAndModifiedInterventions = newAndModifiedInterventions.filter(function(n){
          return n != undefined;
        });

        removedInterventions = _(this.model.get('interventions'))
            .reject(function (e) {
                return _.any(newAndModifiedInterventions, { id: e.id });
            })
            .map(function (e) {
                return { id: e.id, _destroy: true };
            }).value();

        return newAndModifiedInterventions.concat(removedInterventions);
    },

    _currentUser: function (model) {
        return model.get('_id') === this.user.get('_id');
    },

});

function _isFilledAndBoolean(attr) {
    return _.isBoolean(attr) || !_.isEmpty(attr);
};
