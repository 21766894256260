(function () {
    'use strict';

    var Radio = require('shim/radio');

    require('templates/orders/order-details-partial');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/orders/order-details'),
        tagName: 'fieldset',

        ui: {
            'modalContent': '.modal-content',
            'imageButton': '#order-image'
        },

        events: {
            'click .edit':            'onEditClicked',
            'change #is_relevant':    'onRelevantChange',
            'click @ui.imageButton':  'fetchOrder'
        },

        modelEvents: {
            'sync':         'render',
            'change:state': 'render'
        },

        templateHelpers: function () {
          return {
            priorityText: this.model.get('priority') ? _.find(Radio.global.request('app:priorities'), {
              value: this.model.get('priority')
            }).longText : null,
            hasNotesInUnits: this.model.hasNotesInUnits(),
            spentTime: this.model.spentTime(),
            users: this.getUsers(),
            hasImagesOnTasks: this.model.hasImagesOnTasks()
          };
        },

        getUsers:function () {
          return this.model.has('users') ? this.model.get('users').toJSON() : []
        },

        fetchOrder: function () {
            if (this.model.get('has_image') && !this.model.get('image')) {
              return this.model.fetch();
            }
        },

        onEditClicked: function (event) {
            event.preventDefault();
            if (this.model.get('has_image') && !this.model.get('image') && navigator.onLine) {
              this.model.fetch().always(function () {
                Radio.global.trigger('order:edit');
              });
            } else {
              Radio.global.trigger('order:edit');
            }
        },

        onRelevantChange: function (event) {
            event.preventDefault();
            this.model.save({is_relevant: $(event.target).is(':checked')},{patch:true, wait: true});
        },

        onRender: function() {
            $('#helperModal .modal-dialog')
            .empty()
            .append(this.ui.modalContent);
        }

    });

})();
