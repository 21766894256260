(function () {
    'use strict';

    var api = require('shim/api'),

        Dashboard2 = require('models/dashboards/dashboard2');

    module.exports = Backbone.Collection.extend({
        model: Dashboard2,
        url: api.urlRoot + '/dashboards2',

        initialize: function () {
            this.add(new Dashboard2());
            this.add(new Dashboard2());
            this.add(new Dashboard2());
            this.add(new Dashboard2());
            this.add(new Dashboard2());
            this.add(new Dashboard2());
        },

        parse: function (response) {
            return response;
        }
    });

})();
