(function () {
    'use strict';

    var BaseObject = require('controllers/base-object'),
        Users = require('collections/users'),
        OperatorsView = require('views/main/operators-view');

    module.exports = BaseObject.extend({
        initialize: function (options) {
            BaseObject.prototype.initialize.apply(this, arguments);
            options = options || {};
            this.users = options.users;
        },

        showOperators: function () {
            $('.search-ot-group').hide();
            this.show(new OperatorsView({collection: this.users}), this);
        }
    });
})();
