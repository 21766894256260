var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"column icon\">\n    <a href=\"\" title=\"Bloco de Notas\"><img src=\"images/icon-notepad.png\" alt=\"Bloco de Notas\">Bloco de Notas</a>\n</div>\n<div class=\"column icon\">\n    <a href=\"http://wisemetering.com/login\" title=\"WiseMetering\"><img src=\"images/icon-wisemetering.png\"\n                                                                      alt=\"WiseMetering\">WiseMetering</a>\n</div>\n<div class=\"column icon\">\n    <a href=\"http://ngns.dynip.sapo.pt/SmartH2o/Garden/Login.aspx\" title=\"Smart H2O\"><img src=\"images/icon-smarth2o.png\"\n                                                                                          alt=\"Smart H2O\">Smart H2O</a>\n</div>\n<div class=\"column icon\">\n    <a href=\"\" title=\"Skype\"><img src=\"images/icon-skype.png\" alt=\"Skype\">Skype</a>\n</div>\n<div class=\"column icon\">\n    <a title=\"Câmara\" class='camera'><img src=\"images/icon-camera.png\" alt=\"Câmara\">Câmara</a>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}