(function () {
  'use strict';

  var BaseObject   = require('controllers/base-object'),
      Radio        = require('shim/radio'),
      api          = require('shim/api'),
      globalValues = require('shim/global-values'),

      MaterialRequest           = require('models/material-request'),
      MaterialRequestsLayout    = require('layouts/materials/material-requests-layout'),
      MaterialRequestsListView  = require('views/materials/material-requests/material-requests-list-view'),
      MaterialRequestCreateView = require('views/materials/material-requests/material-requests-create-view');

  module.exports = BaseObject.extend({
    radio: {
      'materialRequest:added':  'onMaterialRequestAdded',
      'filter:material-requests:change': 'onFiltersChange',
    },

    initialize: function (options = {}) {
      BaseObject.prototype.initialize.apply(this, arguments);

      this.materials = options.materials;
      this.categories = options.categories;
      this.materialRequests = options.materialRequests;

      this.currentFilters = Radio.global.request('app:filters');
      this.sanitizeFilters(this.currentFilters);
      this.filteredCollection = this.materialRequests.vCollection(this.currentFilters, this);
    },

    showMaterialRequests: function (options = {}) {
      globalValues.lastView.materials = 0;

      var materialRequestsLayout = new MaterialRequestsLayout({
        isNewMaterials: options.isNewMaterials
      });
      //Render MainView
      this.showBind(materialRequestsLayout);
      //Render SubViews
      materialRequestsLayout.showChildView('create', new MaterialRequestCreateView({
        materials:  this.materials,
        categories: this.categories,
        model:      new MaterialRequest(),
        collection: this.filteredCollection
      }));
      //Render SubViews
      materialRequestsLayout.showChildView('view', new MaterialRequestsListView({
        collection: this.filteredCollection
      }));
      //Before rendering modal, this will allow to use select2 search box
      //https://select2.org/troubleshooting/common-problems
      $.fn.modal.Constructor.prototype.enforceFocus = function() {};
      //Render Modal
      materialRequestsLayout.buildModal();


      Radio.global.trigger('anim-loading:hide');
    },

    onMaterialRequestAdded: function () {
      this.showMaterialRequests();
    },

    sanitizeFilters: function (filters) {
      // There are no filters
      this.currentFilters = {};
    },

    onFiltersChange: function (options) {
      this.sanitizeFilters(options);
      this.filteredCollection.updateFilterParams(this.currentFilters);
    }
  });
})();
