var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.atOrderDetails : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <input type=\"checkbox\" class=\"checkbox-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" id=\"checkbox-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"\n             data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n      <label for=\"checkbox-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">&nbsp</label>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "style='margin-left:10px;'";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <a data-id="
    + alias4(((helper = (helper = helpers.order_id || (depth0 != null ? depth0.order_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"order_id","hash":{},"data":data}) : helper)))
    + " class=\"order-link\" style=\"text-decoration:underline;color:#B40043\">\n    "
    + alias4(((helper = (helper = helpers.order_number || (depth0 != null ? depth0.order_number : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"order_number","hash":{},"data":data}) : helper)))
    + "\n  </a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "    Avulso\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.minimum_qty || (depth0 != null ? depth0.minimum_qty : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"minimum_qty","hash":{},"data":data}) : helper)));
},"12":function(container,depth0,helpers,partials,data) {
    return "n.d.";
},"14":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.needed_qty || (depth0 != null ? depth0.needed_qty : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"needed_qty","hash":{},"data":data}) : helper)));
},"16":function(container,depth0,helpers,partials,data) {
    var helper;

  return "  <span style='font-size:14px'>\n    Qt. Sugerida: "
    + container.escapeExpression(((helper = (helper = helpers.suggested_qty || (depth0 != null ? depth0.suggested_qty : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"suggested_qty","hash":{},"data":data}) : helper)))
    + "\n  </span>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <div class=\"button button-danger remove-qty\" id=\"remove-qty-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"\n         style=\"margin-left:3px\">\n      <i aria-hidden=\"true\" class=\"fa fa-trash-o\"></i>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<td>\n  <div style=\"display:flex;flex-flow:row\">\n    <div class=\"checkbox checkbox-primary checkbox-container\" style=\"margin:15px;\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.material_id : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"material-request-info\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.material_order_id : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n      <div>\n        <span style='font-size:14px'>"
    + alias4(((helper = (helper = helpers.code || (depth0 != null ? depth0.code : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data}) : helper)))
    + " | </span>\n        <span style='color:#262626'>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n      </div>\n      <span style='font-size:14px'>"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</span>\n    </div>\n  </div>\n</td>\n\n<td align=\"center\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.order_number : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n\n<td align=\"center\">\n  "
    + alias4((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.created_at : depth0),{"name":"moment","hash":{"format":"DD/MM/YYYY"},"data":data}))
    + "\n  <br>\n  "
    + alias4(((helper = (helper = helpers.requester || (depth0 != null ? depth0.requester : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"requester","hash":{},"data":data}) : helper)))
    + "\n</td>\n\n<td align=\"center\">\n  <span style='color:#262626;display:block'>\n    "
    + alias4(((helper = (helper = helpers.available_qty || (depth0 != null ? depth0.available_qty : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"available_qty","hash":{},"data":data}) : helper)))
    + "\n  </span>\n  <span style='display:block;font-size:14px'>\n    <div class=\"tooltip\">\n      <i class=\"fa fa-info-circle\" aria-hidden=\"true\"></i>\n      <span class=\"tooltiptext\">Quantidade em armazém a partir da qual deverá ser feita uma nova encomenda</span>\n    </div>\n    Stock Mínimo: "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.minimum_qty : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "\n  </span>\n  <span style='display:block;font-size:14px'>\n    <div class=\"tooltip\">\n      <i class=\"fa fa-info-circle\" aria-hidden=\"true\"></i>\n      <span class=\"tooltiptext\">\n        Quantidade a encomendar no caso do stock existente ser inferior à Quandidade Mínima\n      </span>\n    </div>\n    Stock Necessário: "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.needed_qty : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "\n  </span>\n</td>\n\n<td align=\"center\">\n  <input type=\"number\" name=\"order-qty\" id=\"purchase-qty-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + alias4(((helper = (helper = helpers.purchase_qty || (depth0 != null ? depth0.purchase_qty : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"purchase_qty","hash":{},"data":data}) : helper)))
    + "\"\n         style=\"text-align:center;width:50%;height:40px\" disabled></input>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.suggested_qty : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n\n<td align=\"center\">\n  <div>\n    <div class=\"button button-default change-qty\" id=\"change-qty-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"\n         style=\"margin-right:3px\">\n      <i aria-hidden=\"true\" class=\"fa fa-pencil\"></i>\n    </div>\n    <div class=\"button button-success save-qty\" id=\"save-qty-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"\n         style=\"display:none;margin-right:3px\">\n      <i  aria-hidden=\"true\" class=\"fa fa-floppy-o\"></i>\n    </div>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.atOrderDetails : depth0),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n</td>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}