var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "content-not-editable faded-color";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"section-box\">\n    <header class=\"page-header\">\n        <div class=\"bs-callout bs-callout-default\">\n            <i class=\"fa fa-files-o\" aria-hidden=\"true\"></i>\n            <h4>Relatórios</h4>\n        </div>\n    </header>\n\n    <section class=\"content view\" style=\"margin:0\">\n        <section class=\" row report-bar\">\n            <i class=\"fa fa-chevron-left "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMinYear : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" aria-hidden=\"true\"></i>\n            <h3>"
    + container.escapeExpression(((helper = (helper = helpers.currentYear || (depth0 != null ? depth0.currentYear : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"currentYear","hash":{},"data":data}) : helper)))
    + "</h3>\n            <i class=\"fa fa-chevron-right "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMaxYear : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" aria-hidden=\"true\"></i>\n        </section>\n        <section class=\"report-downloads row\"></section>\n    </section>\n</div>";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}