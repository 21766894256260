'use strict';

const Base = require('views/orders/steps/order-step-base-view'),
    autosize = require('autosize'),
    Compress = require('compress.js');

module.exports = Base.extend({
    template: require('templates/orders/steps/order-step-three'),

    events: {
        'change #image_before_input': 'onImageBeforeChanged',
        'click #image_before_remove': 'onImageBeforeRemoved',
        'change #image_after_input':  'onImageAfterChanged',
        'click #image_after_remove':  'onImageAfterRemoved'
    },

    ui: {
        notes: '#notes'
    },

    compressAndSetImage: function(file, image_attr, has_image_attr) {
        const compress = new Compress(), that = this;
        compress.compress([file], {
            size: .2, // the max size in MB, defaults to 2MB
            quality: 1, // the quality of the image, max is 1
        }).then(data => {
            const image = data[0].prefix + data[0].data;
            that.model.set({[image_attr]:image, [has_image_attr]:true}, {trigger:true});
            that.render();
        });
    },

    onImageBeforeChanged: function (event) {
        let file = event.currentTarget.files[0]
        if (file) {
          this.compressAndSetImage(file,'image_before','has_image_before');
        }
    },

    onImageAfterChanged: function (event) {
        let file = event.currentTarget.files[0]
        if (file) {
          this.compressAndSetImage(file,'image_after','has_image_after');
        }
    },

    onImageBeforeRemoved: function () {
        this.model.set({image_before: null, has_image_before: false});
        this.render();
    },

    onImageAfterRemoved: function () {
        this.model.set({image_after: null, has_image_after: false});
        this.render();
    },

    onShow: function () {
        autosize(this.ui.notes);
    },

    fillCurrentModelState: function() {
      var data = this.$el.serializeObject(),
          hasNotes = data.notes ? !!data.notes.replace(/[^\x20-\x7E]/gmi, "").trim() : false,
          notes = hasNotes ? data.notes : null;

      if (hasNotes) {
        this.model.set('notes', notes);
      } else if (data.notes === '') {
        this.model.set('notes', null);
      }
    }
});
