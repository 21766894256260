(function () {
    'use strict';

    var Radio = require('shim/radio'),
        globalValues = require('shim/global-values');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/menu'),
        tagName: 'ul',

        ui: {
            menu: 'a'
        },

        events: {
            'click @ui.menu': 'onClickMenu'
        },

        onShow: function () {
            var resource = location.hash.split('/')[1];
            $('.' + resource).parent().addClass('active');
        },

        templateHelpers: function () {
            var user = Radio.global.request('app:session').get('user'),
                rootzone_id = user.get('rootzone_id'),
                menusToShow = Radio.global.request('app:show:menus')[user.get('role_slug')],
                rootzones = Radio.store.request('get:rootzones'),
                isMaterialManagementActive = rootzones.findWhere({_id: rootzone_id}).get('material_management');

            return {
                lastView:                   this.chooseMaterialLastView,
                user_id:                    user.id,
                type:                       Radio.global.request('app:states').calendar.type,
                isMaterialManagementActive: isMaterialManagementActive,
                showDashboards:             menusToShow.dashboards      || false,
                showDashboard:              menusToShow.dashboard       || false,
                showCalendar:               menusToShow.calendar        || false,
                showTasks:                  menusToShow.tasks           || false,
                showMaterials:              menusToShow.materials       || false,
                showIssues:                 menusToShow.issues          || false,
                showNotifications:          menusToShow.notifications   || false,
                showOperators:              menusToShow.operators       || false,
                showReports:                menusToShow.reports         || false,
            };
        },

        onClickMenu: function (e) {
            var $menu = $(e.currentTarget).parent(),
                $navTrigger = $('.nav-trigger');

            this.$el.find('li.active').removeClass('active');
            if (!$menu.hasClass('active')) {
                $menu.addClass('active');
                $navTrigger.prop('checked', false);
            }
            this.render();
        },

        chooseMaterialLastView: function () {
          return globalValues.lastView.materials == 0 ? 'material_requests' : 'material_orders'
        }
    });
})();
