(function () {
  'use strict';

  module.exports = Marionette.AppRouter.extend({
    appRoutes: {
      'login(/)':  'login',
      'logout(/)': 'logout'
    }
  });
})();
