(function () {
    'use strict';

    var Radio = require('shim/radio'),

        CreateOrderController = require('controllers/orders/create-order-object'),
        EditOrderController = require('controllers/orders/edit-order-object'),
        OrdersController = require('controllers/orders/orders-object'),

        DoOrderController = require('controllers/orders/do-order-object'),
        SubmittedController = require('controllers/orders/submitted-controller'),

        VerifyOrderController = require('controllers/orders/verify-order-controller'),
        VerifyOrderSimplifiedController = require('controllers/orders/verify-order-simplified-controller'),
        ValidateOrderController = require('controllers/orders/validate-order-controller'),
        ValidateOrderSimplifiedController = require('controllers/orders/validate-order-simplified-controller');

    module.exports = Marionette.Controller.extend({
        initialize: function (options) {
            options = options || {};
            this.orders = options.orders;
            this.users = options.users;
            this.userGroups = options.groups;
            this.categories = options.categories;
            this.materials = options.materials;
            this.equipments = options.equipments;
            this.units = options.units;
        },

        /**
         * Routes
         */
        createOrder: function () {
            new CreateOrderController({
                users: this.users,
                groups: this.userGroups,
                typifications: this.options.typifications,
                work_types: this.options.work_types,
                equipment_families: this.options.equipment_families,
                equipments: this.equipments,
                units: this.units,
                zones: this.options.zones,
                orders: this.orders
            }).render();
        },

        editOrder: function (id) {
            id = parseInt(id) || id;
            if (!this.orders.findWhere({ id: id }))
                return;

            new EditOrderController({
                order: this.orders.get(id),
                users: this.users,
                groups: this.userGroups,
                work_types: this.options.work_types,
                equipment_families: this.options.equipment_families,
                equipments: this.equipments,
                units: this.units,
                zones: this.options.zones
            }).render();
        },

        showOrderById: function (id) {
            id = parseInt(id) || id;
            if (!this.orders.findWhere({ id: id }))
                return;

            this.controller = new OrdersController({
                orders: this.orders,
                order: this.orders.get(id),
                materials: this.materials,
                categories: this.categories
            });
            this.controller.render();
        },

        doOrderTask: function (id, taskId) {
            if (!this._hasShownOrder()) {
                return this._goToOrder(id)
            }
            var order = this.controller.getOrder(),
                task = order.get('tasks').get(taskId),
                controller = new DoOrderController(this._getWizardOptions(task, order));
            controller.render();
        },

        validateTask: function (id, taskId) {
            if (!this._hasShownOrder()) {
                return this._goToOrder(id)
            }
            var order = this.controller.getOrder(),
                task = order.get('tasks').get(taskId),
                controller = task.hasVerifications() ? new ValidateOrderController(this._getWizardOptions(task, order))
                    : new ValidateOrderSimplifiedController(this._getWizardOptions(task, order));
            controller.render();
        },

        verifyTask: function (id, taskId) {
            if (!this._hasShownOrder()) {
                return this._goToOrder(id)
            }
            var order = this.controller.getOrder(),
                task = order.get('tasks').get(taskId),
                controller = task.hasVerifications() ? new VerifyOrderController(this._getWizardOptions(task, order))
                    : new VerifyOrderSimplifiedController(this._getWizardOptions(task, order));
            controller.render();
        },


        orderTaskSubmitted: function (id, taskId) {
            if (!this._hasShownOrder()) {
                return this._goToOrder(id)
            }
            var order = this.controller.getOrder(),
                task = order.get('tasks').get(taskId);
            new SubmittedController({ order: order, task: task }).render();
            this.controller = null;
        },

        orderSubmitted: function (id) {
            if (!this._hasShownOrder()) {
                return this._goToOrder(id)
            }
            new SubmittedController({ order: this.controller.getOrder() }).render();
            this.controller = null;
        },

        orderTaskValidated: function (id, taskId) {
            if (!this._hasShownOrder()) {
                return this._goToOrder(id)
            }
            var order = this.controller.getOrder(),
                task = order.get('tasks').get(taskId);
            new SubmittedController({ order: order, task: task }).render();
            this.controller = null;
        },

        orderTaskVerified: function (id, taskId) {
            if (!this._hasShownOrder()) {
                return this._goToOrder(id)
            }
            var order = this.controller.getOrder(),
                task = order.get('tasks').get(taskId);
            new SubmittedController({ order: order, task: task }).render();
            this.controller = null;
        },


        /**
         * Helper Functions
         */
        _getWizardOptions: function (task, order) {
            return {
                task: task,
                orders: this.orders,
                order: order,
                users: this.users
            };
        },
        _hasShownOrder: function () {
            return !_.isUndefined(this.controller) && !_.isNull(this.controller);
        },
        _goToOrder: function (id) {
            Radio.global.command('router:orders:navigate', ['orders', id].join('/'), { trigger: true });
        }
    });
})();
