/* global module, require, Backbone */
(function () {
  'use strict';

  module.exports = Marionette.ItemView.extend({

    template:   require('templates/main/tools'),
    tagName:    'section',
    className:  'section-row two-columns tools-page',
    events: {
      "click .camera": 'onCameraClicked'
    },
    onCameraClicked: function(){
      navigator.camera.getPicture(_.noop,_.noop,{saveToPhotoAlbum: true});
    }
  });

})();
