var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "Pedidos";
},"3":function(container,depth0,helpers,partials,data) {
    return "Ordens";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = 
  "<header class=\"page-header  view-according-device\">\n    <div class=\"bs-callout bs-callout-default\">\n        <i aria-hidden=\"true\" class=\"fa fa-tasks\"></i>\n        <h4>";
  stack1 = ((helper = (helper = helpers.isSolicitant || (depth0 != null ? depth0.isSolicitant : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"isSolicitant","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.isSolicitant) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</h4>\n    </div>\n\n    <form action=\"\" class=\"filters\"></form>\n</header>\n\n<section class=\"content view view-according-device\"></section>\n\n<section class=\"rotate-phone\" align=\"center\" style=\"margin-top:40px\">\n  <i class=\"fa fa-repeat fa-5x\" aria-hidden=\"true\" style=\"margin-bottom:20px\"></i>\n  <p>Por favor rode o telefone</p>\n</section>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}