'use strict';

const api = require('shim/api');
const Radio = require('shim/radio');
const CommentsTmpl = require('templates/main/reports/reports-comments');

module.exports = Marionette.ItemView.extend({
    template: require('templates/main/reports/reports-item'),
    tagName: 'div',
    className: 'col-xs-12 col-sm-6 col-md-3',

    events: {
        'click .add-comments-btn':            'openModal',
        'click .generate-uncommented-report': 'generateUncommentedReport',
        'click .generate-commented-report':   'generateCommentedReport',
        'click .delete-reports':              'deleteReports'
    },

    initialize() {
        this.monthText = moment(this.model.get('month'), 'MM').format('MMMM').capitalize();
        $(this.el).css('padding', '10px');
    },

    onShow() {
      $(document).ready(function() {
         $("li.disabled a").click(function() {
           return false;
         });
      });
    },

    templateHelpers() {
        return {
            apiURL: api.imgRoot,
            month:  this.monthText,
            isNew:  this.model.isNew()
        }
    },

    openModal(ev) {
        const chapters = this.model.attributes.chapters;
        const comment = JSON.parse(this.model.attributes.comment);
        const dateText = this.dateText;
        const month = this.monthText;
        const year = this.model.get('year');

        $('#helperModal').addClass('fullscreen')
        $('#helperModal .modal-dialog')
            .empty()
            .append(CommentsTmpl({ dateText, chapters, comment, month, year }))
            .find('.btn-primary').on('click', () => this.saveComments());
    },

    saveComments() {
      const comment = {}

      $('form').serializeArray().map(
        chapter => {comment[chapter.name]=chapter.value;}
      );

      if (!this.model.changedAttributes({ comment })) {return;}
      this.model.save({ comment });
    },

    onClose() {
        $('#helperModal').removeClass('fullscreen');
        $('#helperModal .modal-dialog').empty();
    },

    generateUncommentedReport(ev) {
      Radio.global.trigger('anim-loading:show');
      ev.preventDefault();
      if ( this.model.isNew() ) {
        this.model.save({generateUncommentedReport: true}).then(function(){
          this.finishLoading()
        }.bind(this));
      } else {
        this.model.save({comment: JSON.parse(this.model.attributes.comment), generateUncommentedReport: true}).then(function(){
          this.finishLoading()
        }.bind(this));
      }
    },

    generateCommentedReport(ev) {
      Radio.global.trigger('anim-loading:show');
      ev.preventDefault();
      if ( this.model.isNew() ) {
        this.model.save({generateCommentedReport: true}).then(function(){
          this.finishLoading()
        }.bind(this));
      } else {
        let comment = this.model.get('comment');
        if ( typeof(comment)==="string" ) {
          comment = JSON.parse(comment)
        }
        this.model.save({comment: comment, generateCommentedReport: true}).then(function(){
          this.finishLoading()
        }.bind(this));
      }
    },

    deleteReports(ev) {
      Radio.global.trigger('anim-loading:show');
      ev.preventDefault();
      this.model.save({comment: JSON.parse(this.model.attributes.comment), delete_reports: true},{wait: true}).then(function(){
        this.finishLoading()
      }.bind(this));
    },

    finishLoading(){
      Backbone.history.loadUrl(Backbone.history.fragment)
      Radio.global.trigger('anim-loading:hide');
    }

});
