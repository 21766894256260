var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "            class=\"btn btn-primary\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\"\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            class=\"btn btn-primary dropdown-toggle\"\n          ";
},"5":function(container,depth0,helpers,partials,data) {
    return "  <button type=\"button\" class=\"btn btn-primary dropdown-toggle\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n    <span class=\"caret\" style=\"color:white\"></span>\n    <span class=\"sr-only\">Toggle Dropdown</span>\n  </button>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = (helpers.unlessEq || (depth0 && depth0.unlessEq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"unlessEq","hash":{"compare":(depths[1] != null ? depths[1].currentPage : depths[1])},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "    <li style=\"text-align:center\">\n      <a href=\"#\" data-page=\""
    + alias1(container.lambda(depth0, depth0))
    + "\">"
    + alias1((helpers.add || (depth0 && depth0.add) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,1,{"name":"add","hash":{},"data":data}))
    + "</a>\n    </li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "<div class=\"panel panel-default\" style=\"margin-top: 20px;\">\n  <div class=\"panel-body\">\n    Atribuir OTs a:\n  </div>\n  <div class=\"panel-footer assignation-container\">\n    <div>\n      <!--GROUP RADIOCHECK-->\n      <div class=\"radio radio-danger\">\n        <input type=\"radio\" id=\"user_group\" name=\"assign_ot\" value=\"user_group_id\" class=\"assign-ot\">\n        <label for=\"user_group\">Um grupo</label>\n      </div>\n      <!--USER RADIOCHECK-->\n      <div class=\"radio radio-danger\">\n        <input type=\"radio\" id=\"user_operator\" name=\"assign_ot\" value=\"user_ids\" class=\"assign-ot\">\n        <label for=\"user_operator\">Um operador</label>\n      </div>\n    </div>\n    <div>\n      <!--CONTENT SELECT-->\n      <div class=\"column input-field assign\"></div>\n      <!--ASSIGN BUTTON-->\n      <p class=\"text-right\" style=\"padding-top: 15px;\">\n        <button class=\"button button-primary assign-button\">Atribuir Ordens</button>\n      </p>\n    </div>\n  </div>\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, buffer = 
  "<div style=\"float:right; margin-top:-30px;\">\n  <span class=\"expandAll\" style=\"padding:0 10px; cursor:pointer\">\n    <i class=\"fa fa-caret-down\" aria-hidden=\"true\"></i> Expand All\n  </span>\n  <span class=\"collapseAll\" style=\"padding:0 10px; cursor:pointer\">\n    <i class=\"fa fa-caret-up\" aria-hidden=\"true\"></i> Collapse All\n  </span>\n</div>\n\n<!--ACCORDION-->\n<section class=\"panel-group calendar-list calendar-no-filters\" id=\"accordion\"\n         role=\"tablist\" aria-multiselectable=\"true\">\n</section>\n\n<!--PAGINATION-->\n<section class='pages row' style=\"padding: 0 20px\">\n  <div class=\"btn-group dropup\">\n    <button type=\"button\"\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showPageDropdown : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + ">\n          Página "
    + container.escapeExpression((helpers.add || (depth0 && depth0.add) || alias2).call(alias1,(depth0 != null ? depth0.currentPage : depth0),1,{"name":"add","hash":{},"data":data}))
    + "\n    </button>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showPageDropdown : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <ul class=\"dropdown-menu dropdown-menu-right\" style=\"min-width:100px;max-height: 245px;overflow: auto;\">\n";
  stack1 = ((helper = (helper = helpers.pages || (depth0 != null ? depth0.pages : depth0)) != null ? helper : alias2),(options={"name":"pages","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.pages) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </ul>\n</div>\n</section>\n\n<!--MESSAGE-->\n<div id=\"message\" class=\"alert alert-danger text-left\" style=\"margin-top:20px\"></div>\n\n<!--ASSIGNEMENT SECTION-->\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.ottype : depth0),"D",{"name":"eq","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<!--VALIDATION SECTION [DEPRECATED?]-->\n<section class=\"section-row two-columns validation\"></section>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}