var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"disabled\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.uncommented_path || (depth0 != null ? depth0.uncommented_path : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uncommented_path","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"btn button-primary\" style=\"margin-right:5px;width:60px\">\n        Abrir\n      </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "      <a href=\"#\" target=\"_blank\" class=\"btn button-primary generate-uncommented-report\">\n        Gerar\n      </a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "      <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.commented_path || (depth0 != null ? depth0.commented_path : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"commented_path","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"btn button-primary\" style=\"margin-right:5px;width:60px\">\n        Abrir\n      </a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "      <a href=\"#\" target=\"_blank\" class=\"btn button-primary generate-commented-report\">\n        Gerar\n      </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = 
  "<div class=\"box\">\n  <div class=\"box-header\">\n    <h4>"
    + container.escapeExpression(((helper = (helper = helpers.month || (depth0 != null ? depth0.month : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"month","hash":{},"data":data}) : helper)))
    + "</h4>\n    <div class=\"dropdown\">\n      <a class=\"dropdown-toggle\" id=\"report-settings\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"true\">\n        <span class=\"fa fa-cog\"><span class=\"caret\"></span></span>\n      </a>\n      <ul class=\"dropdown-menu dropdown-menu-right\" aria-labelledby=\"report-settings\">\n        <li ";
  stack1 = ((helper = (helper = helpers.isNew || (depth0 != null ? depth0.isNew : depth0)) != null ? helper : alias2),(options={"name":"isNew","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isNew) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "><a href=\"#\" class=\"add-comments-btn\" data-toggle=\"modal\" data-target=\"#helperModal\"><i class=\"fa fa-commenting-o\" aria-hidden=\"true\" ></i> Adicionar comentários</a></li>\n        <li ";
  stack1 = ((helper = (helper = helpers.isNew || (depth0 != null ? depth0.isNew : depth0)) != null ? helper : alias2),(options={"name":"isNew","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isNew) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "><a href=\"#\" class=\"delete-reports\"><i class=\"fa fa-trash\" aria-hidden=\"true\"></i> Apagar relatórios</a></li>\n      </ul>\n    </div>\n  </div>\n\n  <div class=\"\" style=\"display:flex\">\n    <div class=\"btn-group\">\n      <a href=\"#\" aria-label=\"Report Sem Comentários\" class=\"btn button-primary content-not-editable\" style=\"margin-left:5px\">\n        <i class=\"fa fa-file-o\" aria-hidden=\"true\"></i>\n      </a>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.uncommented_path : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"btn-group\">\n      <a href=\"#\" class=\"btn button-primary content-not-editable\" style=\"margin-left:5px\">\n        <i class=\"fa fa-file-text-o\" aria-hidden=\"true\"></i>\n      </a>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.commented_path : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}