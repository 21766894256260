var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.number || (depth0 != null ? depth0.number : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"number","hash":{},"data":data}) : helper)))
    + " - ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"is-relevant-switch-group\">\n      <span>Relevante</span>\n      <label class=\"switch\">\n        <input type=\"checkbox\" id=\"is_relevant\" name=\"is_relevant\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_relevant : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n        <div class=\"slider\"></div>\n      </label>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"6":function(container,depth0,helpers,partials,data) {
    return "    <button type=\"button\" class=\"button button-secondary button-md\" title=\"View Details\" data-toggle=\"modal\" data-target=\"#helperModal\" id=\"order-image\">\n      <i class=\"fa fa-file-image-o\" aria-hidden=\"true\"></i>\n      <span>Abrir</span>\n    </button>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isNotDone || (depth0 && depth0.isNotDone) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"isNotDone","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    return "    <button type=\"button\" class=\"button button-secondary button-md edit\">\n      <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n      <span>Editar</span>\n    </button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <div class=\"modal-header\" style=\"display:flex\">\n    <button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\" style=\"margin-left: auto\">\n      Fechar\n    </button>\n  </div>\n  <div class=\"modal-body\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.image : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + "  </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <img class=\"file-zoom-content\" src=\""
    + container.escapeExpression(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"image","hash":{},"data":data}) : helper)))
    + "\" style=\"width:100%;\">\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"text-center\">Please wait, loading image...</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", buffer = 
  "<div class=\"legend ot-details-header\">\n  <span style=\"color:black\">\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.number : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\n  </span>\n\n  <div class=\"right-side\">\n    <!-- IS RELEVANT SWITCH -->\n"
    + ((stack1 = (helpers.isValidated || (depth0 && depth0.isValidated) || alias2).call(alias1,depth0,{"name":"isValidated","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <!-- OPEN IMAGE BUTTON -->\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_image : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <!-- EDIT BUTTON -->\n";
  stack1 = ((helper = (helper = helpers.isNotOperator || (depth0 != null ? depth0.isNotOperator : depth0)) != null ? helper : alias2),(options={"name":"isNotOperator","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isNotOperator) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n</div>\n\n"
    + ((stack1 = container.invokePartial(partials["order-details-partial"],depth0,{"name":"order-details-partial","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<!--MODAL CONTENT FOR IMAGE-->\n<div class=\"modal-content\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_image : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}