var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<header class=\"page-header  view-according-device\">\n    <div class=\"bs-callout bs-callout-default\">\n        <i aria-hidden=\"true\" class=\"fa fa-tasks\"></i>\n        <h4>Tipificação de Ordens</h4>\n    </div>\n\n    <div class=\"pull-right\" style=\"padding: 5px 5px 0 0\">\n      <a href='#settings/typified_orders/create'class=\"btn btn-primary\">\n        <i class=\"fa fa-plus-circle\" aria-hidden=\"true\"  style=\"display:inline\"></i>\n        <span style='color: white'>Tipificação</span>\n      </a>\n    </div>\n\n    <form action=\"\" class=\"filters\"></form>\n</header>\n\n<section class=\"content view view-according-device\"></section>\n\n<section class=\"rotate-phone\" align=\"center\" style=\"margin-top:40px\">\n  <i class=\"fa fa-repeat fa-5x\" aria-hidden=\"true\" style=\"margin-bottom:20px\"></i>\n  <p>Por favor rode o telefone</p>\n</section>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}