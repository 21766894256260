var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "  class=\"btn btn-primary\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\"\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  class=\"btn btn-primary dropdown-toggle\"\n  ";
},"5":function(container,depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn btn-primary dropdown-toggle\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n  <span class=\"caret\" style=\"color:white\"></span>\n  <span class=\"sr-only\">Toggle Dropdown</span>\n</button>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = (helpers.unlessEq || (depth0 && depth0.unlessEq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"unlessEq","hash":{"compare":(depths[1] != null ? depths[1].currentPage : depths[1])},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "  <li style=\"text-align:center\">\n    <a href=\"#\" class=\"page\" data-page=\""
    + alias1(container.lambda(depth0, depth0))
    + "\">"
    + alias1((helpers.add || (depth0 && depth0.add) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,1,{"name":"add","hash":{},"data":data}))
    + "</a>\n  </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, buffer = 
  "<table class=\"bordered table-routines\">\n  <thead>\n    <tr>\n      <th class=\"col-xs-4\">\n        <span style=\"margin-left:60px;color:#262626;\">\n          Nome\n        </span>\n      </th>\n\n      <th class=\"col-xs-1\" style=\"text-align:center\">\n        Ordem\n      </th>\n\n      <th class=\"col-xs-2\" style=\"text-align:center\">Criado em/por</th>\n\n      <th class=\"col-xs-2\" style=\"text-align:center\">\n        Qt. Disponível\n        <div class=\"tooltip\">\n          <i class=\"fa fa-info-circle\" aria-hidden=\"true\"></i>\n          <span class=\"tooltiptext\">Quantidade disponível em armazém</span>\n        </div>\n      </th>\n\n      <th class=\"col-xs-2\" style=\"text-align:center\">\n        Qt. Encomendar\n        <div class=\"tooltip\">\n          <i class=\"fa fa-info-circle\" aria-hidden=\"true\"></i>\n          <span class=\"tooltiptext\">Quantidade efetiva a encomendar</span>\n        </div>\n      </th>\n\n      <th class=\"col-xs-1\" style=\"text-align:center\"></th>\n    </tr>\n  </thead>\n  <tbody></tbody>\n</table>\n\n<!--PAGINATION-->\n<div class=\"btn-group dropup\">\n  <button type=\"button\"\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showPageDropdown : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + ">\n  Página "
    + container.escapeExpression((helpers.add || (depth0 && depth0.add) || alias2).call(alias1,(depth0 != null ? depth0.currentPage : depth0),1,{"name":"add","hash":{},"data":data}))
    + "\n</button>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showPageDropdown : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<ul class=\"dropdown-menu dropdown-menu-right\" style=\"min-width:100px;max-height: 245px;overflow: auto;\">\n";
  stack1 = ((helper = (helper = helpers.pages || (depth0 != null ? depth0.pages : depth0)) != null ? helper : alias2),(options={"name":"pages","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.pages) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</ul>\n</div>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}