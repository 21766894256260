var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<section id=\"login-content\">\n    <div id=\"login-form\">\n        <section id=\"loading\"></section>\n        <section id=\"content\" class=\"section-row\" style=\"margin:0; padding: 0 20px;\"></section>\n    </div>\n</section>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}