(function () {
    'use strict';

    module.exports = Marionette.Behavior.extend({
        extraCollab: 'extra-collab',
        events: {
            'click @ui.more': 'onMoreClicked',
            'click @ui.remove': 'onRemoveClicked',
            'change @ui.assign': 'onAssignChanged'
        },

        onMoreClicked: function (ev) {
            if (this.$('.assign-to').length < this.view.collection.length) {
                this._renderCollaborators();
                this.addDatePicker();
                this.addTimePicker();
                if (!this.options.multiple) {
                    this._disableSelected();
                    this._checkDisabled();
                }
            }

            if (this.$('.assign-to').length === this.view.collection.length) {
                $(ev.target).addClass('disabled');
            }

            ev.preventDefault();
        },

        onRemoveClicked: function (ev) {
            ev.preventDefault();
            $(ev.target).closest('.assign-to').remove();
            this._sanitize();
        },

        onAssignChanged: function (ev) {
            var $option = $(ev.target),
                selected = $option.val();
            if (selected === 'all') {
                this.$('.more').show();
                this._resetCollaborators();
            } else if (selected === 'none') {
                this.$('.more').hide();
            } else {
                this.$('.more').show();
                this._sanitize();
            }
        },

        _sanitize: function () {
            if (this.options.multiple) {
                return;
            }
            this._resetCollection();
            this._disableSelected();
            this._checkDisabled();
        },

        /**
         * Finds what are the disabled models in the collection
         * and disables those options
         */
        _checkDisabled: function () {
            var disabled = this.view.collection.where({disabled: true});
            _.each(disabled, function (model) {
                this.$('.assign-to').find('[data-id=' + model.get('_id') + ']:not(:selected)').attr('disabled', true);
            }.bind(this));
        },

        /**
         * Finds which options are selected and disables the corresponding
         * models in the collection
         */
        _disableSelected: function () {
            var selected = this.$('.assign-to').find('option:selected');

            this.$('.assign-to > select').find('[disabled="disabled"]').attr('disabled', false);
            _.each(selected, function (el) {
                var id = $(el).data('id');
                if (id !== undefined) {
                    this.view.collection.get(id).set('disabled', true);
                }
            }.bind(this));
        },

        _resetCollaborators: function () {
            this.$('.' + this.extraCollab).remove();
            this.$('.more').removeClass('disabled');
            this.$('.assign-to > select').find('[disabled="disabled"]').attr('disabled', false);
            this._resetCollection();
        },

        _resetCollection: function () {
            var disabled = this.view.collection.where({disabled: true});
            _.each(disabled, function (model) {
                model.set('disabled', false);
            });
        },

        _renderCollaborators: function () {
            var resetComponents = this.options.newElementDefaultState ?
                    this.options.newElementDefaultState.apply(this.view) : {},
                templateVars = _.merge(resetComponents,
                    {
                        operators: this.view.collection.toJSON(),
                        extra: this.extraCollab
                    }),
                template = $(this.options.template(templateVars));

            template.find('select').selectize();    
            this.view.ui.more.parent().before(template);
        },

        addDatePicker: function () {
          document.querySelectorAll('.js-date-picker').forEach(function(el){
            return new Picker(el, {
              text: {
                title: 'Selecione a data',
                cancel: 'Cancelar',
              },
              format: 'YYYY-MM-DD',
            });
          });
        },

        addTimePicker: function () {
          document.querySelectorAll('.js-time-picker').forEach(function(el){
            return new Picker(el, {
              text: {
                title: 'Selecione Horas e Minutos',
                cancel: 'Cancelar',
              },
              format: 'HH:mm',
              increment: {
                minute: 5,
              }
            });
          });
        }
    });
})();
