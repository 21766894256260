var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "<div align=\"right\" style=\"margin-top:20px\">\n  <div class=\"all-material-request-items\"></div>\n\n    <button class=\"button button-secondary add-new-material-request\" style='margin: 10px 0px'>\n      <i class=\"fa fa-plus-circle\" aria-hidden=\"true\"></i>\n      <span>Adicionar Novo Material</span>\n    </button>\n    <button class=\"button button-secondary save-new-material-request\" style='display:none;margin: 10px 0px 10px 10px'>\n      <i class=\"fa fa-floppy-o\" aria-hidden=\"true\"></i>\n      <span>Gravar</span>\n    </button>\n  </div>\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row\">\n  <div class=\"col-xs-5\">\n    <b>Material</b>\n  </div>\n  <div class=\"col-xs-3\">\n    <b>Categoria</b>\n  </div>\n  <div class=\"col-xs-1\" style='text-align:right'>\n    <b>Qt. Encomendada</b>\n  </div>\n  <div class=\"col-xs-1\" style='text-align:right'>\n    <b>Custo Unitário</b>\n  </div>\n  <div class=\"col-xs-1\" style='text-align:right'>\n    <b>Qt. Recebida</b>\n  </div>\n  <div class=\"col-xs-1\" style='text-align:right'>\n  </div>\n</div>\n\n<div class=\"material-request-item\"></div>\n\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.status : depth0),"Pendente",{"name":"eq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}