(function () {
    'use strict';

    var VerifyOrderController         = require('./verify-order-controller'),
        DoOrderProgressSimplifiedView = require('views/orders/do-order-progress-simplified-view'),
        DoOrderButtonsSimplifiedView  = require('views/orders/do-order-buttons-simplified-view');

    module.exports = VerifyOrderController.extend({
        steps: [
            require('views/orders/steps/order-step-three-view'),
            require('views/orders/steps/order-step-four-view')
        ],
        maxStep: 3,

        getProgressView: function () {
            return new DoOrderProgressSimplifiedView(this.options);
        },

        getButtonsView: function () {
            return new DoOrderButtonsSimplifiedView({steps: [
                { // Notas
                    prev: 'Voltar à Página Inicial da OT',
                    next: 'Ir para Confirmação'
                },
                { // Confirmação
                    prev: 'Voltar para Notas',
                    next: 'Verificar',
                    unapproved: 'Não Verificar'
                }
            ]});
        },

        onCompleteOrder: function (order, task) {
            return ['orders', order.get('id'), 'verify', task.get('id'), 'submitted'].join('/');
        }
    });
})();
