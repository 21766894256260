var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "active";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), buffer = 
  "<header class=\"page-header\">\n\n    <div class=\"bs-callout bs-callout-default\">\n        <i aria-hidden=\"true\" class=\"fa fa-calendar\"></i>\n        <h4>Calendário</h4>\n\n        <div class=\"col-xs-6\" style=\"margin-left:auto;padding-right:0px\">\n            <!-- trocar isto por uma section no layout e deixar criado como component e injectar ou não-->\n            <div class=\"view-switcher inline-element pull-right\">\n                <div class=\"btn-group\">\n                    <a href=\"#\" title=\"Vista Diária\" class=\"day btn btn-default "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.daily : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">Diária</a>\n                    <a href=\"#\" title=\"Vista Mensal\" class=\"month btn btn-default ";
  stack1 = ((helper = (helper = helpers.monthly || (depth0 != null ? depth0.monthly : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"monthly","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.monthly) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">Mensal</a>\n                </div>\n            </div>\n        </div>\n\n    </div>\n\n    <section class=\"summary section-row two-columns\"></section>\n    <form action=\"\" class=\"filters\"></form>\n</header>\n<section class=\"content view\"></section>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}