var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"section-box\">\n    <header class=\"page-header\">\n        <div class=\"bs-callout bs-callout-default\">\n            <i aria-hidden=\"true\" class=\"fa fa-users\"></i>\n            <h4>Operadores</h4>\n        </div>\n    </header>\n\n    <section class=\"section-row two-columns text-center\"></section>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}