(function () {
    'use strict';

    var Radio = require('shim/radio'),
        BaseObject = require('controllers/base-object'),

        NotificationsLayout = require('layouts/notifications-layout'),
        NotificationsListView = require('views/main/notifications-list-view');

    module.exports = BaseObject.extend({
        initialize: function (options) {
            BaseObject.prototype.initialize.apply(this, arguments);
            options = options || {};
            this.notifications = options.notifications;
        },

        showNotifications: function () {
          $('.search-ot-group').hide();
          var self = this,
              notificationsLayout = new NotificationsLayout();
          //Render MainView
          self.showBind(notificationsLayout);
          //Render SubViews
          notificationsLayout.showChildView('view', new NotificationsListView({
              collection: this.notifications
          }));

          Radio.global.trigger('anim-loading:hide');
        }
    });
})();
