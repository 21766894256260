(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/calendars/calendar-summary'),

        initialize: function (options) {
            this.today = options.today;
            this.user_id = options.user_id === 'me' ? Radio.global.request('app:session').get('user').get('_id') : options.user_id;
            this.users = options.users;
            this.onUserChange = this.onUserChange.bind(this);
            Radio.global.on('calendar:user:change', this.onUserChange);
            //setTimeout(this.render, 100);
        },
        onDestroy: function () {
            Radio.global.off('calendar:user:change', this.onUserChange);
        },
        collectionEvents: {
            'reset': 'render'
        },
        onUserChange: function (user_id) {
            if (this.isDestroyed) return;
            this.user_id = user_id;
            this.render();
        },
        templateHelpers: function () {

            var parsedDate = moment(this.today).format('YYYY-MM-DD'),
                // params = {date: parsedDate, state_id: 0},
                params = {date: parsedDate},
                collection = this.collection.collection ? this.collection.collection : this.collection,
                categoryTotal = this._categoryTotal(params, collection);

            return {
                // todayDate: new Date(),
                todayDate: this.today,
                todayTasks: collection.vCollection(params, this).length,
                //todayTasks:      tmpCollection.length,
                totalWorkOrders: categoryTotal('work_order'),
                totalRoutines: categoryTotal('routine'),
                totalAlerts: categoryTotal('alarm')
            };
        },

        _categoryTotal: function (params, collection) {
            return function (type) {
                var category = {order_category_id: Radio.global.request('category:id', type)},
                    filterParams = _.merge(params, category);
                return collection.vCollection(filterParams, this).length;
            }.bind(this);
        }
    });
})();
