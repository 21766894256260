(function () {
    'use strict';

    var Radio   = require('shim/radio'),
        Users   = require('collections/users'),
        Tasks   = require('collections/tasks'),
        config  = require('shim/config'),
        api     = require('shim/api');

    require("moment-duration-format");

    module.exports = Backbone.Model.extend({
        idAttribute:  'id',
        urlRoot:      api.urlRoot + '/orders',
        store:        'orders',

        defaults: {
            order_category_slug: 'work_order',
            order_category_id: Radio.global.request('category:id', 'work_order')
        },

        putAttrs: [
            'title',
            'start_date_preview',
            'end_date_preview',
            'zone_id',
            'user_group_id',
            'work_type_id',
            'equipment_family_id',
            'equipment_id',
            'transition',
            'notes',
            'description',
            'user_ids',
            'material_assignments_attributes',
            'state',
            'is_blocked',
            'is_relevant',
            'isRequest',
            'image',
            'priority'
        ],

        postAttrs: [
            'title',
            'notes',
            'description',
            'image',
            'start_date_preview',
            'end_date_preview',
            'zone_id',
            'user_group_id',
            'user_ids',
            'work_type_id',
            'equipment_family_id',
            'equipment_id',
            'order_category_id',
            'assign_ot',
            'typification',
            'typifications',
            'tasks_attributes',
            'is_ticket',
            'priority'
        ],

        blockedAttrs: [
            'zone_id',
            'is_blocked'
        ],

        validation: {
            title: {
                required: true,
                msg: 'Por favor introduza um titulo'
            },

            zone_id: {
                required: function (val, attr, values) {
                    return values.unit_id === undefined || values.dummy === "unit_id";

                },
                msg: 'Por favor Selecione uma localização'
            },

            work_type_id: {
                required: function () {
                    //Not required if solicitant
                    return Radio.global.request('app:session').get('user').not('solicitant') && !this.has('is_blocked');
                },
                msg: 'Por favor indique o tipo de trabalho'
            },

            start_date_preview: [
                {
                    required: true,
                    msg: 'Por favor escolha uma data de início'
                }
            ],

            end_date_preview: function (value) {
                if (this.get('start_date_preview') && moment(this.get('start_date_preview')).isAfter(moment(value)))
                    return 'Por favor escolha uma data de fim superior à de inicio';
            },

            equipment_family_id: {
                fn: function (val, attr, values) {
                    if (Radio.global.request('app:session').get('user').is('solicitant') || this.has('is_blocked'))
                        return;

                    if ((!values.update || this.has('isRequest')) && !val)
                        return 'Por favor Selecione uma instalação';
                }
            },

            equipment_id: {
                fn: function (val, attr, values) {
                    if (Radio.global.request('app:session').get('user').is('solicitant') || this.has('is_blocked'))
                        return;

                    if ((!values.update || this.has('isRequest')) && values.dummy !== 'equipment_family_id' && !val)
                        return 'Por favor Selecione um equipamento';
                }
            },

            unit_id: {
                fn: function (val, attr, values) {
                    if (Radio.global.request('app:session').get('user').is('solicitant') || this.has('is_blocked'))
                        return;

                    if ((!values.update || this.has('isRequest')) && values.dummy !== 'equipment_family_id' &&
                        values.dummy !== 'equipment_id' && !val) {
                        return 'Por favor Selecione pelo menos uma unidade';
                    }

                    if (values.dummy === 'unit_id' && _.isArray(val)) {
                        return 'Por favor não selecione  nenhuma unidade se selecionou não aplicável';
                    }

                }
            }
        },

        parse: function (response) {
            if (response.order_category_id !== Radio.global.request('category:id', 'work_order')) {
                response.skip_prefetch = true;
            }

            response = _.extend(response, this.setOfflineState(response));
            response = _.extend(response, this.setOfflineDates(response));

            response.users = response.users.store ? response.users : new Users(response.users);
            response.tasks = response.tasks.store ? response.tasks : new Tasks(response.tasks);
            response.work_type_id ? response.isRequest = null : response.isRequest = true;

            return response;
        },

        save: function (attrs, options) {
            // Get order ID
            var id = this.get('_id') || this.get('id'); // incoherent API regarding ids
            //Parse options
            options = options || {};
            //Get and rebuild attrs -> id ? edit : create
            attrs = attrs || _.extend({}, this.attributes);
            //Build attrs if not in offline sync
            if (options.offline_save) {
              attrs = _.pick(attrs, 'order');
            }
            else {
              attrs = id ? this.getPutAttrs(attrs) : this.getPostAttrs(attrs);
            }

            options.data = JSON.stringify(attrs);
            options.contentType = 'application/json';

            return Backbone.Model.prototype.save.call(this, attrs, options);
        },

        validateOrder: function(opts) {
            let self    = this,
                url     = self.url() + '/validate',
                options = {
                    url: url,
                    type: 'POST'
                };

            _.extend(options, opts);

            return (self.sync || Backbone.sync).call(self, null, self, options);
        },

        verifyOrder: function(opts) {
            let self    = this,
                url     = self.url() + '/verify',
                options = {
                    url: url,
                    type: 'POST'
                };

            _.extend(options, opts);

            return (self.sync || Backbone.sync).call(self, null, self, options);
        },

        hasNotesInUnits: function () {
            return this.get('tasks').any(function (a) {
                return a.get('notes') !== null && a.get('notes') !== "";
            });
        },
        hasNotesInOrderOrInUnits: function () {
            let hasOrderNotes = this.get('notes') !== null && this.get('notes') !== "";
            let hasUnitsNotes = this.hasNotesInUnits();
            return hasOrderNotes || hasUnitsNotes;
        },

        hasMaterials: function () {
            return !_.isEmpty(this.get('material_assignments'))
        },

        spentTime: function () {
            var times = _(this.get('tasks').pluck('interventions'))
                                           .flatten()
                                           .reject(_.isUndefined)
                                           .pluck('time')
                                           .value()

            if (!times.length) {
              return undefined;
            }

            if (times.length > 0 && times.reduce((cum,value)=>(cum+value)) === 0){
              return "00:00"
            }

            times = times.filter(Number);
            //value in minutes
            var orderSpentTime = times.reduce((cum,value)=>(cum+value))/60

            if (orderSpentTime < 60) {
              return "00:" + moment.duration(parseInt(orderSpentTime), "minutes").format("mm");
            }

            return moment.duration(parseInt(orderSpentTime), "minutes").format("HH:mm");
        },

        hasImagesOnTasks: function () {
            var hasImage = false;
            this.get('tasks').forEach(function(task) {
                if ( task.get('has_image_before') || task.get('has_image_after') ){
                    hasImage = true;
                }
            });
            return hasImage
        },

        getUsers: function () {
            let orderAssigment, orderUsersNames, userGroupNames;

            if (['done','validated','verified'].includes(this.get('state'))) {
              orderUsersNames = _(this.get('tasks').pluck('interventions')).flatten()
                                                                           .reject(_.isUndefined)
                                                                           .pluck('user')
                                                                           .value();

              orderAssigment = _.uniq(orderUsersNames)
            } else {
              orderUsersNames = this.get('users').pluck('name');
              userGroupNames = this.get('user_group_title');

              orderAssigment = _.isEmpty(orderUsersNames) ? (userGroupNames ? [userGroupNames] : []) : orderUsersNames
            }

            let orderUsers = _.map(orderAssigment, function (name) {
                return {name: name};
            });

            return orderUsers
        },

        formatNotes: function () {
          let notes = this.get('notes');

          if (!notes) { return; }

          let formattedNote = "",
              noteToDecorate = notes.split('[Notas da Unidade]'),
              orderNotes = noteToDecorate[0].trim(),
              tasksNotes = "";

          if (noteToDecorate.length > 1 && !!noteToDecorate[1]) {
           tasksNotes = noteToDecorate[1].trim().split(/\n/g);
           tasksNotes = tasksNotes.map( taskNote => taskNote.trim() );
           tasksNotes = tasksNotes.join("<br>").replace("> Não aplicável","");
           if (!orderNotes) {tasksNotes = "Notas nas seguintes unidades:<br>" + tasksNotes}
          }

          formattedNote = orderNotes;
          formattedNote += !!formattedNote ? "<br>" + tasksNotes : tasksNotes;
          return formattedNote;
        },

        formatDuration (duration){
          var minutes = duration.asMinutes()

          if (minutes < 60){
            return "00:" + (minutes < 10 ? "0" + minutes : minutes);
          }

          var hours = Math.floor(minutes/60);
          minutes = minutes % 60;
          return (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);
        },

        updateState: function () {
            this._setState(this.attributes);
            return this;
        },

        afterSync: function () {
            this.unset('material_assignments_attributes');
            this.unset('transition');
        },

        /**
         * Create / Update attrs parser
         * Order Assignment
         */
        getPostAttrs: function (attrs) {
            if (!attrs.typifications || attrs.typifications === "-1") {
              if (this.postAttrs.indexOf('typifications') !== -1) {
                this.postAttrs.splice(this.postAttrs.indexOf('typifications'), 1);
              }
            }

            var result = _.pick(attrs, this.postAttrs), units;
            //Convert IDs to Integers
            //todo: check if every ID is covered
            _.each([
                'zone_id',
                'user_group_id',
                'user_ids',
                'work_type_id',
                'equipment_family_id',
                'equipment_id'
            ], function (attr) {
                if (result[attr]) { // we either have user_group_id or user_ids
                    result[attr] = _.isArray(result[attr]) ? _.map(result[attr], function (entry) {
                        return parseInt(entry);
                    }) : parseInt(result[attr]);
                }
            });

            //check if image exists
            if (_.isEmpty(result.image)){
              delete result.image;
            }

            //Categories, Tasks & Units
            units = _.isArray(this.get('unit_id')) ? this.get('unit_id') : [this.get('unit_id')];
            result.tasks_attributes = _.map(units, function (unit) {
                var unitID = unit || 1; //1 Is Default to Not Applicable
                return { unit_id: parseInt(unitID, 10) };
            });

            return { order: _.omit(result, _.isNull) };
        },

        //todo: refactores result to return {order: [...]}
        getPutAttrs: function (attrs) {
            if (attrs.has_image && !attrs.image) {
              this.putAttrs.splice((this.putAttrs.indexOf('image')),1)
            }

            var result = { order: _.pick(attrs, this.putAttrs) },
                units;

            //If Is Blocked
            if (result.order.is_blocked) {
              return { order: _.extend({ is_blocked: true }, _.pick(result.order, this.blockedAttrs)) };
            }
            //Remove material assignments if order is after complete
            if (this.get('state_id') >= 3){
              delete result.order.material_assignments_attributes;
            }

            //Build Tasks if request -> order
            if (this.has('isRequest')) {
                units = _.isArray(this.get('unit_id')) ? this.get('unit_id') : [this.get('unit_id')];
                result.order.tasks_attributes = _.map(units, function (unit) {
                    var unitID = unit || 1; //1 Is Default to Not Applicable
                    return { unit_id: parseInt(unitID, 10) };
                });
            }

            this._setAssignment(result.order);

            return result;
        },

        /**
         * ORDER ASSIGNMENTS
         */
        _setAssignment: function (data) {
            //If order assign to user(s)
            if (data.user_ids)
                _.isArray(data.user_ids) ? this.assignMultipleUsers(data) : this.assignSingleUser(data);
            //If order assign to group
            if (data.user_group_id)
                this.assignSingleGroup(data);
        },

        assignMultipleUsers: function (data) {
            var users = Radio.store.request('get:users');
            data.users = new Users(_.filter(users.models, function (user) {
                return _.contains(data.user_ids, user.get('_id') + '');
            }));
        },

        assignSingleUser: function (data) {
            var users = Radio.store.request('get:users');
            data.users = new Users(users.where({ _id: parseInt(data.user_ids) }));
        },

        assignSingleGroup: function (data) {
            var groups = Radio.store.request('get:userGroups');
            data.user_group_id = parseInt(data.user_group_id) || undefined;
            data.user_group_title = groups.findWhere({ _id: data.user_group_id }).attributes.title;
        },

        /**
         * Offline Emulation
         */
        emulateStateMachine: function () {
            var tasks = this.get('tasks').models,
                doneTasks = _.reject(tasks, function (t) {
                    return t.get('state') != 'done'
                });
            // Check for Assignment
            if ((!this.has('users') || !this.get('users').length) && !this.has('user_group_id')) {
                this.set({ users: new Users(Radio.global.request('app:session').get('user')) });
                this.set(this.setOfflineState({ state: 'pending' }));
            }
            //Check for Task Completion
            if (_.every(tasks, function (t) {
                return t.get('state_id') >= config.getStatus('done').id
            }) && this.is(['ongoing', 'pending'])) {
                this.set(this.setOfflineState({ state: 'complete' }));
            }
            //Check when Order Validated but Tasks are not (revert task validated status)
            else if (!_.every(tasks, function (t) {
                return t.get('state_id') >= config.getStatus('validated').id;
            }) && this.is('validated')) {
                this.set(this.setOfflineState({ state: 'done' }));
            }
            //Check For Ongoing
            else if (_.some(tasks, function (t) {
                return t.get('state') == 'ongoing';
            }) || (!_.isEmpty(doneTasks) && doneTasks.length < tasks.length && this.is('pending'))) {
                this.set(this.setOfflineState({ state: 'ongoing' }));
            }
        },

        emulateAPIBehavior: function (attrs) {
            //Get Default rootzone location if no location provided
            if (!attrs.zone_id)
                attrs.zone_id = Radio.store.request('get:zones').first().get('_id');
            //Set Users if any
            if (attrs.users)
                attrs.users = new Users(attrs.users);
            //Set task attributes
            if (attrs.tasks_attributes)
                attrs.tasks = new Tasks(attrs.tasks_attributes);

            this.set(attrs);
            this.set(this.setOfflineState(attrs));
            this.set(this.setOfflineDates(attrs));
            this.set(this.setOfflineTitles(attrs));
        },

        setOfflineState: function (attrs) {
            var stateShim = attrs.state ? attrs.state : (attrs.assign_ot ? 'pending' : 'unassigned'),
                status = config.getStatus(stateShim),
                response = {};

            response.state = stateShim;
            response.state_title = status.title;
            response.state_id = status.id;

            return response;
        },

        setOfflineDates: function (attrs) {
            var beginDate = moment(attrs.start_date_preview),
                endDate = attrs.end_date_preview ? moment(attrs.end_date_preview) : beginDate,
                response = {};

            response.date = beginDate.format('YYYY-MM-DD');

            response.start = beginDate;
            response.end = endDate;

            response.start_date_preview = beginDate.format('YYYY-MM-DD');
            response.end_date_preview = endDate.format('YYYY-MM-DD');

            return response;
        },

        setOfflineTitles: function (attrs) {
            var res = {};
            _.reduce([
                ["equipment_family", "equipmentFamilies"],
                ["equipment", "equipments"],
                ["work_type", "workTypes"],
                ["zone", "zones"]], function (res, e) {
                    var collection = Radio.store.request('get:' + e[1]),
                        element_id = attrs[e[0] + "_id"];

                    if (element_id === undefined) { return res; }

                    if (!collection.get(element_id)) { return res; }

                    res[e[0] + "_title"] = collection.get(element_id).get('title');
                    return res;
                },
                res);
            return res;
        },

        setOfflineMaterials: function () {
            // Reject assignments mark for deletion
            var materials = _(this.get('material_assignments_attributes')).reject(function (assignment) {
                return assignment._destroy
            }).value()

            this.set({
                material_assignments: materials
            })
        },

        _getState: function (transition) {
            var self = this,
                state = config.fromTransition(transition);
            return state ? state : self.get('state');
        },

        _setState: function (response) {
            var stateShim = (response.state) ? response.state : ((response.order.state) ? response.order.state : ((response.order.assign_ot) ? 'pending' : 'unassigned')),
                status = config.getStatus(stateShim);
            response.state = response.state || stateShim;
            response.state_title = status.title;
            response.state_id = status.id;
            return response;
        },

        is: function (states) {
            var self = this;

            states = _.isArray(states) ? states : [states]; // Convert states to array if string
            return _.some(states, function (state) {
                return self.get('state') == state;
            });
        },

        isToDo: function () {
            return this.is(['pending', 'ongoing', 'complete']);
        },

        hasMaterialProblems: function () {
            return this.get('material_assignment_status');
        },

        submitOrder: function () {
            let self = this
            let transition = self._getTransition();

            self.set({
                transition: transition,
                state: self._getState(transition)
            });

            return self.updateState().save()
                .always(self._updateCollection(), self.afterSync(), self.hideOrder())
                .fail(() => {
                    self.set({ is_declined_verification: false })
                });
        },

        submitAction: function (response, typeOfAction) {
            this.set(this.parse(response));
            this._updateCollection();
            this.afterSync();
            this.hideOrder();
            this.updateTotals(typeOfAction);
            Radio.global.trigger('anim-loading:hide');
        },

        _getTransition: function () {
            var self = this,
                tasks = self.get('tasks');

            if (tasks._some(['pending', 'ongoing']))
                return 'save_order';
            if (tasks._every(['done']) || tasks._some(['done']) || (tasks._some(['validated']) && !tasks._every(['validated']) && !tasks._some(['validated'])))
                return 'submit_order';
            if (tasks._every(['validated']) || (tasks._some(['verified']) && !tasks._every(['verified'])))
                return 'validate';
            if (tasks._every(['verified']))
                return 'verify';
        },

        _updateCollection: function () {
            var self = this;
            self.collection.set(self, { remove: false });
        },

        hideOrder: function () {
            $("#"+"order-line-"+this.get('id')).hide();
        },

        tryAgain: function () {
            $("a[data-id="+this.get('id')+"]").css({'background-color': '#f8c741', 'border-color': '#f8c741'});
        },

        unitsClickTrigger: function (state, action, verifyActions) {
            let self = this
            //Mapping view models
            const orderTasks = self.get('tasks').models;
            //Rejection tasks based on state condition
            const tasks = _.reject(orderTasks, task => !task.isState(state));
            // Call Transition Global Methods
            return tasks.map( task => this._onTransitionNext(self, task, action) );
        },

        _getStateByAction: function (action) {
            switch (action) {
                case 'validate':
                    return 'validated';
                case 'verify':
                    return 'verified';
                default:
                    return '';
            }
        },

        _onTransitionNext: function (order, task, transition) {
            return task._transitionTask(order.get('id'), transition).save();
        },

        updateTotals: function (type) {
          this.updateBadgeTotal(type);
          this.updateDayPanelTotal();
        },

        updateBadgeTotal: function (type) {
          const $badge = $(`#${type}-badge`);
          $badge.text($badge.text() - 1);
        },

        updateDayPanelTotal: function () {
          let orderId = this.get('id');
          const originalHTML = this.getDayPanelTag(orderId).html();
          let oldPaneltotal = parseInt(originalHTML.match(/\d+/)[0]);
          let newPanelTotal = oldPaneltotal ? (oldPaneltotal - 1) : 0;
          let newHTML = originalHTML.replace(oldPaneltotal, newPanelTotal);
          this.getDayPanelTag(orderId).html(newHTML);
        },

        getDayPanelTag: function (orderId) {
          return $("#order-line-"+orderId).parents().eq(2).prev().children().children();
        },

        isClosedAndHasMaterialIssues: function(){
          return this.get('material_assignment_status') && (this.get('state') === 'verified')
        },

        isDoneValidatedOrVerified: function () {
          return this.get('state') === 'done' || this.get('state') === 'validated' || this.get('state') === 'verified'
        }
    });
})();
