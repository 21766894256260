(function () {
    'use strict';

    var Equipment = require('models/equipment'),
        api = require('shim/api'),
        vCollection = require('collections/mixins/virtual-collection');

    module.exports = Backbone.Collection.extend({
        model: Equipment,
        url: api.urlRoot + '/equipments',
        store: 'equipments',

        constructor: function () {
            Backbone.Collection.apply(this, arguments);
            this.vCollection = vCollection.bind(this);
        },

        parse: function (response) {
            return response.equipments;
        }
    });
})();
