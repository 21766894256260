var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<ul class=\"nav nav-tabs\">\n    <li class=\"active\"><a href=\"#\" class=\"step\" data-step=\"1\">Notas</a></li>\n    <li><a href=\"#\" class=\"step\" data-step=\"2\">Confirmação</a></li>\n</ul>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}