'use strict';

var config = require('shim/config');

Handlebars.registerHelper("isNotUnassigned", function (order, options) {
    return order.state_id === config.getStatus('unassigned').id
        ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper("isDone", function (order, options) {
    return (order.state_id === config.getStatus('done').id ||
    order.state_id === config.getStatus('validated').id ||
    order.state_id === config.getStatus('verified').id)
        ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper("isNotDone", function (order, options) {
    return (order.state_id === config.getStatus('done').id ||
    order.state_id === config.getStatus('validated').id ||
    order.state_id === config.getStatus('verified').id)
        ? options.inverse(this) : options.fn(this);
});

Handlebars.registerHelper("isValidated", function (order, options) {
    return (order.state_id === config.getStatus('validated').id || order.state_id === config.getStatus('verified').id)
        ? options.fn(this) : options.inverse(this);
});