var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "    <fieldset>\n        <legend>Equipamento/Unidades</legend>\n        <div class=\"input-group\">\n            <label for=\"equipment-list\">Equipamento</label>\n            <div class=\"input-field\"><span>"
    + container.escapeExpression(((helper = (helper = helpers.equipment || (depth0 != null ? depth0.equipment : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"equipment","hash":{},"data":data}) : helper)))
    + "</span>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.hasTasks : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.unlessEq || (depth0 && depth0.unlessEq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.state_id : depth0),{"name":"unlessEq","hash":{"compare":2},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                        <a href=\"#orders/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "/do\" lass=\"button button-danger pull-right\">\n                            <i aria-hidden=\"true\" class=\"fa fa-wrench\"></i> "
    + alias4(((helper = (helper = helpers.state || (depth0 != null ? depth0.state : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"state","hash":{},"data":data}) : helper)))
    + "\n                        </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                        "
    + container.escapeExpression(((helper = (helper = helpers.state || (depth0 != null ? depth0.state : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"state","hash":{},"data":data}) : helper)))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), buffer = 
  "        <legend>Unidades a Intervir</legend>\n\n        <p>\n            <select id=\"unit-state-filter\" name=\"unit-state-filter\">\n";
  stack1 = ((helper = (helper = helpers.statuses || (depth0 != null ? depth0.statuses : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"statuses","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.statuses) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </select>\n        </p>\n\n        <table class=\"table-striped table-ot-unities\">\n            <thead class=\"hidden\">\n            <th>\n            <td>Unidade</td>\n            <td>Acção</td>\n            </th>\n            </thead>\n            <tbody id=\"order-units\"></tbody>\n        </table>\n\n        <div class=\"btn-group validate-verify-all-button-set\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showValidateAll : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showVerifyAll : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <option value="
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + " "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.id : depth0),(depth0 != null ? depth0.selectedState : depth0),{"name":"eq","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</option>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "selected='selected'";
},"11":function(container,depth0,helpers,partials,data) {
    return "            <a href=\"#\" class=\"button button-success validate_all\">\n                <i aria-hidden=\"true\" class=\"fa fa-check\"></i> Validar Tudo\n            </a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "            <a href=\"#\" class=\"button button-success verify_all\">\n                <i aria-hidden=\"true\" class=\"fa fa-check\"></i> Fechar Tudo\n            </a>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "        <table class=\"table-striped table-ot-unities\">\n            <tbody>\n            <td>"
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</td>\n            <td>\n"
    + ((stack1 = (helpers.unlessEq || (depth0 && depth0.unlessEq) || alias2).call(alias1,(depth0 != null ? depth0.state_id : depth0),{"name":"unlessEq","hash":{"compare":2},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "            </td>\n            </tbody>\n        </table>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    <a href=\"#orders/{id}/do\" class=\"button button-danger\">\n                        <i aria-hidden=\"true\" class=\"fa fa-wrench\"></i> "
    + container.escapeExpression(((helper = (helper = helpers.state_title || (depth0 != null ? depth0.state_title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"state_title","hash":{},"data":data}) : helper)))
    + "\n                    </a>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    "
    + container.escapeExpression(((helper = (helper = helpers.state_title || (depth0 != null ? depth0.state_title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"state_title","hash":{},"data":data}) : helper)))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.equipment : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasTasks : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isOrder : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</fieldset>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}