var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"row material-titles\">\n    <div class=\"col-sm-3 col-md-4\" style=\"padding-left:5px\">Categoria</div>\n    <div class=\"col-sm-5 col-md-6\" style=\"padding-left:5px\">Materiais</div>\n    <div class=\"col-sm-2 col-md-1\" style=\"text-align:right;padding-left:5px\">Qt.</div>\n    <div class=\"col-sm-2 col-md-1\"></div>\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"row\" style=\"font-weight:bold;padding:0;\">\n    <div class=\"col-xs-8\" style=\"padding:5px 0px;\">Materiais</div>\n    <div class=\"col-xs-2\" style=\"padding:5px 0px;text-align:center\">Qt.</div>\n    <div class=\"col-xs-2\" style=\"padding:5px 0px;text-align:center\"></div>\n  </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["material-row"],depth0,{"name":"material-row","hash":{"amount":(depth0 != null ? depth0.quantity : depth0),"material":(depth0 != null ? depth0.stock_id : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"no-material-field\">\n      <b>Esta OT não tem materiais associados</b>\n    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "<p class=\"material-rows-btns text-right\">\n  <button type=\"button\" class=\"button button-secondary save-materials\">\n    <i class=\"fa fa-floppy-o\" aria-hidden=\"true\"></i>\n    <span> Guardar Materiais</span>\n  </button>\n\n  <button type=\"button\" class=\"button button-secondary add-material\">\n    <i class=\"fa fa-plus-circle\" aria-hidden=\"true\"></i>\n    <span> Adicionar Material</span>\n  </button>\n\n  <button class=\"button button-secondary add-new-material\" data-toggle=\"modal\" data-target=\"#secondaryHelperModal\">\n    <i class=\"fa fa-plus-circle\" aria-hidden=\"true\" style=\"display:inline\"></i>\n    <span style='color: white'>Adicionar Novo Material</span>\n  </button>\n</p>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), buffer = 
  "<legend>Materiais</legend>\n\n<div id=\"material-info\" style=\"min-height:120px\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.canEditMaterials : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n  <div id=\"material-rows\">\n";
  stack1 = ((helper = (helper = helpers.hasMaterialAssignments || (depth0 != null ? depth0.hasMaterialAssignments : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"hasMaterialAssignments","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.hasMaterialAssignments) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.canEditMaterials : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}