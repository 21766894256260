(function () {
    'use strict';

    var c3 = require('c3');

    require('chart.js');
    require('color.js');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/dashboards/dashboard-1'),
        tagName: 'section',
        className: 'view',

        ui: {
            'helpText': '#helpText'
        },

        initialize: function (options) {
            this.pattern = ['#B40043', '#F8C741', '#53A784'];
            this.collection = options.collection;
        },

        onRender: function(){
            this._prepareModalBody();
        },

        onDomRefresh: function () {
            this._onCollectionReset();
        },

        _onCollectionReset: function () {
            if (this.collection.length < 3) return;

            var chart1 =
                this._prepareGraphic('#chart1', this.collection.models[0], this.pattern, "");
            var chart2 =
                this._prepareGraphic('#chart2', this.collection.models[1], this.pattern, "");
            var chart3 =
                this._prepareGraphic('#chart3', this.collection.models[2], this.pattern, "");
            var chart4 =
                this._prepareGraphic('#chart4', this.collection.models[0], this.pattern, "_acum");
            var chart5 =
                this._prepareGraphic('#chart5', this.collection.models[1], this.pattern, "_acum");
            var chart6 =
                this._prepareGraphic('#chart6', this.collection.models[2], this.pattern, "_acum");
        },

        _prepareGraphic: function (bindto, model, pattern, acum) {
            $(bindto + 'Legend').html(model.get("ot_done"+acum) + ' fechadas de ' +
              model.get("ot_total"+acum) + ' previstas');

            return c3.generate({
                bindto: bindto,
                data: {
                    columns: [
                        ['data', model.get('percentage' + acum)]
                    ],
                    type: 'gauge'
                },
                gauge: {
                    width: 30 // for adjusting arc thickness
                },
                color: {
                    pattern: pattern, // the three color levels for the percentage values.
                    threshold: {
                        values: [model.get('treshold1'), model.get('treshold2'), model.get('treshold3')]
                    }
                },
                size: {
                    height: 200
                },
                legend: {
                  show: false
                }
            });
        },

        _prepareModalBody: function(){
            $('#helperModal .modal-title').text('Slide n.º 1');
            $('#helperModal .modal-slide-body').html(this.ui.helpText);
        }

    });
})();
