(function () {
  'use strict';

  var Radio     = require('shim/radio');

  var AnimLoadingView = Marionette.ItemView.extend({
    template: require('templates/anim-loading')
  });

  Radio.global.on('anim-loading:show', function () {
    $('.sk-loading').show();
  });

  Radio.global.on('anim-loading:hide', function () {
    $('.sk-loading').hide();
  });

  module.exports = AnimLoadingView;
})();
