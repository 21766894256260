var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "\n                disabled ";
},"3":function(container,depth0,helpers,partials,data) {
    return "fileinput-exists ";
},"5":function(container,depth0,helpers,partials,data) {
    return " fileinput-new";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.image_before : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "              <div style=\"margin-top:10px;margin-bottom:30px\">\n                <span>\n                  <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n                  <b>Não é possível visualizar a imagem porque está em modo offline</b>\n                </span>\n              </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <div class=\"fileinput-preview thumbnail\">\n              <img class='fileinput-image-preview thumbnail' src='"
    + container.escapeExpression(((helper = (helper = helpers.image_before || (depth0 != null ? depth0.image_before : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"image_before","hash":{},"data":data}) : helper)))
    + "'/>\n            </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.image_after : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "            <div style=\"margin-top:10px;margin-bottom:30px\">\n              <span>\n                <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\n                <b>Não é possível visualizar a imagem porque está em modo offline</b>\n              </span>\n            </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper;

  return "          <div class=\"fileinput-preview thumbnail\">\n              <img class='fileinput-image-preview thumbnail' src='"
    + container.escapeExpression(((helper = (helper = helpers.image_after || (depth0 != null ? depth0.image_after : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"image_after","hash":{},"data":data}) : helper)))
    + "'/>\n          </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<form>\n  <fieldset>\n    <legend>Indique as suas notas</legend>\n    <p>\n      <textarea name=\"notes\" id=\"notes\" cols=\"30\" rows=\"2\" style=\"height: auto\"\n                placeholder=\"Introduza Notas da Unidade\" "
    + ((stack1 = (helpers.isDone || (depth0 && depth0.isDone) || alias2).call(alias1,depth0,{"name":"isDone","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "> "
    + alias4(((helper = (helper = helpers.notes || (depth0 != null ? depth0.notes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"notes","hash":{},"data":data}) : helper)))
    + " </textarea>\n    </p>\n    <div class='fileinput-container'>\n        <legend>Fotografia Antes Intervenção</legend>\n        <div class=\"fileinput "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_image_before : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_image_before : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.image_before : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n          <div class=\"btn-file-container\">\n            <div class=\"btn-file\">\n              <span class=\"button button-primary fileinput-new\">\n                <i class=\"fa fa-file-image-o\" aria-hidden=\"true\"></i>\n                <span>Selecionar</span>\n              </span>\n\n              <span class=\"button button-primary fileinput-exists\">\n                <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n                <span>Alterar</span>\n              </span>\n\n              <input id=\"image_before_input\" type=\"file\" name=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" accept=\".jpg,.jpeg,.png\">\n            </div>\n\n            <div class=\"btn-file\">\n              <span id=\"image_before_remove\" class=\"button button-primary fileinput-exists js-fileinput-remove\">\n                <i class=\"fa fa-trash\" aria-hidden=\"true\"></i>\n                <span>Remover</span>\n              </span>\n            </div>\n          </div>\n        </div>\n    </div>\n\n    <div class='fileinput-container'>\n      <legend> Fotografia Após Intervenção</legend>\n      <div class=\"fileinput "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_image_after : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_image_after : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.image_after : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <div class=\"btn-file-container\">\n          <span class=\"btn-file\">\n            <span class=\"button button-primary fileinput-new\">\n              <i class=\"fa fa-file-image-o\" aria-hidden=\"true\"></i>\n              <span>Selecionar</span>\n            </span>\n\n            <span class=\"button button-primary fileinput-exists\">\n              <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n              <span>Alterar</span>\n            </span>\n\n            <input id=\"image_after_input\" type=\"file\" name=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" accept=\".jpg,.jpeg,.png\">\n          </span>\n\n          <div class=\"btn-file\">\n            <span id=\"image_after_remove\" class=\"button button-primary fileinput-exists js-fileinput-remove\">\n              <i class=\"fa fa-trash\" aria-hidden=\"true\"></i>\n              <span>Remover</span>\n            </span>\n          </div>\n        </div>\n      </div>\n    </div>\n  </fieldset>\n</form>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}