(function () {
    'use strict';

    _.extend(Backbone.Validation.validators, {
        timeValidator: function (value, attr, customValue, model) {
            if (customValue === 'start' && !isValidStartTime(model)) {
                return 'A hora de inicio tem de ser após a hora actual';
            }

            if (customValue === 'end' && !isValidEndTime(model)) {
                return 'A hora de finalização tem de ser após a hora de inicio';
            }
        }
    });

    function isValidStartTime(model) {
        var today = moment(),
            startDate = moment(model.get('start_date')),
            start = moment(model.get('start_time'), 'HH:mm');

        return startDate.isAfter(today, 'day') || start.isSame(today, 'minute') || start.isAfter(today, 'minute');
    }

    function isValidEndTime(model) {
        var startDate = moment(model.get('start_date')),
            endDate = moment(model.get('end_date')),
            start = moment(model.get('start_time'), 'HH:mm'),
            end = moment(model.get('end_time'), 'HH:mm');

        return endDate.isAfter(startDate, 'day') || end.isAfter(start, 'minute');
    }
})();
