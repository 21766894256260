var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<header style=\"z-index:9999; display: flex;\">\n    <img src=\"../images/logo.png\" alt=\"\">\n</header>\n<form id=\"user-login\" action=\"#\" method=\"post\" novalidate>\n    <div id=\"alerts\"></div>\n    <p>\n        <label for=\"email\">Username</label>\n        <input type=\"email\" id=\"email\" name=\"email\" placeholder=\"Enter your email\" required value=\""
    + alias4(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data}) : helper)))
    + "\">\n    </p>\n    <p>\n        <label for=\"password\">Password</label>\n        <input type=\"password\" id=\"password\" name=\"password\" placeholder=\"Enter your password\" required\n               value=\""
    + alias4(((helper = (helper = helpers.password || (depth0 != null ? depth0.password : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"password","hash":{},"data":data}) : helper)))
    + "\">\n    </p>\n    <p>\n        <button for=\"user-login\" type=\"submit\" class=\"button\">Entrar</button>\n    </p>\n</form>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}