var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "Pedido "
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.number || (depth0 != null ? depth0.number : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"number","hash":{},"data":data}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return " ("
    + container.escapeExpression(((helper = (helper = helpers.period_title || (depth0 != null ? depth0.period_title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"period_title","hash":{},"data":data}) : helper)))
    + ")";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.all_materials_received : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.waits_material_delivery : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.waits_material_order : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.is_declined_verification : depth0),true,{"name":"eq","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.is_declined_validation : depth0),true,{"name":"eq","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "      <br>\n      <span style=\"color:#016f38\">Encomenda Recebida &nbsp;<i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i></span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "      <br>\n      <span style=\"color:#d82a2a;\">Aguarda entrega &nbsp;<i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i></span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "      <br>\n      <span style=\"color:#d82a2a;\">Por encomendar &nbsp;<i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i></span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "      <br>\n      <span style=\"color:#d82a2a;display:block\"><i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i> Ordem não\n        fechada</span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "      <br>\n      <span style=\"color:#d82a2a;display:block\"><i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i> Ordem não\n        validada</span>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <td align=\"center\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.has_image : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </td>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.image : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <img class=\"file-zoom-content\" src=\""
    + container.escapeExpression(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"image","hash":{},"data":data}) : helper)))
    + "\" style=\"max-height: 100px;\">\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "        <div>\n          <button type=\"button\" class=\"btn btn-default show-image\">\n            <i aria-hidden=\"true\" class=\"fa fa-picture-o\"></i>\n          </button>\n        </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper;

  return "  <td>"
    + container.escapeExpression(((helper = (helper = helpers.equipment_title || (depth0 != null ? depth0.equipment_title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"equipment_title","hash":{},"data":data}) : helper)))
    + "</td>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, buffer = 
  "<td >\n  "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.work_type_id : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n</td>\n\n<td>\n  "
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\n  <br>\n  <span>\n    "
    + alias4(((helper = (helper = helpers.work_type_title || (depth0 != null ? depth0.work_type_title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"work_type_title","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.period_title : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  </span>\n</td>\n\n<td align=\"center\">\n  "
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.priority : depth0)) != null ? stack1.value : stack1), depth0))
    + "\n  <br>\n  <span style=\"font-size: 80%\">\n    "
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.priority : depth0)) != null ? stack1.shortText : stack1), depth0))
    + "\n  </span>\n</td>\n\n<td align=\"center\">\n  "
    + alias4((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.start_date_preview : depth0),{"name":"moment","hash":{"format":"DD/MM/YYYY"},"data":data}))
    + "\n</td>\n\n<td align=\"center\">\n  "
    + alias4(((helper = (helper = helpers.state_title || (depth0 != null ? depth0.state_title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"state_title","hash":{},"data":data}) : helper)))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.notDone : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</td>\n\n";
  stack1 = ((helper = (helper = helpers.isSolicitant || (depth0 != null ? depth0.isSolicitant : depth0)) != null ? helper : alias2),(options={"name":"isSolicitant","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(24, data, 0),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isSolicitant) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}