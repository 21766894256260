var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div align='right' style='margin:40px 0 0 0; text-align:left'>\n  <div class=\"new-material-select-box\" style=\"margin:20px 0 10px 0\">\n    <select name=\"material\" class=\"form-control materials mutate-change\">\n      <option></option>\n    </select>\n  </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}