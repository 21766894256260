(function () {
    'use strict';

    var BaseObject = require('controllers/base-object'),
        NotificationsDetailsView = require('views/main/notifications-details-view');

    module.exports = BaseObject.extend({

        initialize: function (options) {
            BaseObject.prototype.initialize.apply(this, arguments);
            options = options || {};
            this.notifications = options.notifications;
            this.notification = options.notification;
        },

        showNotification: function () {
            this.notification.read();
            this.notifications.set(this.notification, {remove: false});

            //Render View
            this.show(new NotificationsDetailsView({
              model: this.notification,
              collection: this.notifications
            }), this);
        }

    });
})();
