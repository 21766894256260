(function () {
    'use strict';

    var Radio = require('shim/radio'),
        config = require('shim/config'),
        BaseObject = require('controllers/base-object'),

        Orders = require('collections/orders'),
        DashboardLayout = require('layouts/dashboard-layout'),
        TasksFiltersView = require('views/main/filters-view'),
        DashboardGroupView = require('views/dashboard/dashboard-group-view');

    module.exports = BaseObject.extend({
        radio: {
            'filter:dashboard:tab:change': 'onFilterDashboardTabChange',
            'filter:dashboard:change': 'onFilterDashboardPanelChange',
            'dashboard:assign:ot': 'onAssignOrders',
            'dashboard:validate': 'onValidateOrders',
            'filter:orders:change': 'onFiltersChange'
        },

        initialize: function (options) {
            var self = this;
            BaseObject.prototype.initialize.apply(this, arguments);
            options = options || {};
            this.filterBlackList = ['order_state_id', 'begin_date', 'end_date'];
            this.filters = _.omit(Radio.global.request('app:filters'), this.filterBlackList);
            this.state_id = Radio.global.request("app:states").dashboard.state_id;
            this.orders = options.orders;
            this.users = options.users;
            this.groups = options.groups;
            this.notifications = options.notifications;
            this.equipmentFamilies = Radio.store.request('get:equipmentFamilies');
            this.equipments = Radio.store.request('get:equipments');
            this.ordersByFilter = [];

            //Set LastMenu
            Radio.global.command('set:lastmenu', 'main:dashboard');
            Radio.global.reply("get:orders:counter", function () {
                return self.getOrdersCounter();
            });
        },

        //Render Views
        showDashboard: function () {
            $('.search-ot-group').show();

            var self = this;
            //Experimental DashboardGroupViews
            self.sanitizeFilters(self.filters);
            self.filterOrders(new Orders(self.orders.vCollection(self.filters).models));

            //Views
            var dashboardLayout = new DashboardLayout({ collection: self.orders }),
                taskFiltersView = new TasksFiltersView({
                    equipmentFamilies: self.equipmentFamilies, equipments: self.equipments,
                    users: self.users, userGroups: self.groups,
                    showWorkTypesFilters: true,
                    showPriorities: true,
                    priorities: Radio.global.request('app:priorities'),
                    showOriginFilters: true,
                    showMaterialDependence: true,
                    showDatesFilters: false,
                    showUserGroups: true
                }),

                dashboardDistributeGroupView = new DashboardGroupView({
                    collection: new Orders(),
                    users: self.users,
                    groups: self.groups,
                    ottype: 'D'
                }),
                dashboardPendingGroupView = new DashboardGroupView({
                    collection: new Orders(),
                    users: self.users,
                    groups: self.groups,
                    ottype: 'P'
                }),
                dashboardValidationGroupView = new DashboardGroupView({
                    collection: new Orders(),
                    users: self.users,
                    groups: self.groups,
                    ottype: 'V'
                }),
                dashboardSupervisorGroupView = new DashboardGroupView({
                    collection: new Orders(),
                    users: self.users,
                    groups: self.groups,
                    ottype: 'S'
                });

            //Reset and Render MainView
            self.show(dashboardLayout, self);

            //Render SubViews
            self.region.currentView.showChildView('taskFilters', taskFiltersView);
            self.region.currentView.showChildView('distributePanel', dashboardDistributeGroupView);
            self.region.currentView.showChildView('pendingPanel', dashboardPendingGroupView);
            self.region.currentView.showChildView('validationPanel', dashboardValidationGroupView);
            try {
                self.region.currentView.showChildView('supervisorPanel', dashboardSupervisorGroupView);
            } catch (ex) {
            }

            //Add Alarms
            Radio.global.command('convert:alarms', self.notifications, self.orders);

            //Listeners
            self.region.currentView.triggerMethod('aplly:state');
            Radio.global.trigger('anim-loading:hide');
        },

        //Filtering
        filterOrders: function (orders) {
            var self = this;
            self.ordersByFilter = [];
            self.ordersByFilter.push(new Orders(orders.where({ state: 'unassigned' })));
            self.ordersByFilter.push(new Orders(orders.filter(function (model) { return model.isToDo() })));
            self.ordersByFilter.push(new Orders(orders.where({ state: 'done' })));
            self.ordersByFilter.push(new Orders(orders.filter(function (model) {
                return model.is('validated') || (model.is('verified') && model.hasMaterialProblems())
            })));
        },
        sanitizeFilters: function (filters) {
            var mergedFilters = _.merge(this.filters, filters, function (a, b) {
                    if (_.isArray(a) || b === false) return b;
                });

            this.filters = _.pick(mergedFilters, function (value) {
                return value !== false && value != '-1';
            });
        },

        //Filter Triggers
        onFilterDashboardTabChange: function (selectedFilters) {
            selectedFilters = _.omit(selectedFilters, 'state_id');
            this.filters = _.merge(this.filters, selectedFilters); //, {user_group_id: -1, user_id: -1}
            this.onFilterDashboardPanelChange(selectedFilters);
        },
        onFilterDashboardPanelChange: function (selectedFilters) {
            this.onFiltersChange(selectedFilters);
        },
        onFiltersChange: function (filters) {
            var self = this;

            //Merge passed filters with current filters, and pick non false ones.
            self.sanitizeFilters(filters);

            //Check If need for Filter
            self.filterOrders(new Orders(self.orders.vCollection(self.filters).models));

            self.region.currentView.distributePanel.currentView.triggerMethod('orders:filtered', self.ordersByFilter[0]);
            self.region.currentView.pendingPanel.currentView.triggerMethod('orders:filtered', self.ordersByFilter[1]);
            self.region.currentView.validationPanel.currentView.triggerMethod('orders:filtered', self.ordersByFilter[2]);
            try {
                self.region.currentView.supervisorPanel.currentView.triggerMethod('orders:filtered', self.ordersByFilter[3]);
            } catch (ex) {
            }

            Radio.global.trigger('dashboard:filters:changed', self.filters);
        },

        //Orders Triggers
        onAssignOrders: function (data) {
            var self = this,
                reqs = this._makeRequests(data);

            $.when.apply($, reqs).always(function (options) {
                var msg = options.storage ? 'Atribuição <b>guardada</b> para posterior realização' : 'Atribuição realizada com sucesso.',
                    type = options.storage ? 'warning' : 'success';

                Radio.global.command('alerts:show', { msg: msg, type: type });
                Radio.global.trigger('anim-loading:hide');
                self.onFilterDashboardPanelChange(_.omit(self.filters, 'state_id'));
                self.region.currentView.triggerMethod('orders:atribution:changed');
            });
        },
        onValidateOrders: function (data) {
            var self = this,
                reqs = this._makeRequestsValidate(data);

            $.when.apply($, reqs).always(function (options) {
                var msg = options.storage ? 'Validação <b>guardada</b> para posterior submissão' : 'Validação realizada com sucesso.',
                    type = options.storage ? 'warning' : 'success';

                Radio.global.command('alerts:show', { msg: msg, type: type });
                self.onFilterDashboardPanelChange(self.filters);
                self.region.currentView.triggerMethod('orders:validation:changed');
            });
        },

        //Requests Triggers
        _makeRequests: function (data) {
            var self = this,
                orders = data.orders, order;
            data = _.omit(data, 'orders');

            return _.map(orders, function (id) {
                order = self.orders.findWhere({ id: id });
                order.set(_.extend(data, { state: 'pending' }));
                return order.updateState().save();
            });
        },
        _makeRequestsValidate: function (data) {
            var self = this,
                orders = data.orders, order;

            data = _.omit(data, 'orders');
            return _.map(orders, function (id) {
                order = self.orders.findWhere({ id: id });
                //todo if validated then verified
                order.set(_.extend({}, data, { state: 'validated' }));
                return order.updateState().save();
            });
        },
        getOrdersCounter: function () {
            return {
                totalUnassigned: this.ordersByFilter[0] ? this.ordersByFilter[0].length : 0,
                totalPending: this.ordersByFilter[1] ? this.ordersByFilter[1].length : 0,
                totalDone: this.ordersByFilter[2] ? this.ordersByFilter[2].length : 0,
                totalSupervisor: this.ordersByFilter[3] ? this.ordersByFilter[3].length : 0,
            }
        }
    });
})();
