var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "      <li role=\"presentation\" class=\""
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,depth0,0,{"name":"eq","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" style=\"float:none;\">\n        <a href=\"#chapt"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\" role=\"tab\" data-toggle=\"tab\">\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,depth0,0,{"name":"eq","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "        </a>\n      </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "active";
},"4":function(container,depth0,helpers,partials,data) {
    return "          Sumário Executivo\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,depth0,(helpers.add || (depth0 && depth0.add) || alias2).call(alias1,-1,(depths[1] != null ? depths[1].chapters : depths[1]),{"name":"add","hash":{},"data":data}),{"name":"eq","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "          Comentários Finais\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "          Capitulo "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "        <div role=\"tabpanel\" class=\"tab-pane chapt"
    + alias2(alias1(depth0, depth0))
    + "-panel "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias4).call(alias3,depth0,0,{"name":"eq","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"chapt"
    + alias2(alias1(depth0, depth0))
    + "\">\n          <textarea rows=\"10\" name=\"chapter"
    + alias2(alias1(depth0, depth0))
    + "\" style=\"resize: none; height: 100%\">"
    + ((stack1 = helpers.lookup.call(alias3,(depths[1] != null ? depths[1].comment : depths[1]),(helpers.concat || (depth0 && depth0.concat) || alias4).call(alias3,"chapter",depth0,{"name":"concat","hash":{},"data":data}),{"name":"lookup","hash":{},"data":data})) != null ? stack1 : "")
    + "</textarea>\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"modal-content\">\n  <div class=\"modal-header\" style=\"font-weight: bold;\">\n    Comentários para o relatório de "
    + alias4(((helper = (helper = helpers.month || (depth0 != null ? depth0.month : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"month","hash":{},"data":data}) : helper)))
    + " de "
    + alias4(((helper = (helper = helpers.year || (depth0 != null ? depth0.year : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"year","hash":{},"data":data}) : helper)))
    + "\n  </div>\n  <div class=\"modal-body\" style=\"padding: 0; display: flex; flex: 1;\">\n    <!-- Nav tabs -->\n    <ul class=\"nav nav-tabs\" role=\"tablist\" style=\"min-width: 180px;\">\n"
    + ((stack1 = (helpers.times || (depth0 && depth0.times) || alias2).call(alias1,(depth0 != null ? depth0.chapters : depth0),{"name":"times","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </ul>\n\n    <!-- Tab panes -->\n    <form style=\"width:100%;\">\n      <div class=\"tab-content\" style=\"margin:0; border: none;\">\n"
    + ((stack1 = (helpers.times || (depth0 && depth0.times) || alias2).call(alias1,(depth0 != null ? depth0.chapters : depth0),{"name":"times","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </form>\n  </div>\n\n  <div class=\"modal-footer\" style=\"display:flex; min-height: 70px;\">\n    <button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\" style=\"margin-left:0;\">\n      Fechar\n    </button>\n    <button type=\"button\" class=\"btn btn-primary\" data-dismiss=\"modal\" style=\"margin-left: auto\">\n      Guardar\n    </button>\n  </div>\n</div>\n";
},"useData":true,"useDepths":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}