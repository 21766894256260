(function () {
    'use strict';

    module.exports = Marionette.ItemView.extend({
        template: require('templates/dashboards/dashboard-3'),
        tagName: 'section',
        className: 'view',

        ui: {
            'helpText': '#helpText'
        },

        initialize: function (options) {
            this.collection = options.collection;
            this._prepareObjects();
        },

        onRender: function () {
            this._prepareModalBody();
        },

        _onCollectionReset: function () {
            if (this.collection.length < 6) return;

            this._prepareObjects();
            this.render();
        },

        _prepareObjects: function () {
            this.g1 = this.collection.models[0];
            this.g2 = this.collection.models[1];
            this.g3 = this.collection.models[2];
            this.g4 = this.collection.models[3];
            this.g5 = this.collection.models[4];
            this.g6 = this.collection.models[5];
        },

        _prepareModalBody: function () {
            $('#helperModal .modal-title').text('Slide n.º 3');
            $('#helperModal .modal-slide-body').html(this.ui.helpText);
        },

        templateHelpers: function () {
            return {
                graph1: this.g1.toJSON(),
                graph2: this.g2.toJSON(),
                graph3: this.g3.toJSON(),
                graph4: this.g4.toJSON(),
                graph5: this.g5.toJSON(),
                graph6: this.g6.toJSON()
            };
        }
    });
})();
