(function () {
    'use strict';

    const Radio = require('shim/radio');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/settings/settings'),
        tagName: 'section',

        ui: {
            'imageUpload': '.overlay',
            'fileInput': 'input[type=file]'
        },
        behaviors: {
            AvatarUpload: {}
        },

        initialize: function (options) {
          options = options || {};
          this.last_sync = options.last_sync;
        },


        templateHelpers: function () {
            const user = Radio.global.request('app:session').get('user'),
                  showRootZoneSettings = Radio.global.request('app:show:menus')[user.get('role_slug')].rootzone;

            return {
              showRootZoneSettings: showRootZoneSettings,
              last_sync:            this.last_sync
            };
        },

        uploadImage: function (avatarB64) {
            $('.image').attr('src', avatarB64);
            this.model.set({ image: avatarB64, imageChange: true });
            if (this.model.isValid(true))
                Radio.global.trigger('user:update:avatar');
            else
                console.log('User update request not valid');

        }
    });
})();
