var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "<li>\n  <a href=\"#/dashboard\" title=\"Desktop\" class=\"dashboard\">\n    <i aria-hidden=\"true\" class=\"fa fa-cogs\"></i>\n    <span style=\"display:block\">Desktop</span>\n  </a>\n</li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<li>\n"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.type : depth0),"month",{"name":"eq","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "</li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "  <a href=\"#/calendar/month/?user_id="
    + container.escapeExpression(((helper = (helper = helpers.user_id || (depth0 != null ? depth0.user_id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"user_id","hash":{},"data":data}) : helper)))
    + "\" title=\"Calendário\" class=\"calendar\">\n    <i aria-hidden=\"true\" class=\"fa fa-calendar\"></i>\n    <span style=\"display:block\">Calendário</span>\n  </a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return "  <a href=\"#/calendar/day/?user_id="
    + container.escapeExpression(((helper = (helper = helpers.user_id || (depth0 != null ? depth0.user_id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"user_id","hash":{},"data":data}) : helper)))
    + "\" title=\"Calendário\" class=\"calendar\">\n    <i aria-hidden=\"true\" class=\"fa fa-calendar\"></i>\n    <span style=\"display:block\">Calendário</span>\n  </a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = 
  "<li>\n  <a href=\"#/tasks\" title=\"Ordens\" class=\"tasks\">\n    <i aria-hidden=\"true\" class=\"fa fa-tasks\"></i>\n    <span style=\"display:block\">";
  stack1 = ((helper = (helper = helpers.isSolicitant || (depth0 != null ? depth0.isSolicitant : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"isSolicitant","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.isSolicitant) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</span>\n  </a>\n</li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "Pedidos";
},"11":function(container,depth0,helpers,partials,data) {
    return "Ordens";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isMaterialManagementActive : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<li>\n  <a href=\"#/"
    + container.escapeExpression(((helper = (helper = helpers.lastView || (depth0 != null ? depth0.lastView : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"lastView","hash":{},"data":data}) : helper)))
    + "\" title=\"Material Needs\" class=\"materials-needs\">\n    <i class=\"fa fa-cart-arrow-down\" aria-hidden=\"true\"></i>\n    <span style=\"display:block\">Gestão de Materiais</span>\n  </a>\n</li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "<li>\n  <a href=\"#/issues\" title=\"Issues\" class=\"issues\">\n    <i aria-hidden=\"true\" class=\"fa fa-thumb-tack\"></i>\n    <span style=\"display:block\">Assuntos</span>\n  </a>\n</li>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "<li>\n  <a href=\"#/notifications\" title=\"Notificações\" class=\"notifications\">\n    <i aria-hidden=\"true\" class=\"fa fa-envelope\"></i>\n    <span style=\"display:block\">Notificações</span>\n  </a>\n</li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "<li>\n  <a href=\"#/operators\" title=\"Operadores\" class=\"operators\">\n    <i aria-hidden=\"true\" class=\"fa fa-users\"></i>\n    <span style=\"display:block\">Operadores</span>\n  </a>\n</li>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "<li class=\"menu-dashboards\">\n  <a href=\"#/dashboards\" title=\"Dashboards\">\n    <i aria-hidden=\"true\" class=\"fa fa-dashboard\"></i>\n    <span style=\"display:block\">Dashboards</span>\n  </a>\n</li>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "<li>\n  <a href=\"#/reports\" title=\"Relatórios\">\n    <i class=\"fa fa-files-o\" aria-hidden=\"true\"></i>\n    <span style=\"display:block\">Relatórios</span>\n  </a>\n</li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = 
  ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showDashboard : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCalendar : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
  stack1 = ((helper = (helper = helpers.showTasks || (depth0 != null ? depth0.showTasks : depth0)) != null ? helper : alias2),(options={"name":"showTasks","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.showTasks) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.showMaterials || (depth0 != null ? depth0.showMaterials : depth0)) != null ? helper : alias2),(options={"name":"showMaterials","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.showMaterials) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.showIssues || (depth0 != null ? depth0.showIssues : depth0)) != null ? helper : alias2),(options={"name":"showIssues","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.showIssues) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.showNotifications || (depth0 != null ? depth0.showNotifications : depth0)) != null ? helper : alias2),(options={"name":"showNotifications","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.showNotifications) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showOperators : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showDashboards : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showReports : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<li>\n  <a href=\"#/settings\" title=\"Definições\" class=\"settings\">\n    <i aria-hidden=\"true\" class=\"fa fa-cog\"></i>\n    <span style=\"display:block\">Definições</span>\n  </a>\n</li>\n\n<li class=\"logout\">\n  <a href=\"#/logout\" title=\"Terminar Sessão\" class=\"logout\">\n    <i aria-hidden=\"true\" class=\"fa fa-sign-out\"></i>\n    <span style=\"display:block\">Sair</span>\n  </a>\n</li>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}