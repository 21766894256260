var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"section-box\">\n    <header class=\"page-header\">\n        <div class=\"bs-callout bs-callout-default\">\n            <i aria-hidden=\"true\" class=\"fa fa-question-circle\"></i>\n            <h4>Ajuda</h4>\n        </div>\n    </header>\n\n    <div class=\"box help\">\n      <h5 >Ordens de Trabalho</h5>\n      <ul>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.orders_create_ticket || (depth0 != null ? depth0.orders_create_ticket : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orders_create_ticket","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como criar um pedido\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.orders_create || (depth0 != null ? depth0.orders_create : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orders_create","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como criar uma ordem de trabalho\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.orders_assign || (depth0 != null ? depth0.orders_assign : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orders_assign","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como atribuir uma ordem de trabalho\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.orders_do || (depth0 != null ? depth0.orders_do : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orders_do","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como executar uma ordem de trabalho\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.orders_validate || (depth0 != null ? depth0.orders_validate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orders_validate","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como validar uma ordem de trabalho\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.orders_close || (depth0 != null ? depth0.orders_close : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orders_close","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como fechar uma ordem de trabalho\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.orders_typification || (depth0 != null ? depth0.orders_typification : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orders_typification","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como criar um tipificação de ordem\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.orders_no_parameters || (depth0 != null ? depth0.orders_no_parameters : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orders_no_parameters","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            O que fazer quando um ordem não tem parâmetros\n          </a>\n        </li>\n      </ul>\n    </div>\n\n    <div class=\"box help\">\n      <h5 style=\"margin:20px 20px;\">Gestão de Materiais</h5>\n      <ul>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.materials_new_request || (depth0 != null ? depth0.materials_new_request : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"materials_new_request","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como criar necessidades de materiais\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.materials_new_order || (depth0 != null ? depth0.materials_new_order : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"materials_new_order","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como criar e gerir uma Encomenda\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.materials_budget || (depth0 != null ? depth0.materials_budget : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"materials_budget","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como criar um budget anual de materiais\n          </a>\n        </li>\n      </ul>\n    </div>\n\n    <div class=\"box help\">\n      <h5 style=\"margin:20px 20px;\">Assuntos</h5>\n      <ul>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.issues_why || (depth0 != null ? depth0.issues_why : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"issues_why","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            O que são e para que servem\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.issues_create || (depth0 != null ? depth0.issues_create : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"issues_create","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como se criam\n          </a>\n        </li>\n      </ul>\n    </div>\n\n    <div class=\"box help\">\n      <h5 style=\"margin:20px 20px;\">Relatórios</h5>\n      <ul>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.reports_generate || (depth0 != null ? depth0.reports_generate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reports_generate","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como gerar um relatório\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.reports_add_relevant_orders || (depth0 != null ? depth0.reports_add_relevant_orders : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reports_add_relevant_orders","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como adicionar ordens relevantes ao relatório\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.reports_add_comments || (depth0 != null ? depth0.reports_add_comments : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reports_add_comments","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como introduzir comentários num relatório\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.reports_delete || (depth0 != null ? depth0.reports_delete : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reports_delete","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como apagar um relatório\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.reports_regenerate || (depth0 != null ? depth0.reports_regenerate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reports_regenerate","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como voltar a gerar um relatório\n          </a>\n        </li>\n      </ul>\n    </div>\n\n    <div class=\"box help\">\n      <h5 style=\"margin:20px 20px;\">Operadores</h5>\n      <ul>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.operators_view || (depth0 != null ? depth0.operators_view : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"operators_view","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como visualizar os operadores definidos na plataforma\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.operators_calendar || (depth0 != null ? depth0.operators_calendar : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"operators_calendar","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como visualizar o calendário de um operador\n          </a>\n        </li>\n      </ul>\n    </div>\n\n    <div class=\"box help\">\n      <h5 style=\"margin:20px 20px;\">Dashboards</h5>\n      <ul>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.dashboards_overview || (depth0 != null ? depth0.dashboards_overview : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dashboards_overview","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como funcionam\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.dashboards_change_triggers || (depth0 != null ? depth0.dashboards_change_triggers : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dashboards_change_triggers","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como alterar os valores dos semáforos\n          </a>\n        </li>\n      </ul>\n    </div>\n\n    <div class=\"box help\">\n      <h5 style=\"margin:20px 20px;\">Definições</h5>\n      <ul>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.users_add_user || (depth0 != null ? depth0.users_add_user : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"users_add_user","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como criar um novo utilizador\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.users_password_reset || (depth0 != null ? depth0.users_password_reset : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"users_password_reset","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como fazer reset à password de um utilizador\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.users_deactivation || (depth0 != null ? depth0.users_deactivation : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"users_deactivation","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como ativar/desactivar um utilizador\n          </a>\n        </li>\n        <li>\n          <a href=\""
    + alias4(((helper = (helper = helpers.apiURL || (depth0 != null ? depth0.apiURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"apiURL","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.users_change_password || (depth0 != null ? depth0.users_change_password : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"users_change_password","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\n            Como alterar a palavra passe\n          </a>\n        </li>\n      </ul>\n    </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}