var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<ul class=\"nav nav-tabs\">\n    <li class=\"active\">\n        <a href=\"#\" class=\"step\" data-step=\"1\">Protocolo</a>\n        <a href=\"#\" class=\"step\" data-step=\"1\">\n            <i class=\"fa fa-list\" aria-hidden=\"true\"></i>\n        </a>\n    </li>\n    <li>\n        <a href=\"#\" class=\"step\" data-step=\"2\">Leituras</a>\n        <a href=\"#\" class=\"step\" data-step=\"2\">\n            <i class=\"fa fa-check-square-o\" aria-hidden=\"true\"></i>\n        </a>\n    </li>\n    <li>\n        <a href=\"#\" class=\"step\" data-step=\"3\">Notas & Fotos</a>\n        <a href=\"#\" class=\"step\" data-step=\"3\">\n            <i class=\"fa fa-sticky-note-o\" aria-hidden=\"true\"></i>\n        </a>\n    </li>\n    <li>\n        <a href=\"#\" class=\"step\" data-step=\"4\">Tempos</a>\n        <a href=\"#\" class=\"step\" data-step=\"4\">\n            <i class=\"fa fa-check\" aria-hidden=\"true\"></i>\n        </a>\n    </li>\n</ul>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}