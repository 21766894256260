(function () {
    'use strict';

    var BaseObject = require('controllers/base-object'),
        Radio      = require('shim/radio'),
        api        = require('shim/api'),

        SettingsView        = require('views/settings/settings-view'),
        ChangePasswordView  = require('views/settings/change-password-view'),
        RootzoneSettings    = require('views/settings/rootzone-settings-view'),

        UsersLayout         = require('layouts/users-layout'),
        UsersListView       = require('views/settings/users-list-view');

    module.exports = BaseObject.extend({
        radio: {
            'user:update:password':           'onUpdatePassword',
            'user:update:avatar':             'onUpdateAvatar',
            'rootzone:update:semaphores':     'onUpdateSemaphores',
            'rootzone:update:logo':           'onupdateLogo',
            'user:update:access':             'onUpdateAccess',
            'user:filter:access':             'onAccessFilter',
            'user:filter:reset':              'onFilterReset',
        },

        initialize: function (options) {
            BaseObject.prototype.initialize.apply(this, arguments);
            this.user = Radio.global.request('app:session').get('user');
            this.rootzone = Radio.store.request('get:rootzones').findWhere({ _id: parseInt(localStorage.getItem('rootzone_id')) });
            options = options || {}
            this.allUsers = options.allUsers;
            this.allUsersFiltered = this.allUsers;
            this.typifications = options.typifications;
        },

        showSettings: function (options) {
            $('.search-ot-group').hide();
            let self = this,
                url  = api.urlRoot + '/last_sync',
                request_options = {
                    url:  url,
                    type: 'GET',
                    contentType: 'application/json'
                };

            return (self.sync || Backbone.sync).call(self, null, self, request_options).done(function (response){
              this.show(new SettingsView({ model: this.user, last_sync: response.last_sync }), this);
            }.bind(this))
        },

        changePassword: function () {
            this.show(new ChangePasswordView({ model: this.user }), this);
        },

        rootzoneSettings: function (options) {
            this.show(new RootzoneSettings({ model: this.rootzone }), this);
        },

        usersSettings: function (options) {
          options = options || {}
          var usersLayout = new UsersLayout();

          //Render MainView
          this.showBind(usersLayout);
          this.persistFilters(options);

          //Render SubViews
          usersLayout.showChildView('view', new UsersListView({
            collection: this.allUsersFiltered
          }));
          Radio.global.trigger('anim-loading:hide');
        },

        onUpdatePassword: function () {
            this.user.save(null, { skip_store: true }).done(function () {
                Radio.global.command('alerts:show', { msg: 'Palavra passe atualizada com sucesso' });
            }).fail(function (xhr) {
                if (xhr.status === 400) {
                    Radio.global.command('alerts:show', { msg: 'Palavra passe errada', type: 'danger' });
                } else {
                    Radio.global.command('alerts:show', {
                        msg: 'Neste momento não é possível mudar a palavra passe',
                        type: 'danger'
                    });
                }
            });
        },

        onUpdateAccess: function (model) {
          model.save().done(function () {
            Radio.global.command('alerts:show', {
              msg: 'O estado do utilizador foi alterado com sucesso'
            });
            model.get("access") ? Radio.store.request('get:users').add(model) : Radio.store.request('get:users').remove(model)
          }).fail(function (xhr) {
            Radio.global.command('alerts:show', {
                msg: 'Neste momento não é possível alterar o acesso deste utilizador',
                type: 'danger'
            });
          });
        },

        onUpdateAvatar: function () {
            this.user.save()
        },

        onUpdateSemaphores: function () {
            this.rootzone.save()
                .always(() => {
                    window.scrollTo(0, 0);
                    Radio.global.command('alerts:dismiss');
                })
                .done(() => {
                    Radio.global.command('alerts:show', { msg: 'Alterações guardadas com sucesso!', type: 'success' });
                }).fail(() => {
                    Radio.global.command('alerts:show', { msg: 'Erro ao tentar guardar alterações', type: 'danger' });
                });
        },

        onupdateLogo: function () {
            this.rootzone.save();
        },

        onAccessFilter: function (options) {
          let filteredUsers;

          if ( options.withAccess && options.withNoAccess) {
            filteredUsers = this.allUsers.models;
          } else if (!options.withAccess && !options.withNoAccess) {
            filteredUsers = [];
          } else {
            filteredUsers = this.allUsers.where({access: options.withAccess || !options.withNoAccess})
          }

          this.allUsersFiltered = new Backbone.Collection(filteredUsers);
          this.usersSettings(options)
        },

        onFilterReset: function () {
          this.allUsersFiltered = new Backbone.Collection(this.allUsers.models);
          this.usersSettings({withAcess: true, withNoAccess: true});
        },

        persistFilters: function (options) {
          $('.users-access').prop('checked', options.withAccess);
          $('.users-no-access').prop('checked', options.withNoAccess);
          $('#filter-details').attr(options.filter,'');
        },
    });
})();
