(function () {
  'use strict';

  var Radio = require('shim/radio');

  module.exports = Marionette.Behavior.extend({
    onBeforeRender: function () {
      _.each(this.options, function (cb, ev) {
        if (typeof(cb) === 'function') {
          Radio.global.on(ev, cb, this.view);
        } else {
          Radio.global.on(ev, this.view[cb], this.view);
        }
      }.bind(this));
    },

    onBeforeDestroy: function () {
      _.each(this.options, function (cb, ev) {
        if (typeof(cb) === 'function') {
          Radio.global.off(ev, cb, this.view);
        } else {
          Radio.global.off(ev, this.view[cb], this.view);
        }
      }.bind(this));
    }
  });
})();
