var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "                Pedido\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                Ordem de Trabalho\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " style=\"display:none;\" ";
},"7":function(container,depth0,helpers,partials,data) {
    return "                    <li>\n                        <a href=\"#material\" title=\"Material\" role=\"tab\" data-toggle=\"tab\">Material</a>\n                        <a href=\"#material\" title=\"Material\" role=\"tab\" data-toggle=\"tab\">\n                            <i class=\"fa fa-cogs\" aria-hidden=\"true\"></i>\n                        </a>\n                    </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                  <div class=\"tab-pane\" id=\"material\"></div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return " Validação";
},"13":function(container,depth0,helpers,partials,data) {
    return " Fecho";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<header class=\"page-header\">\n    <div class=\"bs-callout bs-callout-default\">\n        <i class=\"fa fa-archive\" aria-hidden=\"true\"></i>\n        <h4>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRequest : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "        </h4>\n    </div>\n</header>\n<div class=\"box\">\n    <form id=\"submit-ot\" action=\"#\" method=\"post\">\n        <section id=\"details\"></section>\n        <section class=\"content\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRequest : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <ul class=\"nav nav-tabs\" role=\"tablist\">\n                <li class=\"active\">\n                    <a href=\"#equipment\" title=\"Equipamento/Unidades\" role=\"tab\" data-toggle=\"tab\">"
    + alias4(((helper = (helper = helpers.tabTitle || (depth0 != null ? depth0.tabTitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tabTitle","hash":{},"data":data}) : helper)))
    + "</a>\n                    <a href=\"#equipment\" title=\"Equipamento/Unidades\" role=\"tab\" data-toggle=\"tab\">\n                        <i class=\"fa fa-list\" aria-hidden=\"true\"></i>\n                    </a>\n                </li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasMaterials : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                <li>\n                  <a href=\"#material-requests\" title=\"MaterialRequests\" role=\"tab\" data-toggle=\"tab\">Pedido de Material</a>\n                  <a href=\"#material-requests\" title=\"MaterialRequests\" role=\"tab\" data-toggle=\"tab\">\n                    <i class=\"fa fa-cogs\" aria-hidden=\"true\"></i>\n                  </a>\n                </li>\n                <li>\n                    <a href=\"#summary\" title=\"Resumo\" role=\"tab\" data-toggle=\"tab\">Resumo</a>\n                    <a href=\"#summary\" title=\"Resumo\" role=\"tab\" data-toggle=\"tab\">\n                        <i class=\"fa fa-file-text-o\" aria-hidden=\"true\"></i>\n                    </a>\n                </li>\n            </ul>\n            <section class=\"tab-content\">\n                <div class=\"tab-pane active\" id=\"equipment\"></div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasMaterials : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                <div class=\"tab-pane\" id=\"material-requests\"></div>\n                <div class=\"tab-pane\" id=\"summary\"></div>\n            </section>\n        </section>\n\n        <p>\n            <button for=\"submit-ot\" type=\"submit\" class=\"button button-primary button-full-width submit-order\" style=\"display:"
    + alias4(((helper = (helper = helpers.showSubmitBtn || (depth0 != null ? depth0.showSubmitBtn : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"showSubmitBtn","hash":{},"data":data}) : helper)))
    + "\">\n                Confirmar"
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.state : depth0),"done",{"name":"eq","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.state : depth0),"validated",{"name":"eq","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </button>\n        </p>\n\n    </form>\n</div>\n\n<div id=\"modal-material-request-content\">\n  <form action=\"\" class=\"create-material-requests\"></form>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}