'use strict';

var Radio = require('shim/radio');

var storage = {

    init: function () {

        /**
         * RADIO CALLS
         */
        Radio.store.comply('save:queue', this.onStoreQueue);
        Radio.store.comply('save:model', this.onCollectionAdd);
        Radio.store.reply('load:queue', this.onLoadQueue);
        Radio.global.on('app:logout', storage.prepareLogoutStorage);

        //Fetch session info
        var rootzone_id = Radio.global.request('app:session').get('rootzone_id'),
            token = localStorage.getItem('auth-token'),
            offlineQueue = localStorage.getItem('queue');

        //Clear storage
        localStorage.clear();

        //Remake essential sesison info
        localStorage.setItem('auth-token', token);
        localStorage.setItem('queue', offlineQueue);
        localStorage.setItem('rootzone_id', rootzone_id);

    },

    //Store Queue
    onStoreQueue: function (queue) {
        localStorage.setItem('queue', JSON.stringify(queue));
    },

    //Load Queue
    onLoadQueue: function () {
        return JSON.parse(localStorage.getItem('queue'))
    },

    //Store callback model into collection
    onCollectionAdd: function (store, model) {
        if (store === 'tasks') {
            var order = Radio.store.request('get:orders').findWhere({ id: parseInt(model.get('order_id')) });
            order.get('tasks').add(model, { merge: true });
            order.emulateStateMachine()

            return;
        }
        Radio.store.request('get:' + store).add(model, { merge: true });
    },

    //Save queue and remove localstorage for logout
    prepareLogoutStorage: function () {
        var offlineQueue = localStorage.getItem('queue');
        localStorage.clear();
        localStorage.setItem('queue', offlineQueue);

        Radio.global.command('offline:terminate');
    }
};


module.exports = storage;
