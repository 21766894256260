(function () {
    'use strict';

    var Radio = require('shim/radio'),
        PAGE_SIZE = 10;

    module.exports = Marionette.CompositeView.extend({
        template: require('templates/main/notifications-list'),
        childView: require('views/main/notifications-list-item-view'),
        childViewContainer: 'tbody',

        ui: {
            'dismissAllButton': 'button.action-dismiss-all'
        },

        events: {
            'click @ui.dismissAllButton': 'onDismissAllButtonClicked'
        },

        templateHelpers: function () {
            return {
                notifications: this.collection.length > 0 ? true : false
            };
        },

        onDismissAllButtonClicked: function () {
            this.collection.discardAll();;
        }
    });
})();
