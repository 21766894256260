(function () {
    'use strict';

    module.exports = {
        filters: {
            category_id:                -1,
            equipment_family_id:        -1,
            equipment_id:               -1,
            user_group_id:              -1,
            user_id:                    -1,
            work_type_id:               -1,
            order_origin:               -1,
            order_material_dependence:  -1,
            material_order_status:      -1,
            typified_group:             -1,
            search_text:                -1,
            begin_date:                 -1,
            end_date:                   -1,
            order_state_id:             -1,
            priority:                   -1
        },
        states: {
            dashboard: {
                state_id: 3
            },
            calendar: {
                type: 'daily'
            }
        },
        menus: {
            admin: {
                dashboards:     false,
                dashboard:      false,
                calendar:       false,
                tasks:          false,
                issues:         false,
                materials:      false,
                notifications:  false,
                operators:      false,
                orders:         false,
                rootzone:       false,
                reports:        false
            },
            solicitant: {
                dashboards:     false,
                dashboard:      false,
                calendar:       false,
                tasks:          true,
                issues:         false,
                materials:      false,
                notifications:  false,
                operators:      false,
                orders:         false,
                rootzone:       false,
                reports:        false
            },
            operator: {
                dashboards:     false,
                dashboard:      false,
                calendar:       true,
                tasks:          true,
                issues:         false,
                materials:      false,
                notifications:  false,
                operators:      false,
                orders:         true,
                rootzone:       false,
                reports:        false
            },
            foreman: {
                dashboards:     true,
                dashboard:      true,
                calendar:       true,
                tasks:          true,
                issues:         false,
                materials:      true,
                notifications:  true,
                operators:      true,
                orders:         true,
                rootzone:       false,
                reports:        false
            },
            manager: {
                dashboards:     true,
                dashboard:      true,
                calendar:       false,
                tasks:          true,
                issues:         true,
                materials:      true,
                notifications:  true,
                operators:      true,
                orders:         true,
                rootzone:       true,
                reports:        true
            }

        },
        forbiddenRouteRedirect: {
            admin: {
                router: 'main',
                redirect: 'dashboards'
            },
            solicitant: {
                router: 'main',
                redirect: 'tasks'
            },
            operator: {
                router: 'calendar',
                redirect: 'calendar/day'
            },
            foreman: {
                router: 'main',
                redirect: 'dashboard'
            },
            manager: {
                router: 'main',
                redirect: 'dashboard'
            }
        },
        lastView: {
          materials: 0
        },
        priorities: [
          {
            value: 1,
            longText: 'Início de Intervenção até 8 horas do dia seguinte',
            shortText: '<8h'
          },
          {
            value: 2,
            longText: 'Início de Intervenção até às próximas 48 horas',
            shortText: '<48h'
          },
          {
            value: 3,
            longText: 'Início de Intervenção até aos próximos 7 dias',
            shortText: '<7d'
          },
          {
            value: 4,
            longText: 'Início de Intervenção até aos próximos 15 dias',
            shortText: '<15d'
          },
          {
            value: 5,
            longText: 'Início de Intervenção até aos próximos 30 dias',
            shortText: '<30d'
          }
        ],
    };
})();
