(function () {
    'use strict';

    var api = require('shim/api');

    module.exports = Backbone.Model.extend({
        urlRoot: api.urlRoot + '/dashboards4',
        defaults: {
            user: {
                photo_url: 'images/collaborator.jpg',
                name: '',
                group: ''
            },
            ots: {
                total_ot: 0,
                done: 0,
                done_perc: 0,
                ongoing: 0,
                ongoing_perc: 0,
                todo: 0,
                todo_perc: 0
            },
            ot_done: [
                {
                    code: '',
                    title: '',
                    work_type: '',
                    frequency: '',
                    equipment: ''
                },
                {
                    code: '',
                    title: '',
                    work_type: '',
                    frequency: '',
                    equipment: ''
                },
                {
                    code: '',
                    title: '',
                    work_type: '',
                    frequency: '',
                    equipment: ''
                },
                {
                    code: '',
                    title: '',
                    work_type: '',
                    frequency: '',
                    equipment: ''
                },
                {
                    code: '',
                    title: '',
                    work_type: '',
                    frequency: '',
                    equipment: ''
                }
            ],
            ot_ongoing: [
                {
                    code: '',
                    title: '',
                    work_type: '',
                    frequency: '',
                    equipment: ''
                }
            ],
            ot_todo: [
                {
                    code: '',
                    title: '',
                    work_type: '',
                    frequency: '',
                    equipment: ''
                }
            ],
            ot_future: [
                {
                    code: '',
                    title: '',
                    work_type: '',
                    frequency: '',
                    equipment: ''
                }
            ]
        }
    });
})();
