var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "  <td style=\"text-align:center\">"
    + container.escapeExpression((helpers.getIssueStatus || (depth0 && depth0.getIssueStatus) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.is_closed : depth0),{"name":"getIssueStatus","hash":{},"data":data}))
    + "</td>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <td align=\"center\">\n    <a class=\"button button-success button-dashboard close-issue\" data-id=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\" style=\"border:0\">\n      <i aria-hidden=\"true\" class=\"fa fa-check\" data-id=\""
    + alias4(((helper = (helper = helpers._id || (depth0 != null ? depth0._id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"_id","hash":{},"data":data}) : helper)))
    + "\"></i> Fechar\n    </a>\n  </td>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<td align=\"center\">\n  <a class=\"button button-default btn-clipboard\" style=\"border:0\">\n    <i aria-hidden=\"true\" class=\"fa fa-clipboard\"></i>\n  </a>\n</td>\n\n<td type=\"text\" class=\"description\" data-description=\""
    + alias3((helpers.formatIssue || (depth0 && depth0.formatIssue) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),{"name":"formatIssue","hash":{},"data":data}))
    + "\" >"
    + alias3((helpers.formatIssue || (depth0 && depth0.formatIssue) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),{"name":"formatIssue","hash":{},"data":data}))
    + "</td>\n\n<td>"
    + alias3(((helper = (helper = helpers.equipment_name || (depth0 != null ? depth0.equipment_name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"equipment_name","hash":{},"data":data}) : helper)))
    + "</td>\n\n<td style=\"text-align:center\">"
    + alias3(((helper = (helper = helpers.order_code || (depth0 != null ? depth0.order_code : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"order_code","hash":{},"data":data}) : helper)))
    + "</td>\n\n<td style=\"text-align:center\">"
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.created_at : depth0),{"name":"moment","hash":{"format":"DD/MM/YYYY"},"data":data}))
    + "<br>"
    + alias3(((helper = (helper = helpers.created_by || (depth0 != null ? depth0.created_by : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"created_by","hash":{},"data":data}) : helper)))
    + "</td>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.is_closed : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}