(function () {
    'use strict';

    var api = require('shim/api');

    module.exports = Backbone.Model.extend({
        urlRoot: api.urlRoot + '/dashboards1',
        defaults: {
            percentage: 0.0,
            ot_done: 0.0,
            ot_total: 0.0,
            percentage_acum: 0.0,
            ot_done_acum: 0.0,
            ot_total_acum: 0.0,
            treshold1: 85.0,
            treshold2: 95.0,
            treshold3: 100.0
        }
    });
})();
