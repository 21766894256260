var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                Em Atraso ("
    + container.escapeExpression(((helper = (helper = helpers.size || (depth0 != null ? depth0.size : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data}) : helper)))
    + ")\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                "
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),{"name":"moment","hash":{"format":"dddd","lang":"pt"},"data":data}))
    + "\n                <abc class='size-hammer'> ("
    + alias3(((helper = (helper = helpers.size || (depth0 != null ? depth0.size : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"size","hash":{},"data":data}) : helper)))
    + ")</abc>\n                <span>"
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),{"name":"moment","hash":{"format":"DD MMMM","lang":"pt"},"data":data}))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, buffer = 
  "                <li data-category-id=\""
    + alias4(((helper = (helper = helpers.order_category_id || (depth0 != null ? depth0.order_category_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"order_category_id","hash":{},"data":data}) : helper)))
    + "\" data-equipment-id=\""
    + alias4(((helper = (helper = helpers.equipment_id || (depth0 != null ? depth0.equipment_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"equipment_id","hash":{},"data":data}) : helper)))
    + "\"\n                    data-equipment-family-id=\""
    + alias4(((helper = (helper = helpers.equipment_family_id || (depth0 != null ? depth0.equipment_family_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"equipment_family_id","hash":{},"data":data}) : helper)))
    + "\" class=\"item order\">\n\n                    <a href=\"#orders/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\">\n\n                        <span class=\"desc\">\n\n                          <div class=\"order-description\">\n                            <div class=\"order-number\">\n                              "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.number : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                            </div>\n                            <div class=\"order-info\">\n                                <b>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</b>\n                                <br>\n                                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.work_type_title : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                            </div>\n                          </div>\n\n                        </span>\n\n                        <time style=\"margin-left:auto;\" datetime=\""
    + alias4((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.start_date_preview : depth0),{"name":"moment","hash":{"format":"YYYY-MM-DD","lang":"pt"},"data":data}))
    + "\">\n";
  stack1 = ((helper = (helper = helpers.isNotOperator || (depth0 != null ? depth0.isNotOperator : depth0)) != null ? helper : alias2),(options={"name":"isNotOperator","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isNotOperator) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                            <span>\n                                "
    + alias4((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.start_date_preview : depth0),{"name":"moment","hash":{"format":"ddd, DD MMM","lang":"pt"},"data":data}))
    + "\n                                <i aria-hidden=\"true\" class=\"fa fa-calendar\"></i>\n                            </span>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.has_image : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.priority : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </time>\n\n\n                    </a>\n                </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.number || (depth0 != null ? depth0.number : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"number","hash":{},"data":data}) : helper)));
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return alias4(((helper = (helper = helpers.equipment_title || (depth0 != null ? depth0.equipment_title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"equipment_title","hash":{},"data":data}) : helper)))
    + " | "
    + alias4(((helper = (helper = helpers.work_type_title || (depth0 != null ? depth0.work_type_title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"work_type_title","hash":{},"data":data}) : helper)))
    + "\n                                "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || alias2).call(alias1,(depth0 != null ? depth0.work_type_title : depth0),"Preventivo",{"name":"eq","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return " ("
    + container.escapeExpression(((helper = (helper = helpers.period_title || (depth0 != null ? depth0.period_title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"period_title","hash":{},"data":data}) : helper)))
    + ")";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.duration : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                                    <span style=\"color:#B9B9B9\">\n                                        "
    + container.escapeExpression(((helper = (helper = helpers.duration || (depth0 != null ? depth0.duration : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"duration","hash":{},"data":data}) : helper)))
    + " <i class=\"fa fa-clock-o\"></i>\n                                    </span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "                            <span>\n                              Tem Imagem\n                              <i aria-hidden=\"true\" class=\"fa fa-picture-o\"></i>\n                            </span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                            <span "
    + ((stack1 = (helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.priority : depth0)) != null ? stack1.value : stack1),1,{"name":"eq","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                              Prioridade: "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.priority : depth0)) != null ? stack1.value : stack1), depth0))
    + " ("
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.priority : depth0)) != null ? stack1.shortText : stack1), depth0))
    + ")\n                              <i class=\"fa fa-first-order\" aria-hidden=\"true\"></i>\n                            </span>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return " style=\"color: red;\" ";
},"19":function(container,depth0,helpers,partials,data) {
    return "                <li>Não foram encontrados eventos</li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=helpers.blockHelperMissing, buffer = 
  "<div class=\"panel-heading\">\n    <h4 class=\"panel-title\">\n        <a href=\"#"
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),{"name":"moment","hash":{"format":"YYYYMMDD","lang":"pt"},"data":data}))
    + "\" title=\""
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),{"name":"moment","hash":{"format":"dddd","lang":"pt"},"data":data}))
    + "\"\n           data-toggle=\"collapse\"\n           class=\"collapsed\">\n";
  stack1 = ((helper = (helper = helpers.isLate || (depth0 != null ? depth0.isLate : depth0)) != null ? helper : alias2),(options={"name":"isLate","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!helpers.isLate) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </a>\n    </h4>\n</div>\n<div id=\""
    + alias3((helpers.moment || (depth0 && depth0.moment) || alias2).call(alias1,(depth0 != null ? depth0.date : depth0),{"name":"moment","hash":{"format":"YYYYMMDD","lang":"pt"},"data":data}))
    + "\" class=\"panel-collapse collapse\">\n    <div class=\"panel-body\">\n        <ul id=\"cal\" class=\"day-view cal-day-view\">\n";
  stack1 = ((helper = (helper = helpers.items || (depth0 != null ? depth0.items : depth0)) != null ? helper : alias2),(options={"name":"items","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(19, data, 0),"data":data}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!helpers.items) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </ul>\n    </div>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}