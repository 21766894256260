(function () {
    'use strict';

    var BaseObject = require('controllers/base-object'),
        Radio = require('shim/radio'),

        IssuesLayout = require('layouts/issues-layout'),
        IssuesListView = require('views/main/issues-list-view');

    module.exports = BaseObject.extend({
        initialize: function (options) {
          BaseObject.prototype.initialize.apply(this, arguments);
          options = options || {};
          this.issues = options.issues;
          this.filteredCollection = this.issues.vCollection(this.currentFilters, this);
        },
        showIssues: function () {
            $('.search-ot-group').hide();
            var self = this,
                issuesLayout = new IssuesLayout();
            //Render MainView
            self.showBind(issuesLayout);
            //Render SubViews
            issuesLayout.showChildView('view', new IssuesListView({
                collection: self.filteredCollection
            }));
            Radio.global.trigger('anim-loading:hide');
        },
    });
})();
