(function () {
  'use strict';

  var Radio = require('shim/radio'),
  Users = require('collections/users'),
  dateGroupedCollection = require('collections/date-grouped-collection');

  module.exports = Marionette.CompositeView.extend({
    template: require('templates/dashboard/dashboard-group'),
    childView: require('./dashboard-item-view'),
    childViewContainer: '#accordion',
    childViewOptions: function () {
      return {
        selected: this.selected,
        ottype: this.ottype
      };
    },
    ui: {
      'assign':       '.assign-ot',
      'panel_group':  '.panel-group'
    },
    behaviors: {
      OrdersAssign: {
        container: '.assign'
      }
    },
    events: {
      'click .assign-button':   'onAssignButtonClick',
      'change .order':          'onOrderChange',
      'click .pages a':         'onChangePage',
      'click .validate-button': 'onValidateButtonClick',
      'click .expandAll':       'onExpandAll',
      'click .collapseAll':     'onCollapseAll'
    },
    initialize: function (options) {
      this.today = new Date();
      this.userGroups = options.groups;
      this.users = options.users;
      this.ottype = options.ottype;
      this.originalCollection = options.collection;
      this.selected = [];
      this.currentPage = 0;
    },
    onRender: function () {
      var self = this;
      this.children.each(_.bind(function (childView) {
        childView.ui.atag.attr('href', '#' + self.ottype + childView.ui.atag.attr('href').substr(1));
        childView.ui.bodytag.attr('id', self.ottype + childView.ui.bodytag.attr('id'));
      }, this));
      this.$('.assign-button,#message').hide();
    },
    templateHelpers: function () {
      var pages = _.times(this.totalPages, _.identity)
      return {
        currentPage: this.currentPage,
        pages: pages,
        ottype: this.ottype,
        showPageDropdown: pages.length > 1
      };
    },
    _loadPages: function () {
      var last_day = _(this.originalCollection.pluck('end')).max().value();
      if (isFinite(last_day)) {
        var diff_days = (last_day.diff(this.today, 'days')) + 1;
        this.totalPages = Math.ceil(diff_days / 7);
        this.totalPages = this.totalPages < 1 ? 1 : this.totalPages;
        if (this.totalPages <= this.currentPage)
          this.currentPage = this.totalPages - 1;
        return;
      }
      this.totalPages = 1;
      this.currentPage = 0;
    },
    // we need to keep track of the checkboxes
    // because of the accordions
    onExpandAll: function () {
      this.ui.panel_group.find('.panel-collapse:not(".in")')
      .collapse('show');
    },
    onCollapseAll: function () {
      this.ui.panel_group.find('.panel-collapse.in')
      .collapse('hide');
    },
    onOrderChange: function (ev) {
      var $el = $(ev.currentTarget),
      val = parseInt($el.data('id'), 10);

      if ($el.is(':checked')) {
        this.selected.push(val);
      } else {
        this.selected.splice(this.selected.indexOf(val), 1);
      }
    },
    onAssignButtonClick: function () {
      this._setMessage('');
      var data = _.extend(this.$el.serializeObject(), { orders: this.selected });

      if (_.isEmpty(data.orders)) {
        this._setMessage('Por favor Selecione pelo menos uma OT');
        return;
      }

      //Clean Selected List
      this.selected = [];

      Radio.global.trigger('anim-loading:show');
      Radio.global.trigger('dashboard:assign:ot', data);
    },
    onValidateButtonClick: function () {
      this._setMessage('');

      var data = _.extend(this.$el.serializeObject(), { orders: this.selected });
      if (_.isEmpty(data.orders)) {
        this._setMessage('Por favor Selecione pelo menos uma OT');
        return;
      }

      Radio.global.trigger('dashboard:validate', data);
    },
    onOrdersFiltered: function (orders) {
      this.originalCollection = orders;
      this._setCollection();
    },
    onHideAssignment: function () {
      this.$('.assignment').hide();
    },
    onHideValidation: function () {
      this.$('.validation').hide();
    },
    onChangePage: function (evt) {
      evt.preventDefault();
      var page = $(evt.target).data('page');
      this.currentPage = page;
      this._setCollection();
    },
    _setCollection: function (options) {
      options = options || {};
      var shouldRender = options.render || true,
          addLate = this.currentPage === 0,
          isValidated = (this.ottype == 'V' || this.ottype == 'S'),
          date = moment(this.today).add(this.currentPage * 7, 'days').toDate();

      this.collection = dateGroupedCollection({
        originalCollection: this.originalCollection,
        today: date,
        addLate: addLate,
        isValidated: isValidated,
        view: this
      });

      this._loadPages();
      if (shouldRender) {
        this.render();
      }
    },
    _parse: function (data) {
      var omit = data.assign_ot === 'user_group_id' ? 'user_ids' : 'user_group_id',
          val = data.assign_ot === 'user_group_id' ? [] : null,
          result;

      data[data.assign_ot] = parseInt(data[data.assign_ot], 10);
      result = _.pick(data, ['orders', data.assign_ot]);
      result[omit] = val;

      if (!_.isUndefined(result.user_ids) && !_.isArray(result.user_ids)) {
        result.user_ids = [result.user_ids];
      }

      this.selected = [];

      return result;
    },
    _setMessage: function (message) {
      var $message = this.$('#message');
      $message.text(message);
      return message === '' ? $message.hide() : $message.show();
    }
  });
})();
