(function () {
  'use strict';

  var Radio    = require('shim/radio');

  module.exports = Marionette.ItemView.extend({
    template: require('templates/main/notifications-list-item'),
    tagName:  'tr',

    ui: {
      'dismissButton': 'button.action-dismiss'
    },

    events: {
      'click .notification-data': 'onNotificationClicked',
      'click @ui.dismissButton':  'onDismissClicked'
    },

    modelEvents: {
      change: 'render',
    },

    onNotificationClicked: function () {
      var resource = 'router:notifications:navigate',
          route    = ['notifications', this.model.get('_id')].join('/');

      Radio.global.command(resource, route, { trigger: true });
    },

    onDismissClicked: function () {
      this.model.discard();
    },

  });
})();
