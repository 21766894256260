(function () {
  'use strict';

  var BaseObject = require('controllers/base-object'),
      Radio      = require('shim/radio'),
      globalValues = require('shim/global-values'),

      MaterialOrdersLayout       = require('layouts/materials/material-orders-layout'),
      MaterialOrdersListView     = require('views/materials/material-orders/material-orders-list-view'),
      MaterialOrdersFiltersView  = require('views/main/filters-view');

  module.exports = BaseObject.extend({
    radio: {
      'materialOrders:print':          'onPrintMaterialOrders',
      'filter:material-orders:change': 'onFiltersChange',
    },

    initialize: function (options = {}) {
      BaseObject.prototype.initialize.apply(this, arguments);

      this.materialOrders = options.materialOrders;
      this.currentFilters = Radio.global.request('app:filters');
      this.sanitizeFilters(this.currentFilters);
      this.filteredCollection = this.materialOrders.vCollection(this.currentFilters, this);
    },

    sanitizeFilters: function (filters) {
      var merged_filters =  _.merge( this.currentFilters, filters, function (a, b) {
        if (_.isArray(a) || b === false) return b;
      });

      this.currentFilters = _.pick(merged_filters, function (value, key) {
        return ['material_order_status', 'search_text'].includes(key)  && value !== false && value != '-1';
      });
    },

    showMaterialOrders: function (options) {
      $('.search-ot-group').show();
      globalValues.lastView.materials = 1

      var materialOrdersLayout = new MaterialOrdersLayout()
      //Render MainView
      this.show(materialOrdersLayout, this);
      //Render SubViews
      materialOrdersLayout.showChildView('filters', new MaterialOrdersFiltersView({
        isMaterialOrder:         true,
        showMaterialOrderStatus: true,
        showPrintButton:         true
      }));

      materialOrdersLayout.showChildView('view', new MaterialOrdersListView({
        collection: this.filteredCollection
      }));

      Radio.global.trigger('anim-loading:hide');
    },

    onPrintMaterialOrders: function () {
      var materialOrderIds = this.filteredCollection.pluck('id');

      if ( _.isEmpty(materialOrderIds) ) {
        Radio.global.trigger('anim-loading:hide');
      } else {
        Radio.global.trigger('anim-loading:show');
        this.materialOrders.generatePDF(materialOrderIds, this.currentFilters).done(function(data){
          Radio.global.trigger('anim-loading:hide');
          window.open(data.url);
        })
      }
    },

    onFiltersChange: function (options) {
      this.sanitizeFilters(options);
      this.filteredCollection.updateFilterParams(this.currentFilters);
      const disabled = _.isEmpty(this.filteredCollection.pluck('id'));
      $('.print-pdf').prop('disabled', disabled);
    }
  });
})();
