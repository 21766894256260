(function () {
    'use strict';

    // POSSIBLE FIXME: fetch these ids from API /api/order_categories
    var Radio = require('shim/radio'),
        ids = {
            work_order: 3,
            event: 2,
            routine: 1,
            alarm: 2
        };

    Radio.global.reply('category:id', function (slug) {
        return ids[slug];
    });

})();
