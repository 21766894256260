'use strict';

const Reports = require('collections/reports');

module.exports = Marionette.CompositeView.extend({
    template: require('templates/main/reports/reports'),
    emptyView: require('views/main/reports/reports-empty-view.js'),
    childView: require('views/main/reports/reports-item-view.js'),
    childViewContainer: '.report-downloads',
    events: {
        'click .fa-chevron-left': 'minusYear',
        'click .fa-chevron-right': 'plusYear'
    },
    collectionEvents: {
        'reset': 'render'
    },

    viewComparator: report => {
        return `${report.get('month').toString().padStart(2, "0")}/${report.get('year')}`;
    },

    initialize() {
        moment.locale('pt');
        this.year = parseInt(moment().format('YYYY'));
        this.minYear = _.min(this.options.years);
        this.maxYear = _.max(this.options.years);
        this.originalCollection = new Reports(this.options.collection.models);

        this.changeReportsYear(this.year)
    },

    templateHelpers() {
        return {
            currentYear: this.year,
            isMinYear: this.year === this.minYear ? true : false,
            isMaxYear: this.year === this.maxYear ? true : false
        }
    },

    minusYear() {
        if (this.year - 1 < this.minYear)
            return;

        this.changeReportsYear(--this.year)
    },

    plusYear() {
        if (this.year + 1 > this.maxYear)
            return;

        this.changeReportsYear(++this.year)
    },

    changeReportsYear(year) {
        this.collection.reset(this.originalCollection.where({ year }));
    }
});
