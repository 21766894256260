(function () {
    'use strict';

    _.extend(Backbone.Model.prototype, Backbone.Validation.mixin);

    var oldBind = Backbone.Validation.bind;
    Backbone.Validation.bind = function (view) {
        view.model.bind('validated:invalid', function () {
            var $err = view.$('.smm-error-field').first();
            if ($err && $err.offset()) {
                $('body').animate({
                    // stupid scroll is stupid
                    // it's not showing the erroneous input without the -100
                    scrollTop: $err.offset().top - 100
                });
            }
        });
        oldBind.apply(Backbone.Validation, arguments);
    };

    var oldUnbind = Backbone.Validation.unbind;
    Backbone.Validation.unbind = function (view) {
        view.model.unbind('validated:invalid');
        oldUnbind.apply(Backbone.Validation, arguments);
    };
})();
