'use strict';

var Base = require('views/orders/steps/order-step-base-view');

module.exports = Base.extend({
    template: require('templates/orders/steps/order-step-two'),

    initialize: function () {
        if (!this.model.has('readings_attributes') && this.model.has('readings'))
            this.cloneAttributes();
    },

    fillCurrentModelState: function () {
        var readings = this.model.get('readings_attributes'),
            data = this.$el.serializeObject();

        _.each(readings, function (reading, index) {
            reading.value = data[index];
            reading.date = moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        })
    },

    cloneAttributes: function () {
        this.model.set('readings_attributes', JSON.parse(JSON.stringify(this.model.get('readings'))));
    },

    templateHelpers: function () {
        return {
            readings: this.model.has('readings_attributes') ? this.model.get('readings_attributes') : null
        };
    }
});
