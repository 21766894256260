(function () {
    'use strict';

    var api = require('shim/api'),
        vCollection = require('collections/mixins/virtual-collection'),

        OrderTypification = require('models/order-typification');

    module.exports = Backbone.Collection.extend({
        model: OrderTypification,
        url: api.urlRoot + '/order_typifications',
        store: 'order_typifications',
        comparator: function (item) {
          return [item.get("typified_group"), item.get("title")]
        },

        constructor: function () {
            Backbone.Collection.apply(this, arguments);
            this.vCollection = vCollection.bind(this);
        },

        parse: function (response) {
            return response.order_typifications;
        },

        getCategories: function () {
          // Pluck categories and get unique values
          let categories = [...new Set(this.pluck('typified_group'))]
          return categories.sort();
        },

        getAllActive: function () {
          let filtered = this.where({active: true});
          return new module.exports( filtered );
        }
    });
})();
