var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<header class=\"page-header\">\n    <div class=\"bs-callout bs-callout-default\">\n      <i class=\"fa fa-list\" aria-hidden=\"true\"></i>\n      <h4>Encomenda de Materiais</h4>\n    </div>\n</header>\n\n<section class=\"view\"></section>\n\n<section class=\"requests\" style='padding:0'></section>\n\n<div style=\"display:flex\">\n  <a href=\"#material_orders\">\n    <button class=\"button button-primary\">\n      <i class=\"fa fa-arrow-left\" aria-hidden=\"true\"></i>\n      <span>Voltar</span>\n    </button>\n  </a>\n  <a  style=\"margin-left:10px\">\n    <button class=\"button button-primary print-material-order\">\n      <i class=\"fa fa-file-pdf-o\" aria-hidden=\"true\"></i>\n      <span>Imprimir</span>\n    </button>\n  </a>\n  <a href=\"#material_orders/edit/"
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)))
    + "\" style=\"margin-left:10px\">\n    <button class=\"button button-secondary\">\n      <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n      <span>Editar</span>\n    </button>\n  </a>\n  <a style=\"margin-left:10px\">\n    <button class=\"button button-secondary delete-material-order\" style='display:none'>\n      <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n      <span>Apagar</span>\n    </button>\n  </a>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}