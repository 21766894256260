(function () {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: require('templates/materials/material-orders/material-order-details'),
    tagName: 'div',
    className: 'box',
    modelEvents: {
      change: 'render'
    }
  });
})();
