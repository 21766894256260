(function () {
  'use strict';

  var Radio = require('shim/radio');

  module.exports = Marionette.ItemView.extend({
    template: require('templates/header'),

    ui: {
      navTrigger: 'label',
      sync: '.sync',
      search: '.search'
    },

    behaviors: {
      Radio: {
        'spinner:on': 'onSpinnerOn',
        'spinner:progress': 'onSpinnerProgress',
        'spinner:off': 'onSpinnerOff'
      }
    },

    events: {
      'click @ui.sync':   'onSyncClicked',
      'click @ui.search': 'onSearchClicked',
      'keyup #search-ot': 'onSearchKeyUp'
    },

    collectionEvents: {
      'reset':  'onNotificationsChange',
      'change': 'onNotificationsChange',
      'update': 'onNotificationsChange'
    },

    onRender: function () {
      this.$('.sync').hide();
      Radio.global.trigger('offline:check:queue');
    },

    onNotificationsChange: function () {
      var unread_notifications = Radio.store.request('get:notifications')
                                            .where({state: "unread"}).length

      if (unread_notifications > 0) {
        this.$('.bell').find('span').text(this._count(unread_notifications));
      } else {
        this.$('.bell').hide();
      }
    },

    onSpinnerOn: function (options) {
      var $sync = this.$('.sync');
      $sync.find('span').text(this._count(options.count));
      $sync.show().find('i').removeClass('fa-spin');
    },

    onSpinnerProgress: function (options) {
      var $sync = this.$('.sync');
      $sync.find('span').text(this._count(options.count));
      $sync.find('i').addClass('fa-spin');
    },

    onSpinnerOff: function () {
      this.$('.sync').hide();
    },

    onSyncClicked: function (ev) {
      ev.preventDefault();
      Radio.global.command('offline:sync');
    },

    onSearchClicked: function (ev) {
      ev.preventDefault();
      Radio.global.trigger(this.getRoute(), this._getTextToSearch());
    },

    onSearchKeyUp: function (ev) {
      ev.preventDefault();
      if (ev.keyCode == 13) {
        Radio.global.trigger(this.getRoute(), this._getTextToSearch());
      }
    },

    templateHelpers: function () {
      var unreadNotifications = Radio.store.request('get:notifications')
                                            .where({state: "unread"}).length;

      return {
        notifications: this._count(unreadNotifications),
        user: Radio.global.request('app:session').get('user').toJSON()
      };
    },

    _getTextToSearch: function () {
      var text = $('#search-ot').val();
      return {search_text: text};
    },

    _count: function (count) {
      return count < 100 ? count : '∞';
    },

    getRoute: function () {
      var url = window.location.href;

      if ( url.includes('material_orders') ) {
        return 'filter:material-orders:change';
      } else if ( url.includes('typified_orders') ) {
        return 'filter:typified-orders:change';
      } else {
        return 'filter:orders:change';
      }
    }
  });
})();
