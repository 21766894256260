(function () {
    'use strict';

    var Radio = require('shim/radio');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/orders/do-order-progress'),

        events: {
            'click .step': 'onStepClicked'
        },

        behaviors: {
            Radio: {
                'do:order:change:step': 'onOrderStep'
            }
        },

        initialize: function (options) {
          this.order = options.order;
          this.task = options.task;
          this.task.set('order_id', this.order.get('id'));
          if (this.order.hasImagesOnTasks()) {
            this.task.fetch();
          }
        },

        onStepClicked: function (ev) {
            ev.preventDefault();
            var data = {step: $(ev.target).data('step')};
            Radio.global.trigger('do:order:step', data);
        },

        onOrderStep: function (option) {
            if (this.isValidStep(option.step)) {
                this.$('.active').removeClass('active');
                this.$('[data-step="' + option.step + '"]').parent().addClass('active');
            }
        },

        isValidStep: function (step) {
            return step >= 1 && step <= 4;
        }
    });
})();
