(function () {
    'use strict';

    var api = require('shim/api');

    module.exports = Backbone.Model.extend({
        urlRoot: api.urlRoot + '/users',
        idAttribute: '_id',
        defaults: {
            name: '',
            email: '',
            password: '',
            actual_password: '',
            new_password: '',
            confirm_password: '',
            image: 'https://i.imgur.com/FrN6054.png',
            access: ''
        },

        validation: {
            actual_password: [
                {
                    required: function (val, attr, values) {
                        return values.passChange || false;
                    },
                    msg: 'Por favor introduza a sua palavra passe'
                }
            ],
            new_password: [
                {
                    required: function (val, attr, values) {
                        return values.passChange || false;
                    },
                    msg: 'Por favor introduza a palavra passe'
                },
                {
                    minLength: 6,
                    msg: 'Por favor introduza uma senha com o minimo de 6 caracteres.'
                }
            ],
            confirm_password: [
                {
                    required: function (val, attr, values) {
                        return values.passChange || false;
                    },
                    msg: 'Por favor introduza a palavra passe'
                },
                {
                    equalTo: 'new_password',
                    msg: 'Confirmação não corresponde à nova palavra-passe'
                }
            ]
        },

        parse(response) {
            if (!response.image)
                delete response.image

            return response;
        },

        save(attrs, options) {
            options = options || {};
            this.changeBaseUrl(options);
            attrs = attrs || _.clone(this.attributes);
            var access = attrs.access;
            attrs = {
              user: {
                old_password: attrs.actual_password,
                password: attrs.new_password,
                password_confirmation: attrs.confirm_password,
                image: attrs.image,
              }
            };
            attrs.user = _.omit(attrs.user, _.isEmpty);
            attrs.user.access = access;
            options.data = JSON.stringify(attrs);
            options.contentType = 'application/json';

            return Backbone.Model.prototype.save.call(this, attrs, options)
                .always(this.removeTmpAttrs());
        },

        changeBaseUrl(options) {
            //Change Base URL if request is Image Upload
            if (this.attributes.imageChange) {
              options.url = _.result(this, 'url').replace('/users/', '/users_image/');
            }
        },

        removeTmpAttrs() {
            this.unset('password');
            this.unset('actual_password');
            this.unset('new_password');
            this.unset('confirm_password');
            this.unset('passChange');
            this.unset('imageChange');
        },

        is(roles) {
            roles = _.isArray(roles) ? roles : [roles];
            return _.contains(roles, this.get('role_slug'))
        },

        not(roles) {
            return !this.is(roles);
        },

        resetPassword() {
            let self    = this,
                url     = self.url() + '/reset',
                options = {
                    url: url,
                    type: 'PUT'
                };

            return (self.sync || Backbone.sync).call(self, null, self, options);
        },
    }
    );
})();
