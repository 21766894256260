(function () {
    'use strict';

    var Collection = require('collections/dashboards/dashboard4-collection');

    module.exports = Marionette.ItemView.extend({
        template: require('templates/dashboards/dashboard-4'),
        tagName: 'section',
        className: 'view',

        ui: {
            'helpText': '#helpText'
        },
        events:{
            'click .btn-left': '_moveLeft',
            'click .btn-right': '_moveRight'
        },

        initialize: function (options) {
            this.isAuto = options.isAuto;
            this.collection =options.collection;
            this.current_page = 0;
            this.total_pages = 1;
            this.slideTime = 60000;
            this.page_collection = new Collection();
            this._onCollectionReset();
        },

        onRender: function(){
            this._prepareModalBody();
        },

        onDestroy: function () {
            clearInterval(this.slide);
        },

        _onCollectionReset: function () {
            this.total_pages = Math.ceil(this.collection.length / 3);
            this.current_page = 1;
            this.slideTime = Math.floor(this.slideTime / this.total_pages);
            if(this.total_pages > 1 && this.isAuto == "on")
                this.slide = setInterval(this._onSlide.bind(this), this.slideTime);

            this._populatePageCollection();
        },

        _populatePageCollection: function () {
            this.page_collection = new Collection();
            for (var i = (this.current_page * 3 - 3); i < this.current_page * 3; i++) {
                if (i < this.collection.length)
                    this.page_collection.add(this.collection.models[i]);
            }
            this.render();
        },

        _onSlide: function () {
            if (this.current_page == this.total_pages)
                this.current_page = 0;
            this.current_page++;
            this._populatePageCollection();
        },

        _moveRight: function(){
            if (this.current_page+1 > this.total_pages)
                this.current_page = 1;
            else
                this.current_page++;

            this._populatePageCollection();
        },

        _moveLeft: function(){
            if (this.current_page-1 < 1)
                this.current_page = this.total_pages;
            else
                this.current_page--;

            this._populatePageCollection();
        },

        _prepareModalBody: function(){
            $('#helperModal .modal-title').text('Slide n.º 4');
            $('#helperModal .modal-slide-body').html(this.ui.helpText);
        },

        templateHelpers: function () {
            return {
                page_collection: this.page_collection.toJSON(),
                isAuto: this.isAuto,
                current_page: this.current_page,
                total_pages: this.total_pages
            }
        }
    });
})();
