var __templateData = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<header class=\"page-header view-according-device\">\n    <div class=\"bs-callout bs-callout-default\">\n        <i aria-hidden=\"true\" class=\"fa fa-envelope\"></i>\n        <h4>Notificações</h4>\n    </div>\n</header>\n\n<section class=\"content view view-according-device\"></section>\n\n<section class=\"rotate-phone\" align=\"center\" style=\"margin-top:40px\">\n  <i class=\"fa fa-repeat fa-5x\" aria-hidden=\"true\" style=\"margin-bottom:20px\"></i>\n  <p>Por favor rode o telefone</p>\n</section>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}